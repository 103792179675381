import { useQuery } from '@tanstack/react-query';
import React from 'react';

import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { LeadStatesResponse } from '../types';
import { LEAD_STATES } from './constants';

const useGetAllLeadStates = () => {
    const req = useRequest();

    const request = React.useCallback(() => {
        return req<LeadStatesResponse>({
            url: `${envVars.API_RTM_HOSTNAME}enterprise/lead-states/`
        });
    }, [req]);

    return useQuery({
        queryFn: request,
        queryKey: [LEAD_STATES]
    });
};

export default useGetAllLeadStates;
