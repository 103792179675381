import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CircularProgress from '../../../../ui-kit/components/CircularProgress';
import Loader from '../../../../ui-kit/graphics/Loader';
import QuestionIcon from '../../../../ui-kit/icons/utility/QuestionIcon';
import Container from '../../../../ui-kit/layout/Container';
import Section from '../../../../ui-kit/layout/Section';

import { PARAM_DATE, PARAM_TIMEFRAME } from '../constants/searchParams';
import { Timeframes } from '../Filters/Timeframe';
import * as Style from './style.module.scss';
import { useAdherenceContext } from '../context/AdherenceContext';
import { AdherenceSummary } from '../types';

type Props = {};

const AdherenceCircle: React.FC<Props> = () => {
    const [searchParams] = useSearchParams();
    const { getAdherenceSummary } = useAdherenceContext();

    const timeframe = searchParams.get(PARAM_TIMEFRAME) as Timeframes;
    const dateTo = searchParams.get(PARAM_DATE);

    const [fetch, setFetch] = React.useState<{
        isLoading: boolean;
        data: AdherenceSummary | undefined;
    }>({
        isLoading: false,
        data: undefined
    });

    const { isLoading, data } = fetch;

    React.useEffect(() => {
        if (!!timeframe && !!dateTo) {
            setFetch({ isLoading: true, data: undefined });
            getAdherenceSummary({ mode: timeframe, target_datetime: `${dateTo} 00:00:00` })
                .then((response) => setFetch({ isLoading: false, data: response }))
                .catch(() => setFetch({ isLoading: false, data: undefined }));
        }
    }, [timeframe, dateTo]);

    if (isLoading) {
        return (
            <Section centered styles={{ height: '100%' }}>
                <Loader />
            </Section>
        );
    }

    if (!data) {
        return <p>No data</p>;
    }

    const pillsTaken = data.positive;
    const pillsMissed = data.negative + data.unknown;
    const dosesTaken = data.positive_doses;
    const dosesMissed = data.negative_doses + data.unknown_doses;
    const percentTaken = Math.round((pillsTaken * 100) / (pillsMissed + pillsTaken)) || 0;

    return (
        <div className={Style.wrapper}>
            <Section centered noDefaultPadding marginBottom="regular" className={Style.progress}>
                <CircularProgress size={210} progress={[{ percent: percentTaken }]} border />
                <div>
                    <h2>
                        {percentTaken}
                        <sup>%</sup>
                    </h2>
                    <small>
                        {pillsTaken} of {pillsTaken + pillsMissed} reported
                    </small>
                </div>
            </Section>

            <Container gridTemplateColumns={'1fr 1fr'} gridColumnGap="regular">
                <Section centered noDefaultPadding>
                    <h5>{dosesTaken}</h5>
                    <small>Dose taken</small>
                </Section>
                <Section centered noDefaultPadding>
                    <h5>{dosesMissed}</h5>
                    <small>Skipped / Missed</small>
                </Section>
            </Container>

            <div className={Style.tooltip}>
                <QuestionIcon size="utility" />

                <div className={Style.tooltipDetails}>
                    Adherence % is calculated by pills from reported doses, and affected by partial
                    doses taken.
                </div>
            </div>
        </div>
    );
};
export default AdherenceCircle;
