// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l4i6ODfM5GCQ1EbAO5Zw{display:flex;align-items:center;justify-content:center;position:relative;margin:auto}.NUxYLnUgUAxEeaRP1UzK{position:absolute;top:0;left:0;width:100%;height:100%;transform:rotateY(-180deg) rotateZ(-90deg);overflow:visible}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/PatientActivity/components/CircleCountdown/style.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,WAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,0CAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
export var countdownContainer = `l4i6ODfM5GCQ1EbAO5Zw`;
export var svg = `NUxYLnUgUAxEeaRP1UzK`;
export default ___CSS_LOADER_EXPORT___;
