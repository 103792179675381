import { useMutation } from '@tanstack/react-query';

import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { LeadQueryParams, LeadDataResponse } from '../types';
import { LEAD_LIST_KEY } from './constants';

const useGetLeadsList = () => {
    const req = useRequest();

    const request = ({ payload }: { payload: LeadQueryParams }) => {
        return req<LeadDataResponse>({
            url: `${envVars.API_RTM_HOSTNAME}enterprise/leads/`,
            method: 'POST',
            body: JSON.stringify(payload)
        });
    };
    return useMutation({
        mutationFn: request,
        mutationKey: [LEAD_LIST_KEY]
    });
};

export default useGetLeadsList;
