// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes dzCgpdlHLavoKwP4LtED{0%{opacity:0;transform:translateX(10rem)}100%{opacity:1}}@keyframes iV7XrM7_YEAllQnWVo4g{0%{opacity:1}100%{opacity:0;transform:translateX(10rem)}}.OaGmGx1raFFKQJzUatTq{position:fixed;width:36rem;height:100vh;top:0;right:2rem;background:var(--color-neutrals-background);border-left:1px solid var(--color-neutrals-borderAlpha);box-shadow:-15px 0px 15px 0px rgba(67,67,67,.1);animation:iV7XrM7_YEAllQnWVo4g ease-in-out .3s 1 forwards;transition:right .25s cubic-bezier(0.4, 0, 0.3, 1) 0s}.OaGmGx1raFFKQJzUatTq:last-child{right:0}.vc4PzpgPdoOnbqqahY6l{animation:dzCgpdlHLavoKwP4LtED ease-in-out .3s 1 forwards}.qlNelCvuMIb5celuNjwX{position:absolute;top:3rem;right:2.4rem;cursor:pointer;z-index:10}.DdZHUFZ4olkJln1PZXt7{position:absolute;right:0;top:0;width:100%;height:100%;padding:2.4rem 1.2rem;overflow:auto}`, "",{"version":3,"sources":["webpack://./../hero-frontend-packages/packages/enterprise-portal-common/src/Adherence/SideBar/style.module.scss"],"names":[],"mappings":"AAUA,gCACI,GALA,SAAA,CACA,2BAAA,CAOA,KAZA,SAAA,CAAA,CAiBJ,gCACI,GAlBA,SAAA,CAqBA,KAjBA,SAAA,CACA,2BAAA,CAAA,CAqBJ,sBACI,cAAA,CACA,WAAA,CACA,YAAA,CACA,KAAA,CACA,UAAA,CACA,2CAAA,CACA,uDAAA,CACA,+CAAA,CACA,yDAAA,CACA,qDAAA,CAEA,iCACI,OAAA,CAIR,sBACI,yDAAA,CAGJ,sBACI,iBAAA,CACA,QAAA,CACA,YAAA,CACA,cAAA,CACA,UAAA,CAGJ,sBACI,iBAAA,CACA,OAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
export var sidebar = `OaGmGx1raFFKQJzUatTq`;
export var fadeOut = `iV7XrM7_YEAllQnWVo4g`;
export var isVisible = `vc4PzpgPdoOnbqqahY6l`;
export var fadeIn = `dzCgpdlHLavoKwP4LtED`;
export var closeIcon = `qlNelCvuMIb5celuNjwX`;
export var content = `DdZHUFZ4olkJln1PZXt7`;
export default ___CSS_LOADER_EXPORT___;
