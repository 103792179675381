import React from 'react';
import AlertIcon from '@hero/ui-kit/icons/utility/AlertIcon';
import Divider from '@hero/ui-kit/components/Divider';
import useTheme from '@hero/branding/theme';
import * as Style from './DeviceFilter.module.scss';
import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import { useSearchParams } from 'react-router-dom';
import { OrganizationDeviceStatistics } from '../../types';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import StatusCircle from '@hero/ui-kit/graphics/StatusCircle';
import Circle from '@hero/ui-kit/graphics/Circle';
import Filter from '../../../../components/Filter';

type FilterItemProps = {
    showAlertIcon?: boolean;
    filter: string;
    label: string;
    isActive?: boolean;
    disabled?: boolean;
    onChange?: (filter: string) => void;
};

const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const FilterItem: React.FC<FilterItemProps> = ({
    showAlertIcon = false,
    filter,
    label,
    isActive = false,
    disabled = false,
    onChange
}) => {
    const handleClick = React.useCallback(() => {
        onChange && onChange(filter);
    }, [onChange, filter]);

    return (
        <ClickableOpacity
            fullWidth
            alt={`Toggle ${label}`}
            className={Style.filterItem}
            onClick={handleClick}
            disabled={disabled}
        >
            <div className="icon-title-right">
                {isActive ? (
                    <StatusCircle status="success" size="small" />
                ) : (
                    <Circle disabled inverted size="small" />
                )}
                <span>{label}</span>
            </div>

            {showAlertIcon ? <AlertIcon width={20} height={16} /> : null}
        </ClickableOpacity>
    );
};

const DeviceFilter: React.FC<{
    deviceStatistics?: OrganizationDeviceStatistics;
    isDeviceStatisticsLoading?: boolean;
}> = ({ deviceStatistics, isDeviceStatisticsLoading = false }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();

    const handleReset = React.useCallback(() => {
        setSearchParams((prevState) => {
            prevState.delete('online_status');
            prevState.delete('device_status');
            return prevState;
        });
    }, [setSearchParams]);

    const filterLabels = React.useMemo(() => {
        const search = searchParams.get('search');
        const showStatistics = (!search || search?.length === 0) && !isDeviceStatisticsLoading;

        const onlineLabel = showStatistics
            ? `Online (${deviceStatistics?.online_count || 0})`
            : 'Online';

        const offlineLabel = showStatistics
            ? `Offline (${deviceStatistics?.offline_count || 0})`
            : 'Offline';

        const orderedLabel = showStatistics
            ? `Ordered (${deviceStatistics?.ordered_count || 0})`
            : 'Ordered';

        const deliveredLabel = showStatistics
            ? `Delivered (${deviceStatistics?.delivered_count || 0})`
            : 'Delivered';

        const setupNeededLabel = showStatistics
            ? `Setup Needed (${deviceStatistics?.setup_needed_count || 0})`
            : 'Setup Needed';

        return {
            onlineLabel,
            offlineLabel,
            orderedLabel,
            deliveredLabel,
            setupNeededLabel
        };
    }, [searchParams, deviceStatistics, isDeviceStatisticsLoading]);

    const handleFilterSet = React.useCallback(
        (filterName: string, filter: string) => {
            setSearchParams((prevState) => {
                prevState.get(filterName) === filter
                    ? prevState.delete(filterName)
                    : prevState.set(filterName, filter);
                prevState.delete('search');
                prevState.delete('limit');
                prevState.delete('offset');
                return prevState;
            });
        },
        [setSearchParams]
    );

    const handleOnlineStatusChange = React.useCallback(
        (filter: string) => {
            handleFilterSet('online_status', filter);
        },
        [handleFilterSet]
    );

    const handleDeviceStatusChange = React.useCallback(
        (filter: string) => {
            handleFilterSet('device_status', filter);
        },
        [handleFilterSet]
    );

    const onlineStatus = searchParams.get('online_status');
    const deviceStatus = searchParams.get('device_status');

    const isDisabledStatus = React.useMemo(() => {
        return {
            online: deviceStatus === 'ordered' || deviceStatistics?.online_count === 0,
            offline: deviceStatistics?.offline_count === 0,
            ordered: onlineStatus === 'online' || deviceStatistics?.ordered_count === 0,
            delivered: deviceStatistics?.delivered_count === 0,
            setupNeeded: deviceStatistics?.setup_needed_count === 0
        };
    }, [deviceStatistics, onlineStatus, deviceStatus]);

    const filterLabel = React.useMemo(() => {
        if (deviceStatus || onlineStatus) {
            return `${capitalize(onlineStatus || '')}${
                onlineStatus && deviceStatus ? ' & ' : ''
            }${capitalize(deviceStatus || '')}`;
        }

        return 'Status';
    }, [onlineStatus, deviceStatus]);

    return (
        <Filter filterLabel={filterLabel} width="28rem">
            <>
                <FilterItem
                    label={filterLabels.onlineLabel}
                    filter={'online'}
                    onChange={handleOnlineStatusChange}
                    isActive={onlineStatus === 'online'}
                    disabled={isDisabledStatus.online}
                />
                <FilterItem
                    label={filterLabels.offlineLabel}
                    filter={'offline'}
                    showAlertIcon
                    onChange={handleOnlineStatusChange}
                    isActive={onlineStatus === 'offline'}
                    disabled={isDisabledStatus.offline}
                />
                <Divider
                    noDefaultMargin
                    styles={{
                        borderTopWidth: '0.1rem',
                        borderTopColor: theme.colors.neutrals.borderBeta,
                        marginBottom: '2.4rem',
                        marginLeft: '1.6rem',
                        marginRight: '1.6rem'
                    }}
                />
                <FilterItem
                    label={filterLabels.orderedLabel}
                    filter={'ordered'}
                    onChange={handleDeviceStatusChange}
                    isActive={deviceStatus === 'ordered'}
                    disabled={isDisabledStatus.ordered}
                />
                <FilterItem
                    label={filterLabels.deliveredLabel}
                    filter={'delivered'}
                    onChange={handleDeviceStatusChange}
                    isActive={deviceStatus === 'delivered'}
                    disabled={isDisabledStatus.delivered}
                />
                <FilterItem
                    label={filterLabels.setupNeededLabel}
                    filter={'setup_needed'}
                    onChange={handleDeviceStatusChange}
                    isActive={deviceStatus === 'setup_needed'}
                    showAlertIcon
                    disabled={isDisabledStatus.setupNeeded}
                />
                <Divider
                    noDefaultMargin
                    styles={{
                        borderTopWidth: '0.1rem',
                        borderTopColor: theme.colors.neutrals.borderBeta,
                        marginBottom: '0',
                        marginLeft: '1.6rem',
                        marginRight: '1.6rem'
                    }}
                />
                <Section centered noDefaultPadding>
                    <Button
                        variant="primary"
                        noDefaultMargin
                        className={Style.resetBtn}
                        onClick={handleReset}
                    >
                        Reset Filter
                    </Button>
                </Section>
            </>
        </Filter>
    );
};

export default DeviceFilter;
