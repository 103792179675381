import Section from '@hero/ui-kit/layout/Section';
import React from 'react';
import SearchForm, { SearchProps } from '../SearchForm';
import MenuNavbar from './MenuNavbar';
import H from '@hero/ui-kit/typography/H';
import ProviderDetails from './ProviderDetails';
import envVars from '../../constants/envVars';
import { useUserStatusContext } from '../../context/UserStatus';

export type HeaderProps = {
    additionalElement?: React.ReactNode;
    title?: string;
    search?: boolean;
} & SearchProps;

const Header: React.FC<HeaderProps> = ({
    additionalElement,
    search,
    searchLabel,
    placeholder,
    searchName,
    removeQueryParamsOnSearch,
    title
}) => {
    const { userStatus } = useUserStatusContext();
    return (
        <>
            <Section
                paddingTop="small"
                styles={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <MenuNavbar />
                {envVars.TEST_MODE && userStatus?.hero_organization_id ? (
                    <ProviderDetails herOrgId={userStatus.hero_organization_id} />
                ) : null}
            </Section>
            {title || search || additionalElement ? (
                <Section
                    paddingTop="small"
                    styles={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    {title && (
                        <H noDefaultMargin role="h4">
                            {title}
                        </H>
                    )}
                    <Section
                        noDefaultPadding
                        styles={{ height: '5.2rem', display: 'flex', alignItems: 'center' }}
                    >
                        {search && (
                            <SearchForm
                                searchLabel={searchLabel}
                                placeholder={placeholder}
                                searchName={searchName}
                                removeQueryParamsOnSearch={removeQueryParamsOnSearch}
                            />
                        )}

                        <div className="flex-center">{additionalElement}</div>
                    </Section>
                </Section>
            ) : null}
        </>
    );
};

export default Header;
