import { useSearchParams } from 'react-router-dom';
import { PARAM_SIDEBAR } from '../constants/searchParams';
import { URLSearchParams } from 'url';

export const getSidebarParam = (searchParams: URLSearchParams) => {
    let sidebars = searchParams.get(PARAM_SIDEBAR);
    return sidebars ? sidebars.split(',') : [];
};

export const setSidebarParam = (
    searchParams: URLSearchParams,
    sidebars: string[]
): URLSearchParams => {
    if (sidebars.length > 0) {
        searchParams.set(PARAM_SIDEBAR, sidebars.join(','));
    } else {
        searchParams.delete(PARAM_SIDEBAR);
    }

    return searchParams;
};

export const useActiveSidebars = () => {
    const [searchParams] = useSearchParams();
    return getSidebarParam(searchParams);
};
