import React from 'react';

import Layout from '../../components/Layout';
import NoResults from '../../components/States/NoResults';

import CheckboxSquare from '@hero/ui-kit/graphics/CheckboxSquare';
import ReportsTable from './ReportsTable';
import useGetReportsList from './api/useGetReportsList';
import ChoiceLabel from '@hero/ui-kit/typography/ChoiceLabel';
import Button from '@hero/ui-kit/inputs/Button';
import DownloadIcon from '@hero/ui-kit/icons/utility/DownloadIcon';
import useReportsQueryParams from './useReportsQueryParams';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import useDownloadReport from './api/useDownloadReport';
import * as Style from './style.module.scss';
import downloadFile from '../../utils/downloadFile';
import DateFilter from '../../components/DateFilter';
import LimitItems from '../../components/LimitItems';

const ReportsManagement: React.FC = () => {
    const query = useReportsQueryParams();
    const { limit, offset } = query;
    const [fetchAll, setFetchAll] = React.useState(false);
    const [reportDownloadNames, setReportDownloadNames] = React.useState<string[]>([]);
    const { data: downloadReports } = useDownloadReport(
        { report_names: reportDownloadNames.join(',') },
        reportDownloadNames.length > 0 && fetchAll
    );
    const { isLoading, data } = useGetReportsList(query);

    React.useEffect(() => {
        if (downloadReports?.length && fetchAll) {
            downloadReports.forEach((report) => {
                downloadFile(report.report_url, report.report_name);
            });
            setFetchAll(false);
        }
    }, [downloadReports, fetchAll]);

    const handleReportCheck = (reportName: string) => {
        setReportDownloadNames((prevState) => {
            const find = prevState.find((state) => state === reportName);

            return find
                ? prevState.filter((state) => state !== reportName)
                : [...prevState, reportName];
        });
    };

    const handleSelectAll = () => {
        setReportDownloadNames((prevState) => {
            return data?.reports.length && prevState.length !== data?.reports.length
                ? data.reports.map((report) => report.name)
                : [];
        });
    };

    const handleDownloadAll = () => {
        setFetchAll(true);
    };

    return (
        <>
            <Layout
                title="Device Activity Reports"
                search
                placeholder="Enter User Name, ID or Email"
                searchLabel="Search report"
            >
                <>
                    <div className={Style.filter}>
                        <LimitItems
                            isLoading={isLoading}
                            limit={limit ? +limit : 10}
                            offset={offset ? +offset : 0}
                            total_count={data?.total_count}
                        />
                        <DateFilter
                            searchParam="cycle_end"
                            defaultLabel="Cycle end"
                            disableFilter={['today', 'yesterday']}
                        />
                        <DateFilter searchParam="created" defaultLabel="Created" />
                    </div>
                    <div className={Style.downloadAllSection}>
                        <div className="flex-center">
                            {data?.reports.length ? (
                                <ClickableOpacity onClick={handleSelectAll} alt="Select All">
                                    <div className="flex-center">
                                        {data?.reports.length === reportDownloadNames.length ? (
                                            <div className={Style.deselectCheckbox}>
                                                <div />
                                            </div>
                                        ) : (
                                            <CheckboxSquare
                                                isChecked={false}
                                                isFocused={false}
                                                isHovered={false}
                                                disabled={false}
                                                styles={{ height: '3rem', width: '3rem' }}
                                            />
                                        )}

                                        <ChoiceLabel htmlFor={''} styles={{ minWidth: '75px' }}>
                                            {data?.reports.length === reportDownloadNames.length
                                                ? 'Deselect'
                                                : 'Sellect all'}
                                        </ChoiceLabel>
                                    </div>
                                </ClickableOpacity>
                            ) : null}

                            {reportDownloadNames.length !== 0 ? (
                                <Button
                                    size="small"
                                    width="natural"
                                    variant="primary"
                                    onClick={handleDownloadAll}
                                    disabled={reportDownloadNames.length === 0}
                                    styles={{
                                        height: '4rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '1rem',
                                        fontSize: '1.6rem'
                                    }}
                                    noDefaultMargin
                                >
                                    <DownloadIcon
                                        size="small"
                                        width={20}
                                        height={20}
                                        type="inverted"
                                    />
                                    Download
                                </Button>
                            ) : null}
                        </div>
                    </div>
                </>

                {data?.reports.length === 0 ? (
                    <NoResults
                        search={query.search || ''}
                        hideSearchLabel={!query.search}
                        searchSupportsLabel="User Name, ID or Email"
                    />
                ) : (
                    <ReportsTable
                        reports={data?.reports}
                        pagerData={{
                            limit: query.limit,
                            total_count: data?.total_count,
                            offset: data?.offset
                        }}
                        isLoading={isLoading}
                        onReportCheck={handleReportCheck}
                        reportsChecked={reportDownloadNames}
                    />
                )}
            </Layout>
        </>
    );
};

export default ReportsManagement;
