import { useEffect } from 'react';

type UseKeyDownEventProps = (handleKeyDown: (event: KeyboardEvent) => void) => void;

const useKeyDownEvent: UseKeyDownEventProps = (handleKeyDown) => {
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
};

export default useKeyDownEvent;
