import { format, add } from 'date-fns';
import { MedicationSchedule, MedicationDetails } from '../../../../../pages/FleetManagement/types';
import getPreparedMedications, { everyDayLabel } from './getPreparedMedications';

const count = (acc: number, item: MedicationSchedule) => {
    const qty = item.qty || 0;
    return acc + qty;
};

const calculateDowPillDayTake = (
    dowListGrouped: {
        [key: string]: MedicationSchedule[];
    },
    date: Date
) => {
    const dayOfWeek = format(date, 'EEE'); //'Mon, Wed, Sat',

    let dowAmount = 0;

    Object.entries(dowListGrouped).forEach((entry) => {
        const schedules = entry[1];
        const label = entry[0];

        if (label === everyDayLabel) {
            dowAmount = schedules.reduce(count, 0);
        }

        const noWhitespaceDaysOfWeek = label.replace(/\s/g, '');
        const splittedDaysOfWeek = noWhitespaceDaysOfWeek.split(',').filter(Boolean); // ['Mon', 'Tue', 'Fri'...]

        if (splittedDaysOfWeek.includes(dayOfWeek)) {
            dowAmount = schedules.reduce(count, 0);
        }
    });

    return dowAmount;
};

const calculateEveryXDaysPillDayTake = (
    everyXDaysList: {
        [key: string]: MedicationSchedule[];
    },
    date: Date,
    takenDate?: Date
) => {
    const dayOfYear = format(date, 'd'); // 1,2, ...365, 366 day
    let everyXDaysAmount = 0;
    Object.entries(everyXDaysList).forEach((entry) => {
        const schedules = entry[1];
        const label = entry[0];
        const xDays = +label;

        schedules.forEach((schedule) => {
            const nextDate = schedule?.next_date || undefined;
            const qty = schedule?.qty || 0;
            if (nextDate && !takenDate && dayOfYear === format(new Date(nextDate), 'd')) {
                everyXDaysAmount = everyXDaysAmount + qty;
            }
            if (takenDate) {
                const newTakenDate = add(takenDate, { days: xDays });
                if (dayOfYear === format(newTakenDate, 'd')) {
                    everyXDaysAmount = everyXDaysAmount + qty;
                }
            }
        });
    });

    return everyXDaysAmount;
};

const getPillLeftDays = (medication: MedicationDetails): number | undefined => {
    if (
        medication.exact_pill_count === null ||
        medication.pill_schedules === null ||
        medication.pill_schedules.length === 0
    ) {
        return undefined;
    }

    const preparedMedications = getPreparedMedications(medication);
    const currentDate = new Date();

    let pillCount = medication.exact_pill_count;
    let days = 0;
    let everyXDaysTakenDate = undefined;
    let takenDays = 0;

    while (pillCount > 0) {
        const nextDate = days > 0 ? add(currentDate, { days }) : currentDate;
        const takenDowDayPillQuantity = calculateDowPillDayTake(
            preparedMedications.dowListGrouped,
            nextDate
        );
        const takenEveryXDaysDayPillQuantity = calculateEveryXDaysPillDayTake(
            preparedMedications.everyXDaysList,
            nextDate,
            everyXDaysTakenDate
        );
        if (takenEveryXDaysDayPillQuantity > 0) {
            everyXDaysTakenDate = nextDate;
        }
        const totalDayAmount = takenDowDayPillQuantity + takenEveryXDaysDayPillQuantity;

        pillCount = pillCount - totalDayAmount;

        if (totalDayAmount > 0 && pillCount >= 0) {
            takenDays++;
        }

        days++;
    }

    return takenDays;
};

export default getPillLeftDays;
