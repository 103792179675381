import { useQuery } from '@tanstack/react-query';
import React from 'react';

import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { LeadStateResponse } from '../types';
import { LEAD_STATE } from './constants';

const useGetLeadState = (lead_id: string) => {
    const req = useRequest();

    const request = React.useCallback(() => {
        return req<LeadStateResponse>({
            url: `${envVars.API_RTM_HOSTNAME}enterprise/current-lead-state/`,
            query: { lead_id }
        });
    }, [req, lead_id]);

    return useQuery({
        queryFn: request,
        queryKey: [LEAD_STATE, lead_id]
    });
};

export default useGetLeadState;
