import { MemberRole } from '../../types';

const getMemberRoleLabel = (member_role?: MemberRole | null) => {
    if (member_role === 'CAREGIVER_ADMIN') {
        return 'Admin';
    }
    if (member_role === 'CAREGIVER_READ_ONLY') {
        return 'Read Only';
    }
    if (member_role === 'PRIMARY_USER_READ_ONLY') {
        return 'Primary User - Read Only';
    }
    if (member_role === 'PRIMARY_USER_ADMIN') {
        return 'Primary User - Admin';
    }
    return 'N/A';
};

export default getMemberRoleLabel;
