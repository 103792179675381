import { AllApprovalStatusSubStates, LeadStatesResponse } from '../types';

const getApprovalSubStatusesByStatus = (
    allLeadStates?: LeadStatesResponse,
    approvalStatus?: string
) => {
    if (!allLeadStates || !approvalStatus) {
        return undefined;
    }

    let statuses: AllApprovalStatusSubStates[] = [];
    for (const [status, subStatuses] of Object.entries(allLeadStates)) {
        const preparedStatus = status.toUpperCase();
        const preparedSubStatuses = subStatuses.map((status) =>
            status.toUpperCase()
        ) as AllApprovalStatusSubStates[];
        if (preparedStatus.includes(approvalStatus)) {
            statuses = [...statuses, ...preparedSubStatuses];
        }
    }

    return statuses;
};

export default getApprovalSubStatusesByStatus;
