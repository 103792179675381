import Section from '@hero/ui-kit/layout/Section';
import React from 'react';
import { MedicationDetails } from '../../../../FleetManagement/types';
import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import P from '@hero/ui-kit/typography/P';
import Container from '@hero/ui-kit/layout/Container';
import ScheduleDetails from '../../../../FleetManagement/OrganizationMemberDetails/MedDetails/ScheduleDetails';

interface HeroAssessmentMedListProps {
    med: MedicationDetails;
    styles?: {};
    isLastElement?: boolean;
}

const HeroAssessmentMedList: React.FC<HeroAssessmentMedListProps> = ({
    med,
    styles = {},
    isLastElement = false
}) => {
    const isMinL = useIsMinBreakpoint('l');
    // const [show, setShow] = React.useState(true);
    const show = true;

    const isMedAvailable = React.useMemo(() => {
        return med.name !== null;
    }, [med]);

    const allowAsNeededText = React.useMemo(() => {
        const mainLabel = med?.max_doses ? `Max ${med.max_doses} pills/day` : 'Not Allowed';
        const maxPillsPerDispense = med?.max_pills_per_dispense;
        const minTimeBetweenDispenses = med?.min_time_between_dispenses;
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <P noDefaultMargin strong={isMedAvailable}>
                    {isMedAvailable ? mainLabel : ''}
                </P>
                {show ? (
                    <>
                        {maxPillsPerDispense ? (
                            <P noDefaultMargin>{`Up to ${maxPillsPerDispense} pills / dispense`}</P>
                        ) : null}
                        {minTimeBetweenDispenses ? (
                            <P noDefaultMargin>{`Min ${minTimeBetweenDispenses} hrs apart`}</P>
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    }, [isMedAvailable, show, med]);

    return (
        <div
            role="button"
            tabIndex={0}
            style={{
                outline: 'none',
                padding: '2rem 0',
                ...(isLastElement && {
                    borderBottomLeftRadius: '2rem',
                    borderBottomRightRadius: '2rem'
                })
            }}
        >
            <Section noDefaultPadding paddingHorizontal="small">
                <Container
                    gridTemplateColumns={isMinL ? '0.6fr 4.4fr 5fr 2fr' : '1fr'}
                    styles={{ ...styles }}
                >
                    <P noDefaultMargin>{med.slot || 'N/A'}</P>
                    <P noDefaultMargin>{med.name || 'N/A'}</P>

                    {med.pill_schedules?.length ? (
                        <ScheduleDetails medication={med} show={show} />
                    ) : (
                        <P noDefaultMargin strong={isMedAvailable}>
                            {isMedAvailable ? 'No schedule' : ''}
                        </P>
                    )}
                    {allowAsNeededText}
                </Container>
            </Section>
        </div>
    );
};

export default HeroAssessmentMedList;
