import P from '@hero/ui-kit/typography/P';
import React from 'react';
import Layout from '../../../../components/Layout';
import * as Style from './style.module.scss';
import getApprovalStatus from '../../utils/getApprovalStatus';
import { useParams } from 'react-router-dom';
import useGetLeadState from '../../api/useGetLeadState';
import useGetLeadDetails from '../../api/useGetLeadDetails';
import { ApprovalStatus } from '../../types';
import Loading from '../../../../components/States/Loading';
import AlertIcon from '@hero/ui-kit/icons/utility/AlertIcon';
import { useUserStatusContext } from '../../../../context/UserStatus';
import useExtendLockUnlockLead from '../../hooks/useExtendLockUnlockLead';
import LeadActivities from '../LeadActivities';
import HealthAssessment from '../HealthAssessment';
import Section from '@hero/ui-kit/layout/Section';
import General from './components/General';
import CalendarIcon from '@hero/ui-kit/icons/utility/CalendarIcon';
import Pill from '../../components/Pill';
import VerifiedIcon from '@hero/ui-kit/icons/utility/VerifiedIcon';
import LeadContactDetails from '../LeadContactDetails';
import LeadDetailsProvider, { useLeadDetailsContext } from './context/LeadDetailsContext';
import useBack from '../../../../hooks/useBack';
import BackButton from '../../../../components/BackButton';
import getShouldShowExpiringAlert from '../../utils/getShouldShowExpiringAlert';
import ExpiringAlert from '../../components/ExpiringAlert';

const getLabel = (label?: string | null) => {
    return label || '-';
};

const DetailsExpiringAlert: React.FC<{ days?: number; hours?: number; show?: boolean }> = ({
    days,
    hours,
    show
}) => {
    const daysText = days && days > 0 ? `${days} days!` : `${hours} hours!`;

    return show ? (
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
            <AlertIcon size="utility" width={20} />

            <P noDefaultMargin strong>{`Application expires in ${daysText}`}</P>
        </div>
    ) : null;
};

const LeadDetailsV2Inner: React.FC<{ id: string }> = ({ id }) => {
    const { isFormChanged, tab, handleChangeTab, handlePrepareChangeTab } = useLeadDetailsContext();

    const back = useBack();

    const { userStatus, permissions } = useUserStatusContext();
    const {
        data: lead,
        refetch: refetchLeadDetails,
        isLoading: isGetLeadDetailsLoading
    } = useGetLeadDetails(id);

    const {
        data: leadState,
        refetch: refetchLeadState,
        isLoading: isGetLeadStateLoading
    } = useGetLeadState(id);

    const isLocked = !!lead?.claimed_by_id && lead?.claimed_by_id !== userStatus?.hero_user_id;

    useExtendLockUnlockLead(
        lead?.lock_expires || undefined,
        id,
        lead?.claimed_by_id === userStatus?.hero_user_id,
        permissions.leads.leadListEdit
    );

    const canEdit = isLocked ? false : permissions.leads.leadListEdit;

    const showAlert = getShouldShowExpiringAlert({
        isLocked,
        previous_member_id: lead?.primary_user_details.previous_member_id,
        approval_status: lead?.status,
        approval_sub_status: lead?.sub_status
    });

    const approvalStatus = getApprovalStatus(lead?.status.toUpperCase() as ApprovalStatus);

    const tabs = [
        {
            label: 'Demographics',
            value: 'general',
            show: true
        },
        {
            label: 'Health Assessment',
            value: 'health_assessment',
            show: true
        },
        {
            label: 'Activities',
            value: 'activities',
            show: true
        }
    ];

    const handleBack = () => {
        back('/leads');
    };

    const handleSetTab = (tabItemValue: string) => {
        if (!isFormChanged) {
            handleChangeTab(tabItemValue);
        } else {
            handlePrepareChangeTab(tabItemValue);
        }
    };

    return (
        <>
            {isLocked ? (
                <div className={Style.lockedAlertWrapper}>
                    <div className={Style.lockedAlert}>
                        <AlertIcon type="utility" width={22} height={20} />
                        <P noDefaultMargin strong>
                            Another agent is working with the patient currently
                        </P>
                    </div>
                </div>
            ) : null}
            <Layout paddingHorizontal="none">
                {isGetLeadDetailsLoading || isGetLeadStateLoading ? (
                    <Loading />
                ) : (
                    <Section
                        styles={{
                            width: '100%'
                        }}
                        paddingTop="none"
                        paddingBottom="none"
                        paddingHorizontal="none"
                    >
                        <Section noDefaultPadding paddingHorizontal="regular">
                            <BackButton onBack={handleBack} alt="back to leads" />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: '3.6rem',
                                        marginTop: '2.4rem',
                                        marginBottom: '1.2rem'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            columnGap: '1.2rem'
                                        }}
                                    >
                                        {lead?.is_existing_member ? (
                                            <VerifiedIcon type="utility" width={36} height={36} />
                                        ) : null}
                                        <P
                                            strong
                                            noDefaultMargin
                                            styles={{ fontSize: '3.2rem', lineHeight: '4rem' }}
                                        >
                                            {lead?.primary_user_details?.first_name &&
                                            lead?.primary_user_details?.last_name
                                                ? `${lead.primary_user_details.first_name} ${lead.primary_user_details.last_name}`
                                                : `-`}
                                        </P>
                                    </div>

                                    <Pill
                                        label={approvalStatus.label}
                                        className={approvalStatus.className}
                                    />
                                </div>
                                {showAlert && lead?.primary_user_details.created_date ? (
                                    <ExpiringAlert
                                        createdAt={lead?.primary_user_details.created_date}
                                        reactivatedAt={lead.primary_user_details.reactivated_date}
                                    >
                                        <DetailsExpiringAlert />
                                    </ExpiringAlert>
                                ) : null}
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    columnGap: '1rem',
                                    marginBottom: '2.4rem'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: '1rem'
                                    }}
                                >
                                    <CalendarIcon type="utility" width={20} height={20} />
                                    <P noDefaultMargin size="small">
                                        {getLabel(lead?.primary_user_details?.created_date)}
                                    </P>
                                </div>
                                <P
                                    noDefaultMargin
                                    styles={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden'
                                    }}
                                    title={getLabel(lead?.primary_user_details?.lead_id)}
                                >{`Lead ID: ${getLabel(lead?.primary_user_details?.lead_id)}`}</P>
                            </div>

                            <div className={Style.nav}>
                                {tabs
                                    .filter((tabItem) => tabItem.show)
                                    .map((tabItem) => (
                                        <button
                                            className={tabItem.value === tab ? Style.active : ''}
                                            key={tabItem.label}
                                            onClick={() => handleSetTab(tabItem.value)}
                                        >
                                            {tabItem.label}
                                        </button>
                                    ))}
                            </div>
                        </Section>
                        {tab === 'general' ? <General lead={lead} canEdit={canEdit} /> : null}
                        {tab === 'activities' ? (
                            <div
                                style={{
                                    padding: '3.6rem 3.6rem',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    gap: '2.6rem'
                                }}
                            >
                                <LeadContactDetails
                                    hideNameSection
                                    approvalStatus={approvalStatus}
                                    lead={lead}
                                    refetchLeadDetails={refetchLeadDetails}
                                    isLocked={isLocked}
                                    hideEdit
                                />
                                <Section
                                    border
                                    styles={{
                                        width: '100%'
                                    }}
                                    paddingTop="small"
                                    paddingBottom="none"
                                    paddingHorizontal="regular"
                                    borderBottom={false}
                                >
                                    <LeadActivities
                                        leadId={id}
                                        refetchLeadDetails={refetchLeadDetails}
                                        refetchLeadState={refetchLeadState}
                                        lead={lead}
                                        leadState={leadState}
                                        isLocked={isLocked}
                                        canEdit={permissions.leads.leadListEdit}
                                    />
                                </Section>
                            </div>
                        ) : null}
                        {tab === 'health_assessment' ? (
                            <div style={{ padding: '3.6rem 3.6rem' }}>
                                <HealthAssessment lead={lead} />
                            </div>
                        ) : null}
                    </Section>
                )}
            </Layout>
        </>
    );
};

const LeadDetailsV2: React.FC = () => {
    const { id = '' } = useParams<Required<{ id: string }>>();

    return (
        <LeadDetailsProvider>
            <LeadDetailsV2Inner id={id} />
        </LeadDetailsProvider>
    );
};

export default LeadDetailsV2;
