import CaretIcon from '@hero/ui-kit/icons/utility/CaretIcon';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import P from '@hero/ui-kit/typography/P';
import * as React from 'react';

interface BackButtonProps {
    onBack: () => void;
    alt: string;
}

const BackButton: React.FC<BackButtonProps> = ({ onBack, alt }) => {
    return (
        <ClickableOpacity onClick={onBack} alt={alt}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.6rem'
                }}
            >
                <CaretIcon
                    direction="backward"
                    width={20}
                    height={20}
                    styles={{ minWidth: '22px' }}
                />
                <P noDefaultMargin strong styles={{ fontSize: '1.8rem' }}>
                    Back
                </P>
            </div>
        </ClickableOpacity>
    );
};

export default BackButton;
