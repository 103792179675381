// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lc9iWc34IX0UWF1ZRdiW{display:flex;align-items:center;justify-content:space-between}.mAFGICo1_uXXnlgO6Aet{display:flex;align-items:center;column-gap:1.4rem}.kIFb1dFARhPIxGQossKV{width:100%;border-radius:2rem;margin-bottom:2rem}.ZaD_DhGJOv_aKk49H8rl{margin-bottom:3.6rem !important;height:160px}.mDmHK2aAGVbNsSyhgAkg{margin-top:2.4rem;margin-bottom:3.6rem}.YoZdvH8VafhZne1h1Lnf{margin-top:3.6rem;display:flex;align-items:center;justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/PatientActivity/components/PatientActivityDetailsModal/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,sBACI,UAAA,CACA,kBAAA,CACA,kBAAA,CAGJ,sBACI,+BAAA,CACA,YAAA,CAGJ,sBACI,iBAAA,CACA,oBAAA,CAGJ,sBACI,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
export var flexBetween = `lc9iWc34IX0UWF1ZRdiW`;
export var flexAlign = `mAFGICo1_uXXnlgO6Aet`;
export var videoStyle = `kIFb1dFARhPIxGQossKV`;
export var textarea = `ZaD_DhGJOv_aKk49H8rl`;
export var textSection = `mDmHK2aAGVbNsSyhgAkg`;
export var cancelBtn = `YoZdvH8VafhZne1h1Lnf`;
export default ___CSS_LOADER_EXPORT___;
