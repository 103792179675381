import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import * as Style from './style.module.scss';

interface LinkProps {
    to: string;
    children?: React.ReactNode;
}

const Link: React.FC<LinkProps> = ({ to, children }) => {
    return (
        <RouterLink to={to} className={Style.link}>
            {children}
        </RouterLink>
    );
};

export default Link;
