import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetNotification } from '../../../context/Notification';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { MemberReadmitPayload } from '../types';
import { ORGANIZATION_MEMBER_LIST_KEY } from './constants';

const useMemberReadmit = (onSuccess?: () => void) => {
    const req = useRequest();
    const queryClient = useQueryClient();
    const setNotification = useSetNotification();

    const request = ({ payload }: { payload: MemberReadmitPayload }) => {
        return req({
            url: `${envVars.API_COMMERCE_HOSTNAME}/enterprise/discharge-member/`,
            method: 'DELETE',
            query: payload
        });
    };

    return useMutation({
        mutationFn: request,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ORGANIZATION_MEMBER_LIST_KEY] });
            setNotification({ message: `Member re-admit` });
            onSuccess && onSuccess();
        },
        onError: (error?: string) => {
            return error;
        }
    });
};

export default useMemberReadmit;
