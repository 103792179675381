import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';

import AuthProvider from '@hero/hero-auth/v2/AuthProvider';
import { MediaQueryContextProvider } from '@hero/react-hooks/mediaQuery';

import App from './App';
import envVars from './constants/envVars';
import UserStatusProvider from './context/UserStatus';
import UserPolicyProvider from './context/UserPolicy';
import NotificationProvider from './context/Notification';
import RecorderProvider from './context/RecorderContext';
import React from 'react';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
            staleTime: 0
        }
    }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <AuthProvider constAccessParams={envVars}>
        <UserStatusProvider>
            <UserPolicyProvider>
                <QueryClientProvider client={queryClient}>
                    <MediaQueryContextProvider>
                        <RecorderProvider>
                            <NotificationProvider>
                                <App />
                            </NotificationProvider>
                        </RecorderProvider>
                    </MediaQueryContextProvider>
                </QueryClientProvider>
            </UserPolicyProvider>
        </UserStatusProvider>
    </AuthProvider>
);
