type UseHasHighlightedTexProps = (text?: string, highlight?: string) => boolean;

export const escapeRegExp = (str = '') => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');

const getHasHighlightedText: UseHasHighlightedTexProps = (text, highlight) => {
    const parts = text?.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));

    if (highlight?.length === 0) {
        return false;
    }

    return parts?.some((part) => part.toLowerCase() === highlight?.toLowerCase()) || false;
};

export default getHasHighlightedText;
