import useTheme from '@hero/branding/theme';
import getIconColor from '@hero/ui-kit/icons/getIconColor';
import { IconComponentProps } from '@hero/ui-kit/types';
import React from 'react';

const LocationIcon: React.FC<IconComponentProps> = ({
    type = 'utility',
    size = 'regular',
    disabled = false,
    color,
    styles
}) => {
    const { colors, sizing } = useTheme();
    color = color || getIconColor(colors, type, disabled);
    const sizePx = sizing.widths.icon[size];

    return (
        <svg
            width={sizePx}
            height={sizePx}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styles}
        >
            <path
                d="M12.5 12.9805C13.05 12.9805 13.5208 12.7846 13.9125 12.393C14.3042 12.0013 14.5 11.5305 14.5 10.9805C14.5 10.4305 14.3042 9.95964 13.9125 9.56797C13.5208 9.1763 13.05 8.98047 12.5 8.98047C11.95 8.98047 11.4792 9.1763 11.0875 9.56797C10.6958 9.95964 10.5 10.4305 10.5 10.9805C10.5 11.5305 10.6958 12.0013 11.0875 12.393C11.4792 12.7846 11.95 12.9805 12.5 12.9805ZM12.5 20.3305C14.5333 18.4638 16.0417 16.768 17.025 15.243C18.0083 13.718 18.5 12.3638 18.5 11.1805C18.5 9.3638 17.9208 7.8763 16.7625 6.71797C15.6042 5.55964 14.1833 4.98047 12.5 4.98047C10.8167 4.98047 9.39583 5.55964 8.2375 6.71797C7.07917 7.8763 6.5 9.3638 6.5 11.1805C6.5 12.3638 6.99167 13.718 7.975 15.243C8.95833 16.768 10.4667 18.4638 12.5 20.3305ZM12.5 22.9805C9.81667 20.6971 7.8125 18.5763 6.4875 16.618C5.1625 14.6596 4.5 12.8471 4.5 11.1805C4.5 8.68047 5.30417 6.6888 6.9125 5.20547C8.52083 3.72214 10.3833 2.98047 12.5 2.98047C14.6167 2.98047 16.4792 3.72214 18.0875 5.20547C19.6958 6.6888 20.5 8.68047 20.5 11.1805C20.5 12.8471 19.8375 14.6596 18.5125 16.618C17.1875 18.5763 15.1833 20.6971 12.5 22.9805Z"
                fill={color}
            />
        </svg>
    );
};

export default LocationIcon;
