import React, { useMemo } from 'react';
import Section from '../../../../../../ui-kit/layout/Section';
import { dosesByPeriod, sortDoses, parseTimeLabel } from '../../../AdherenceCalendar/common';

import * as Style from './SidebarDay.module.scss';
import Loader from '../../../../../../ui-kit/graphics/Loader';
import * as CommonStyle from '../../common.module.scss';
import DosePills from '../../../AdherenceCalendar/DosePills';
import { useAdherenceContext } from '../../../context/AdherenceContext';
import { WeeklyDoses } from '../../AdherenceCalendarWeekly/Acw.types';

interface SidebarDayProps {
    day?: string;
    onDoseClick: (time: string) => void;
}

const SidebarDay: React.FC<SidebarDayProps> = ({ day, onDoseClick }) => {
    const { getSidebarDay } = useAdherenceContext();

    const [fetch, setFetch] = React.useState<{
        isLoading: boolean;
        data: WeeklyDoses[] | undefined;
    }>({
        isLoading: false,
        data: undefined
    });

    const { isLoading, data } = fetch;

    React.useEffect(() => {
        if (!!day) {
            setFetch({ isLoading: true, data: undefined });
            getSidebarDay({
                target_datetime: `${day} 00:00:00`
            })
                .then((response) => setFetch({ isLoading: false, data: response }))
                .catch(() => setFetch({ isLoading: false, data: undefined }));
        }
    }, [day]);

    const parsedHeader = useMemo(() => {
        return day && new Date(`${day}T00:00:00`).toString().slice(4, 10);
    }, [day]);
    const doses = useMemo(
        () => dosesByPeriod(data || [], [{ value: new Date(day || '') }]),
        [data, day]
    );
    const sortedDoses = useMemo(() => sortDoses(doses), [doses]);
    // @ts-ignore
    const handlePillClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, time: string) => {
        onDoseClick(time);
    };
    if (!doses || isLoading)
        return (
            <div className={CommonStyle.loader}>
                <Loader />
            </div>
        );
    return (
        <div className={Style.root}>
            <div className={Style.header}>{parsedHeader}</div>
            <Section className={Style.content} noDefaultPadding>
                {Array.from(sortedDoses).map(([key, value], index) => {
                    return (
                        <React.Fragment key={`period-${index}`}>
                            <div
                                className={[
                                    Style.periodRow,
                                    key.includes('As-Needed') ? Style.asneeded : ''
                                ].join(' ')}
                            >
                                <div style={{ borderRight: 'none', width: 'auto' }}>
                                    {parseTimeLabel(key)}
                                </div>
                                <div className={CommonStyle.dosesDow}>
                                    {Array.from(value).map(([key2, doseArr], index) => (
                                        <DosePills
                                            key={`dose-pill-${key2}-${index}`}
                                            doses={doseArr}
                                            onClick={handlePillClick}
                                        />
                                    ))}
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
            </Section>
        </div>
    );
};

export default SidebarDay;
