import Section from '@hero/ui-kit/layout/Section';
import React from 'react';
import empty from './images/empty.png';
import P from '@hero/ui-kit/typography/P';
import H from '@hero/ui-kit/typography/H';

const HeroAssessmentEmptyState: React.FC = () => {
    return (
        <Section centered paddingVertical="small" paddingBottom="regular">
            <H role="h6" noDefaultMargin>
                Why is this page blank?
            </H>
            <P
                size="small"
                centered
                noDefaultMargin
                styles={{ maxWidth: '42rem', marginBottom: '4.8rem', marginTop: '1rem' }}
            >
                Currently, we can only display Heath Assessment information for applicants who have
                filled out the RTM application form using Hero mobile app. Applicants that used the
                online web application form are not included at this time.
            </P>
            <img
                src={empty}
                alt="hero-assessment-empty-state"
                style={{ width: '37.5rem', height: '28rem' }}
            />
        </Section>
    );
};

export default HeroAssessmentEmptyState;
