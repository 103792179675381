import { useSetNotification } from '../../../context/Notification';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { LeadQueryExport } from '../types';
import { useState } from 'react';

const useExportLeads = (query?: LeadQueryExport) => {
    const request = useRequest('blob');
    const setNotification = useSetNotification();
    const [loading, setLoading] = useState(false);

    const exportLeads = async () => {
        setLoading(true);
        setNotification({ message: 'Exporting leads' });

        const file = await request<Blob>({
            method: 'POST',
            url: `${envVars.API_RTM_HOSTNAME}enterprise/export-leads/`,
            body: JSON.stringify(query)
        });

        const fileUrl = URL.createObjectURL(file);
        const anchor = document.createElement('a');
        anchor.href = fileUrl;
        anchor.download = 'Leads export'; // TODO: clever dynamic name

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(fileUrl);

        setLoading(false);
        setNotification({ message: 'Leads exported' });
    };

    return { exportLeads, loading };
};

export default useExportLeads;
