import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import { useFormContext } from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import React from 'react';

interface ContactPersonFormProps {
    isLoading?: boolean;
    canEdit?: boolean;
}

const FormElements: React.FC<{ isSaveDisabled?: boolean; canEdit?: boolean }> = ({ canEdit }) => {
    const isMinL = useIsMinBreakpoint('l');

    const disabled = !canEdit;

    const {
        setValue,
        formState: { isDirty }
    } = useFormContext();

    React.useEffect(() => {
        setValue('isDirty', isDirty);
    }, [setValue, isDirty]);

    return (
        <Section noDefaultPadding styles={{ width: '100%' }}>
            <div
                style={{
                    width: '100%',
                    textAlign: 'left',
                    marginBottom: '3.6rem',
                    marginTop: '0.4rem'
                }}
            >
                <P
                    noDefaultMargin
                    strong
                    size="large"
                    styles={{ marginBottom: '3.6rem', fontSize: '2.4rem' }}
                >
                    Contact person
                </P>
                <P noDefaultMargin>
                    Secondary contact or person submitting application on behalf of loved one
                </P>
            </div>
            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <Input
                    disabled={disabled}
                    name="caregiver_first_name"
                    displayName="Contact Person First Name"
                />
                <Input
                    disabled={disabled}
                    name="caregiver_last_name"
                    displayName="Contact Person Last Name"
                />
            </Container>

            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <Input disabled={disabled} name="email" displayName="Contact Person Email" />
                <Input disabled={disabled} name="phone" displayName="Contact Person Phone number" />
            </Container>
        </Section>
    );
};

const ContactPersonForm: React.FC<ContactPersonFormProps> = ({ isLoading, canEdit }) => {
    return <FormElements isSaveDisabled={isLoading} canEdit={canEdit} />;
};

export default ContactPersonForm;
