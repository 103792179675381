type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
    ? I
    : never;

export type MessageWithType<T extends string> = { type: T };
export type MessageWithTypeAndPayload<T extends string, P> = MessageWithType<T> & { payload: P };
export type MessageWithTypeAndUserID<T extends string, P> = MessageWithType<T> & {
    payload: P;
    user_id: number[];
};

export type Message<T extends string, P = void> = P extends void
    ? MessageWithType<T>
    : MessageWithTypeAndPayload<T, P>;

export type MessagesWithoutPayload<T> = T extends Message<any, any>
    ? Exclude<T, { payload: any }>
    : never;

// ============== Response Messages ==============
export type ResponseAuthorizationSuccessMessage = Message<
    'response_authorization',
    { status: 'success' | 'failure' }
>;

export type ResponseAuthorizationErrorMessage = Message<
    'response_authorization',
    { status: 'failure' | 'success'; message: 'Token not sent' | 'Authorization failed' }
>;

export type ResponseUnauthorizedErrorMessage = Message<'response_error', { error: 'unauthorized' }>;

export type ResponseShouldCallUserStatusMessage = Message<'request_should_call_user_status'>;
export type ResponseShouldCallUserFlagsMessage = Message<'request_should_call_user_flags'>;
export type ResponseReconnectedMessage = Message<'reconnected'>;
export type ResponseConnectedMessage = Message<'connected'>;
export type ResponseEnterpriseNewLead = Message<
    'enterprise_new_lead',
    { hero_organization_id: string }
>;

export type IsAuthenticatedMessage = Message<'isAuthenticated', { isAuthenticated: boolean }>;

type MappedMessageUnion<T> = T extends Message<infer K, infer P>
    ? { [k in K]: Message<K, P> }
    : T extends Message<infer K>
    ? { [k in K]: Message<K> }
    : never;

export type ResponseChangeMessage =
    | ResponseShouldCallUserStatusMessage
    | ResponseShouldCallUserFlagsMessage;

export type ResponseMessages =
    | ResponseAuthorizationSuccessMessage
    | ResponseAuthorizationErrorMessage
    | ResponseUnauthorizedErrorMessage
    | ResponseReconnectedMessage
    | ResponseConnectedMessage
    | ResponseEnterpriseNewLead;

export type ResponseMessagesDictionary = UnionToIntersection<MappedMessageUnion<ResponseMessages>>;

// ============== Request Messages ==============
export type RequestAuthorizationMessage = Message<
    'request_authorization',
    { authorization_token: string }
>;
export type RequestPingMessage = Message<'request_ping'>;

export type RequestMessage = RequestAuthorizationMessage | RequestPingMessage;

export enum ReadyState {
    CONNECTING = 0,
    OPEN = 1,
    CLOSING = 2,
    CLOSED = 3
}

export type Subscriptions = UnionToIntersection<
    MappedMessageUnion<
        | ResponseShouldCallUserStatusMessage
        | ResponseShouldCallUserFlagsMessage
        | ResponseReconnectedMessage
        | ResponseConnectedMessage
        | ResponseAuthorizationSuccessMessage
        | IsAuthenticatedMessage
        | ResponseEnterpriseNewLead
    >
>;

export type HandlerWithoutPayload = () => void;
export type HandlerWithPayload<P> = (arg: P) => void;

export type ExtractMapValueType<T> = T extends Map<unknown, infer V> ? V : never;

export type WsMessageSubscribers = {
    [K in keyof Subscriptions]: Map<
        Record<string, unknown>,
        Subscriptions[K] extends MessageWithTypeAndPayload<K, infer P>
            ? HandlerWithPayload<P>
            : HandlerWithoutPayload
    >;
};

type WsLog<T extends string, P> = { type: T; payloadFactory: () => P };

export type WsLogInfo = WsLog<'info', string>;
export type WsLogResponseMessage = WsLog<'responseMessage', ResponseMessages>;
export type WsLogRequestMessage = WsLog<'requestMessage', RequestMessage>;
export type WsLogError<T extends Error = Error> = WsLog<'error', T>;

export type WsLogs = WsLogInfo | WsLogError | WsLogResponseMessage | WsLogRequestMessage;

export type HandleWsLog = (log: WsLogs) => void;
