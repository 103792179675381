const formatTime = (milliseconds: number, asText?: boolean) => {
    let seconds = Number.parseFloat((milliseconds / 1000).toFixed(0));

    let minutes = Math.floor(seconds / 60);
    let hours = 0;
    if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        minutes = minutes - hours * 60;
    }

    seconds = Math.floor(seconds % 60);

    const secondsFormatted = seconds >= 10 ? seconds : '0' + seconds;

    if (asText) {
        return hours !== 0
            ? `${hours} h ${minutes} min ${secondsFormatted} s`
            : `${minutes} min ${secondsFormatted} s`;
    }

    return hours !== 0
        ? `${hours}:${minutes}:${secondsFormatted}`
        : `${minutes}:${secondsFormatted}`;
};

export default formatTime;
