import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

type UnsavedChangeBlockerProps = {
    isChanged: boolean;
    isSuccess: boolean;
    changeBlockerModal: (show: boolean) => void;
};

type ReturnProps = {
    handleCancel: () => void;
    handleDiscard: () => void;
};

type UseUnsavedChangeBlockerProps = (props: UnsavedChangeBlockerProps) => ReturnProps;

const useUnsavedChangeBlocker: UseUnsavedChangeBlockerProps = ({
    isChanged,
    isSuccess,
    changeBlockerModal
}) => {
    const hasUnsavedChanges = false;

    // useEffect(() => {
    //     handleFormChanged(isChanged);
    // }, [handleFormChanged, isChanged]);

    const onBlockNext = () => {
        alert('next');
    };

    const onBlockCancel = () => {
        alert('cancel');
    };

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            isChanged && currentLocation.pathname !== nextLocation.pathname
    );

    useEffect(() => {
        if (blocker.state === 'blocked' || hasUnsavedChanges) {
            changeBlockerModal(true);
        }
    }, [blocker, hasUnsavedChanges, changeBlockerModal]);

    useEffect(() => {
        if (blocker.state === 'blocked' && isSuccess) {
            blocker.proceed && blocker.proceed();
        }
    }, [blocker, isSuccess]);

    // useEffect(() => {
    //     if (hasUnsavedChanges && isSuccess) {
    //         onBlockNext();
    //     }
    // }, [hasUnsavedChanges, isSuccess, onBlockNext]);

    const handleDiscard = () => {
        blocker.proceed ? blocker.proceed() : onBlockNext();
    };

    const handleCancel = () => {
        blocker.reset ? blocker.reset() : onBlockCancel();
        changeBlockerModal(false);
    };

    return {
        handleCancel,
        handleDiscard
    };
};

export default useUnsavedChangeBlocker;
