import React from 'react';
import { useAuthContext } from '@hero/hero-auth/v2/AuthProvider';
import envVars from '../constants/envVars';
import { IUserStatus } from '../pages/IAM/types';
import useGetUserPermissions, { UserPermissions } from '../hooks/useGetUserPermissions';

type IUserStatusContext = {
    isLoading: boolean;
    userStatus?: IUserStatus;
    permissions: UserPermissions;
};

const UserStatusContext = React.createContext<IUserStatusContext | undefined>(undefined);

export const useUserStatusContext = () => {
    const ctx = React.useContext(UserStatusContext);

    if (ctx === undefined) {
        throw new Error(`'useUserStatusContext' must be used within a 'UserStatusContextProvider'`);
    }

    return ctx;
};

const UserStatusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [userStatus, setUserStatus] = React.useState<IUserStatus>();
    const [isLoading, setIsLoading] = React.useState(false);
    const { auth, logout } = useAuthContext();
    const permissions = useGetUserPermissions(userStatus?.permissions);

    React.useEffect(() => {
        if (!auth) {
            return;
        }

        setIsLoading(true);

        fetch(`${envVars.API_ID_HOSTNAME}api/enterprise/user-status`, {
            headers: { Authorization: `Bearer ${auth.access_token}` }
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`${res.status} ${res.statusText}`);
                }

                res.json().then((data) => {
                    setUserStatus(data);
                    setIsLoading(false);
                });
            })
            .catch((error) => {
                setIsLoading(false);
                console.log('Error: ', error);
            });
    }, [auth, logout]);

    return (
        <UserStatusContext.Provider
            value={{
                userStatus,
                isLoading,
                permissions
            }}
        >
            {children}
        </UserStatusContext.Provider>
    );
};

export default UserStatusProvider;
