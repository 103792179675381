import * as yup from 'yup';

import email, { validEmailRegex } from '@hero/validators/email';
import emailDomain from '@hero/validators/email-domain';
import { phoneYupValidation } from '../../../../../utils/validator';

const emailSchema = yup.lazy((val?: string) => {
    if (!val) {
        return yup.string().trim().required('Email is required');
    } else if (!validEmailRegex.test(`${val}`)) {
        return email;
    } else {
        return emailDomain;
    }
}) as unknown as yup.StringSchema<string, object>;

const patientDetailsFormSchema = yup
    .object()
    .shape({
        is_member: yup.boolean().default(false),
        admin_email: yup
            .string()
            .trim()
            .when('is_member', {
                is: (is_member?: boolean) => !!is_member,
                then: () => emailSchema,
                otherwise: (schema) => schema.nullable().notRequired()
            }),
        external_serial: yup
            .string()
            .trim()
            .when('is_member', {
                is: (is_member?: boolean) => !!is_member,
                then: (schema) => schema.nullable().required('Hero serial number is required'),
                otherwise: (schema) => schema.nullable().notRequired()
            }),
        patient_email: emailSchema,
        patient_phone: phoneYupValidation,
        isDirty: yup.boolean().default(false),
        insurance_type: yup.string().required('Insurance type is required'),
        insurance_company: yup.lazy((val) =>
            !val
                ? yup.string().trim().notRequired()
                : yup
                      .string()
                      .trim()
                      .matches(
                          /^[a-zA-Z0-9\s]+$/,
                          'Please ensure that Insurance Company Name contains only alphanumeric characters (letters and numbers). Special characters are not allowed.'
                      )
        ),
        insurance_id: yup.lazy((val) =>
            !val
                ? yup.string().trim().notRequired()
                : yup
                      .string()
                      .trim()
                      .matches(
                          /^[a-zA-Z0-9\s]+$/,
                          'Please ensure Member ID contains only alphanumeric characters (letters and numbers). Special characters are not allowed.'
                      )
        )
    })
    .defined();

export type PatientDetailsFormSchemaParams = yup.InferType<typeof patientDetailsFormSchema>;

export { patientDetailsFormSchema };
