import { useMemo } from 'react';

import { PaginationData } from '@hero/hero-types';

import usePagerClickQuery, { HandleSetPagerClickQuery } from './usePagerClickQuery';

type PagerProps = PaginationData & {
    handleClick: HandleSetPagerClickQuery;
    padding: number;
};
type UsePagerProps = (paginationData: PaginationData, padding?: number) => PagerProps;

const usePagerProps: UsePagerProps = (paginationData, padding = 3) => {
    const handlePagination = usePagerClickQuery();
    const total_count = paginationData.total_count || 0;

    return useMemo(
        () => ({
            handleClick: handlePagination,
            ...paginationData,
            total_count,
            padding
        }),
        [paginationData, handlePagination, padding, total_count]
    );
};

export default usePagerProps;
