import React from 'react';

import Input from '@hero/ui-kit/inputs/Input';

type Props = {
    displayName: string;
    className?: string;
    disabled?: boolean;
};

function checkValue(str: string, max: number) {
    if (str.charAt(0) !== '0' || str === '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str =
            num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
                ? '0' + num
                : num.toString();
    }

    return str;
}

const DOB: React.FC<Props> = ({ displayName, className, disabled = false }) => {
    const handleOnChange = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        let target = event.target as HTMLInputElement;
        let input = target.value;

        if (/\D\/$/.test(input)) {
            input = input.substring(0, input.length - 3);
        }

        let values = input.split('-').map(function (v) {
            return v.replace(/\D/g, '');
        });

        if (values[0]) {
            values[0] = checkValue(values[0], 12);
        }
        if (values[1]) {
            values[1] = checkValue(values[1], 31);
        }

        let output = values.map(function (v, i) {
            return v.length === 2 && i < 2 ? v + '-' : v;
        });

        target.value = output.join('').substring(0, 10);
    }, []);

    return (
        <Input
            disabled={disabled}
            name="date_of_birth"
            type="text"
            displayName={displayName}
            className={className}
            onKeyUp={handleOnChange}
            placeholder={'MM-DD-YYYY'}
            data-maxlength={10}
            inputMode="numeric"
        />
    );
};

export default DOB;
