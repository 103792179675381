import React from 'react';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import * as Styles from './style.module.scss';
import P from '@hero/ui-kit/typography/P';
import { useIdle } from '../../../../../../hooks/useIdle';
import CircleCountdown from '../CircleCountdown';

type Props = {} & Pick<ModalProps, 'externalControls'>;

const ModalComponent: React.FC<Props> = ({ externalControls }) => {
    const [, setExternalState] = externalControls ?? [];

    const onCloseModal = React.useCallback(() => {
        setExternalState && setExternalState(false);
    }, [setExternalState]);

    const onComplete = React.useCallback(() => {
        onCloseModal();
    }, [onCloseModal]);

    const keepRecording = () => {
        onCloseModal();
    };

    return (
        <Modal externalControls={externalControls} styles={{ padding: 0, width: '56rem' }}>
            <div style={{ width: '100%' }}>
                <div className={Styles.timerWrapper}>
                    <CircleCountdown
                        onEnd={onComplete}
                        seconds={60}
                        size={72}
                        strokeBgColor="transparent"
                        strokeColor="#f26c3a"
                        strokeWidth={4}
                        timeSequence="s"
                        fontColor="#444444"
                        fontWeight="700"
                    />
                </div>
                <Section
                    noDefaultPadding
                    styles={{ marginTop: '2.4rem', marginBottom: '2.4rem' }}
                    centered
                >
                    <P noDefaultMargin strong styles={{ fontSize: '3.2rem' }}>
                        Are you still there?
                    </P>
                </Section>
                <P noDefaultMargin>
                    We noticed that you are not active in a Hero platform. Recording will stop in
                    less than 1 minute. Your activity will be saved on member's Activities page.
                </P>
                <div className={Styles.btnWrapper}>
                    <Button variant="outlineSecondary" onClick={onComplete}>
                        Complete
                    </Button>
                    <Button
                        onClick={keepRecording}
                        variant="outlineSecondary"
                        styles={{ marginLeft: '1.2rem' }}
                    >
                        Keep recording
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const InactivityModal: React.FC = () => {
    const ms = 60000 * 5; // 5 minutes
    const isIdle = useIdle(ms);
    const [showModal, setShowModal] = React.useState(false);

    React.useEffect(() => {
        if (isIdle) {
            setShowModal(true);
        }
    }, [isIdle]);

    return showModal ? <ModalComponent externalControls={[showModal, setShowModal]} /> : null;
};

export default InactivityModal;
