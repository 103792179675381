import React from 'react';
import { showMemberActivityFeature } from '../constants/featureFlags';
import { StatusMessages, useMediaRecorder } from '../hooks/useMediaRecorder';
import { SelectedPatientActivity } from '../pages/FleetManagement/types';

type IRecorderContext = {
    status: StatusMessages;
    stopRecording: () => void;
    startRecording: () => void;
    clearRecording: () => void;
    mediaBlobUrl?: string;
    onSetPatientActivity: (activity: SelectedPatientActivity | undefined) => void;
    mediaBlob?: Blob;
    patientActivity?: SelectedPatientActivity;
    getMediaStream: () => Promise<React.MutableRefObject<MediaStream | null> | undefined>;
    previewStream: MediaStream | null;
    error: string;
};

const RecorderContext = React.createContext<IRecorderContext | undefined>(undefined);

export const useRecorderContext = () => {
    const ctx = React.useContext(RecorderContext);

    if (ctx === undefined) {
        throw new Error(`'useRecorderContext' must be used within a 'RecorderContextProvider'`);
    }

    return ctx;
};

const RecorderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [patientActivity, setPatientActivity] = React.useState<
        SelectedPatientActivity | undefined
    >();

    const onSetPatientActivity = (activity?: SelectedPatientActivity) => {
        setPatientActivity(activity);
    };

    const {
        stopRecording,
        startRecording,
        mediaBlobUrl,
        status,
        mediaBlob,
        clearRecording,
        error,
        getMediaStream,
        previewStream
    } = useMediaRecorder({
        audio: patientActivity?.value === 'call_to_patient',
        video: true,
        screen: true
    });

    React.useEffect(() => {
        showMemberActivityFeature && error && console.warn(`Recorder error: ${error}`);
    }, [error]);

    return (
        <RecorderContext.Provider
            value={{
                status,
                startRecording,
                stopRecording,
                clearRecording,
                mediaBlobUrl,
                onSetPatientActivity,
                mediaBlob,
                patientActivity,
                getMediaStream,
                previewStream,
                error
            }}
        >
            {children}
        </RecorderContext.Provider>
    );
};

export default RecorderProvider;
