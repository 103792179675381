import { useMemo } from 'react';
import { useUserPolicyContext } from '../context/UserPolicy';
import { useUserStatusContext } from '../context/UserStatus';

const useGetUserMenuLinks = () => {
    const { permissions } = useUserStatusContext();
    const { orgProperties } = useUserPolicyContext();

    return useMemo(() => {
        return [
            {
                to: '/members',
                label: 'Members',
                show: permissions.fleet.fleetListView
            },
            {
                to: '/leads',
                label: 'Leads',
                show: permissions.leads.leadListView
            },
            {
                to: '/users',
                label: 'Users',
                show: permissions.iam.listInvites
            },
            {
                to: '/reports',
                label: 'Reports',
                show: orgProperties?.has_device_activity_report
            }
        ];
    }, [permissions, orgProperties]);
};

export default useGetUserMenuLinks;
