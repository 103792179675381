import { groupBy, mergeMap, Subscription, Subject, tap, throttleTime } from 'rxjs';
import {
    HandleWsLog,
    WsLogError,
    WsLogInfo,
    WsLogRequestMessage,
    WsLogResponseMessage,
    WsLogs
} from './types';
import envVars from '../constants/envVars';

export class WsLogger {
    private readonly showLogs = envVars.DEV_MODE;
    private readonly showPingLogs = true;

    private static readonly exceptionSubject$ = new Subject<Error>();
    private static exceptionSubscription: Subscription;

    constructor() {
        if (WsLogger.exceptionSubscription) {
            WsLogger.exceptionSubscription.unsubscribe();
        }

        WsLogger.exceptionSubscription = WsLogger.exceptionSubject$
            .pipe(
                groupBy((err) => err.name),
                mergeMap((group$) => group$.pipe(throttleTime(20000))),
                tap((err) => console.error(err.message))
            )
            .subscribe();
    }

    infoLogFactory = (payloadFactory: WsLogInfo['payloadFactory']): WsLogInfo => ({
        type: 'info',
        payloadFactory
    });

    requestLogFactory = (
        payloadFactory: WsLogRequestMessage['payloadFactory']
    ): WsLogRequestMessage => ({
        type: 'requestMessage',
        payloadFactory
    });

    responseLogFactory = (
        payloadFactory: WsLogResponseMessage['payloadFactory']
    ): WsLogResponseMessage => ({
        type: 'responseMessage',
        payloadFactory
    });

    errorLogFactory = (payloadFactory: WsLogError['payloadFactory']): WsLogError => ({
        type: 'error',
        payloadFactory
    });

    handleWsLog: HandleWsLog = (log) => {
        if (log.type === 'error') {
            WsLogger.exceptionSubject$.next(log.payloadFactory());
        }

        if (!this.showLogs) return;

        if (!this.showPingLogs && log.type === 'responseMessage') {
            return;
        }

        const payload = this.getLogPayload(log);

        try {
            const stringPayload = JSON.stringify(payload);

            !stringPayload.includes('request_authorization') && this.formattedLog(stringPayload);
        } catch (_) {
            const err = new Error(`failed to stringify payload: ${payload}`);

            WsLogger.exceptionSubject$.next(err);

            this.formattedLog(`Error: ${err.message}`);
        }
    };

    private getLogPayload = (log: WsLogs) =>
        log.type === 'error' ? `Error: ${log.payloadFactory().message}` : log.payloadFactory();

    private formattedLog = (message: string) =>
        console.log(`%c __WS__ ${new Date().toISOString()} `, `background: green`, message);
}
