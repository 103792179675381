import { useMutation, useQueryClient } from '@tanstack/react-query';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { useSetNotification } from '../../../context/Notification';
import { USERS_LIST_KEY } from './constants';
import { UpdateUserAction } from '../types';

type UseUpdateUserInviteParams = {
    id: string;
    action: UpdateUserAction[];
    role_id?: string;
};

const useUpdateUserInvite = () => {
    const req = useRequest();
    const setNotification = useSetNotification();
    const queryClient = useQueryClient();

    return useMutation<unknown, { errors: string[] }, UseUpdateUserInviteParams>({
        mutationFn: (body) => {
            return req({
                url: `${envVars.API_ID_HOSTNAME}api/enterprise/invite/`,
                method: 'PATCH',
                body: { action: body.action, ...(body.role_id && { role_id: body.role_id }) },
                query: { id: body.id }
            });
        },
        onSuccess: (_, params) => {
            let data = { message: `Update confirmed`, icon: 'checkmark' as const };

            if (params.action.includes('ENABLE_INVITE')) {
                data.message = `User reactivated`;
            } else if (params.action.includes('REVOKE_INVITE')) {
                data.message = `Invite marked as expired`;
            } else if (params.action.length === 1 && params.action.includes('RESET_MFA')) {
                data.message = `MFA reset sent`;
            } else if (params.action.length === 1 && params.action.includes('UPDATE_ROLE')) {
                data.message = `User role updated`;
            }

            setNotification(data);

            queryClient.invalidateQueries({ queryKey: [USERS_LIST_KEY] });
        },
        onError: (error: { errors: string[] }) => {
            return error;
        }
    });
};

export default useUpdateUserInvite;
