// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aK_Npd2wWM62cEPB7IrD{border-radius:0 !important;border:none !important;color:var(--color-primary-alpha) !important;padding:0 !important;line-height:normal !important;background-color:rgba(0,0,0,0) !important}.E3f3nL6F6I5wI7oysraL{width:100%;display:flex;justify-content:space-between;align-items:center}.sXvFRgbf_ps1n6UjY5Fx{min-height:5.4rem;width:100%;border-radius:8px;display:flex;align-items:center;column-gap:1.6rem;background:#f4f4f4;margin-top:1.2rem;padding-left:1.6rem}.IBO1kIoA2ZNNeJfNkCLA{display:flex;align-items:center;justify-content:center;column-gap:1.2rem;margin-top:6rem}.uBBRiOLoD5WoaVsvet9X{width:100%;text-align:left;margin-top:3.6rem;margin-bottom:2.4rem}.GZkdMc0DcsR0IlHFdIxG{width:100%;margin-top:3.6rem;margin-bottom:3.6rem}.J6P4sxJIGNncQdm1ya_g{width:100%;margin-top:3.6rem}`, "",{"version":3,"sources":["webpack://./pages/UsersManagement/UserList/UserDetails/style.module.scss"],"names":[],"mappings":"AACA,sBACI,0BAAA,CACA,sBAAA,CACA,2CAAA,CACA,oBAAA,CACA,6BAAA,CACA,yCAAA,CAGJ,sBACI,UAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAGJ,sBACI,iBAAA,CACA,UAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,kBAAA,CACA,iBAAA,CACA,mBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,eAAA,CAGJ,sBACI,UAAA,CACA,eAAA,CACA,iBAAA,CACA,oBAAA,CAGJ,sBACI,UAAA,CACA,iBAAA,CACA,oBAAA,CAGJ,sBACI,UAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var btnStyle = `aK_Npd2wWM62cEPB7IrD`;
export var mfaResetWrapper = `E3f3nL6F6I5wI7oysraL`;
export var infoResetMFA = `sXvFRgbf_ps1n6UjY5Fx`;
export var btnWrapper = `IBO1kIoA2ZNNeJfNkCLA`;
export var roleLabel = `uBBRiOLoD5WoaVsvet9X`;
export var divider = `GZkdMc0DcsR0IlHFdIxG`;
export var dividerInvite = `J6P4sxJIGNncQdm1ya_g`;
export default ___CSS_LOADER_EXPORT___;
