import React from 'react';

import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import Button from '@hero/ui-kit/inputs/Button';
import { useFormContext, useWatch } from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Radio from '@hero/ui-kit/inputs/Radio';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import * as Style from './style.module.scss';
import DOB from './fields/DOB';
import StandaloneCheckbox from '@hero/ui-kit/inputs/StandaloneCheckbox';
import ExistingHeroUserFormFields from '../ExistingHeroUserFormFields';
import Phone from './fields/Phone';

const AdditionalElements: React.FC<{
    isMember?: boolean;
    heroFor?: string;
    showAdminEmailError?: boolean;
    showHeroSerialErrorError?: boolean;
}> = ({
    isMember,
    heroFor = '',
    showAdminEmailError = false,
    showHeroSerialErrorError = false
}) => {
    const isMinL = useIsMinBreakpoint('l');

    const insuranceType = useWatch({ name: 'insurance_type' });

    return (
        <>
            <Section
                noDefaultPadding
                marginVertical="small"
                marginTop="none"
                styles={{
                    minWidth: '75rem',
                    marginTop: '1rem',
                    ...(!isMember && { marginBottom: '3rem' })
                }}
            >
                <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="small">
                    <StandaloneCheckbox
                        id="is_member"
                        labelText={'Already using Hero dispenser'}
                        name="is_member"
                        className={`${Style.checkboxStyle} ${Style.center}`}
                    />
                </Container>
            </Section>
            {isMember ? (
                <div style={{ marginBottom: '1.2rem' }}>
                    <ExistingHeroUserFormFields
                        showAdminEmailError={showAdminEmailError}
                        showHeroSerialErrorError={showHeroSerialErrorError}
                        explanation="To enroll a person with an existing Hero membership into the program, you will need their correct admin email and device serial number to validate their membership. You have the option to skip entering this information now, but please note that it will be mandatory for enrollment into the program."
                    />
                </div>
            ) : null}

            <h5 className={Style.infoTitle}>
                {heroFor === 'loved_one' ? 'Their Insurance' : 'Insurance'}
            </h5>
            <div style={{ width: '100%' }}>
                <RadioGroup
                    className={Style.radioGroup}
                    name="insurance_type"
                    displayName={''}
                    noBorders
                >
                    <Radio
                        id="original_medicare"
                        value="ORIGINAL_MEDICARE"
                        labelText="Original Medicare"
                        className={Style.radio}
                    />
                    {insuranceType === 'ORIGINAL_MEDICARE' ? (
                        <Section noDefaultPadding>
                            <Input name="insurance_id" displayName="Member ID" />
                            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                                <Input
                                    name="medigap_insurance_policy_id"
                                    displayName="Medigap Member ID"
                                />
                                <Input
                                    name="medigap_insurance_name"
                                    displayName="Medigap Insurance Company Name"
                                />
                            </Container>
                        </Section>
                    ) : (
                        <Radio id="none-1" value="" labelText="" styles={{ display: 'none' }} />
                    )}
                    <Radio
                        id="medicare_advantage"
                        value="MEDICARE_ADVANTAGE"
                        labelText="Medicare Advantage"
                        className={Style.radio}
                    />
                    {insuranceType === 'MEDICARE_ADVANTAGE' ? (
                        <Section noDefaultPadding>
                            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                                <Input name="insurance_id" displayName="Member ID" />
                                <Input name="insurance_company" displayName="Insurance Company" />
                            </Container>
                        </Section>
                    ) : (
                        <Radio id="none-1" value="" labelText="" styles={{ display: 'none' }} />
                    )}
                    <Radio
                        id="not_sure"
                        value="NOT_SURE"
                        labelText="Not sure"
                        className={Style.radio}
                    />
                    {insuranceType === 'NOT_SURE' ? (
                        <Section noDefaultPadding>
                            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                                <Input name="insurance_id" displayName="Member ID" />
                                <Input name="insurance_company" displayName="Insurance Company" />
                            </Container>
                        </Section>
                    ) : (
                        <Radio id="none-1" value="" labelText="" styles={{ display: 'none' }} />
                    )}
                </RadioGroup>
            </div>
        </>
    );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const FormElements: React.FC<{
    loading?: boolean;
    showAdminEmailError?: boolean;
    showHeroSerialErrorError?: boolean;
}> = ({ loading, showAdminEmailError = false, showHeroSerialErrorError = false }) => {
    const formContext = useFormContext();
    const isMinL = useIsMinBreakpoint('l');

    const isSubmitDisabled: boolean = React.useMemo(() => {
        return loading || (formContext?.formState && !formContext.formState.isValid);
    }, [formContext, loading]);

    const hero_for = formContext.watch('hero_for');
    const isMember = formContext.watch('is_member');

    return (
        <Section noDefaultPadding styles={{ maxWidth: '75rem' }}>
            <h5 className={Style.infoTitle}>Who will be the primary user for Hero?</h5>
            <RadioGroup name="hero_for" displayName="" noBorders className={Style.radioGroup}>
                <Radio
                    status="success"
                    id="myself"
                    value="myself"
                    labelText="Oneself"
                    styles={{ padding: 0 }}
                    className={Style.radio}
                />
                <Radio
                    status="success"
                    id="loved_one"
                    value="loved_one"
                    labelText="A loved one"
                    styles={{ padding: 0 }}
                    className={Style.radio}
                />
            </RadioGroup>

            {hero_for === 'loved_one' ? (
                <>
                    <h5 className={Style.infoTitle}>Loved one's information</h5>

                    <Container
                        gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'}
                        gridColumnGap="regular"
                    >
                        <Input name="first_name_user" displayName="THEIR FIRST NAME" />
                        <Input name="last_name_user" displayName="THEIR LAST NAME" />
                    </Container>
                    <Container
                        gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'}
                        gridColumnGap="regular"
                    >
                        <DOB displayName="THEIR DATE OF BIRTH" />

                        <div style={{ width: '100%' }}>
                            <Input name="zip" displayName="THEIR ZIP" />
                        </div>
                    </Container>
                    <Input
                        name="external_user_id"
                        displayName="Patient ID (optional)"
                        placeholder="Patient ID form your EMR"
                    />

                    <h5 className={Style.infoTitle}>Contact person</h5>

                    <Container
                        gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'}
                        gridColumnGap="regular"
                    >
                        <Input name="first_name" displayName="FIRST NAME" />
                        <Input name="last_name" displayName="LAST NAME" />
                    </Container>
                    <Container
                        gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'}
                        gridColumnGap="regular"
                    >
                        <Input
                            styles={{ marginBottom: '0.5rem' }}
                            name="email"
                            displayName="Email"
                            placeholder={isMember ? 'Preferably Hero email address' : undefined}
                        />
                        <Phone displayName="Phone" styles={{ marginBottom: '0.5rem' }} />
                    </Container>
                </>
            ) : (
                <>
                    <h5 className={Style.infoTitle}>Program applicant</h5>
                    <Container
                        gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'}
                        gridColumnGap="regular"
                        styles={{ marginBottom: '2rem' }}
                    >
                        <Input name="first_name_user" displayName="First name" />
                        <Input name="last_name_user" displayName="Last name" />
                        <DOB displayName="DATE OF BIRTH" />
                        <div style={{ width: '100%' }}>
                            <Input name="zip" displayName="ZIP" />
                        </div>
                        <Input
                            styles={{ marginBottom: '0.5rem' }}
                            name="email"
                            displayName="Email"
                        />
                        <Phone displayName="Phone" styles={{ marginBottom: '0.5rem' }} />
                    </Container>
                    <Input
                        name="external_user_id"
                        displayName="Patient ID (optional)"
                        placeholder="Patient ID form your EMR"
                    />
                </>
            )}

            <AdditionalElements
                isMember={isMember}
                heroFor={hero_for}
                showAdminEmailError={showAdminEmailError}
                showHeroSerialErrorError={showHeroSerialErrorError}
            />

            <Section noDefaultPadding marginVertical="regular">
                <Section centered noDefaultPadding>
                    <Button
                        type="submit"
                        disabled={isSubmitDisabled}
                        width={isMinL ? 'large' : 'full'}
                    >
                        Submit
                    </Button>
                </Section>
            </Section>
        </Section>
    );
};

export default FormElements;
