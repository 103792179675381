import P from '@hero/ui-kit/typography/P';
import React from 'react';
import DotSignifier, { DotSignifierType } from '../../../../components/DotSignifier';

interface DotLabelProps {
    showDot?: boolean;
    type: DotSignifierType;
    label: string;
    labelStrong?: boolean;
}

const DotLabel: React.FC<DotLabelProps> = ({
    showDot = false,
    type,
    label,
    labelStrong = false
}) => {
    return (
        <div style={{ position: 'relative' }}>
            {showDot ? (
                <DotSignifier
                    styles={{ position: 'absolute', left: '-4.5%', top: '0.6rem' }}
                    type={type}
                />
            ) : null}
            <P noDefaultMargin strong={labelStrong}>
                {label}
            </P>
        </div>
    );
};

export default DotLabel;
