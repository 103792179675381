import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { UserList } from '../types';
import { USERS_LIST_KEY } from './constants';

type GetUsersListParams = {
    status?: string;
    role?: string;
    search?: string; // optional, search string filter (by email field)
    sort_by?: string; // sort criteria [email | created]
    sort_dir?: string; // ASC | DESC
    offset?: number;
    limit?: number;
};

const useGetUsersList = (query: GetUsersListParams) => {
    const req = useRequest();

    const request = useCallback(() => {
        return req<UserList>({
            url: `${envVars.API_ID_HOSTNAME}api/enterprise/invites`,
            query
        });
    }, [query, req]);

    return useQuery({
        queryFn: request,
        queryKey: [USERS_LIST_KEY, query]
    });
};

export default useGetUsersList;
