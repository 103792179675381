// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JleAbxVSIeqo5abFkFHD{height:8rem;padding:16px 24px 16px 24px;border-radius:8px;border:1px solid #c4c4c4;display:flex;align-items:center;justify-content:space-between;margin:3.6rem 4rem 0 4rem}.No62WLX2SzO4ttAwAnkP{display:flex;align-items:flex-start;column-gap:1.6rem}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/DischargeHeaderNote/style.module.scss"],"names":[],"mappings":"AACA,sBACI,WAAA,CACA,2BAAA,CACA,iBAAA,CACA,wBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,yBAAA,CAGJ,sBACI,YAAA,CACA,sBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var wrapper = `JleAbxVSIeqo5abFkFHD`;
export var inner = `No62WLX2SzO4ttAwAnkP`;
export default ___CSS_LOADER_EXPORT___;
