import { IconComponentProps } from '@hero/ui-kit/types';
import React from 'react';

const ImageIcon: React.FC<IconComponentProps> = ({ className, color = '#444444', styles }) => {
    return (
        <svg
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            style={styles}
            className={className}
        >
            <path
                d="M0 4C0 1.79086 1.79086 0 4 0H26C28.2091 0 30 1.79086 30 4V26C30 28.2091 28.2091 30 26 30H4C1.79086 30 0 28.2091 0 26V4Z"
                fill="white"
            />
            <path
                d="M8 24C7.45 24 6.97917 23.8042 6.5875 23.4125C6.19583 23.0208 6 22.55 6 22V8C6 7.45 6.19583 6.97917 6.5875 6.5875C6.97917 6.19583 7.45 6 8 6H22C22.55 6 23.0208 6.19583 23.4125 6.5875C23.8042 6.97917 24 7.45 24 8V22C24 22.55 23.8042 23.0208 23.4125 23.4125C23.0208 23.8042 22.55 24 22 24H8ZM8 22H22V8H8V22ZM9 20H21L17.25 15L14.25 19L12 16L9 20Z"
                fill={color}
            />
        </svg>
    );
};

export default ImageIcon;
