import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import React from 'react';
import Link from '../Link';

const PageNotFound: React.FC = () => {
    return (
        <Section centered>
            <H>Page not found</H>
            <Link to="/">Go home</Link>
        </Section>
    );
};

export default PageNotFound;
