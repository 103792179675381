import React from 'react';
import Filter from '../../../../components/Filter';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import CheckboxSquare from '@hero/ui-kit/graphics/CheckboxSquare';
import { useSearchParams } from 'react-router-dom';
import * as Style from './style.module.scss';
import P from '@hero/ui-kit/typography/P';

const items = [
    {
        id: 1,
        label: 'Hero Member',
        value: '1'
    },
    {
        id: 2,
        label: 'Not a Hero Member',
        value: '0'
    }
];

const HeroMemberFilter: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const searchParam = 'is_member';
    const currentValue = searchParams.get(searchParam);

    const updateStatusFilter = React.useCallback(
        (isMember: string) => {
            setSearchParams((searchParams) => {
                if (isMember !== currentValue) {
                    searchParams.set(searchParam, isMember);
                } else {
                    searchParams.delete(searchParam);
                }

                searchParams.delete('offset');

                return searchParams;
            });
        },
        [setSearchParams, searchParam, currentValue]
    );

    const filterLabel = React.useMemo(() => {
        const mainLabel = 'Hero Member:';

        if (currentValue) {
            return `${mainLabel} ${currentValue === '1' ? 'Yes' : 'No'}`;
        }

        return `${mainLabel} All`;
    }, [currentValue]);

    return (
        <Filter
            hasFilters={typeof currentValue === 'string'}
            filterLabel={filterLabel}
            width="28rem"
        >
            <div className={`${Style.filterWrapper}`}>
                {items.map((item) => (
                    <ClickableOpacity
                        key={item.id}
                        fullWidth
                        alt={`Toggle ${item.label}`}
                        className={Style.filterStatusItem}
                        onClick={() => {
                            updateStatusFilter(item.value);
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                            <CheckboxSquare
                                isChecked={currentValue === item.value}
                                isFocused={false}
                                isHovered={false}
                                disabled={false}
                                styles={{
                                    minWidth: '1.6rem',
                                    height: '1.6rem',
                                    width: '1.6rem'
                                }}
                            />

                            <P noDefaultMargin>{item.label}</P>
                        </div>
                    </ClickableOpacity>
                ))}
            </div>
        </Filter>
    );
};

export default HeroMemberFilter;
