import React from 'react';
import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements
} from 'react-router-dom';

import FleetManagement from '../pages/FleetManagement';
import OrganizationMemberDetails from '../pages/FleetManagement/OrganizationMemberDetails';
import AdherenceModule from '../pages/FleetManagement/OrganizationMemberDetails/AdherenceModule';
import CaregivingInfo from '../pages/FleetManagement/OrganizationMemberDetails/CaregivingInfo';
import MedicationInfo from '../pages/FleetManagement/OrganizationMemberDetails/MedicationInfo';
import Home from '../pages/Home';
import LeadsManagement from '../pages/LeadsManagement';
import UsersManagement from '../pages/UsersManagement';
import ProtectedRoute from './ProtectedRoute';
// import LeadDetails from '../pages/LeadsManagement/Details';
import ReportsManagement from '../pages/ReportsManagement';
import { useUserPolicyContext } from '../context/UserPolicy';
import PageNotFound from '../components/PageNotFound';
import PatientActivity from '../pages/FleetManagement/OrganizationMemberDetails/PatientActivity';
import { showMemberActivityFeature } from '../constants/featureFlags';
import LeadDetailsV2 from '../pages/LeadsManagement/Details/v2';

const AppRoutes: React.FC = () => {
    const { orgProperties } = useUserPolicyContext();

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <Home />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/members"
                    element={
                        <ProtectedRoute>
                            <FleetManagement />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/members/:id"
                    element={
                        <ProtectedRoute>
                            <OrganizationMemberDetails />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/members/:id/medications"
                    element={
                        <ProtectedRoute>
                            <MedicationInfo />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/members/:id/caregiving"
                    element={
                        <ProtectedRoute>
                            <CaregivingInfo />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/members/:id/adherence"
                    element={
                        <ProtectedRoute>
                            <AdherenceModule />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/members/:id/activity"
                    element={
                        <ProtectedRoute>
                            {showMemberActivityFeature ? <PatientActivity /> : <PageNotFound />}
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/leads"
                    element={
                        <ProtectedRoute>
                            <LeadsManagement />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/leads/:id"
                    element={
                        <ProtectedRoute>
                            <LeadDetailsV2 />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/users"
                    element={
                        <ProtectedRoute>
                            <UsersManagement />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/reports"
                    element={
                        <ProtectedRoute>
                            {orgProperties?.has_device_activity_report ? (
                                <ReportsManagement />
                            ) : (
                                <PageNotFound />
                            )}
                        </ProtectedRoute>
                    }
                />
                <Route path="*" element={<PageNotFound />} />
            </>
        )
    );

    return <RouterProvider router={router} />;
};

export default AppRoutes;
