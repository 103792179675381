import React from 'react';

import { UserInvite } from '../../types';
import { IAMListRole } from '../../../IAM/types';

import EditActiveUser from './EditActiveUser';
import EditInvitedUser from './EditInvitedUser';
import EditDeactivatedUser from './EditDeactivatedUser';
import { useUserStatusContext } from '../../../../context/UserStatus';

export interface UserDetailsProps {
    userName: string;
    closeModal: () => void;
    roles?: IAMListRole[];
    user: UserInvite;
    isLoggedUser?: boolean;
}

const UserDetails: React.FC<UserDetailsProps> = ({ user, userName, closeModal, roles }) => {
    const { userStatus } = useUserStatusContext();

    if (user.status === 'DEACTIVATED') {
        return (
            <EditDeactivatedUser
                user={user}
                userName={userName}
                closeModal={closeModal}
                roles={roles}
                isLoggedUser={userStatus?.email === user.email}
            />
        );
    }

    if (user.status === 'INVITED') {
        return (
            <EditInvitedUser
                user={user}
                userName={userName}
                closeModal={closeModal}
                roles={roles}
                isLoggedUser={userStatus?.email === user.email}
            />
        );
    }

    return (
        <EditActiveUser
            user={user}
            userName={userName}
            closeModal={closeModal}
            roles={roles}
            isLoggedUser={userStatus?.email === user.email}
        />
    );
};

export default UserDetails;
