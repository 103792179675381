import React from 'react';
import { intervalToDuration } from 'date-fns';

interface ExpiringAlertProps {
    createdAt: string;
    reactivatedAt?: string | null;
    children?: React.ReactNode;
}

const getUtcDate = (date: string) => {
    const dateObj = new Date(date);
    return new Date(
        Date.UTC(
            dateObj.getFullYear(),
            dateObj.getMonth(),
            dateObj.getUTCDate(),
            dateObj.getUTCHours(),
            dateObj.getUTCMinutes(),
            dateObj.getUTCSeconds()
        )
    );
};

const productDate = getUtcDate('2024-04-23 13:00:00');

const ExpiringAlert: React.FC<ExpiringAlertProps> = ({ createdAt, reactivatedAt, children }) => {
    const now = new Date();

    const referenceDate = reactivatedAt ? getUtcDate(reactivatedAt) : getUtcDate(createdAt);

    const maxDate = productDate.getTime() >= referenceDate.getTime() ? productDate : referenceDate;

    const { hours, days: daysDiffInterval } = intervalToDuration({ start: now, end: maxDate });

    const daysDiff =
        daysDiffInterval !== undefined && Number.isInteger(daysDiffInterval)
            ? Math.abs(daysDiffInterval)
            : undefined;

    const daysLength = maxDate.getHours() <= 13 ? 28 : 29;

    const days = daysDiff ? daysLength - daysDiff : 0;

    const limit = daysLength - 10;

    return React.cloneElement(children as React.ReactElement, {
        days,
        hours,
        show: daysDiff && daysDiff >= limit
    });
};

export default ExpiringAlert;
