import * as yup from 'yup';
import phone from '@hero/validators/libphonenumber';

const regex = /[^\u0000-\u00ff]/; // check for unicode characters

export const phoneYupValidation = yup.lazy((val?: string) => {
    if (!val) {
        return yup.string().trim().required('Phone number is required');
    } else if (regex.test(`${val}`)) {
        return yup
            .string()
            .trim()
            .test('special-chars', 'Phone number invalid', () => false);
    } else {
        return phone;
    }
}) as unknown as yup.StringSchema<string, object>;
