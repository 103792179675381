import React from 'react';
import { StatusInfo } from '../../types';
import * as Style from './style.module.scss';
import P from '@hero/ui-kit/typography/P';
import CloseIcon from '../../../../icons/CloseIcon';
import DischargeMemberStatusPill from '../../components/DischargeMemberStatusPill';

interface DischargeheaderNoteProps {
    statusInfo?: StatusInfo | null;
}

const DischargeHeaderNote: React.FC<DischargeheaderNoteProps> = ({ statusInfo }) => {
    const isDischarged = !!statusInfo?.discharged_at;
    const isCancelled = statusInfo?.status === 'CANCELLED';
    const reason = statusInfo?.discharge_reason;

    return isDischarged || isCancelled ? (
        <div className={Style.wrapper}>
            <div className={Style.inner}>
                <CloseIcon size="utility" />
                <div>
                    <P noDefaultMargin strong>{`Member is discharged ${
                        reason ? ` - ${reason}` : ''
                    }`}</P>
                    {isCancelled ? (
                        <P noDefaultMargin>
                            The data displayed in the patient’s profile reflects their status up
                            until the date their discharge was finalized. No updates are recorded
                            after this date.
                        </P>
                    ) : null}
                </div>
            </div>
            <DischargeMemberStatusPill statusInfo={statusInfo} />
        </div>
    ) : null;
};

export default DischargeHeaderNote;
