import P from '@hero/ui-kit/typography/P';
import React from 'react';
import HighlightedText from '../../../../components/HighlightedText';
import useTheme from '@hero/branding/theme';
import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import getIsDeviceSetupNeeded from '../../utils/getIsDeviceSetupNeeded';
import getHasHighlightedText from '../../../../components/HighlightedText/getHasHighlightedText';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import BoxShipmentIcon from '@hero/ui-kit/icons/utility/BoxShipmentIcon';
import { ExchangeDeviceInfo, OrganizationMemberListDeviceInfo } from '../../types';

const DeviceSerial: React.FC<{
    deviceInfo?: OrganizationMemberListDeviceInfo;
    search: string;
    exchangeDeviceInfo?: ExchangeDeviceInfo;
}> = ({ deviceInfo, search, exchangeDeviceInfo }) => {
    const theme = useTheme();
    const isMinL = useIsMinBreakpoint('l');
    const isSetupNeeded = getIsDeviceSetupNeeded(deviceInfo?.device_status);
    const [showReturnInfo, setShowReturnInfo] = React.useState(false);

    const handleReturnClick = React.useCallback((e: React.SyntheticEvent) => {
        e.preventDefault();
        setShowReturnInfo((prevState) => !prevState);
    }, []);

    const hasExchangeDeviceSerialHighlighted = React.useMemo(() => {
        if (exchangeDeviceInfo?.device_serial) {
            return getHasHighlightedText(exchangeDeviceInfo?.device_serial, search);
        }
        return false;
    }, [exchangeDeviceInfo, search]);

    const exchangeInfo = React.useMemo(() => {
        const show = ['ACTIVATED', 'CONFIGURED'].includes(deviceInfo?.device_status || '');

        return show ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {exchangeDeviceInfo ? (
                    <ClickableOpacity
                        onClick={handleReturnClick}
                        alt="exchange-device-serial-toggle"
                        styles={{
                            paddingRight: '1rem',
                            ...(hasExchangeDeviceSerialHighlighted &&
                                !showReturnInfo && {
                                    borderRadius: '100%',
                                    border: '0.2rem solid',
                                    borderColor: theme.colors.brand.primary.alpha
                                })
                        }}
                    >
                        <BoxShipmentIcon width={20} height={20} />
                    </ClickableOpacity>
                ) : null}
            </div>
        ) : null;
    }, [
        isSetupNeeded,
        deviceInfo,
        showReturnInfo,
        exchangeDeviceInfo,
        hasExchangeDeviceSerialHighlighted,
        handleReturnClick,
        theme
    ]);

    const wrapperStyle: React.CSSProperties = React.useMemo(() => {
        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            ...(!isMinL && { flexWrap: 'wrap' }),
            ...(showReturnInfo && {
                backgroundColor: theme.colors.brand.secondary.beta
            })
        };
    }, [isMinL, showReturnInfo, theme]);

    const deviceSerial = React.useMemo(() => {
        const serial = showReturnInfo
            ? `${exchangeDeviceInfo?.device_serial} - Exchange S/N`
            : deviceInfo?.device_serial;

        return <HighlightedText highlight={search} text={serial || 'N/A'} />;
    }, [search, deviceInfo, exchangeDeviceInfo, showReturnInfo]);

    return (
        <div style={wrapperStyle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <P noDefaultMargin strong styles={{ minWidth: '11rem' }}>
                    {deviceSerial}
                </P>
            </div>
            {exchangeInfo}
        </div>
    );
};

export default DeviceSerial;
