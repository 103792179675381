import { useCallback, useEffect } from 'react';
import useExtendLockLead from '../api/useExtendLockLead';
import useUnlockLead from '../api/useUnlockLead';
import { differenceInMilliseconds } from 'date-fns';

type UseExtendLockUnlockLeadProps = (
    lockExpires?: string,
    lead_id?: string,
    isLockedByLoggedUser?: boolean,
    enabled?: boolean
) => void;

let timeout: NodeJS.Timeout;

const useExtendLockUnlockLead: UseExtendLockUnlockLeadProps = (
    lockExpires,
    lead_id,
    isLockedByLoggedUser = false,
    enabled = true
) => {
    const { mutateAsync: extendLead } = useExtendLockLead();
    const { mutate: unlockLead } = useUnlockLead();

    const handleExtendLeadLock = useCallback(
        (expires: string) => {
            if (lead_id) {
                clearTimeout(timeout);
                const now = new Date();
                const lockExpiresDate = new Date(expires);
                const utcLockExpiresDate = new Date(
                    Date.UTC(
                        lockExpiresDate.getFullYear(),
                        lockExpiresDate.getMonth(),
                        lockExpiresDate.getDate(),
                        lockExpiresDate.getHours(),
                        lockExpiresDate.getMinutes(),
                        lockExpiresDate.getSeconds()
                    )
                );
                const result = differenceInMilliseconds(utcLockExpiresDate, now);

                timeout = setTimeout(() => {
                    extendLead({ payload: { lead_id } })
                        .then((response) => {
                            handleExtendLeadLock(response.lock_expires);
                        })
                        .catch((error) => {
                            console.error(error);
                            return null;
                        });
                }, result - 30000);
            }
        },
        [lead_id, extendLead]
    );

    useEffect(() => {
        enabled && lockExpires && handleExtendLeadLock(lockExpires);
        return () => {
            clearTimeout(timeout);
            enabled && lead_id && isLockedByLoggedUser && unlockLead({ payload: { lead_id } });
        };
    }, [lead_id, isLockedByLoggedUser, lockExpires, handleExtendLeadLock, unlockLead, enabled]);
};

export default useExtendLockUnlockLead;
