// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bRfjhQnqkkTr7XUhSmqN{position:fixed;z-index:9999999;padding-top:100px;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:#000;background-color:rgba(0,0,0,.4)}.FRqgsG9yIu4wJ56RPwJg{background-color:#fefefe;margin:auto;padding:20px;border-radius:12px;width:42%}.zoIsw69D1RkbfTguplC2{display:flex;align-items:center;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/Details/v2/components/UnsavedChangesModal/style.module.scss"],"names":[],"mappings":"AACA,sBACI,cAAA,CACA,eAAA,CACA,iBAAA,CACA,MAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,aAAA,CACA,qBAAA,CACA,+BAAA,CAGJ,sBACI,wBAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,SAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA","sourceRoot":""}]);
// Exports
export var modal = `bRfjhQnqkkTr7XUhSmqN`;
export var modalContent = `FRqgsG9yIu4wJ56RPwJg`;
export var modalHeader = `zoIsw69D1RkbfTguplC2`;
export default ___CSS_LOADER_EXPORT___;
