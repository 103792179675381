import React, { createContext, Dispatch, useContext, useState } from 'react';

export type INotificationContext =
    | {
          message?: string;
          icon?: 'checkmark';
          type?: 'success' | 'fail' | 'neutral';
      }
    | undefined;

const initialState = {} as INotificationContext;
const NotificationCtx = createContext<INotificationContext>(initialState);
const SetNotificationCtx = createContext<Dispatch<INotificationContext>>(() => null);

const NotificationProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = useState(initialState);

    return (
        <SetNotificationCtx.Provider value={dispatch}>
            <NotificationCtx.Provider value={state}>{children}</NotificationCtx.Provider>
        </SetNotificationCtx.Provider>
    );
};

export const useNotification = () => useContext(NotificationCtx);
export const useSetNotification = () => useContext(SetNotificationCtx);

export default NotificationProvider;
