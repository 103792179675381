// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TN_Lxag7m_n8NLUjRS5Q{padding:1.2rem 2.4rem;display:flex}.TN_Lxag7m_n8NLUjRS5Q>div{display:flex;align-items:center;margin-right:3.6rem}.TN_Lxag7m_n8NLUjRS5Q>div>div:first-of-type{margin-right:1.2rem}.BEmnRzfh3KprRoczJd2L{width:2rem;height:2rem;border-radius:2rem}.E9eRXltpmvw6b2awvOYu{margin-right:1.2rem}.E9eRXltpmvw6b2awvOYu g{stroke:var(--color-neutrals-textGamma)}`, "",{"version":3,"sources":["webpack://./../hero-frontend-packages/packages/enterprise-portal-common/src/Adherence/AdherenceCalendar/AdherenceCalendarMonthly/Legend/Legend.module.scss"],"names":[],"mappings":"AACA,sBACI,qBAAA,CACA,YAAA,CACA,0BACI,YAAA,CACA,kBAAA,CACA,mBAAA,CACA,4CACI,mBAAA,CAIZ,sBACI,UAAA,CACA,WAAA,CACA,kBAAA,CAEJ,sBACI,mBAAA,CACA,wBACI,sCAAA","sourceRoot":""}]);
// Exports
export var root = `TN_Lxag7m_n8NLUjRS5Q`;
export var circle = `BEmnRzfh3KprRoczJd2L`;
export var triangle = `E9eRXltpmvw6b2awvOYu`;
export default ___CSS_LOADER_EXPORT___;
