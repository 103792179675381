import React from 'react';

import envVars from '../../../../../constants/envVars';
import useRequest from '../../../../../hooks/useRequest';
import { AdherencePeriods } from '@hero/enterprise-portal-common/src/Adherence/types';

type Query = {
    target_datetime: string | null; // ending datetime for the period in YYYY-mm-dd HH:MM:SS
    mode: 'weekly' | 'monthly' | null;
    number_of_periods: number;
};

const useGetAdherencePeriods = (hero_user_id: number) => {
    const req = useRequest();

    const request = React.useCallback(
        (query: Query) => {
            return req<AdherencePeriods>({
                url: `${envVars.API_CLOUD_HOSTNAME}enterprise/adherence/by-period`,
                query: { ...query, hero_user_id }
            });
        },
        [req, hero_user_id]
    );

    return request;
};

export default useGetAdherencePeriods;
