import { useQuery } from '@tanstack/react-query';
import React from 'react';

import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { LeadHealthAssessmentResponse } from '../types';
import { LEAD_HEALTH_ASSESSMENT } from './constants';

const useGetHealthAssessment = (lead_id: string) => {
    const req = useRequest();

    const request = React.useCallback(() => {
        return req<LeadHealthAssessmentResponse>({
            url: `${envVars.API_RTM_HOSTNAME}enterprise/health-assessment/`,
            query: { lead_id }
        });
    }, [lead_id, req]);

    return useQuery({
        queryFn: request,
        queryKey: [LEAD_HEALTH_ASSESSMENT, lead_id]
    });
};

export default useGetHealthAssessment;
