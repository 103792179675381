import useOutsideClick from '@hero/ui-kit/hooks/useOutsideClick';
import CaretButton from '@hero/ui-kit/inputs/CaretButton';
import Section from '@hero/ui-kit/layout/Section';
import React from 'react';
import * as Style from './style.module.scss';

interface FilterProps {
    filterLabel: string | React.ReactElement;
    children: React.ReactNode;
    ignoreClickOutside?: boolean;
    width?: string;
    className?: string;
    dropdownPosition?: 'left' | 'right';
    activeClassName?: string;
    hasFilters?: boolean;
}

const Filter: React.FC<FilterProps> = ({
    filterLabel,
    children,
    ignoreClickOutside = false,
    width = '24rem',
    className = '',
    dropdownPosition = 'left',
    activeClassName = Style.activeFilter,
    hasFilters = false
}) => {
    const [filterStatusExpanded, setFilterStatusExpanded] = React.useState(false);
    const [ref, isOutsideClick] = useOutsideClick(filterStatusExpanded);

    React.useEffect(() => {
        isOutsideClick && !ignoreClickOutside && setFilterStatusExpanded(false);
    }, [isOutsideClick, ignoreClickOutside]);

    const filterPositionClass =
        dropdownPosition === 'left' ? Style.filterDropdownLeft : Style.filterDropdownRight;

    const handleToggleFilter = () => {
        setFilterStatusExpanded((current) => !current);
    };

    const filterElement =
        typeof filterLabel === 'string' ? (
            <CaretButton
                className={`${Style.filterStatusToggle} ${
                    filterStatusExpanded ? Style.filterStatusExpanded : ''
                } ${hasFilters ? activeClassName : ''}`}
                textBefore={filterLabel}
                directionCollapsed="down"
                directionExpanded="up"
                isExpanded={filterStatusExpanded}
                onClick={() => {
                    setFilterStatusExpanded((current) => !current);
                }}
            />
        ) : (
            React.cloneElement(filterLabel, {
                onClick: handleToggleFilter,
                className: `${filterLabel.props.className} ${
                    filterStatusExpanded ? Style.filterStatusExpanded : ''
                }`
            })
        );

    return (
        // @ts-ignore
        <div className={`${Style.filterStatus} ${className}`} ref={ref}>
            {filterElement}
            {filterStatusExpanded && (
                <Section
                    noDefaultPadding
                    className={`${Style.filterStatusDropdown} ${filterPositionClass}`}
                    styles={{ width }}
                    border={true}
                >
                    {children}
                </Section>
            )}
        </div>
    );
};

export default Filter;
