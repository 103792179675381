import { useMemo } from 'react';
import { FleetPermission, IAMPermission, LeadPermission, Permission } from '../pages/IAM/types';

export type IAMUserPermissions = {
    orgPolicy: boolean;
    createInvites: boolean;
    editInvites: boolean;
    listInvites: boolean;
    inviteUserEdit: boolean;
    inviteUserView: boolean;
};

export type FleetUserPermissions = {
    fleetListView: boolean;
    fleetListEdit: boolean;
    fleetMemberView: boolean;
    fleetMemberEdit: boolean;
};

export type LeadsUserPermissions = {
    leadListView: boolean;
    leadListEdit: boolean;
};

export type UserPermissions = {
    iam: IAMUserPermissions;
    fleet: FleetUserPermissions;
    leads: LeadsUserPermissions;
};

const getIAMPermissions = (permissions?: Permission[]): IAMUserPermissions => {
    const find = permissions?.find((item) => item.module === 'ENT_IAM');

    if (find) {
        const modulePermissions = find.permissions as unknown as IAMPermission[];
        return {
            orgPolicy: !!modulePermissions.find((item) => item.name === 'ENT_IAM_ORG_POLICY')
                ?.value,
            createInvites: !!modulePermissions.find(
                (item) => item.name === 'ENT_IAM_INVITES_CREATE'
            )?.value,
            editInvites: !!modulePermissions.find((item) => item.name === 'ENT_IAM_INVITES_EDIT')
                ?.value,
            listInvites: !!modulePermissions.find((item) => item.name === 'ENT_IAM_INVITES_VIEW')
                ?.value,
            inviteUserEdit: !!modulePermissions.find((item) => item.name === 'ENT_IAM_ROLE_EDIT')
                ?.value,
            inviteUserView: !!modulePermissions.find((item) => item.name === 'ENT_IAM_ROLE_VIEW')
                ?.value
        };
    }

    return {
        orgPolicy: false,
        createInvites: false,
        editInvites: false,
        listInvites: false,
        inviteUserEdit: false,
        inviteUserView: false
    };
};

const getFleetPermissions = (permissions?: Permission[]): FleetUserPermissions => {
    const find = permissions?.find((item) => item.module === 'ENT_FLEET');

    if (find) {
        const modulePermissions = find.permissions as unknown as FleetPermission[];
        return {
            fleetListView: !!modulePermissions.find(
                (item) => item.name === 'ENT_FLEET_ORGANIZATION_VIEW'
            )?.value,
            fleetListEdit: !!modulePermissions.find(
                (item) => item.name === 'ENT_FLEET_ORGANIZATION_EDIT'
            )?.value,
            fleetMemberView: !!modulePermissions.find(
                (item) => item.name === 'ENT_FLEET_MEMBER_VIEW'
            )?.value,
            fleetMemberEdit: !!modulePermissions.find(
                (item) => item.name === 'ENT_FLEET_MEMBER_EDIT'
            )?.value
        };
    }

    return {
        fleetListView: false,
        fleetListEdit: false,
        fleetMemberView: false,
        fleetMemberEdit: false
    };
};

const getLeadsPermissions = (permissions?: Permission[]): LeadsUserPermissions => {
    const find = permissions?.find((item) => item.module === 'ENT_LEADS');

    if (find) {
        const modulePermissions = find.permissions as unknown as LeadPermission[];
        return {
            leadListView: !!modulePermissions.find(
                (item) => item.name === 'ENT_LEAD_PATIENT_LEAD_VIEW'
            )?.value,
            leadListEdit: !!modulePermissions.find(
                (item) => item.name === 'ENT_LEAD_PATIENT_LEAD_EDIT'
            )?.value
        };
    }
    return {
        leadListView: false,
        leadListEdit: false
    };
};

type UseGetUserPermissions = (permissions?: Permission[]) => UserPermissions;

const useGetUserPermissions: UseGetUserPermissions = (permissions) => {
    return useMemo(() => {
        return {
            iam: getIAMPermissions(permissions),
            fleet: getFleetPermissions(permissions),
            leads: getLeadsPermissions(permissions)
        };
    }, [permissions]);
};

export default useGetUserPermissions;
