import VerifiedIcon from '@hero/ui-kit/icons/utility/VerifiedIcon';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import React from 'react';

interface VerifiedUserProps {
    adminEmail: string;
    serialNumber: string;
}

const VerifiedUser: React.FC<VerifiedUserProps> = ({ adminEmail, serialNumber }) => {
    return (
        <Section
            styles={{
                background: '#F4F4F4',
                padding: '1.6rem',
                borderRadius: '8px'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.8rem',
                    marginBottom: '3.6rem'
                }}
            >
                <VerifiedIcon width={20} height={20} />
                <P strong noDefaultMargin>
                    Verified User!
                </P>
                <P noDefaultMargin>Already using Hero dispenser.</P>
            </div>
            <Container gridTemplateColumns={'1fr 1fr'} gridColumnGap="regular">
                <P
                    size="small"
                    noDefaultMargin
                    styles={{
                        textTransform: 'uppercase',
                        fontSize: '1.2rem',
                        marginBottom: '1.2rem',
                        fontWeight: 600,
                        letterSpacing: '0.1rem'
                    }}
                >
                    Hero Admin Email
                </P>
                <P
                    size="small"
                    noDefaultMargin
                    styles={{
                        textTransform: 'uppercase',
                        fontSize: '1.2rem',
                        marginBottom: '1.2rem',
                        fontWeight: 600,
                        letterSpacing: '0.1rem'
                    }}
                >
                    Hero Serial Number
                </P>
                <P noDefaultMargin strong>
                    {adminEmail}
                </P>
                <P noDefaultMargin strong>
                    {serialNumber}
                </P>
            </Container>
        </Section>
    );
};

export default VerifiedUser;
