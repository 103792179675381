import { useNavigate, useLocation } from 'react-router-dom';

type UseSetQueryParams = () => (newQuery: string) => void;

const useSetQueryParams: UseSetQueryParams = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (newQuery) => navigate(location.pathname + newQuery);
};

export default useSetQueryParams;
