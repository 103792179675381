import { useMutation, useQueryClient } from '@tanstack/react-query';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { USERS_LIST_KEY } from './constants';
import { useSetNotification } from '../../../context/Notification';

type UseRemoveUserInviteParams = {
    id: string;
};

const useRemoveUserInvite = () => {
    const req = useRequest();
    const setNotification = useSetNotification();
    const queryClient = useQueryClient();

    return useMutation<unknown, { errors: string[] }, UseRemoveUserInviteParams>({
        mutationFn: (body) => {
            return req({
                url: `${envVars.API_ID_HOSTNAME}api/enterprise/invite/`,
                method: 'DELETE',
                query: { id: body.id }
            });
        },
        onSuccess: () => {
            setNotification({ message: 'User deactivated', icon: 'checkmark' });
            queryClient.invalidateQueries({ queryKey: [USERS_LIST_KEY] });
        },
        onError: (error: { errors: string[] }) => {
            return error;
        }
    });
};

export default useRemoveUserInvite;
