import React from 'react';
import { ApprovalStatus, LeadDetailsResponse } from '../../../../types';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';
import MenuNavbar from '../../../../../../components/Layout/MenuNavbar';
import CaretIcon from '@hero/ui-kit/icons/utility/CaretIcon';
import Pill from '../../../../components/Pill';
import getApprovalStatus from '../../../../utils/getApprovalStatus';
import VerifiedIcon from '@hero/ui-kit/icons/utility/VerifiedIcon';
import useBack from '../../../../../../hooks/useBack';

interface GeneralStickyNavbarProps {
    lead?: LeadDetailsResponse;
    showStickyNavbar?: boolean;
    isChanged?: boolean;
    isLoading?: boolean;
}

const GeneralStickyNavbar: React.FC<GeneralStickyNavbarProps> = ({
    lead,
    isChanged = false,
    isLoading,
    showStickyNavbar
}) => {
    const back = useBack();

    const handleBack = () => {
        back('/leads');
    };

    const approvalStatus = getApprovalStatus(lead?.status.toUpperCase() as ApprovalStatus);

    return (
        <div
            style={{
                position: 'sticky',
                top: 0,
                left: '0',
                background: 'rgba(255, 255, 255, 1)',
                minHeight: showStickyNavbar ? '60px' : '48px',
                zIndex: 10,
                opacity: showStickyNavbar ? 1 : 0,
                transition: 'all .2s ease-in-out',
                borderBottom: '1px solid rgba(231, 231, 231, 1)',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16)'
            }}
        >
            <div
                style={{
                    minHeight: showStickyNavbar ? '60px' : '48px',
                    margin: '0 2.4rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        visibility: showStickyNavbar ? 'visible' : 'hidden'
                    }}
                >
                    <MenuNavbar hideLogo />
                    <ClickableOpacity
                        onClick={handleBack}
                        alt="back to leads"
                        styles={{ marginLeft: '1.6rem' }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <CaretIcon
                                direction="backward"
                                width={20}
                                height={20}
                                styles={{ minWidth: '22px', marginRight: '1.6rem' }}
                            />
                            {lead?.is_existing_member ? (
                                <VerifiedIcon
                                    type="utility"
                                    width={24}
                                    height={24}
                                    styles={{ marginRight: '0.6rem' }}
                                />
                            ) : null}
                            <P
                                noDefaultMargin
                                strong
                                styles={{ fontSize: '1.8rem', marginRight: '3.6rem' }}
                            >
                                {lead?.primary_user_details?.first_name &&
                                lead?.primary_user_details?.last_name
                                    ? `${lead.primary_user_details.first_name} ${lead.primary_user_details.last_name}`
                                    : `-`}
                            </P>
                        </div>
                    </ClickableOpacity>
                    <Pill label={approvalStatus.label} className={approvalStatus.className} />
                </div>
                {isChanged ? (
                    <div>
                        <Button
                            noDefaultMargin
                            type="submit"
                            disabled={isLoading}
                            styles={{ lineHeight: '3.6rem' }}
                        >
                            Save
                        </Button>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default GeneralStickyNavbar;
