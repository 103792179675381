import { useMutation, useQueryClient } from '@tanstack/react-query';

import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { LEAD_LIST_KEY } from './constants';

const useUnlockLead = (onSuccess?: () => void) => {
    const req = useRequest();
    const queryClient = useQueryClient();

    const request = ({ payload }: { payload: { lead_id: string } }) => {
        return req({
            url: `${envVars.API_RTM_HOSTNAME}enterprise/release-lead-claim/`,
            method: 'POST',
            body: JSON.stringify(payload)
        });
    };

    return useMutation({
        mutationFn: request,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [LEAD_LIST_KEY] });
            onSuccess && onSuccess();
        },
        onError: (error: { errors: string[] }) => {
            return error;
        }
    });
};

export default useUnlockLead;
