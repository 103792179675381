import {
    differenceInCalendarMonths,
    differenceInCalendarWeeks,
    format,
    isSameMonth,
    isSameWeek,
    isThisMonth,
    isThisWeek,
    subDays
} from 'date-fns';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import ProgressBar from '../../../../ui-kit/components/ProgressBar';
import Loader from '../../../../ui-kit/graphics/Loader';
import ClickableOpacity from '../../../../ui-kit/inputs/ClickableOpacity';
import Section from '../../../../ui-kit/layout/Section';

import { DATE_FORMAT, PARAM_DATE, PARAM_TIMEFRAME } from '../constants/searchParams';
import { AdherencePeriods } from '../types';
import { Timeframes } from '../Filters/Timeframe';
import * as Style from './style.module.scss';
import { useAdherenceContext } from '../context/AdherenceContext';

const BarGraph: React.FC = () => {
    const element = React.useRef<HTMLButtonElement[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState<{ weekly: AdherencePeriods; monthly: AdherencePeriods }>(
        { weekly: [], monthly: [] }
    );
    const { getAdherencePeriods } = useAdherenceContext();

    const timeframe = React.useMemo(() => {
        return searchParams.get(PARAM_TIMEFRAME) as Timeframes;
    }, [searchParams]);

    const selectedDate = React.useMemo(() => {
        return searchParams.get(PARAM_DATE);
    }, [searchParams]);

    const updateSearchParams = React.useCallback(
        (dateTo: string) => {
            setSearchParams((params) => {
                params.set(PARAM_DATE, dateTo);
                return params;
            });
        },
        [setSearchParams]
    );

    const fetchData = React.useCallback(
        (target_datetime: string, number_of_periods: number) => {
            getAdherencePeriods({
                mode: timeframe,
                target_datetime,
                number_of_periods
            }).then((res) => {
                if (res?.length) {
                    setData((data) => {
                        let mergedData = data[timeframe];

                        // iterate results backwardly and prepend if not already there
                        for (let i = res.length; i > 0; i--) {
                            if (
                                !data[timeframe].find((d) => d.date_from === res[i - 1].date_from)
                            ) {
                                mergedData.unshift(res[i - 1]);
                            }
                        }

                        data[timeframe] = mergedData;
                        return { ...data };
                    });
                }
                setIsLoading(false);
            });
        },
        [getAdherencePeriods, timeframe]
    );

    React.useEffect(() => {
        if (element.current?.length) {
            const find = element.current.find((el) => el?.className?.includes('isSelected'));
            find && find?.focus();
        }
        if (!selectedDate) {
            return;
        }

        // initial load
        if (!data[timeframe].length) {
            fetchData(`${format(new Date(), DATE_FORMAT)} 00:00:00`, 12);
            return;
        }

        // data already exists
        if (data[timeframe].find((i) => i.date_to === selectedDate)) {
            return;
        }

        // due to useEffect firing upon data update, this will keep triggering until the selectedDate has been loaded
        // this means re-fetching for the new batch of previous data periods
        // backend has a limit on 24 periods
        const periods = Math.min(
            24,
            timeframe === 'monthly'
                ? differenceInCalendarMonths(
                      new Date(data[timeframe][0].date_to),
                      new Date(selectedDate)
                  )
                : differenceInCalendarWeeks(
                      new Date(data[timeframe][0].date_to),
                      new Date(selectedDate),
                      {
                          weekStartsOn: 1
                      }
                  )
        );

        periods > 0 &&
            fetchData(
                `${format(
                    subDays(new Date(data[timeframe][0].date_from), 1),
                    DATE_FORMAT
                )} 00:00:00`,
                periods
            );
    }, [data, fetchData, selectedDate, timeframe]);

    if (isLoading) {
        return (
            <Section centered>
                <Loader />
            </Section>
        );
    }

    if (!data[timeframe]?.length) {
        return <p>No data</p>;
    }

    return (
        <Section noDefaultPadding className={Style.barsWrapper}>
            <div className={Style.barsBackground}>
                {[100, 80, 60, 40, 20, 0].map((row) => (
                    <div key={row}>
                        <small>{row}%</small>
                        <hr />
                    </div>
                ))}
            </div>
            <div className={Style.bars}>
                {data[timeframe].map((item, index) => {
                    const dateFrom = new Date(`${item.date_from}T00:00:00`);
                    const dateTo = new Date(`${item.date_to}T00:00:00`);
                    const isToday =
                        timeframe === 'monthly' ? isThisMonth(dateFrom) : isThisWeek(dateFrom);
                    const isSelected =
                        timeframe === 'monthly'
                            ? isSameMonth(new Date(selectedDate || ''), new Date(item.date_to))
                            : isSameWeek(new Date(selectedDate || ''), new Date(item.date_to));

                    const dateLabel =
                        timeframe === 'monthly' ? (
                            <>
                                <small>{format(dateTo, 'MMM')}</small>
                                <div>{format(dateTo, 'M') === '1' && format(dateTo, 'y')}</div>
                            </>
                        ) : (
                            <>
                                <small>
                                    {format(dateFrom, 'd')}-
                                    {format(dateTo, 'd', { weekStartsOn: 1 })}
                                </small>
                                <div>
                                    {parseInt(format(dateTo, 'd')) <= 7 && format(dateTo, 'MMM')}
                                </div>
                            </>
                        );

                    if (!(item.positive + item.negative + item.unknown)) {
                        return (
                            <div
                                key={item.date_to}
                                className={`${Style.bar} ${isToday ? Style.isToday : ''}`}
                            >
                                {dateLabel}
                            </div>
                        );
                    }

                    return (
                        <ClickableOpacity
                            ref={(el: HTMLButtonElement) => (element.current[index] = el)}
                            key={item.date_to}
                            alt={`Set date to ${item.date_to}`}
                            onClick={() => {
                                updateSearchParams(item.date_to);
                            }}
                            className={`${Style.bar} ${isToday ? Style.isToday : ''} ${
                                isSelected ? Style.isSelected : ''
                            }`}
                        >
                            <ProgressBar
                                height={202}
                                percent={
                                    (item.positive * 100) /
                                    (item.positive + item.negative + item.unknown)
                                }
                            />
                            {dateLabel}
                        </ClickableOpacity>
                    );
                })}
            </div>
        </Section>
    );
};
export default BarGraph;
