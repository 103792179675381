/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';

import { RequestConfig } from '@hero/api-services/types';
import buildBody from '@hero/api-services/utils/buildBody';
import buildHeaders from '@hero/api-services/utils/buildHeaders';
import buildUrl from '@hero/api-services/utils/buildUrl';
import { useAuthContext } from '@hero/hero-auth/v2/AuthProvider';

import { useUserStatusContext } from '../context/UserStatus';
import { useLocation } from 'react-router-dom';
import { mainPage } from '../pages/Home';
import { QueryParams } from '@hero/hero-utils/buildQueryParams';

type UseRequestConfig = {
    body?: {} | string | URLSearchParams;
} & Omit<RequestConfig, 'body'>;

const mainPathnames = ['', '/', mainPage];

const returnResponse = (response: Response, responseType?: 'blob' | 'json') => {
    return responseType === 'blob'
        ? response.blob()
        : response
              .json()
              .then((json) => json)
              .catch(() => null); // when empty response on success, return null
};

const fetchRequest = (
    params: UseRequestConfig,
    preparedQueries: QueryParams,
    accessToken: string
) =>
    fetch(buildUrl(params.url, preparedQueries), {
        method: params.method || 'GET',
        headers: buildHeaders({
            ...params.headers,
            Authorization: 'Bearer ' + accessToken
        }),
        ...(params.body && { body: buildBody(params.body) })
    });

const useRequest = (responseType?: 'blob' | 'json') => {
    const { auth, logout, refreshToken } = useAuthContext();
    const { userStatus } = useUserStatusContext();
    const location = useLocation();

    const request = React.useCallback(
        async <T>(params: UseRequestConfig): Promise<T> => {
            if (!auth || !auth.access_token || !userStatus) {
                return Promise.reject('Auth not provided');
            }

            // Inject hero_organization_id -> mandatory for all request methods
            let preparedQueries = params.query || {};

            preparedQueries = {
                ...preparedQueries,
                hero_organization_id: userStatus.hero_organization_id
            };

            const response = await fetchRequest(params, preparedQueries, auth.access_token);

            if (!response.ok) {
                if ([403, 401].includes(response.status)) {
                    const refreshReq = await refreshToken();
                    if (refreshReq.success) {
                        const retry = await fetchRequest(
                            params,
                            preparedQueries,
                            refreshReq.data.access_token
                        );

                        if (!retry.ok) {
                            // forbidden request on main page, logout
                            if (retry.status === 403 && mainPathnames.includes(location.pathname)) {
                                logout();
                            }

                            // forbidden request on other pages, navigate to home
                            if (
                                retry.status === 403 &&
                                !mainPathnames.includes(location.pathname)
                            ) {
                                window.location.replace('/');
                            }

                            const data = await retry.json();
                            return Promise.reject(data);
                        }

                        return returnResponse(retry, responseType);
                    }
                }

                const data = await response.json();
                return Promise.reject(data);
            }

            return returnResponse(response, responseType);
        },
        [auth, responseType, userStatus, logout, location, refreshToken]
    );

    return request;
};

export default useRequest;
