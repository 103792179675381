// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PLu9RVCmPqiP5OBipCMf{min-height:3rem}.M2FZZxU_K9kflWnHOjrK{border-bottom:2px solid var(--color-primary-alpha)}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberList/MemberTabs/style.module.scss"],"names":[],"mappings":"AACA,sBACI,eAAA,CAGJ,sBACI,kDAAA","sourceRoot":""}]);
// Exports
export var btn = `PLu9RVCmPqiP5OBipCMf`;
export var active = `M2FZZxU_K9kflWnHOjrK`;
export default ___CSS_LOADER_EXPORT___;
