import Container from '@hero/ui-kit/layout/Container';
import React from 'react';
import DeviceInfo from '../DeviceInfo';
import Input from '@hero/ui-kit/inputs/Input';
import P from '@hero/ui-kit/typography/P';
import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import { useFormContext } from '@hero/ui-kit/inputs/Form';

interface ExistingHeroUserFormFieldsProps {
    showAdminEmailError?: boolean;
    showHeroSerialErrorError?: boolean;
    explanation?: string;
    canEdit?: boolean;
}

const ExistingHeroUserFormFields: React.FC<ExistingHeroUserFormFieldsProps> = ({
    showAdminEmailError = false,
    showHeroSerialErrorError = false,
    explanation,
    canEdit = true
}) => {
    const isMinL = useIsMinBreakpoint('l');

    const { setFocus } = useFormContext();

    const disabled = !canEdit;

    React.useEffect(() => {
        if (showAdminEmailError) {
            setFocus('admin_email');
        }

        if (showHeroSerialErrorError) {
            setFocus('external_serial');
        }
    }, [showAdminEmailError, showHeroSerialErrorError, setFocus]);

    return (
        <>
            <DeviceInfo explanation={explanation} />
            <Container
                gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'}
                gridColumnGap="regular"
                gridRowGap="small"
            >
                <div>
                    <Input
                        name="admin_email"
                        displayName="Hero Admin Email"
                        isError={showAdminEmailError}
                        styles={{ ...(showAdminEmailError && { marginBottom: '1.2rem' }) }}
                        disabled={disabled}
                    />
                    {showAdminEmailError ? <P size="small">Email not found</P> : null}
                </div>
                <div>
                    <Input
                        name="external_serial"
                        displayName="Hero Serial Number"
                        isError={showHeroSerialErrorError}
                        styles={{ ...(showHeroSerialErrorError && { marginBottom: '1.2rem' }) }}
                        disabled={disabled}
                    />
                    {showHeroSerialErrorError ? (
                        <P size="small">Hero serial number not found</P>
                    ) : null}
                </div>
            </Container>
        </>
    );
};

export default ExistingHeroUserFormFields;
