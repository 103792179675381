import { IconComponentProps } from '@hero/ui-kit/types';
import React from 'react';

const PDFIcon: React.FC<IconComponentProps> = ({ className, styles }) => {
    return (
        // <svg
        //     viewBox="0 0 30 30"
        //     fill="none"
        //     xmlns="http://www.w3.org/2000/svg"
        //     width={30}
        //     height={30}
        //     style={styles}
        //     className={className}
        // >
        //     <path
        //         d="M0 4C0 1.79086 1.79086 0 4 0H26C28.2091 0 30 1.79086 30 4V26C30 28.2091 28.2091 30 26 30H4C1.79086 30 0 28.2091 0 26V4Z"
        //         fill="white"
        //     />
        //     <path
        //         d="M12 15.5H13V13.5H14C14.2833 13.5 14.5208 13.4042 14.7125 13.2125C14.9042 13.0208 15 12.7833 15 12.5V11.5C15 11.2167 14.9042 10.9792 14.7125 10.7875C14.5208 10.5958 14.2833 10.5 14 10.5H12V15.5ZM13 12.5V11.5H14V12.5H13ZM16 15.5H18C18.2833 15.5 18.5208 15.4042 18.7125 15.2125C18.9042 15.0208 19 14.7833 19 14.5V11.5C19 11.2167 18.9042 10.9792 18.7125 10.7875C18.5208 10.5958 18.2833 10.5 18 10.5H16V15.5ZM17 14.5V11.5H18V14.5H17ZM20 15.5H21V13.5H22V12.5H21V11.5H22V10.5H20V15.5ZM11 21C10.45 21 9.97917 20.8042 9.5875 20.4125C9.19583 20.0208 9 19.55 9 19V7C9 6.45 9.19583 5.97917 9.5875 5.5875C9.97917 5.19583 10.45 5 11 5H23C23.55 5 24.0208 5.19583 24.4125 5.5875C24.8042 5.97917 25 6.45 25 7V19C25 19.55 24.8042 20.0208 24.4125 20.4125C24.0208 20.8042 23.55 21 23 21H11ZM11 19H23V7H11V19ZM7 25C6.45 25 5.97917 24.8042 5.5875 24.4125C5.19583 24.0208 5 23.55 5 23V9H7V23H21V25H7Z"
        //         fill="#E83F3F"
        //     />
        // </svg>

        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            style={styles}
            className={className}
        >
            <path
                d="M9.99984 36.6663C9.08317 36.6663 8.29845 36.34 7.64567 35.6872C6.99289 35.0344 6.6665 34.2497 6.6665 33.333V6.66634C6.6665 5.74967 6.99289 4.96495 7.64567 4.31217C8.29845 3.6594 9.08317 3.33301 9.99984 3.33301H23.3332L33.3332 13.333V33.333C33.3332 34.2497 33.0068 35.0344 32.354 35.6872C31.7012 36.34 30.9165 36.6663 29.9998 36.6663H9.99984ZM21.6665 14.9997H29.9998L21.6665 6.66634V14.9997Z"
                fill="#FF655B"
            />
            <path
                d="M29.6658 26.1082H26.4658V28.0053H29.5744V29.5139H26.4658V32.5996H24.8887V24.5996H29.6658V26.1082Z"
                fill="white"
            />
            <path
                d="M19.9179 24.5996C22.1236 24.5996 23.7693 26.3482 23.7693 28.5996C23.7693 30.851 22.1236 32.5996 19.9179 32.5996H16.7407V24.5996H19.9179ZM19.9179 31.091C21.3007 31.091 22.2607 30.0739 22.2607 28.5996C22.2607 27.1253 21.3007 26.1082 19.9179 26.1082H18.3179V31.091H19.9179Z"
                fill="white"
            />
            <path
                d="M12.9829 24.5996C14.5257 24.5996 15.7143 25.7882 15.7143 27.2853C15.7143 28.7825 14.5257 29.971 12.9829 29.971H11.5771V32.5996H10V24.5996H12.9829ZM12.9829 28.4968C13.6571 28.4968 14.1486 27.971 14.1486 27.2853C14.1486 26.5882 13.6571 26.0739 12.9829 26.0739H11.5771V28.4968H12.9829Z"
                fill="white"
            />
        </svg>
    );
};

export default PDFIcon;
