// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhXduxcz5qusQ2sT_9t4{border:.2rem solid var(--color-neutrals-borderBeta);border-radius:var(--border-radius-large);list-style:none;padding:0;overflow:hidden}.PhXduxcz5qusQ2sT_9t4>li{min-height:7rem;display:flex;align-items:center}.PhXduxcz5qusQ2sT_9t4>li>*{flex:1}.PhXduxcz5qusQ2sT_9t4>li+li{border-radius:0 !important;border-top:.2rem solid var(--color-neutrals-borderBeta)}.PhXduxcz5qusQ2sT_9t4.h1k6COzXigttVLMzu5hI{padding-top:calc(var(--gutter)/2);padding-bottom:calc(var(--gutter)/2)}.PhXduxcz5qusQ2sT_9t4.h1k6COzXigttVLMzu5hI>li{min-height:4rem}.PhXduxcz5qusQ2sT_9t4.h1k6COzXigttVLMzu5hI>li+li{border-top:none}.HDA7YLsZODrIShTHzwf2{border:.2rem solid var(--color-neutrals-borderBeta)}.HDA7YLsZODrIShTHzwf2 .PhXduxcz5qusQ2sT_9t4{border-radius:0 !important;border-left:none;border-right:none}.HDA7YLsZODrIShTHzwf2 .PhXduxcz5qusQ2sT_9t4:last-child{border-bottom:none}`, "",{"version":3,"sources":["webpack://./components/Table/style.module.scss"],"names":[],"mappings":"AACA,sBACI,mDAAA,CACA,wCAAA,CACA,eAAA,CACA,SAAA,CACA,eAAA,CAEA,yBACI,eAAA,CACA,YAAA,CACA,kBAAA,CAEA,2BACI,MAAA,CAIR,4BACI,0BAAA,CACA,uDAAA,CAGJ,2CACI,iCAAA,CACA,oCAAA,CAEA,8CACI,eAAA,CAGJ,iDACI,eAAA,CAKZ,sBACI,mDAAA,CAEA,4CACI,0BAAA,CACA,gBAAA,CACA,iBAAA,CAEA,uDACI,kBAAA","sourceRoot":""}]);
// Exports
export var table = `PhXduxcz5qusQ2sT_9t4`;
export var borderless = `h1k6COzXigttVLMzu5hI`;
export var tableWrapper = `HDA7YLsZODrIShTHzwf2`;
export default ___CSS_LOADER_EXPORT___;
