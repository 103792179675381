import * as yup from 'yup';

const supervisorFormSchema = yup
    .object()
    .shape({
        duration: yup.string().required('Duration is required'),
        supervisor_comment: yup.string().trim().required('Comment is required.')
    })
    .defined();

export type SupervisorFormSchemaParams = yup.InferType<typeof supervisorFormSchema>;

export default supervisorFormSchema;
