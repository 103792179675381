import Circle from '@hero/ui-kit/graphics/Circle';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import React from 'react';
import { IAMListRole } from '../../../../pages/IAM/types';
import * as Style from './style.module.scss';
import ControlIcon from '../../../../icons/ControlIcon';
import P from '@hero/ui-kit/typography/P';

interface RolePickerProps {
    selectedId?: string;
    onChange?: (selectedId: string) => void;
    roles?: IAMListRole[];
    disabled?: boolean;
}

const RolePicker: React.FC<RolePickerProps> = ({ roles, selectedId, onChange, disabled }) => {
    const handleClick = React.useCallback(
        (newId: string) => {
            onChange && onChange(newId);
        },
        [onChange]
    );

    const getHeroRoleDescription = (role: IAMListRole) => {
        if (role.name.includes('Admin')) {
            return 'View and edit all member details, plus manages internal accounts.';
        }

        if (role.name.includes('Standard')) {
            return 'View and edit all member details.';
        }

        return 'View only. Can not edit or update member details.';
    };

    return (
        <div>
            <div className={Style.roleWrapper}>
                {roles?.map((role) => (
                    <ClickableOpacity
                        disabled={disabled}
                        fullWidth
                        key={role.id}
                        alt={`Toggle ${role.name} ${disabled ? 'disabled' : 'enabled'}`}
                        className={`${Style.roleItem} ${disabled ? Style.disabled : ''}`}
                        onClick={() => handleClick(role.id)}
                    >
                        <div className={Style.roleItemInner}>
                            {role.id === selectedId ? (
                                <ControlIcon
                                    type="brand"
                                    styles={{ minWidth: '24px' }}
                                    width={'24px'}
                                />
                            ) : (
                                <Circle
                                    disabled
                                    inverted
                                    styles={{ minWidth: '24px' }}
                                    width={'24px'}
                                />
                            )}
                            <div style={{ textAlign: 'left' }}>
                                <P strong noDefaultMargin>
                                    {role.name}
                                </P>
                                <P noDefaultMargin>{getHeroRoleDescription(role)}</P>
                            </div>
                        </div>
                    </ClickableOpacity>
                ))}
            </div>
        </div>
    );
};

export default RolePicker;
