import React from 'react';

import UploadIcon from '@hero/ui-kit/icons/utility/UploadIcon';
import PlusIcon from '@hero/ui-kit/icons/utility/PlusIcon';
import CircularButton from '@hero/ui-kit/inputs/CircularButton';
import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import Layout from '../../components/Layout';
import NoResults from '../../components/States/NoResults';
import useExportLeads from './api/useExportLeads';
import useGetLeadsList from './api/useGetLeadsList';
import useQueryParams from './useQueryParams';
import LeadsForm from './components/LeadsForm';
import LeadsTable from './LeadsTable';
import StatusFilter from './components/StatusFilter';
import DateFilter from '../../components/DateFilter';
import useGetAllLeadStates from './api/useGetAllLeadStates';
import useStatesQueryParams from './useStatesQueryParams';
import LeadsFilter from './components/LeadsFilter';
import LimitItems from '../../components/LimitItems';
import HeroMemberFilter from './components/HeroMemberFilter';
import LocationFilter from '../../components/LocationFilter';
import * as Style from './style.module.scss';

const LeadsManagement: React.FC = () => {
    const [createLeadModal, setCreateLeadModal] = React.useState(false);
    const { data: allLeadStates, isLoading: isLoadingLeadStates } = useGetAllLeadStates();

    const { query, setSearchParams, hasFilters } = useQueryParams();
    const states = useStatesQueryParams(allLeadStates);
    const { exportLeads, loading } = useExportLeads({ ...query, states });
    const { data, mutateAsync: fetchLeads, isPending: isLeadsLoading } = useGetLeadsList();

    const locationParam = 'state';

    const handleFetchLeads = React.useCallback(() => {
        return fetchLeads({ payload: { ...query, states } });
    }, [query, states, fetchLeads]);

    const handleClearFilter = () => {
        setSearchParams((prevState) => {
            let keys = [];

            for (let key of prevState.keys()) {
                keys.push(key);
            }

            window.sessionStorage.removeItem(locationParam);

            keys.forEach((key) => prevState.delete(key));

            return prevState;
        });
    };

    React.useEffect(() => {
        // we need to wait for unlock lead to finish on lead details
        setTimeout(() => {
            handleFetchLeads();
        }, 300);
    }, [handleFetchLeads]);

    const headerElements = (
        <>
            <LocationFilter searchParam={locationParam} />
            {query?.filter_type === 'default' ? (
                <CircularButton
                    disabled={loading}
                    variant="secondary"
                    isLink={false}
                    onClick={exportLeads}
                    marginLeft="small"
                    className="flex-center"
                    styles={{ opacity: loading ? 0.5 : 1 }}
                    alt="Export Leads"
                >
                    <UploadIcon size="utility" styles={{ transform: 'rotateZ(180deg)' }} />
                </CircularButton>
            ) : null}

            <CircularButton
                variant="secondary"
                isLink={false}
                onClick={(e) => {
                    e.stopPropagation();
                    setCreateLeadModal(true);
                }}
                marginLeft="small"
                className="flex-center"
                alt="Add Lead"
            >
                <PlusIcon size="utility" />
            </CircularButton>
        </>
    );

    return (
        <>
            <Layout
                title="Leads"
                search
                placeholder="Enter User Name, Patient ID or Email"
                additionalElement={headerElements}
                searchLabel="Search lead"
            >
                <LeadsFilter />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1.6rem',
                        minHeight: '4rem',
                        marginBottom: '2.4rem',
                        justifyContent: 'space-between'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1.6rem',
                            minHeight: '4rem'
                        }}
                    >
                        <LimitItems
                            isLoading={isLeadsLoading}
                            limit={query?.limit ? +query.limit : 10}
                            offset={query?.offset ? +query.offset : 0}
                            total_count={data?.total_count}
                        />
                        {query?.filter_type === 'default' ? (
                            <>
                                <DateFilter searchParam="created" defaultLabel="Created" />
                                <DateFilter searchParam="updated" defaultLabel="Updated" />
                                <StatusFilter allLeadStates={allLeadStates} />
                                <StatusFilter isSubStatus allLeadStates={allLeadStates} />
                                <HeroMemberFilter />
                            </>
                        ) : null}
                    </div>
                    {query?.filter_type === 'default' && hasFilters ? (
                        <button className={Style.filterClearBtn} onClick={handleClearFilter}>
                            Clear filters
                        </button>
                    ) : null}
                </div>

                {data?.leads.length === 0 ? (
                    <NoResults
                        search={query.search || ''}
                        hideSearchLabel={!query.search}
                        searchSupportsLabel="User Name, ID or Email"
                    />
                ) : (
                    <LeadsTable
                        search={query.search || ''}
                        onFetchLeads={handleFetchLeads}
                        data={data}
                        isLoading={isLoadingLeadStates || isLeadsLoading}
                    />
                )}
            </Layout>
            {createLeadModal && (
                <Modal
                    isCancelable
                    onClose={() => {
                        setCreateLeadModal(false);
                    }}
                    styles={{ padding: '0' }}
                    width="page"
                >
                    <H role="h5" noDefaultMargin>
                        Add Lead
                    </H>

                    <LeadsForm onSuccess={() => setCreateLeadModal(false)} />
                </Modal>
            )}
        </>
    );
};

export default LeadsManagement;
