import useTheme from '@hero/branding/theme';
import getIconColor from '@hero/ui-kit/icons/getIconColor';
import { IconComponentProps } from '@hero/ui-kit/types';
import React from 'react';

const RefreshIcon: React.FC<IconComponentProps> = ({
    type = 'utility',
    size = 'regular',
    disabled = false,
    styles,
    ...rest
}) => {
    const { colors, sizing } = useTheme();
    const color = getIconColor(colors, type, disabled);
    const sizePx = sizing.widths.icon[size];

    return (
        <svg
            viewBox="0 0 20 20"
            fill="none"
            width={sizePx}
            height={sizePx}
            style={styles}
            {...rest}
        >
            <g clip-path="url(#clip0_2796_341)">
                <path
                    d="M17.5098 10.8396C17.3498 12.5296 16.6298 14.1696 15.3298 15.4596C12.3798 18.4096 7.60977 18.4096 4.65977 15.4596C1.70977 12.5096 1.70977 7.73965 4.65977 4.78965C7.60977 1.83965 12.3798 1.83965 15.3298 4.78965C15.7198 5.17965 15.9698 5.45965 16.1298 5.63965"
                    stroke={color}
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
                <path
                    d="M16.4202 2.90039V6.10039H13.2202"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2796_341">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RefreshIcon;
