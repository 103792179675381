const getMemberUrl = (
    heroUserId?: number | null,
    memberId?: number | null,
    additionalPath?: string
) => {
    const id = heroUserId || memberId;
    const role = heroUserId ? 'caregiver' : 'primary_user';
    const path = additionalPath?.length ? `/${additionalPath}` : '';

    return `/members/${id}${path}?role=${role}`;
};

export default getMemberUrl;
