import React from 'react';

import Loader from '@hero/ui-kit/components/Loader';
import Form from '@hero/ui-kit/inputs/Form';

import { MemberCreatePayload } from '../../types';
import FormElements from './FormElements';
import { createMemberFormSchema, CreateMemberFormSchema } from './validator';
import useMemberCreate from '../../api/useMemberCreate';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
import prepareState from '../../../../utils/prepareState';

type Props = {
    onSuccess?: () => void;
};

const CreateMemberForm: React.FC<Props> = ({ onSuccess }) => {
    const mutation = useMemberCreate(onSuccess);

    const handleSubmit = React.useCallback(
        ({
            first_name,
            last_name,
            role,
            email,
            phone,
            external_user_id,
            address_line_1,
            address_line_2,
            city,
            zip_code,
            state,
            ...rest
        }: CreateMemberFormSchema) => {
            const payload: MemberCreatePayload = {
                first_name,
                last_name,
                role,
                email,
                phone: formatPhoneNumber(phone),
                external_user_id,
                shipping_address: {
                    address_line_1,
                    address_line_2,
                    city,
                    zip_code,
                    state: prepareState(state)
                },
                ...rest
            };

            mutation.mutate({ payload });
        },
        [mutation]
    );

    return (
        <Form submitFn={handleSubmit} validationSchema={createMemberFormSchema}>
            {mutation.isPending && <Loader hideLogo={false} type="warn" />}

            <FormElements error={mutation.error || undefined} loading={mutation.isPending} />
        </Form>
    );
};

export default CreateMemberForm;
