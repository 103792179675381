import { InferType, lazy, object, string, StringSchema, boolean } from 'yup';
import state from '@hero/validators/state';

const shippingAddressFormSchema = object()
    .shape({
        address_line_1: string()
            .trim()
            .required('Address is required')
            .test(
                'no-po-boxes',
                'P.O. Boxes are not allowed',
                (val) => !(val && /po\s*box/i.test(val))
            ),
        address_line_2: string().trim().nullable(),
        city: string().trim().required('City is required'),
        state: lazy((val) =>
            !val ? string().trim().required('State is required') : state
        ) as unknown as StringSchema<string, object>,
        zip: string()
            .trim()
            .matches(/^\d{5}$/, 'Invalid zip code')
            .required('Zip code is required'),
        isDirty: boolean().default(false)
    })
    .defined();

export type ShippingAddressFormSchemaParams = InferType<typeof shippingAddressFormSchema>;

export { shippingAddressFormSchema };
