import React from 'react';

import { usStateNames } from '@hero/hero-utils/address/states';
import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import Button from '@hero/ui-kit/inputs/Button';
import Combobox from '@hero/ui-kit/inputs/Combobox';
import { useFormContext } from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Radio from '@hero/ui-kit/inputs/Radio';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import RadioPill from '@hero/ui-kit/inputs/RadioPill';
import RadioPillGroup from '@hero/ui-kit/inputs/RadioPillGroup';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';

import * as Style from './style.module.scss';
import AddressCombobox from '../../../../components/AddressGoogleCombobox';

const FormElements: React.FC<{ error?: string; loading?: boolean }> = ({ error, loading }) => {
    const formContext = useFormContext();
    const isMinL = useIsMinBreakpoint('l');

    const isSubmitDisabled: boolean = React.useMemo(() => {
        return loading || (formContext?.formState && !formContext.formState.isValid);
    }, [formContext, loading]);

    const role = formContext.watch('role');

    return (
        <Section noDefaultPadding>
            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <Input name="first_name" displayName="First name" />
                <Input name="last_name" displayName="Last name" />
            </Container>

            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <RadioGroup name="role" displayName="" noBorders className={Style.radioGroup}>
                    <Radio
                        status="success"
                        id="primary_user"
                        value="primary_user"
                        labelText="Primary User"
                    />
                    <Radio
                        status="success"
                        id="caregiver"
                        value="caregiver"
                        labelText="Caregiver"
                    />
                </RadioGroup>
            </Container>

            <Input name="email" displayName="Email" />
            <Input
                name="external_user_id"
                displayName="Patient ID (optional)"
                placeholder="If you have a Patient ID from your EMR, you may enter it here"
            />

            <Section centered padding="small">
                <strong>Shipping information</strong>
            </Section>

            <AddressCombobox zipName="zip_code" />
            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <Input name="address_line_2" displayName="Apt, suite, etc (optional)" />
                <Input name="city" displayName="City" />
            </Container>

            <Container gridTemplateColumns={isMinL ? '1fr 1fr 2fr' : '1fr'} gridColumnGap="regular">
                <div style={{ width: '100%' }}>
                    <Combobox
                        name="state"
                        displayName="State"
                        suggestions={usStateNames}
                        noExtraMargin
                        onlyValidateWithError={true}
                    />
                </div>
                <Input name="zip_code" displayName="Zip" />
                <Input name="phone" displayName="Phone" styles={{ marginBottom: '0.5rem' }} />
            </Container>

            {role === 'caregiver' && (
                <div className={Style.radioPillWrap}>
                    <strong>Has the caregiver spoken to their loved one about Hero?</strong>
                    <RadioPillGroup
                        name="caregiver_spoken"
                        alignment="right"
                        styles={{ width: '18rem' }}
                    >
                        <RadioPill id="yes" value="yes" labelText="Yes" />
                        <RadioPill id="no" value="no" labelText="No" />
                    </RadioPillGroup>
                </div>
            )}

            <Section noDefaultPadding marginVertical="regular">
                {error && (
                    <Section
                        subtheme={['status', 'fail', 'beta']}
                        role="aside"
                        border
                        padding="small"
                        marginVertical="small"
                    >
                        Error: Network response was not ok
                    </Section>
                )}
                <Section centered noDefaultPadding>
                    <Button
                        type="submit"
                        disabled={isSubmitDisabled}
                        width={isMinL ? 'large' : 'full'}
                    >
                        Submit
                    </Button>
                </Section>
            </Section>
        </Section>
    );
};

export default FormElements;
