import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReportsQueryParams } from './types';
import { format } from 'date-fns';

const formatDate = 'yyyy-MM-dd';

const getDates = (param: string, dates: string[], withoutTime = false) => {
    return {
        [`${param}_after`]: withoutTime ? format(new Date(dates[0]), formatDate) : dates[0],
        [`${param}_before`]: withoutTime ? format(new Date(dates[1]), formatDate) : dates[1]
    };
};

const useReportsQueryParams = () => {
    const [searchParams] = useSearchParams();

    return useMemo(() => {
        let params = {
            limit: searchParams.get('limit') || 10,
            offset: searchParams.get('offset') || 0,
            ...(searchParams.get('created') && {
                ...getDates('created', searchParams.get('created')!.split('|'))
            }),
            ...(searchParams.get('cycle_end') && {
                ...getDates('report_to', searchParams.get('cycle_end')!.split('|'), true)
            }),
            ...(searchParams.get('search') && {
                search: searchParams.get('search')
            })
        };

        return params as ReportsQueryParams;
    }, [searchParams]);
};

export default useReportsQueryParams;
