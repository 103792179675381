import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import { SearchProps } from '../SearchForm';
import Header, { HeaderProps } from './Header';
import { WhiteSpaceSize } from '@hero/ui-kit/types';

type Props = HeaderProps & {
    children: React.ReactNode;
    paddingHorizontal?: WhiteSpaceSize;
} & SearchProps;

const Layout: React.FC<Props> = ({ children, paddingHorizontal = 'regular', ...props }) => {
    return (
        <>
            <Header {...props} />
            <Section paddingHorizontal={paddingHorizontal} paddingTop="none">
                {children}
            </Section>
        </>
    );
};

export default Layout;
