import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { ReportDataResponse, ReportQueryParams } from '../types';
import { useQuery } from '@tanstack/react-query';
import { REPORT_NAME } from './constants';

const useDownloadReport = (query?: ReportQueryParams, enabled = false) => {
    const req = useRequest();

    const request = () => {
        return req<ReportDataResponse>({
            url: `${envVars.API_RTM_HOSTNAME}billing/device-activity-report/urls/`,
            query
        });
    };

    return useQuery({
        queryFn: request,
        queryKey: [REPORT_NAME, query],
        enabled
    });
};

export default useDownloadReport;
