import { InferType, lazy, object, string, StringSchema, boolean } from 'yup';

import email, { validEmailRegex } from '@hero/validators/email';
import emailDomain from '@hero/validators/email-domain';
import { phoneYupValidation } from '../../../../../utils/validator';

const contactPersonFormSchema = object()
    .shape({
        first_name: string().trim().required('First name is required.'),
        last_name: string().trim().required('Last name is required.'),
        email: lazy((val?: string) => {
            if (!val) {
                return string().trim().required('Email address is required');
            } else if (!validEmailRegex.test(`${val}`)) {
                return email;
            } else {
                return emailDomain;
            }
        }) as unknown as StringSchema<string, object>,
        phone: phoneYupValidation,
        isDirty: boolean().default(false)
    })
    .defined();

export type ContactPersonFormSchemaParams = InferType<typeof contactPersonFormSchema>;

export { contactPersonFormSchema };
