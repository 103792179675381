import { formatShortDate } from '@hero/hero-utils/date';
import { format, isBefore } from 'date-fns';

export const getFormattedDate = (
    date: Date,
    dateFormat: string,
    options?: {
        locale?: Locale;
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        firstWeekContainsDate?: number;
        useAdditionalWeekYearTokens?: boolean;
        useAdditionalDayOfYearTokens?: boolean;
    }
) => {
    return format(date, dateFormat, options);
};

export const getIsBeforeComparedDate = (date: Date, comparedDate: Date) => {
    return isBefore(date, comparedDate);
};

export const getUtcDateFormat = (date?: string, dateFormat?: string) => {
    const dateT = date ? new Date(date) : undefined;
    if (dateT) {
        const dateObj = new Date(dateT.getUTCFullYear(), dateT.getUTCMonth(), dateT.getUTCDate());

        return format(dateObj, dateFormat || 'MMM d, yyyy');
    }

    return 'N/A';
};

export const getDOBFormat = (date?: string | null, dateFormat?: string) => {
    if (date) {
        const dob = new Date(date);

        const hours = dob.getUTCHours();

        const dateObj = new Date(
            Date.UTC(
                dob.getUTCFullYear(),
                dob.getUTCMonth(),
                dob.getUTCDate(),
                hours === 0 ? 12 : dob.getUTCHours(),
                dob.getUTCMinutes(),
                dob.getUTCSeconds()
            )
        );

        return format(dateObj, dateFormat || 'MM-dd-yyyy');
    }

    return '';
};
