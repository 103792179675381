// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wOiqD5pxEJMARDLQT8dl{pointer-events:none;position:fixed;bottom:var(--gutter);left:0;right:0;display:flex;align-items:center;justify-content:center;transition:all .25s cubic-bezier(0.4, 0, 0.3, 1) 0s}.wOiqD5pxEJMARDLQT8dl.cntAeRh4YIs2aMZogImX{opacity:0;transform:translateY(2rem)}.hBPKHZS7mJnU3kds9hYf{display:flex;align-items:center;justify-content:center;column-gap:1rem;padding:12px 16px 12px 16px;border-radius:10rem;color:var(--color-neutrals-background);font-weight:var(--typo-fontweight-strong)}.hBPKHZS7mJnU3kds9hYf.fuZriDMC7ygWIkWeaz23{background-color:var(--color-neutrals-textAlpha)}.hBPKHZS7mJnU3kds9hYf.rZI6fsqknv2rMHzVXYjL{background-color:#74a305}.hBPKHZS7mJnU3kds9hYf.bqRyj9zoKqiuAyaj0fXB{background-color:var(--lead-status-red)}`, "",{"version":3,"sources":["webpack://./components/Notification/style.module.scss","webpack://./style/vars-and-mixins.scss"],"names":[],"mappings":"AACA,sBACI,mBAAA,CACA,cAAA,CACA,oBAAA,CACA,MAAA,CACA,OAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CC6FA,mDAAA,CD1FA,2CACI,SAAA,CACA,0BAAA,CAIR,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,2BAAA,CACA,mBAAA,CACA,sCAAA,CACA,yCAAA,CAEA,2CACI,gDAAA,CAGJ,2CACI,wBAAA,CAGJ,2CACI,uCAAA","sourceRoot":""}]);
// Exports
export var wrapper = `wOiqD5pxEJMARDLQT8dl`;
export var hidden = `cntAeRh4YIs2aMZogImX`;
export var notification = `hBPKHZS7mJnU3kds9hYf`;
export var neutral = `fuZriDMC7ygWIkWeaz23`;
export var success = `rZI6fsqknv2rMHzVXYjL`;
export var fail = `bqRyj9zoKqiuAyaj0fXB`;
export default ___CSS_LOADER_EXPORT___;
