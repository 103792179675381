import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { DischargeReasons } from '../types';
import { DISCHARGE_REASONS_KEY } from './constants';

const useGetDischargeReasons = () => {
    const req = useRequest();

    const action = useCallback(() => {
        return req<DischargeReasons>({
            url: `${envVars.API_COMMERCE_HOSTNAME}enterprise/discharge-reasons/`
        });
    }, [req]);

    return useQuery({
        queryFn: action,
        queryKey: [DISCHARGE_REASONS_KEY]
    });
};

export default useGetDischargeReasons;
