import React from 'react';
import useTheme from '@hero/branding/theme';
import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';
import {
    CaregiverInfo,
    OrganizationMemberListDeviceInfo,
    ExchangeDeviceInfo,
    MemberRole
} from '../types';
import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import getMemberUrl from '../utils/getMemberUrl';
import * as Style from './ListItem.module.scss';
import HighlightedText from '../../../components/HighlightedText';
import { useLocation, useNavigate } from 'react-router-dom';
import DeviceSerial from './DeviceSerial';
import Caregivers from './Caregivers';
import DeviceStatus from './DeviceStatus';
import { getFormattedDate } from '../../../utils/date';

const ListItem: React.FC<{
    memberId?: number | null;
    heroUserId?: number | null;
    activatedAt?: string | null;
    externalUserId?: string | null;
    name?: string;
    email?: string;
    deviceInfo?: OrganizationMemberListDeviceInfo;
    exchangeDeviceInfo?: ExchangeDeviceInfo;
    caregivers?: CaregiverInfo[];
    search: string;
    role: MemberRole | null;
    canOpenDetails?: boolean;
}> = ({
    name,
    heroUserId,
    memberId,
    email,
    deviceInfo,
    caregivers,
    search,
    exchangeDeviceInfo,
    externalUserId,
    activatedAt,
    // role,
    canOpenDetails = false
}) => {
    const theme = useTheme();
    const isMinL = useIsMinBreakpoint('l');
    const navigate = useNavigate();
    const location = useLocation();

    const hasId: boolean = React.useMemo(() => {
        return !!memberId || !!heroUserId;
    }, [memberId, heroUserId]);

    const handleNavigate = React.useCallback(
        (e: React.SyntheticEvent) => {
            if (!e.isDefaultPrevented() && hasId && canOpenDetails) {
                const from = `${location.pathname}${location.search}`;
                navigate(`${getMemberUrl(heroUserId, memberId)}`, { state: { from } });
            }
        },
        [hasId, memberId, heroUserId, navigate, canOpenDetails, location]
    );

    return (
        <div
            onClick={handleNavigate}
            onKeyDown={() => null}
            tabIndex={0}
            role="button"
            style={{ cursor: hasId ? 'pointer' : 'not-allowed' }}
            className={Style.memberListWrapper}
        >
            <Container
                gridTemplateColumns={isMinL ? '3.4fr 2fr 2fr 3fr 1.6fr' : '1fr'}
                gridColumnGap="small"
                className={Style.container}
            >
                <>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'start',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <P noDefaultMargin strong>
                            <HighlightedText highlight={search} text={name} />
                        </P>
                        {email ? (
                            <P
                                size="small"
                                noDefaultMargin
                                styles={{
                                    color: theme.colors.neutrals.textGamma
                                }}
                            >
                                <HighlightedText highlight={search} text={email} /> |{' '}
                                <HighlightedText
                                    highlight={search}
                                    text={externalUserId || undefined}
                                />
                            </P>
                        ) : (
                            ''
                        )}
                    </div>
                    <DeviceSerial
                        deviceInfo={deviceInfo}
                        search={search}
                        exchangeDeviceInfo={exchangeDeviceInfo}
                    />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <P
                            noDefaultMargin
                            styles={{
                                color: theme.colors.neutrals.textGamma
                            }}
                        >
                            {activatedAt
                                ? getFormattedDate(new Date(activatedAt), 'dd/MM/yyyy')
                                : 'Not Activated Yet'}
                        </P>
                    </div>
                    <Caregivers search={search} caregivers={caregivers} />
                    <DeviceStatus deviceInfo={deviceInfo} exchangeDeviceInfo={exchangeDeviceInfo} />
                </>
            </Container>
        </div>
    );
};

export default ListItem;
