import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetNotification } from '../../../context/Notification';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { MemberUpdatePayload } from '../types';
import { ORGANIZATION_MEMBER_KEY } from './constants';

type GetOrganizationMemberParams = {
    member_id?: number | null;
};

const useMemberUpdate = (query: GetOrganizationMemberParams, onSuccess?: () => void) => {
    const req = useRequest();
    const queryClient = useQueryClient();
    const setNotification = useSetNotification();

    const request = ({ payload }: { payload: MemberUpdatePayload }) => {
        return req({
            url: `${envVars.API_COMMERCE_HOSTNAME}enterprise/member/`,
            query,
            method: 'PATCH',
            body: JSON.stringify(payload)
        });
    };

    return useMutation({
        mutationFn: request,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ORGANIZATION_MEMBER_KEY] });
            setNotification({ message: `Member updated` });
            onSuccess && onSuccess();
        },
        onError: (error: { errors: string[] }) => {
            return error;
        }
    });
};

export default useMemberUpdate;
