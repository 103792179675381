import React from 'react';
import { useSearchParams } from 'react-router-dom';

import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';

import { AllApprovalStatusSubStates, ApprovalStatus, LeadStatesResponse } from '../../types';
import * as Style from './style.module.scss';
import getApprovalStatus from '../../utils/getApprovalStatus';
import Pill from '../Pill';
import CheckboxSquare from '@hero/ui-kit/graphics/CheckboxSquare';
import getApprovalSubStatus from '../../utils/getApprovalSubStatus';
import P from '@hero/ui-kit/typography/P';
import Filter from '../../../../components/Filter';
import getApprovalSubStatusesByStatus from '../../utils/getApprovalSubStatusesByStatus';
import getApprovalStatusesBySubStatus from '../../utils/getApprovalStatusesBySubStatus';

const approvalStatuses: ApprovalStatus[] = [
    'INITIAL_INTEREST',
    'INSURANCE_VALIDATION',
    'QUALIFIED',
    'MEDICAL_CHECK',
    'APPROVED',
    'REJECTED',
    'EXPIRED'
];

const approvalSubStatus: AllApprovalStatusSubStates[] = [
    'MISSED_APPOINTMENT',
    'CALL_ATTEMPTED',
    'DUPLICATE',
    'WRONG_NUMBER',
    'NO_INSURANCE',
    'NO_NECESSITY',
    'PENDING_CALL',
    'CONSENT_NEEDED',
    'STALE',
    'NOT_INTERESTED',
    'SCHEDULED_CALL',
    'NEED_TO_VALIDATE',
    'ENROLMENT_NEEDED',
    'ENROLLED',
    'VALIDATING',
    'IMPENDING_APPOINTMENT'
];

type GetAllStatusesProps = {
    isSubStatus: boolean;
    selectedApprovalStatuses: string[];
    selectedApprovalSubStatuses: string[];
    allLeadStates?: LeadStatesResponse;
};

const getAllStatuses = ({
    isSubStatus,
    selectedApprovalStatuses,
    selectedApprovalSubStatuses,
    allLeadStates
}: GetAllStatusesProps) => {
    let filteredStatuses = approvalStatuses;
    let filteredSubStatuses = approvalSubStatus;

    if (selectedApprovalStatuses.length) {
        let newSubStatuses: AllApprovalStatusSubStates[] = [];
        selectedApprovalStatuses.forEach((selectedApprovalStatus) => {
            const result = getApprovalSubStatusesByStatus(allLeadStates, selectedApprovalStatus);
            if (result) {
                newSubStatuses = [...new Set([...newSubStatuses, ...result])];
            }
        });

        filteredSubStatuses = [
            ...new Set([
                ...newSubStatuses,
                ...(selectedApprovalSubStatuses as AllApprovalStatusSubStates[])
            ])
        ];
    }

    if (selectedApprovalSubStatuses.length) {
        let newStatuses: ApprovalStatus[] = [];
        selectedApprovalSubStatuses.forEach((selectedApprovalSubStatus) => {
            const result = getApprovalStatusesBySubStatus(allLeadStates, selectedApprovalSubStatus);
            if (result) {
                newStatuses = [...new Set([...newStatuses, ...result])];
            }
        });

        filteredStatuses = [
            ...new Set([...newStatuses, ...(selectedApprovalStatuses as ApprovalStatus[])])
        ];
    }

    return isSubStatus ? filteredSubStatuses : filteredStatuses;
};

const StatusFilter: React.FC<{ isSubStatus?: boolean; allLeadStates?: LeadStatesResponse }> = ({
    isSubStatus = false,
    allLeadStates
}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const searchParam = isSubStatus ? 'approval_sub_status' : 'approval_status';
    const selectedStatuses = searchParams.get(searchParam);

    const updateStatusFilter = React.useCallback(
        (status: ApprovalStatus | AllApprovalStatusSubStates) => {
            setSearchParams((searchParams) => {
                const param = selectedStatuses?.split(',') || [];

                const index = param?.indexOf(status);

                if (index < 0) {
                    param.push(status);
                } else {
                    param.splice(index, 1);
                }

                const paramString = param.filter((i) => !!i).join(',');

                if (paramString) {
                    searchParams.set(searchParam, paramString);
                } else {
                    searchParams.delete(searchParam);
                }

                searchParams.delete('offset');

                return searchParams;
            });
        },
        [setSearchParams, searchParam, selectedStatuses]
    );

    const handleClear = React.useCallback(() => {
        setSearchParams((prev) => {
            prev.delete(searchParam);

            return prev;
        });
    }, [searchParam, setSearchParams]);

    const filterLabel = React.useMemo(() => {
        if (selectedStatuses) {
            const statusesArray = selectedStatuses.split(',');
            return isSubStatus
                ? statusesArray
                      .map((s) => getApprovalSubStatus(s as AllApprovalStatusSubStates).label)
                      .join(', ')
                : statusesArray.map((s) => getApprovalStatus(s as ApprovalStatus).label).join(', ');
        }

        return isSubStatus ? 'Status: All' : 'Stage: All';
    }, [isSubStatus, selectedStatuses]);

    const selectedArray = React.useMemo(() => {
        return selectedStatuses ? selectedStatuses.split(',').reverse() : [];
    }, [selectedStatuses]);

    const statuses = React.useMemo(() => {
        let selectedApprovalStatuses = searchParams.get('approval_status')
            ? searchParams.get('approval_status')!.split(',')
            : [];
        let selectedApprovalSubStatuses = searchParams.get('approval_sub_status')
            ? searchParams.get('approval_sub_status')!.split(',')
            : [];

        const allStatuses = getAllStatuses({
            isSubStatus,
            selectedApprovalStatuses,
            selectedApprovalSubStatuses,
            allLeadStates
        });

        return allStatuses.sort((a, b) => selectedArray.indexOf(b) - selectedArray.indexOf(a));
    }, [isSubStatus, selectedArray, allLeadStates, searchParams]);

    return (
        <Filter
            hasFilters={typeof selectedStatuses === 'string'}
            filterLabel={filterLabel}
            width="28rem"
        >
            <div className={`${Style.filterWrapper}`}>
                {selectedArray.length > 1 ? (
                    <ClickableOpacity
                        onClick={handleClear}
                        alt={`Clear ${isSubStatus ? 'substatus' : 'status'} filter`}
                        className={Style.filterClearBtn}
                    >
                        Clear selected items
                    </ClickableOpacity>
                ) : null}
                {statuses.map((s) => {
                    const status = isSubStatus
                        ? getApprovalSubStatus(s as AllApprovalStatusSubStates)
                        : getApprovalStatus(s as ApprovalStatus);
                    return (
                        <ClickableOpacity
                            fullWidth
                            key={s}
                            alt={`Toggle ${status.label}`}
                            className={Style.filterStatusItem}
                            onClick={() => {
                                updateStatusFilter(s);
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                <CheckboxSquare
                                    isChecked={selectedStatuses?.includes(s) || false}
                                    isFocused={false}
                                    isHovered={false}
                                    disabled={false}
                                    styles={{
                                        minWidth: '1.6rem',
                                        height: '1.6rem',
                                        width: '1.6rem'
                                    }}
                                />
                                {isSubStatus ? (
                                    <P noDefaultMargin>{status.label}</P>
                                ) : (
                                    <Pill label={status.label} className={status.className} />
                                )}
                            </div>
                        </ClickableOpacity>
                    );
                })}
            </div>
        </Filter>
    );
};

export default StatusFilter;
