import React from 'react';

import envVars from '../../../../../constants/envVars';
import useRequest from '../../../../../hooks/useRequest';
import { WeeklyDoses } from '@hero/enterprise-portal-common/src/Adherence/AdherenceCalendar/AdherenceCalendarWeekly/Acw.types';

type Query = {
    target_datetime: string | null;
};

const useGetSidebarDay = (hero_user_id: number) => {
    const req = useRequest();

    const request = React.useCallback(
        (query: Query) => {
            return req<WeeklyDoses[]>({
                url: `${envVars.API_CLOUD_HOSTNAME}enterprise/adherence/dose-list`,
                query: Object.assign(query, { mode: 'daily', hero_user_id })
            });
        },
        [req, hero_user_id]
    );

    return request;
};

export default useGetSidebarDay;
