import { MedicationDetails, MedicationSchedule } from '../../../../../pages/FleetManagement/types';

export const everyDayLabel = 'Every day';

const getPreparedMedications = (medication: MedicationDetails) => {
    const dowList = medication.pill_schedules?.filter((med) => med.dow !== null) || [];
    const everyXDaysList =
        medication.pill_schedules?.filter((med) => med.every_x_days !== null) || [];

    return {
        dowListGrouped: dowList.reduce(
            (group: { [key: string]: MedicationSchedule[] }, dowListItem) => {
                const { dow } = dowListItem;
                const label = dow === 'Mon, Tue, Wed, Thu, Fri, Sat, Sun' ? everyDayLabel : dow;
                const groupProperty = label || 'unknown';
                group[groupProperty] = group[groupProperty] ?? [];
                group[groupProperty].push(dowListItem);
                return group;
            },
            {}
        ),
        everyXDaysList: everyXDaysList.reduce(
            (group: { [key: string]: MedicationSchedule[] }, dowListItem) => {
                const { every_x_days } = dowListItem;
                const groupProperty = every_x_days || 'unknown';
                group[groupProperty] = group[groupProperty] ?? [];
                group[groupProperty].push(dowListItem);
                return group;
            },
            {}
        )
    };
};

export default getPreparedMedications;
