import React from 'react';
import { useNavigate } from 'react-router-dom';

export const mainPage = '/members';

const Home: React.FC = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        navigate(mainPage);
    }, [navigate]);

    return null;
};

export default Home;
