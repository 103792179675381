import * as yup from 'yup';
import state from '@hero/validators/state';
import email, { validEmailRegex } from '@hero/validators/email';
import emailDomain from '@hero/validators/email-domain';
import phone from '@hero/validators/libphonenumber';

const regex = /[^\u0000-\u00ff]/; // check for unicode characters

export const phoneYupValidation = yup.lazy((val?: string) => {
    if (!val) {
        return yup.string().trim().notRequired();
    } else if (regex.test(`${val}`)) {
        return yup
            .string()
            .trim()
            .test('special-chars', 'Phone number invalid', () => false);
    } else {
        return phone;
    }
}) as unknown as yup.StringSchema<string, object>;

const emailSchema = yup.lazy((val?: string) => {
    if (!val) {
        return yup.string().trim().required('Email is required');
    } else if (!validEmailRegex.test(`${val}`)) {
        return email;
    } else {
        return emailDomain;
    }
}) as unknown as yup.StringSchema<string, object>;

const memberUpdateFormSchema = yup
    .object()
    .shape({
        external_user_id: yup.string().trim().notRequired(),
        email: emailSchema,
        phone: phoneYupValidation,
        first_name: yup.string().trim().notRequired(),
        last_name: yup.string().trim().notRequired(),
        date_of_birth: yup.string().trim().notRequired(),
        address_line_1: yup
            .string()
            .trim()
            .notRequired()
            .test(
                'no-po-boxes',
                'P.O. Boxes are not allowed',
                (val) => !(val && /po\s*box/i.test(val))
            ),
        address_line_2: yup.string().trim().nullable(),
        city: yup.string().trim().notRequired(),
        state: yup.lazy((val) =>
            !val ? yup.string().trim().notRequired() : state
        ) as unknown as yup.StringSchema<string, object>,
        zip: yup.lazy((val) =>
            !val
                ? yup.string().trim().notRequired()
                : yup
                      .string()
                      .trim()
                      .matches(/^\d{5}$/, 'Invalid zip code')
        ) as unknown as yup.StringSchema<string, object>,
        isDirty: yup.boolean().default(false)
    })
    .defined();

export type MemberUpdateFormSchemaParams = yup.InferType<typeof memberUpdateFormSchema>;

export { memberUpdateFormSchema };
