/* eslint-disable sonarjs/cognitive-complexity */
import {
    register,
    MediaRecorder as ExtendableMediaRecorder,
    IMediaRecorder
} from 'extendable-media-recorder';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'extendable-media-recorder-wav-encoder';

export type MediaRecorderRenderProps = {
    error: string;
    muteAudio: () => void;
    unMuteAudio: () => void;
    startRecording: () => void;
    pauseRecording: () => void;
    resumeRecording: () => void;
    stopRecording: () => void;
    mediaBlobUrl: undefined | string;
    status: StatusMessages;
    isAudioMuted: boolean;
    previewStream: MediaStream | null;
    previewAudioStream: MediaStream | null;
    clearRecording: () => void;
    mediaBlob?: Blob;
    getMediaStream: () => Promise<React.MutableRefObject<MediaStream | null> | undefined>;
};

export type MediaRecorderHookProps = {
    audio?: boolean | MediaTrackConstraints;
    video?: boolean | MediaTrackConstraints;
    screen?: boolean;
    onStop?: (blobUrl: string, blob: Blob) => void;
    onStart?: () => void;
    blobPropertyBag?: BlobPropertyBag;
    mediaRecorderOptions?: MediaRecorderOptions | undefined;
    customMediaStream?: MediaStream | null;
    stopStreamsOnStop?: boolean;
    askPermissionOnMount?: boolean;
};
export type MediaRecorderProps = MediaRecorderHookProps & {
    render: (props: MediaRecorderRenderProps) => ReactElement;
};

export type StatusMessages =
    | 'media_aborted'
    | 'permission_denied'
    | 'no_specified_media_found'
    | 'media_in_use'
    | 'invalid_media_constraints'
    | 'no_constraints'
    | 'recorder_error'
    | 'idle'
    | 'acquiring_media'
    | 'delayed_start'
    | 'recording'
    | 'stopping'
    | 'stopped'
    | 'paused';

export enum RecorderErrors {
    AbortError = 'media_aborted',
    NotAllowedError = 'permission_denied',
    NotFoundError = 'no_specified_media_found',
    NotReadableError = 'media_in_use',
    OverconstrainedError = 'invalid_media_constraints',
    TypeError = 'no_constraints',
    NONE = '',
    NO_RECORDER = 'recorder_error'
}

export function useMediaRecorder({
    audio = true,
    video = false,
    onStop = () => null,
    onStart = () => null,
    blobPropertyBag,
    screen = false,
    mediaRecorderOptions = undefined,
    customMediaStream = null,
    stopStreamsOnStop = true,
    askPermissionOnMount = false
}: MediaRecorderHookProps): MediaRecorderRenderProps {
    const mediaRecorder = useRef<IMediaRecorder | null>(null);
    const mediaChunks = useRef<Blob[]>([]);
    const mediaStream = useRef<MediaStream | null>(null);
    const [status, setStatus] = useState<StatusMessages>('idle');
    const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
    const [mediaBlobUrl, setMediaBlobUrl] = useState<string | undefined>(undefined);
    const [mediaBlob, setMediaBlob] = useState<Blob | undefined>(undefined);
    const [error, setError] = useState<keyof typeof RecorderErrors>('NONE');
    const [init, setInit] = useState(false);

    useEffect(() => {
        // avoid re-registering the encoder
        if (init) {
            return;
        }

        const setup = async () => {
            try {
                await register(await connect());
            } catch (e) {
                //
            }
        };

        setup();
        setInit(true);
    }, []);

    const stopRecording = () => {
        if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
            setStatus('stopping');
            mediaRecorder.current.stop();
            if (stopStreamsOnStop) {
                mediaStream.current &&
                    mediaStream.current.getTracks().forEach((track) => track.stop());
            }
            mediaChunks.current = [];
        }
    };

    const getMediaStream = useCallback(async () => {
        setStatus('acquiring_media');
        const requiredMedia: MediaStreamConstraints = {
            audio: typeof audio === 'boolean' ? !!audio : audio,
            video: typeof video === 'boolean' ? !!video : video
        };

        try {
            if (customMediaStream) {
                mediaStream.current = customMediaStream;
            } else if (screen) {
                const stream = (await window.navigator.mediaDevices.getDisplayMedia({
                    video: video || true
                })) as MediaStream;
                stream.getVideoTracks()[0].addEventListener('ended', () => {
                    stopRecording();
                });
                if (audio) {
                    const audioStream = await window.navigator.mediaDevices.getUserMedia({
                        audio
                    });

                    audioStream
                        .getAudioTracks()
                        .forEach((audioTrack) => stream.addTrack(audioTrack));
                }
                mediaStream.current = stream;
            } else {
                const stream = await window.navigator.mediaDevices.getUserMedia(requiredMedia);
                mediaStream.current = stream;
            }
            setStatus('idle');
            return mediaStream;
        } catch (error: any) {
            setError(error.name);
            setStatus('idle');
            return mediaStream;
        }
    }, [audio, video, screen, customMediaStream]);

    useEffect(() => {
        if (!window.MediaRecorder) {
            throw new Error('Unsupported Browser');
        }

        if (screen && !window.navigator.mediaDevices.getDisplayMedia) {
            throw new Error("This browser doesn't support screen capturing");
        }

        const checkConstraints = (mediaType: MediaTrackConstraints) => {
            const supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();
            const unSupportedConstraints = Object.keys(mediaType).filter(
                (constraint) => !(supportedMediaConstraints as { [key: string]: any })[constraint]
            );

            if (unSupportedConstraints.length > 0) {
                console.error(
                    `The constraints ${unSupportedConstraints.join(
                        ','
                    )} doesn't support on this browser. Please check your ReactMediaRecorder component.`
                );
            }
        };

        if (typeof audio === 'object') {
            checkConstraints(audio);
        }
        if (typeof video === 'object') {
            checkConstraints(video);
        }

        if (
            mediaRecorderOptions &&
            mediaRecorderOptions.mimeType &&
            !MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)
        ) {
            console.error(
                `The specified MIME type you supplied for MediaRecorder doesn't support this browser`
            );
        }

        if (!mediaStream.current && askPermissionOnMount) {
            getMediaStream();
        }

        return () => {
            if (mediaStream.current) {
                const tracks = mediaStream.current.getTracks();
                tracks.forEach((track) => track.clone().stop());
            }
        };
    }, [audio, screen, video, getMediaStream, mediaRecorderOptions, askPermissionOnMount]);

    // Media Recorder Handlers

    const startRecording = async () => {
        setError('NONE');
        if (!mediaStream.current) {
            await getMediaStream();
        }
        if (mediaStream.current) {
            const isStreamEnded = mediaStream.current
                .getTracks()
                .some((track) => track.readyState === 'ended');
            if (isStreamEnded) {
                await getMediaStream();
            }

            // User blocked the permissions (getMediaStream errored out)
            if (!mediaStream.current.active) {
                return;
            }
            mediaRecorder.current = new ExtendableMediaRecorder(
                mediaStream.current,
                mediaRecorderOptions || undefined
            );
            mediaRecorder.current.ondataavailable = onRecordingActive;
            mediaRecorder.current.onstop = onRecordingStop;
            mediaRecorder.current.onstart = onRecordingStart;
            mediaRecorder.current.onerror = () => {
                setError('NO_RECORDER');
                setStatus('idle');
            };
            mediaRecorder.current.start();
            setStatus('recording');
        }
    };

    const onRecordingActive = ({ data }: BlobEvent) => {
        mediaChunks.current.push(data);
    };

    const onRecordingStart = () => {
        onStart();
    };

    const onRecordingStop = () => {
        const [chunk] = mediaChunks.current;
        const blobProperty: BlobPropertyBag = Object.assign(
            { type: chunk.type },
            blobPropertyBag || (video ? { type: 'video/mp4' } : { type: 'audio/wav' })
        );
        const blob = new Blob(mediaChunks.current, blobProperty);
        const url = URL.createObjectURL(blob);
        setStatus('stopped');
        setMediaBlob(blob);
        setMediaBlobUrl(url);
        onStop(url, blob);
    };

    const muteAudio = (mute: boolean) => {
        setIsAudioMuted(mute);
        if (mediaStream.current) {
            mediaStream.current
                .getAudioTracks()
                .forEach((audioTrack) => (audioTrack.enabled = !mute));
        }
    };

    const pauseRecording = () => {
        if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
            setStatus('paused');
            mediaRecorder.current.pause();
        }
    };
    const resumeRecording = () => {
        if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
            setStatus('recording');
            mediaRecorder.current.resume();
        }
    };

    return {
        error: RecorderErrors[error],
        muteAudio: () => muteAudio(true),
        unMuteAudio: () => muteAudio(false),
        startRecording,
        pauseRecording,
        resumeRecording,
        stopRecording,
        mediaBlobUrl,
        mediaBlob,
        status,
        isAudioMuted,
        previewStream: mediaStream.current
            ? new MediaStream(mediaStream.current.getVideoTracks())
            : null,
        previewAudioStream: mediaStream.current
            ? new MediaStream(mediaStream.current.getAudioTracks())
            : null,
        clearRecording: () => {
            mediaStream.current?.getTracks()?.forEach((track) => track.stop());
            if (mediaBlobUrl) {
                URL.revokeObjectURL(mediaBlobUrl);
            }
            setMediaBlobUrl(undefined);
            setMediaBlob(undefined);
            setStatus('idle');
        },
        getMediaStream
    };
}

export const ReactMediaRecorder = (props: MediaRecorderProps) =>
    props.render(useMediaRecorder(props));
