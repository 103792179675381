import { endOfMonth, endOfWeek, format, isFuture, isSameMonth } from 'date-fns';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CaretButton from '../../../../ui-kit/inputs/CaretButton';
import ClickableOpacity from '../../../../ui-kit/inputs/ClickableOpacity';
import Section from '../../../../ui-kit/layout/Section';

import { DATE_FORMAT, PARAM_DATE, PARAM_TIMEFRAME } from '../constants/searchParams';
import * as Style from './style.module.scss';

const timeframes = ['weekly', 'monthly'] as const;
export type Timeframes = typeof timeframes[number];

const getDate = (date: string, timeframe: Timeframes) => {
    const dateObj = new Date(date);

    if (timeframe === 'weekly') {
        return format(
            endOfWeek(isFuture(dateObj) ? new Date() : dateObj, { weekStartsOn: 1 }),
            DATE_FORMAT
        );
    }

    // if next month, reset to current month
    if (isFuture(dateObj) && !isSameMonth(dateObj, new Date())) {
        return format(endOfMonth(new Date()), DATE_FORMAT);
    } else {
        return format(endOfMonth(dateObj), DATE_FORMAT);
    }
};

const Timeframe: React.FC = () => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const updateSearchParams = React.useCallback(
        (timeframe: Timeframes) => {
            setSearchParams((params) => {
                params.set(PARAM_TIMEFRAME, timeframe);
                params.set(PARAM_DATE, getDate(params.get(PARAM_DATE)!, timeframe));

                return params;
            });
            setIsExpanded(false);
        },
        [setSearchParams]
    );

    return (
        <div className={Style.element}>
            <CaretButton
                className={`icon-title-right ${Style.toggle}`}
                textBefore={searchParams.get(PARAM_TIMEFRAME) || ''}
                directionCollapsed="down"
                directionExpanded="up"
                isExpanded={isExpanded}
                onClick={() => {
                    setIsExpanded((current) => !current);
                }}
            />

            {isExpanded && (
                <Section noDefaultPadding className={Style.dropdown} border={true}>
                    {timeframes.map((s) => (
                        <ClickableOpacity
                            fullWidth
                            key={s}
                            alt={`Toggle ${s}`}
                            className={Style.item}
                            onClick={() => {
                                updateSearchParams(s);
                            }}
                        >
                            {s}
                        </ClickableOpacity>
                    ))}
                </Section>
            )}
        </div>
    );
};
export default Timeframe;
