// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.npF__nis20eYuRB7jZvl{display:flex;align-items:center;gap:2rem;margin:calc(var(--gutter)/2) 0;padding:0 !important}.PiK4hcKzRCaUx5GFAOT7{display:flex;align-items:center;justify-content:center;width:2.4rem;height:2.4rem;border-radius:100%;border:.2rem solid var(--color-primary-beta)}.RD7fxoroz1Ptkm3SfrF9{box-shadow:-2px 0px 24px 0px rgba(0,0,0,.24) !important;border-left:1px solid #fff !important}.GXkftyqWKSWDULoPyb7Z{height:100%}.GXkftyqWKSWDULoPyb7Z form{height:100%}.kCLay9utTQqLTVCw7Dy_{color:var(--color-fail-alpha) !important}.QSjOvpT8dQs0JezPnqSi{opacity:.5}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/components/NewActivityForm/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,QAAA,CACA,8BAAA,CACA,oBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,aAAA,CACA,kBAAA,CACA,4CAAA,CAGJ,sBACI,uDAAA,CACA,qCAAA,CAGJ,sBACI,WAAA,CACA,2BACI,WAAA,CAIR,sBACI,wCAAA,CAGJ,sBACI,UAAA","sourceRoot":""}]);
// Exports
export var radioItem = `npF__nis20eYuRB7jZvl`;
export var successOption = `PiK4hcKzRCaUx5GFAOT7`;
export var sidebarContent = `RD7fxoroz1Ptkm3SfrF9`;
export var formWrapper = `GXkftyqWKSWDULoPyb7Z`;
export var failText = `kCLay9utTQqLTVCw7Dy_`;
export var disabled = `QSjOvpT8dQs0JezPnqSi`;
export default ___CSS_LOADER_EXPORT___;
