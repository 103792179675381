import { endOfWeek, format, isBefore } from 'date-fns';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
    ADHERENCE_BEGINNING_OF_TIME,
    DATE_FORMAT,
    PARAM_DATE,
    PARAM_TIMEFRAME
} from '../constants/searchParams';

const useDefaultFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    React.useEffect(() => {
        if (searchParams.get(PARAM_TIMEFRAME) && searchParams.get(PARAM_DATE)) {
            return;
        }

        setSearchParams((params) => {
            if (!params.get(PARAM_TIMEFRAME)) {
                params.set(PARAM_TIMEFRAME, 'weekly');
            }

            const date = params.get(PARAM_DATE);
            if (!date) {
                params.set(
                    PARAM_DATE,
                    format(endOfWeek(new Date(), { weekStartsOn: 1 }), DATE_FORMAT)
                );
            } else {
                if (isBefore(new Date(date), new Date(ADHERENCE_BEGINNING_OF_TIME))) {
                    params.set(PARAM_DATE, ADHERENCE_BEGINNING_OF_TIME);
                }
            }
            return params;
        });
    }, [searchParams, setSearchParams]);

    return null;
};

export default useDefaultFilters;
