import React from 'react';
import BasicInfo from './BasicInfo';
import CaregiverBasicInfo from './CaregiverBasicInfo';
import CaregivingInfo from './CaregivingInfo';
import { useOrganizationMemberDetailsContext } from './context/OrganizationMemberDetailsContext';
import DeviceInfo from './DeviceInfo';
import LogsInfo from './LogsInfo';

import MemberDetailsWrapper from './MemberDetailsWrapper';
import Demographics from './Demographics';

const OrganizationMemberDetails: React.FC = () => {
    const showV2 = true;
    const { memberDetails } = useOrganizationMemberDetailsContext();

    return memberDetails.owned_device_info?.member_role === 'CAREGIVER_READ_ONLY' ? (
        <>
            <CaregiverBasicInfo />
            <CaregivingInfo />
        </>
    ) : (
        <>
            {showV2 ? (
                <Demographics member={memberDetails} />
            ) : (
                <>
                    <BasicInfo />
                    <DeviceInfo styles={{ paddingTop: '4.8rem' }} />
                    <LogsInfo styles={{ marginTop: '2.4rem' }} />
                </>
            )}
        </>
    );
};

const OrganizationMemberDetailsWrapped = () => (
    <MemberDetailsWrapper>
        <OrganizationMemberDetails />
    </MemberDetailsWrapper>
);

export default OrganizationMemberDetailsWrapped;
