const insuranceTypes = {
    original_medicare: 'Original Medicare',
    medicare_advantage: 'Medicare Advantage',
    other_insurance: 'Other insurance',
    not_sure: 'Not sure'
};

const getInsuranceTypeLabel = (insuranceType?: string | null) => {
    const lowerCase = insuranceType?.toLowerCase() as keyof typeof insuranceTypes;
    return insuranceTypes[lowerCase] || '-';
};

export default getInsuranceTypeLabel;
