import React from 'react';

import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import SearchIcon from '@hero/ui-kit/icons/utility/SearchIcon';
import XIcon from '@hero/ui-kit/icons/utility/XIcon';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import { useFormContext } from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import useMouseDownEvent from '../../hooks/useMouseDownEvent';
import useKeyDownEvent from '../../hooks/useKeyDownEvent';

type Props = {
    name: string;
    activeSearch: string;
    onSubmit: (params: Record<string, string>) => void;
    closeSearch?: () => void;
    placeholder?: string;
    customSearchWidth?: string;
};

const SearchBar: React.FC<Props> = ({
    name,
    // activeSearch,
    onSubmit,
    placeholder = 'Enter User Name, ID, Email, or Device SN',
    closeSearch,
    customSearchWidth
}) => {
    const { watch, setValue, handleSubmit } = useFormContext<Record<string, string>>();
    const search = watch(name);
    const isMinL = useIsMinBreakpoint('l');
    const searchWrapper = React.useRef<HTMLDivElement>(null);

    const width = customSearchWidth ? customSearchWidth : isMinL ? '60rem' : '26rem';

    const wrapperStyles: React.CSSProperties = {
        position: 'relative',
        width
    };

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setValue(name, value);
        },
        [setValue, name]
    );

    const handleClear = React.useCallback(() => {
        setValue(name, '');
        handleSubmit(onSubmit)();
        closeSearch && closeSearch();
    }, [setValue, handleSubmit, onSubmit, name, closeSearch]);

    const handleSearchIconClick = React.useCallback(() => {
        handleSubmit(onSubmit)();
    }, [handleSubmit, onSubmit]);

    const handleClickOutside = React.useCallback(
        (event: MouseEvent) => {
            if (
                event?.target instanceof HTMLElement &&
                !searchWrapper.current?.contains(event.target) &&
                search?.length === 0
            ) {
                closeSearch && closeSearch();
            }
        },
        [search, closeSearch]
    );

    const handleKeydown = React.useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape' && search?.length === 0) {
                closeSearch && closeSearch();
            }
        },
        [search, closeSearch]
    );

    useKeyDownEvent(handleKeydown);
    useMouseDownEvent(handleClickOutside);

    return (
        <div style={wrapperStyles} ref={searchWrapper}>
            <ClickableOpacity
                alt="Submit"
                onClick={handleSearchIconClick}
                styles={{ position: 'absolute', top: '1.9rem', left: '2.4rem' }}
            >
                <SearchIcon size="small" />
            </ClickableOpacity>

            <Input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                name={name}
                value={search}
                onChange={handleChange}
                placeholder={placeholder}
                styles={{
                    margin: '0',
                    paddingRight: '4.8rem',
                    paddingLeft: '4.8rem'
                }}
            />

            <ClickableOpacity
                alt="clear"
                onClick={handleClear}
                styles={{ position: 'absolute', top: '1.5rem', right: '2rem' }}
            >
                <XIcon size="utility" />
            </ClickableOpacity>
        </div>
    );
};

export default SearchBar;
