export type Config = {
    ws: {
        pingIntervalInMs: number;
        reauthenticateIntervalInMs: number;
        reconnectIntervalInMs: number;
    };
};

export const config: Config = {
    ws: {
        pingIntervalInMs: 10000,
        reauthenticateIntervalInMs: 10000,
        reconnectIntervalInMs: 5000
    }
};
