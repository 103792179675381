import * as React from 'react';
import { StatusInfo } from '../../types';
import * as Style from './style.module.scss';
import getDischargeStatus from '../../utils/getDischargeStatus';
import Pill from '../../../LeadsManagement/components/Pill';

interface DischargeMemberStatusPillProps {
    statusInfo?: StatusInfo | null;
    styles?: React.CSSProperties;
}

const DischargeMemberStatusPill: React.FC<DischargeMemberStatusPillProps> = ({
    statusInfo,
    styles
}) => {
    const status = getDischargeStatus(statusInfo);

    return (
        <Pill
            label={status}
            className={status === 'FINALIZED' ? Style.finalized : Style.processing}
            styles={styles}
        />
    );
};

export default DischargeMemberStatusPill;
