import P from '@hero/ui-kit/typography/P';
import React from 'react';
import { getFormattedDate } from '../../../../utils/date';
import { MedicationDetails } from '../../types';
import DotLabel from './DotLabel';
import getPillLeftDays from './utils/gePillLeftDays';
import getPillLevel from './utils/getPillLevel';

interface PillDetailsProps {
    med: MedicationDetails;
    isPillLevelLow?: boolean;
    showDot?: boolean;
    isMedDateExpired?: boolean;
}

const PillDetails: React.FC<PillDetailsProps> = ({
    med,
    showDot = false,
    isMedDateExpired = false
}) => {
    const pillLevel = React.useMemo(() => {
        return getPillLevel(med);
    }, [med]);

    const labelText = React.useMemo(() => {
        const leftPills = med?.exact_pill_count;
        const pillsLabel = (leftPills || 0) > 1 ? 'pills' : 'pill';

        if (pillLevel === 'low') {
            const leftDays = getPillLeftDays(med);
            const daysLabel = (leftDays || 0) > 1 ? 'days' : 'day';

            return leftPills
                ? `Pill level: ${pillLevel} ${leftPills} ${pillsLabel} (${
                      leftDays !== undefined ? leftDays : 'unknown'
                  } ${daysLabel})`
                : '';
        }

        if (pillLevel === 'empty') {
            return 'Pill level: Empty';
        }

        if (pillLevel === 'estimating') {
            return 'Pill level: Estimating';
        }

        if (pillLevel === 'N/A') {
            return 'Pill level: N/A';
        }

        return `Pill level: ${pillLevel} (~${
            leftPills !== null ? leftPills : 'unknown'
        } ${pillsLabel})`;
    }, [med, pillLevel]);

    const expDate = React.useMemo(() => {
        if (med?.expires) {
            const formatDate = getFormattedDate(new Date(med.expires), 'MMM Y');
            return isMedDateExpired ? (
                <DotLabel type={'fail'} showDot label={`Expiration: ${formatDate}`} />
            ) : (
                <P noDefaultMargin>{`Expiration: ${formatDate}`}</P>
            );
        }

        return null;
    }, [med, isMedDateExpired]);

    return (
        <>
            <DotLabel
                type={pillLevel === 'low' ? 'warn' : 'fail'}
                showDot={showDot}
                label={labelText}
            />
            {expDate}
        </>
    );
};

export default PillDetails;
