import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import React from 'react';
import DischargeReasonPicker from './DischargeResonPicker';
import P from '@hero/ui-kit/typography/P';
import useGetDischargeReasons from '../../api/useGetDischargeReasons';
import Loader from '@hero/ui-kit/components/Loader';
import useMemberDischarge from '../../api/useMemberDischarge';

type DischargeModalProps = {
    memberName: string;
    memberId: number;
    onSuccess?: () => void;
} & Pick<ModalProps, 'externalControls'>;

const DischargeModal: React.FC<DischargeModalProps> = ({
    externalControls,
    memberName,
    memberId,
    onSuccess
}) => {
    const [, setExternalState] = externalControls ?? [];

    const { data: reasons, isFetching } = useGetDischargeReasons();
    const { mutateAsync: dischargeMember, isError, isPending, error } = useMemberDischarge();

    const [reason, selectedReason] = React.useState<number | undefined>();
    const [showError, setShowError] = React.useState(false);
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [openReasons, setOpenReasons] = React.useState<undefined | string>();

    const handleContinue = () => {
        if (!reason) {
            setShowError(true);
        } else {
            setShowConfirmation(true);
        }
    };

    const handleClose = () => {
        setShowConfirmation(false);
        setExternalState && setExternalState(false);
        selectedReason(undefined);
        setOpenReasons(undefined);
    };

    const handleDischarge = () => {
        memberId > 0 &&
            reason &&
            dischargeMember({ payload: { member_id: memberId, discharge_reason: reason } })
                .then(() => {
                    onSuccess && onSuccess();
                    handleClose();
                })
                .catch(() => {});
    };

    return (
        <Modal
            externalControls={externalControls}
            isCancelable
            styles={{ minWidth: '54rem', padding: 0 }}
            onClose={handleClose}
        >
            {isFetching ? (
                <Loader />
            ) : (
                <>
                    {showConfirmation ? (
                        <Section centered>
                            <H role="h5">Confirm member discharge</H>
                            <P centered>
                                You are about to discharge {memberName}. Their profile and adherence
                                records will remain accessible in the “Discharged Members” tab.
                            </P>

                            <Button disabled={isPending} onClick={handleDischarge}>
                                Discharge member
                            </Button>
                            {isError ? (
                                <P noDefaultMargin styles={{ color: 'red' }}>
                                    {error}
                                </P>
                            ) : null}
                        </Section>
                    ) : (
                        <>
                            <H role="h5" styles={{ fontSize: '2.4rem', lineHeight: '3.3rem' }}>
                                Select discharge reason
                            </H>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '1.2rem',
                                    width: '100%'
                                }}
                            >
                                {reasons?.discharge_reasons?.map((item) => (
                                    <DischargeReasonPicker
                                        key={item.group_title}
                                        showReasons={openReasons === item.group_title}
                                        label={item.group_title}
                                        reasons={item.reasons.map((reason) => ({
                                            label: reason.human_readable,
                                            value: reason.reason_id
                                        }))}
                                        onSelect={(reason) => {
                                            selectedReason(reason);
                                            setShowError(false);
                                        }}
                                        selectedReason={reason}
                                        onToggleReasons={() =>
                                            setOpenReasons((prevState) => {
                                                if (prevState === item.group_title) {
                                                    return undefined;
                                                } else {
                                                    return item.group_title;
                                                }
                                            })
                                        }
                                    />
                                ))}
                            </div>
                            {showError ? (
                                <P
                                    noDefaultMargin
                                    styles={{
                                        color: 'rgba(232, 63, 63, 1)',
                                        fontWeight: 400,
                                        marginTop: '3.6rem',
                                        width: '100%'
                                    }}
                                >
                                    Please select discharge reason if you want to proceed.
                                </P>
                            ) : null}
                            <Section centered noDefaultPadding styles={{ marginTop: '6rem' }}>
                                <Button
                                    noDefaultMargin
                                    variant="outlineSecondary"
                                    onClick={handleContinue}
                                >
                                    Continue
                                </Button>
                            </Section>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};

export default DischargeModal;
