import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import formatTime from '../../utils/formatTime';
import * as Styles from './style.module.scss';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';

const DeleteActivity: React.FC<
    { time: number; onDelete: () => void; onCancel: () => void } & Pick<
        ModalProps,
        'externalControls'
    >
> = ({ time, onDelete, onCancel, externalControls }) => {
    return (
        <Modal externalControls={externalControls} styles={{ padding: 0 }}>
            <P
                noDefaultMargin
                strong
                styles={{ fontSize: '3.2rem', marginBottom: '3.6rem' }}
                centered
            >
                Delete activity?
            </P>
            <P>
                Recording will be permanently deleted! Are you sure you want to delete activity? You
                have recorded <strong>{formatTime(time, true)}</strong>
            </P>
            <div className={Styles.deleteStateBtnWrapper}>
                <Button variant="outlineSecondary" onClick={onDelete}>
                    Delete
                </Button>
                <Button onClick={onCancel} styles={{ marginLeft: '1.2rem' }}>
                    Cancel
                </Button>
            </div>
        </Modal>
    );
};

export default DeleteActivity;
