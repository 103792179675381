import React from 'react';
import Table from '../../../components/Table';
import P from '@hero/ui-kit/typography/P';
import * as Styles from './style.module.scss';
import useGetHealthAssessment from '../api/useGetHealthAssessment';
import { LeadDetailsResponse } from '../types';
import Loading from '../../../components/States/Loading';
import HeroAssessmentEmptyState from './components/HeroAssessmentEmptyState';
import HeroAssessmentMedList from './components/HeroAssessmentMedList';

interface HealthAssessmentProps {
    lead?: LeadDetailsResponse;
}

const HealthAssessmentData: React.FC<HealthAssessmentProps> = ({ lead }) => {
    const { data, isLoading, isError } = useGetHealthAssessment(
        lead?.primary_user_details.lead_id || ''
    );

    const medications = React.useMemo(() => {
        return {
            outside: data?.pills?.filter((med) => !med.stored_in_hero) || [],
            inside: data?.pills?.filter((med) => med.stored_in_hero) || []
        };
    }, [data]);

    if (isError) {
        return <HeroAssessmentEmptyState />;
    }

    return isLoading ? (
        <Loading />
    ) : (
        <>
            {data?.health_conditions?.length ? (
                <>
                    <P
                        noDefaultMargin
                        strong
                        size="large"
                        styles={{ marginBottom: '2rem', marginLeft: '2.4rem' }}
                    >
                        Health Conditions
                    </P>
                    <div style={{ marginLeft: '2.4rem' }}>
                        {data?.health_conditions.map((codition, index) => (
                            <span key={`${codition.alias}-${codition.id}`}>
                                <span className={index !== 0 ? Styles.healthConditions : ''}>
                                    {codition.alias || ''}
                                </span>
                            </span>
                        ))}
                    </div>
                </>
            ) : null}

            <P
                noDefaultMargin
                strong
                size="large"
                styles={{ marginBottom: '2.4rem', marginTop: '4.8rem', marginLeft: '2.4rem' }}
            >
                Medications
            </P>
            {medications.inside.length ? (
                <>
                    <P strong noDefaultMargin styles={{ marginLeft: '2.4rem' }}>
                        Inside Hero
                    </P>
                    <Table
                        headerGridTemplateColumns="0.6fr 4.4fr 5fr 2fr"
                        headerColumns={['Slot', 'Name', 'Schedule', 'Allow As needed']}
                        style={{ borderRadius: '0px', borderLeft: 'none', borderRight: 'none' }}
                    >
                        {medications.inside.map((med, index) => (
                            <HeroAssessmentMedList
                                key={`${index}-${med.name}`}
                                med={med}
                                isLastElement={medications.inside.length - 1 === index}
                            />
                        ))}
                    </Table>
                </>
            ) : null}

            {medications.outside.length ? (
                <>
                    <P
                        strong
                        noDefaultMargin
                        styles={{ marginTop: '2.4rem', marginLeft: '2.4rem' }}
                    >
                        Outside Hero
                    </P>
                    <Table
                        headerGridTemplateColumns="0.6fr 4.4fr 5fr 2fr"
                        headerColumns={['Slot', 'Name', 'Schedule', 'Allow As needed']}
                        style={{
                            borderRadius: '0px',
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderBottom: 'none'
                        }}
                    >
                        {medications.outside.map((med, index) => (
                            <HeroAssessmentMedList
                                key={`${index}-${med.name}`}
                                med={med}
                                isLastElement={medications.outside.length - 1 === index}
                            />
                        ))}
                    </Table>
                </>
            ) : null}
        </>
    );
};

const HealthAssessment: React.FC<HealthAssessmentProps> = ({ lead }) => {
    return lead?.is_existing_member &&
        lead.primary_user_details.external_serial &&
        lead.primary_user_details.admin_email ? (
        <HealthAssessmentData lead={lead} />
    ) : (
        <HeroAssessmentEmptyState />
    );
};

export default HealthAssessment;
