import TextArea from '@hero/ui-kit/inputs/TextArea';
import DefaultSelect from '@hero/ui-kit/inputs/DefaultSelect';
import DatePicker from '@hero/ui-kit/components/DatePicker';
import React from 'react';
import Label from '@hero/ui-kit/typography/Label';
import Section from '@hero/ui-kit/layout/Section';
import { AllApprovalStatusSubStates, ApprovalStatus, LeadStateResponse } from '../../types';
import getApprovalStatus from '../../utils/getApprovalStatus';
import getApprovalSubStatus from '../../utils/getApprovalSubStatus';
import { useFormContext, useWatch, Controller } from '@hero/ui-kit/inputs/Form';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import Circle from '@hero/ui-kit/graphics/Circle';
import * as Style from './style.module.scss';
import { format, setHours, setMinutes, isToday } from 'date-fns';
import Button from '@hero/ui-kit/inputs/Button';

interface FormInputsProps {
    state?: LeadStateResponse;
    isSubmitDisabled?: boolean;
    requiredDateTimeStates?: string[];
}

const FormInputs: React.FC<FormInputsProps> = ({
    state,
    isSubmitDisabled = false,
    requiredDateTimeStates = []
}) => {
    const chosenActivityType = useWatch({ name: 'activity_type' });
    const rejectReason = useWatch({ name: 'reject_reason' });
    const {
        setValue,
        control,
        formState: { isValid }
    } = useFormContext();

    const [timeDate, setTimeDate] = React.useState<Date | undefined>();
    const [date, setDate] = React.useState<Date | undefined>();

    const currentStateStatus = state?.current_state.toUpperCase() as ApprovalStatus;
    const showRejectedRadioOptions = chosenActivityType === 'REJECTED';

    const showDateAndTimeFields = React.useMemo(() => {
        return requiredDateTimeStates.includes(chosenActivityType || '');
    }, [chosenActivityType, requiredDateTimeStates]);

    const activityTypes = React.useMemo(() => {
        const types = state?.possible_state_transitions.map((type) => {
            const approvalStatus = type.toUpperCase() as ApprovalStatus;
            return {
                value: approvalStatus,
                label: getApprovalStatus(approvalStatus).label
            };
        });

        const subTypes = state?.possible_sub_state_transitions_for_current_state.map((type) => {
            const approvalSubStatus = type.toUpperCase() as AllApprovalStatusSubStates;
            return {
                value: approvalSubStatus,
                label: getApprovalSubStatus(approvalSubStatus, currentStateStatus).stageLabel
            };
        });

        return [...(types || []), ...(subTypes || [])];
    }, [state, currentStateStatus]);

    const rejectedOptions = React.useMemo(() => {
        const rejectedState = state?.states_requiring_sub_state?.find(
            (subState) => subState.state === 'rejected'
        );

        return rejectedState
            ? rejectedState.sub_state_transitions_for_state.map((value) => ({
                  value: value.toUpperCase(),
                  label: getApprovalSubStatus(
                      value.toUpperCase() as AllApprovalStatusSubStates,
                      'REJECTED'
                  ).label
              }))
            : undefined;
    }, [state]);

    const handleRejectedReason = (reason: string) => {
        setValue('reject_reason', reason, { shouldValidate: true, shouldDirty: true });
    };

    const now = new Date();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {activityTypes.length ? (
                <DefaultSelect
                    name="activity_type"
                    displayName="Type"
                    options={activityTypes.map(({ label, value }) => ({ label, value }))}
                />
            ) : null}
            {showDateAndTimeFields ? (
                <>
                    <Section
                        paddingLeft="none"
                        paddingBottom="small"
                        paddingTop="none"
                        paddingRight="none"
                    >
                        <Label htmlFor="date">DATE</Label>
                        <Controller
                            control={control}
                            name="schedule_date"
                            render={({ field: { onBlur, onChange } }) => (
                                <DatePicker
                                    onChange={(date: Date) => {
                                        setDate(date);
                                        onChange(format(date, 'P'));
                                    }}
                                    onBlur={onBlur}
                                    selected={date}
                                    minDate={now}
                                />
                            )}
                        />
                    </Section>
                    <Section
                        paddingLeft="none"
                        paddingBottom="small"
                        paddingTop="none"
                        paddingRight="none"
                        className={!date ? Style.disabled : ''}
                    >
                        <Label htmlFor="time">TIME</Label>
                        <Controller
                            control={control}
                            name="schedule_time"
                            render={({ field: { onBlur, onChange } }) => (
                                <DatePicker
                                    disabled={!date}
                                    selected={timeDate}
                                    onChange={(date: Date) => {
                                        setTimeDate(date);
                                        onChange(format(date, 'pppp'));
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    onBlur={onBlur}
                                    minTime={date && isToday(date) ? now : undefined}
                                    maxTime={
                                        date && isToday(date)
                                            ? setHours(setMinutes(now, 59), 23)
                                            : undefined
                                    }
                                />
                            )}
                        />
                    </Section>
                </>
            ) : null}
            {showRejectedRadioOptions && rejectedOptions ? (
                <Section
                    paddingLeft="none"
                    paddingBottom="small"
                    paddingTop="none"
                    paddingRight="none"
                >
                    <Label htmlFor="reject_reason">Rejection reason</Label>
                    {rejectedOptions.map((rejectedOption) => (
                        <ClickableOpacity
                            fullWidth
                            key={rejectedOption.value}
                            alt={`Select ${rejectedOption.label}`}
                            onClick={() => {
                                handleRejectedReason(rejectedOption.value);
                            }}
                        >
                            <div className={Style.radioItem}>
                                {rejectReason === rejectedOption.value ? (
                                    <div className={Style.successOption}>
                                        <Circle size="tiny" />
                                    </div>
                                ) : (
                                    <Circle disabled inverted size="small" />
                                )}
                                <span>{rejectedOption.label}</span>
                            </div>
                        </ClickableOpacity>
                    ))}
                </Section>
            ) : null}
            <TextArea
                name="note"
                displayName="Note"
                placeholder="Add note"
                styles={{ minHeight: '24rem', width: '100%' }}
            />

            <div
                style={{
                    marginTop: 'auto',

                    minHeight: '5rem',
                    width: '100%'
                }}
            >
                <Button
                    type="submit"
                    width="full"
                    disabled={!isValid || isSubmitDisabled}
                    styles={{ marginBottom: '3rem' }}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default FormInputs;
