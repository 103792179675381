import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetNotification } from '../../../context/Notification';

import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { LeadData, UpdateLeadPayload } from '../types';
import { LEAD_DETAILS, LEAD_LIST_KEY } from './constants';

const useUpdateLead = (onSuccess?: () => void) => {
    const req = useRequest();
    const queryClient = useQueryClient();
    const setNotification = useSetNotification();

    const request = ({
        query,
        payload
    }: {
        query: { lead_id: LeadData['lead_id'] };
        payload: UpdateLeadPayload;
    }) => {
        return req({
            url: `${envVars.API_RTM_HOSTNAME}enterprise/lead/`,
            method: 'PATCH',
            query,
            body: JSON.stringify(payload)
        });
    };

    return useMutation({
        mutationFn: request,
        onSuccess: (res, req) => {
            queryClient.invalidateQueries({ queryKey: [LEAD_LIST_KEY] });
            queryClient.invalidateQueries({ queryKey: [LEAD_DETAILS, req.query.lead_id] });
            setNotification({ message: 'Lead updated' });
            onSuccess && onSuccess();
        },
        onError: (error: { errors: string[] }) => {
            return error;
        }
    });
};

export default useUpdateLead;
