import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import Button from '@hero/ui-kit/inputs/Button';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import { ContactPersonFormSchemaParams, contactPersonFormSchema } from './validator';
import { LeadDetailsResponse } from '../../types';
import useUpdateLead from '../../api/useUpdateLead';
import { formatPhoneNumber } from '@hero/hero-utils/phone';

interface ContactPersonFormProps {
    lead?: LeadDetailsResponse;
    onSuccess?: () => void;
}

const FormElements: React.FC<{ isSaveDisabled?: boolean }> = ({ isSaveDisabled = false }) => {
    const isMinL = useIsMinBreakpoint('l');

    const {
        setValue,
        formState: { isDirty }
    } = useFormContext();

    React.useEffect(() => {
        setValue('isDirty', isDirty);
    }, [setValue, isDirty]);

    return (
        <Section noDefaultPadding styles={{ width: '100%' }}>
            <div
                style={{
                    width: '100%',
                    textAlign: 'left',
                    marginBottom: '3.6rem',
                    marginTop: '0.4rem'
                }}
            >
                <P noDefaultMargin strong size="large" styles={{ marginBottom: '1.2rem' }}>
                    Contact person
                </P>
                <P noDefaultMargin>
                    Secondary contact or person submitting application on behalf of loved one
                </P>
            </div>
            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <Input name="first_name" displayName="Contact Person First Name" />
                <Input name="last_name" displayName="Contact Person Last Name" />
            </Container>

            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <Input name="email" displayName="Contact Person Email" />
                <Input name="phone" displayName="Contact Person Phone number" />
            </Container>
            <Section centered noDefaultPadding marginTop="small">
                <Button type="submit" styles={{ width: '16.5rem' }} disabled={isSaveDisabled}>
                    Save
                </Button>
            </Section>
        </Section>
    );
};

const ContactPersonForm: React.FC<ContactPersonFormProps> = ({ lead, onSuccess }) => {
    const { mutate, isPending: isLoading, isError } = useUpdateLead(onSuccess);
    const handleSubmit = (attributes: ContactPersonFormSchemaParams) => {
        if (!attributes.isDirty) {
            onSuccess && onSuccess();
            return;
        }

        const leadId = lead?.primary_user_details.lead_id;
        leadId &&
            mutate({
                query: { lead_id: leadId },
                payload: {
                    caregiver_first_name: attributes.first_name,
                    caregiver_last_name: attributes.last_name,
                    caregiver_email: attributes.email,
                    caregiver_phone: formatPhoneNumber(attributes.phone)
                }
            });
    };

    return (
        <Form
            submitFn={handleSubmit}
            validationSchema={contactPersonFormSchema}
            defaultValues={{ ...lead?.contact_details }}
        >
            <FormElements isSaveDisabled={isLoading} />
            {isError ? (
                <Section
                    subtheme={['status', 'fail', 'beta']}
                    role="aside"
                    border
                    padding="small"
                    marginVertical="small"
                >
                    Error: Network response was not ok
                </Section>
            ) : null}
        </Form>
    );
};

export default ContactPersonForm;
