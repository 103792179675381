import React from 'react';
import { NavLink, useLocation, useParams, useSearchParams } from 'react-router-dom';

import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';

import Layout from '../../../../components/Layout';
import ErrorState from '../../../../components/States/Error';
import Loading from '../../../../components/States/Loading';
import useGetOrganizationMemberById from '../../api/useGetOrganizationMemberById';
import { OrganizationMemberDetailsRouteParams } from '../../types';
import OrganizationMemberDetailsProvider, {
    useOrganizationMemberDetailsContext
} from '../context/OrganizationMemberDetailsContext';
import * as Style from './style.module.scss';
import getMemberUrl from '../../utils/getMemberUrl';
import { showMemberActivityFeature } from '../../../../constants/featureFlags';
import useBack from '../../../../hooks/useBack';
import BackButton from '../../../../components/BackButton';
import UserInfo from './UserInfo';
import MemberDeviceInfo from './MemberDeviceInfo';
import Pill from '../../../LeadsManagement/components/Pill';
import Button from '@hero/ui-kit/inputs/Button';
import DischargeModal from '../DischargeModal';
import DischargeHeaderNote from '../DischargeHeaderNote';
import ReadmitModal from '../ReadmitModal';

const Header: React.FC = () => {
    const { memberDetails, memberName, currentDevice } = useOrganizationMemberDetailsContext();
    const { caregiving, member_info, medication_info } = memberDetails;

    const location = useLocation();

    const state = { from: location.state?.from };

    React.useEffect(() => {
        if (location.pathname.includes('adherence') && location.state?.from) {
            localStorage.setItem('locationFrom', location.state?.from);
        }
    }, [location]);

    const tabs = React.useMemo(() => {
        return [
            {
                label: 'Demographics',
                link: getMemberUrl(member_info.hero_user_id, member_info.member_id),
                hasPipe: false,
                show: true
            },
            {
                label: 'Health Assessment',
                link: getMemberUrl(member_info.hero_user_id, member_info.member_id, 'medications'),
                hasPipe: false,
                show: !!medication_info?.length
            },
            {
                label: 'Adherence',
                link: getMemberUrl(member_info.hero_user_id, member_info.member_id, 'adherence'),
                hasPipe: false,
                show: !!medication_info?.length && currentDevice?.device_status === 'CONFIGURED'
            },
            // {
            //     label: 'Caregiving',
            //     link: getMemberUrl(member_info.hero_user_id, member_info.member_id, 'caregiving'),
            //     hasPipe: true,
            //     show: !!caregiving?.length
            // },
            {
                label: 'Activity',
                link: getMemberUrl(member_info.hero_user_id, member_info.member_id, 'activity'),
                hasPipe: false,
                show: showMemberActivityFeature
            }
        ];
    }, [
        caregiving?.length,
        medication_info?.length,
        member_info?.hero_user_id,
        member_info?.member_id,
        currentDevice
    ]);

    const isAdmin = currentDevice?.member_role === 'PRIMARY_USER_ADMIN';
    const isDischarged = !!member_info.status_info?.discharged_at;
    const isCancelled = member_info.status_info?.status === 'CANCELLED';

    return (
        <Section noDefaultPadding paddingVertical="small" paddingHorizontal="regular">
            <div>
                <div className={Style.name}>
                    <H role="h5" noDefaultMargin>
                        {`${memberName}${isDischarged || isCancelled ? ' (Discharged)' : ''}`}
                    </H>

                    <Pill
                        label={isAdmin ? 'Admin' : 'Read only'}
                        styles={{
                            textTransform: 'none',
                            fontWeight: '700',
                            fontSize: '1.6rem',
                            height: '3.2rem',
                            borderRadius: '1.8rem',
                            background: isAdmin ? 'rgba(255, 229, 214, 1)' : 'transparent',

                            ...(!isAdmin && {
                                border: '2px solid rgba(231, 231, 231, 1)'
                            })
                        }}
                    />
                </div>

                <div className={Style.subhead}>
                    <div className={Style.userInfoWrapper}>
                        <UserInfo />
                        <MemberDeviceInfo />
                    </div>

                    <div className={Style.nav}>
                        {tabs
                            .filter((tab) => tab.show)
                            .map((tab) => (
                                <React.Fragment key={tab.label}>
                                    {tab.hasPipe && <div className={Style.pipe} />}
                                    <NavLink end key={tab.label} to={tab.link} state={state}>
                                        {tab.label}
                                    </NavLink>
                                </React.Fragment>
                            ))}
                    </div>
                </div>
            </div>
        </Section>
    );
};

const MemberDetailsWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { id } = useParams<Required<OrganizationMemberDetailsRouteParams>>();

    const [showDischargeModal, setShowDischargeModal] = React.useState(false);
    const [showReadmitModal, setShowReadmitModal] = React.useState(false);

    const back = useBack();

    const [searchParams] = useSearchParams();

    const query = React.useMemo(() => {
        const preparedId = id ? +id : 0;
        if (searchParams.get('role') === 'caregiver') {
            return {
                hero_user_id: preparedId
            };
        }

        return {
            member_id: preparedId
        };
    }, [searchParams, id]);

    const {
        data,
        isLoading,
        isError,
        refetch: refetchMember
    } = useGetOrganizationMemberById(query);

    const canDischarge = data?.member_info.status_info?.can_discharge;
    const canReadmit = data?.member_info.status_info?.can_readmit;

    return (
        <Layout paddingHorizontal="none">
            {isLoading ? (
                <Loading />
            ) : isError ? (
                <ErrorState />
            ) : data ? (
                <OrganizationMemberDetailsProvider memberDetails={data}>
                    <Section noDefaultPadding paddingHorizontal="regular">
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <BackButton
                                onBack={() =>
                                    back(localStorage.getItem('locationFrom') || '/members')
                                }
                                alt="Back to members"
                            />
                            {canDischarge || canReadmit ? (
                                <Button
                                    styles={{ lineHeight: '3.2rem' }}
                                    noDefaultMargin
                                    variant="outlineSecondary"
                                    onClick={() =>
                                        canDischarge
                                            ? setShowDischargeModal(true)
                                            : setShowReadmitModal(true)
                                    }
                                >
                                    {canDischarge ? 'Discharge' : 'Re-admit'}
                                </Button>
                            ) : null}
                        </div>
                    </Section>
                    <DischargeHeaderNote statusInfo={data?.member_info.status_info} />
                    <Header />

                    {children}
                    <DischargeModal
                        externalControls={[showDischargeModal, setShowDischargeModal]}
                        memberName={data?.member_info.first_name || ''}
                        memberId={data?.member_info.member_id || 0}
                        onSuccess={refetchMember}
                    />
                    <ReadmitModal
                        memberId={data?.member_info.member_id || 0}
                        externalControls={[showReadmitModal, setShowReadmitModal]}
                        memberName={data?.member_info.first_name || ''}
                        onSuccess={refetchMember}
                    />
                </OrganizationMemberDetailsProvider>
            ) : null}
        </Layout>
    );
};
export default MemberDetailsWrapper;
