import React from 'react';
import Table from '../../components/Table';
import { LeadData, LeadDataResponse } from './types';
import Section from '@hero/ui-kit/layout/Section';
import Container from '@hero/ui-kit/layout/Container';
import * as Style from './style.module.scss';
import formatDateTime from './utils/formatDateTime';
import getApprovalStatus from './utils/getApprovalStatus';
import { useLocation, useNavigate } from 'react-router-dom';
import Pill from './components/Pill';
import getApprovalSubStatus from './utils/getApprovalSubStatus';
import P from '@hero/ui-kit/typography/P';
import LockIcon from '@hero/ui-kit/icons/branding/LockIcon';
import { format } from 'date-fns';
import useWSNewLeadListener from '../../hooks/realtime/useWSNewLeadListener';
import VerifiedIcon from '@hero/ui-kit/icons/utility/VerifiedIcon';
import { usStateByName } from '@hero/hero-utils/address/states';
import HighlightedText from '../../components/HighlightedText';
import ExpiringAlert from './components/ExpiringAlert';
import AlertIcon from '@hero/ui-kit/icons/utility/AlertIcon';
import getShouldShowExpiringAlert from './utils/getShouldShowExpiringAlert';

const ListExpiringAlert: React.FC<{ days?: number; hours?: number; show?: boolean }> = ({
    days,
    hours,
    show
}) => {
    const daysText = days && days > 0 ? `${days} days!` : `${hours} hours!`;

    return show ? (
        <div className={Style.tooltip}>
            <AlertIcon size="utility" />

            <div className={Style.tooltipDetails}>Expiring in {daysText}</div>
        </div>
    ) : null;
};

const templateColumns = '2fr 0.6fr 1fr 1fr 1fr 1fr 0.2fr';

const Lead: React.FC<{ lead: LeadData; search?: string }> = ({ lead, search }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const approvalStatus = getApprovalStatus(lead.approval_status);
    const approvalSubStatus = getApprovalSubStatus(
        lead.approval_sub_status || undefined,
        lead.approval_status
    );

    const isLocked = React.useMemo(() => {
        return lead.lock_status === 'locked';
    }, [lead.lock_status]);

    const handleNavigate = () => {
        const from = `${location.pathname}${location.search}`;

        navigate(`/leads/${lead.lead_id}`, { state: { from } });
    };

    const noteDateChangeTime = lead.status_change_note_date_time
        ? new Date(lead.status_change_note_date_time)
        : undefined;

    const state = lead.state?.length > 2 ? usStateByName(lead.state)?.code : lead.state;
    const name = `${lead.first_name} ${lead.last_name}`;
    const subText = `${lead.email || ''}${
        lead?.external_user_id ? ` | ${lead.external_user_id}` : ''
    }`;

    const showAlert = React.useMemo(() => {
        return getShouldShowExpiringAlert({
            isLocked,
            previous_member_id: lead.previous_member_id,
            approval_status: lead.approval_status,
            approval_sub_status: lead.approval_sub_status
        });
    }, [lead, isLocked]);

    return (
        <div
            tabIndex={0}
            role="button"
            className={`${Style.item} ${isLocked ? Style.locked : ''}`}
            onClick={handleNavigate}
            onKeyDown={() => null}
        >
            <Section noDefaultPadding className={`${Style.itemTopLevel}`}>
                <Container
                    gridTemplateColumns={templateColumns}
                    className={Style.itemTopLevelContainer}
                >
                    <div className={Style.listName}>
                        {lead.is_member ? (
                            <div style={{ minWidth: '2.4rem' }}>
                                <VerifiedIcon type="utility" width={24} height={24} />
                            </div>
                        ) : null}
                        <div>
                            <strong>
                                <HighlightedText text={name} highlight={search} />
                            </strong>
                            <P size="small" noDefaultMargin>
                                <HighlightedText text={subText} highlight={search} />
                            </P>
                        </div>
                    </div>
                    <div>{state}</div>
                    <div>{formatDateTime(lead.created_at)}</div>
                    <div>
                        <strong>{lead?.updated_at ? formatDateTime(lead.updated_at) : '-'}</strong>
                    </div>
                    <Pill label={approvalStatus.label} className={approvalStatus.className} />
                    <P noDefaultMargin strong className={approvalSubStatus.className}>
                        {approvalSubStatus.label}
                        <div className={Style.scheduleDate}>
                            {noteDateChangeTime &&
                            !['expired', 'rejected'].includes(
                                lead?.approval_status?.toLowerCase() || ''
                            )
                                ? format(
                                      new Date(
                                          Date.UTC(
                                              noteDateChangeTime.getFullYear(),
                                              noteDateChangeTime.getMonth(),
                                              noteDateChangeTime.getDate(),
                                              noteDateChangeTime.getHours(),
                                              noteDateChangeTime.getMinutes(),
                                              noteDateChangeTime.getSeconds()
                                          )
                                      ),
                                      'LLL d @ p'
                                  )
                                : ''}
                        </div>
                    </P>

                    <div style={{ textAlign: 'right', position: 'relative' }}>
                        {isLocked ? (
                            <div className={Style.tooltip}>
                                <LockIcon
                                    size="utility"
                                    type="utility"
                                    className={Style.lockIcon}
                                />

                                <div className={Style.tooltipDetails} style={{ minWidth: '22rem' }}>
                                    Another agent is working with the patient.
                                </div>
                            </div>
                        ) : null}
                        {showAlert ? (
                            <ExpiringAlert
                                createdAt={lead.created_at}
                                reactivatedAt={lead.reactivated_at}
                            >
                                <ListExpiringAlert />
                            </ExpiringAlert>
                        ) : null}
                    </div>
                </Container>
            </Section>
        </div>
    );
};

interface LeadsTableProps {
    data?: LeadDataResponse;
    isLoading?: boolean;
    onFetchLeads: () => void;
    search?: string;
}

const LeadsTable: React.FC<LeadsTableProps> = ({
    data,
    isLoading = false,
    onFetchLeads,
    search = ''
}) => {
    useWSNewLeadListener(onFetchLeads);

    return (
        <Table
            headerGridTemplateColumns={templateColumns}
            headerColumns={['Name', 'Location', 'Created', 'Updated', 'Stage', 'Status', '']}
            pagerData={data}
            loading={isLoading}
        >
            {data?.leads.map((item) => (
                <Lead search={search} key={item.id} lead={item} />
            ))}
        </Table>
    );
};

export default LeadsTable;
