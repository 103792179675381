import { useQuery } from '@tanstack/react-query';
import React from 'react';

import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { LeadDetailsResponse } from '../types';
import { LEAD_DETAILS } from './constants';

const useGetLeadDetails = (lead_id: string) => {
    const req = useRequest();

    const request = React.useCallback(() => {
        return req<LeadDetailsResponse>({
            url: `${envVars.API_RTM_HOSTNAME}enterprise/lead/`,
            query: { lead_id }
        });
    }, [lead_id, req]);

    return useQuery({
        queryFn: request,
        queryKey: [LEAD_DETAILS, lead_id]
    });
};

export default useGetLeadDetails;
