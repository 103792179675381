import Section from '../../../../../ui-kit/layout/Section';
import React, { useMemo, useState } from 'react';
import { AdhereceCalendarMonthlyProps, MonthlyDose } from './Acm.types';
import { weekDaysLabels, getDate, isSameDay } from '../common';
import Legend from './Legend';
import CircularProgress from '../../../../../ui-kit/components/CircularProgress';
import SidebarDay from '../Sidebar/SidebarDay';
import SidebarPeriod from '../Sidebar/SidebarPeriod';

import * as Style from './Acm.module.scss';
import * as CommonStyle from '../common.module.scss';
import SideBar from '../../SideBar';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { getSidebarParam, setSidebarParam, useActiveSidebars } from '../../SideBar/sidebarHooks';
import AlertIcon from '../../../../../ui-kit/icons/utility/AlertIcon';
import Loader from '../../../../../ui-kit/graphics/Loader';
import { PARAM_SIDEBAR } from '../../constants/searchParams';
import { useAdherenceContext } from '../../context/AdherenceContext';

const getPercent = ({ positive, negative, unknown }: MonthlyDose, type: 'taken' | 'missed') =>
    positive + negative + unknown > 0
        ? ((type === 'taken' ? positive : negative) * 100) / (positive + negative + unknown)
        : 0;

const AdherenceCalendarMonthly: React.FC<AdhereceCalendarMonthlyProps> = ({ dateTo }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const sbd = useActiveSidebars();
    const [selected, setSelected] = useState(() => {
        return searchParams.get(PARAM_SIDEBAR) || '';
    });
    const today = new Date();

    const { getAdherenceCalendarMonthly } = useAdherenceContext();

    const [fetch, setFetch] = React.useState<{
        isLoading: boolean;
        data: MonthlyDose[] | undefined;
    }>({
        isLoading: false,
        data: undefined
    });

    const { isLoading, data } = fetch;

    React.useEffect(() => {
        setFetch({ isLoading: true, data: undefined });
        getAdherenceCalendarMonthly({
            target_datetime: `${dateTo} 00:00:00`
        })
            .then((response) => setFetch({ isLoading: false, data: response }))
            .catch(() => setFetch({ isLoading: false, data: undefined }));
    }, [dateTo]);

    const weekDays = useMemo(
        () =>
            Array.from(Array(7)).map((_, index) => ({
                label: weekDaysLabels[index],
                value: ''
            })),
        []
    );

    const calendarMatrix = useCallback(
        (data: MonthlyDose[]): Array<Array<MonthlyDose>> => {
            const arrayToMatrixWithOffset = (arr: Array<unknown>, offset: number) => {
                const newArr = [...Array.from(Array(offset)), ...arr];
                const matrix = Array.from(Array(7)).map(() =>
                    Array.from(Array(Math.ceil(arr.length / 7)))
                );
                newArr.forEach((each, i) => {
                    matrix[i % 7][Math.floor(i / 7)] = each;
                });
                return matrix;
            };
            const newDateTo = new Date(dateTo);
            let firstDay = new Date(newDateTo.getFullYear(), newDateTo.getMonth()).getDay();
            let firstDayN = firstDay === 0 ? 6 : firstDay - 1;
            return arrayToMatrixWithOffset(data, firstDayN);
        },
        [dateTo]
    );

    const handleCircleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>, dose: MonthlyDose) => {
            e.stopPropagation();
            e.preventDefault();
            setSelected(dose.date);
            setSearchParams((prevState) => {
                return setSidebarParam(prevState, [dose.date]);
            });
        },
        [setSearchParams]
    );
    const handleDoseClick = (time: string) => {
        setSearchParams((prevState) => {
            let sidebars = getSidebarParam(prevState);
            sidebars.push(time);
            return setSidebarParam(prevState, sidebars);
        });
    };

    const parsedData = useMemo(() => calendarMatrix(data || []), [data, calendarMatrix]);

    const renderData = useCallback(
        (data: Array<Array<MonthlyDose>>, index: number) => {
            if (isLoading) {
                return null;
            }
            return data[index].map((column, j) => {
                if (column?.date) {
                    return (
                        <React.Fragment key={`circle-${index + j}`}>
                            <div
                                role="button"
                                className={[
                                    Style.circle,
                                    selected === column.date ? Style.active : ''
                                ].join(' ')}
                                onClick={(e) => handleCircleClick(e, column)}
                                onKeyDown={() => {}}
                                tabIndex={0}
                            >
                                <CircularProgress
                                    progress={[
                                        {
                                            percent:
                                                getPercent(column, 'taken') +
                                                getPercent(column, 'missed'),
                                            color: 'red'
                                        },
                                        {
                                            percent: getPercent(column, 'taken'),
                                            color: 'green'
                                        }
                                    ]}
                                    size={68}
                                    strokeWidth={8}
                                />
                                <div>{parseInt(column.date.slice(-2))}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    minHeight: '2.4rem'
                                }}
                            >
                                {column.unscheduled_doses > 0 && (
                                    <div
                                        className={Style.circleIcon}
                                        style={{
                                            backgroundColor: 'var(--color-secondary-alpha)'
                                        }}
                                    ></div>
                                )}
                                {column.partial && (
                                    <AlertIcon
                                        type="inverted"
                                        size="utility"
                                        className={Style.triangleIcon}
                                    />
                                )}
                            </div>
                        </React.Fragment>
                    );
                }

                return (
                    <React.Fragment key={`circle-${index + j}`}>
                        <div className={Style.circle} />
                        <div style={{ minHeight: '2.4rem' }}></div>
                    </React.Fragment>
                );
            });
        },
        [isLoading, handleCircleClick, selected]
    );

    return (
        <Section noDefaultPadding border>
            <div role="button" className={CommonStyle.root} onKeyDown={() => {}} tabIndex={0}>
                {weekDays.map((day, i) => (
                    <div className={CommonStyle.columnByDay} key={`header-${i}`}>
                        <div
                            className={[
                                CommonStyle.heading,
                                `${
                                    isSameDay(today, getDate(new Date(dateTo), i - 1))
                                        ? Style.active
                                        : ''
                                }`
                            ].join(' ')}
                        >
                            <div style={{ margin: 0 }}>{day.label}</div>
                        </div>
                        <hr />
                        <div className={Style.column}>
                            {parsedData && renderData(parsedData, i)}
                        </div>
                    </div>
                ))}
            </div>
            {isLoading && (
                <div className={CommonStyle.loader}>
                    <Loader />
                </div>
            )}
            <Legend />

            {sbd[0] && (
                <SideBar listenToKeydown={sbd.length === 1}>
                    <SidebarDay day={sbd[0]} onDoseClick={handleDoseClick} />
                </SideBar>
            )}

            {sbd[1] && (
                <SideBar listenToKeydown={sbd.length === 2}>
                    <SidebarPeriod day={sbd[1]} />
                </SideBar>
            )}
        </Section>
    );
};

export default AdherenceCalendarMonthly;
