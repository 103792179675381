import * as yup from 'yup';

import email, { validEmailRegex } from '@hero/validators/email';
import emailDomain from '@hero/validators/email-domain';
import { validateMMDDYYYYDateFormat } from '@hero/hero-utils/date';
import { phoneYupValidation } from '../../../../../utils/validator';

type TestFn = (val: string | null | undefined) => boolean;
const testDate: TestFn = (val) => !val || validateMMDDYYYYDateFormat(val);

const emailSchema = yup.lazy((val?: string) => {
    if (!val) {
        return yup.string().trim().required('Email address is required');
    } else if (!validEmailRegex.test(`${val}`)) {
        return email;
    } else {
        return emailDomain;
    }
}) as unknown as yup.StringSchema<string, object>;

const createLeadFormSchema = yup
    .object()
    .shape({
        is_member: yup.bool().default(false),
        hero_for: yup.lazy((val?: string) =>
            !val
                ? yup.string().nullable().required('Considering Hero field is required')
                : yup.string().oneOf(['myself', 'loved_one'], 'Invalid application submit')
        ) as unknown as yup.StringSchema<'myself' | 'loved_one', object>,
        first_name_user: yup.string().trim().required('First name is required.'),
        last_name_user: yup.string().trim().required('Last name is required.'),
        first_name: yup
            .string()
            .trim()
            .when('hero_for', {
                is: (hero_for?: string) => hero_for && hero_for.toLowerCase().includes('loved_one'),
                then: (schema) => schema.nullable().required('First name is required'),
                otherwise: (schema) => schema
            }),
        last_name: yup
            .string()
            .trim()
            .when('hero_for', {
                is: (hero_for?: string) => hero_for && hero_for.toLowerCase().includes('loved_one'),
                then: (schema) => schema.nullable().required('Last name is required'),
                otherwise: (schema) => schema
            }),
        admin_email: yup.lazy((val?: string) => {
            if (!val) {
                return yup.string().trim().notRequired();
            } else if (!validEmailRegex.test(`${val}`)) {
                return email;
            } else {
                return emailDomain;
            }
        }) as unknown as yup.StringSchema<string, object>,
        external_serial: yup.lazy((val?: string) => {
            if (!val) {
                return yup.string().trim().notRequired();
            } else if (!val.startsWith('H100-')) {
                return yup
                    .string()
                    .trim()
                    .matches(/^H100-/, 'Invalid external serial, example: H100-#########');
            } else {
                return yup.string().trim().required('External serial is required');
            }
        }) as unknown as yup.StringSchema<string, object>,
        email: emailSchema,
        zip: yup
            .string()
            .trim()
            .required('Zip code is required')
            .matches(/^\d{5}$/, 'Invalid zip code'),
        phone: phoneYupValidation,
        date_of_birth: yup
            .string()
            .defined()
            .required('Date of birth is required.')
            .test('date-or-empty', 'Must be a valid date (mm-dd-yyyy)', testDate),
        insurance_company: yup.lazy((val) =>
            !val
                ? yup.string().trim().notRequired()
                : yup
                      .string()
                      .trim()
                      .matches(
                          /^[a-zA-Z0-9\s]+$/,
                          'Please ensure that Insurance Company Name contains only alphanumeric characters (letters and numbers). Special characters are not allowed.'
                      )
        ),
        insurance_id: yup.lazy((val) =>
            !val
                ? yup.string().trim().notRequired()
                : yup
                      .string()
                      .trim()
                      .matches(
                          /^[a-zA-Z0-9\s]+$/,
                          'Please ensure Member ID contains only alphanumeric characters (letters and numbers). Special characters are not allowed.'
                      )
        ),
        insurance_type: yup.lazy((val) =>
            !val
                ? yup.string().nullable().required('Insurance field is required')
                : yup
                      .string()
                      .oneOf(
                          ['ORIGINAL_MEDICARE', 'MEDICARE_ADVANTAGE', 'NOT_SURE'],
                          'Invalid insurance type'
                      )
        ) as unknown as yup.StringSchema<
            'ORIGINAL_MEDICARE' | 'MEDICARE_ADVANTAGE' | 'NOT_SURE',
            object
        >,
        medigap_insurance_name: yup.lazy((val) =>
            !val
                ? yup.string().trim().notRequired()
                : yup
                      .string()
                      .trim()
                      .matches(
                          /^[a-zA-Z0-9\s]+$/,
                          'Please ensure that Insurance Company Name contains only alphanumeric characters (letters and numbers). Special characters are not allowed.'
                      )
        ),
        medigap_insurance_policy_id: yup.lazy((val) =>
            !val
                ? yup.string().trim().notRequired()
                : yup
                      .string()
                      .trim()
                      .matches(
                          /^[a-zA-Z0-9\s]+$/,
                          'Please ensure Member ID contains only alphanumeric characters (letters and numbers). Special characters are not allowed.'
                      )
        )
    })
    .defined();

export type CreateLeadFormShema = yup.InferType<typeof createLeadFormSchema>;

export { createLeadFormSchema };
