import Combobox from '@hero/ui-kit/inputs/Combobox';
import Input from '@hero/ui-kit/inputs/Input';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import React from 'react';
import { usStateNames } from '@hero/hero-utils/address/states';
import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import { ShippingAddressFormSchemaParams, shippingAddressFormSchema } from './validator';
import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';
import { LeadDetailsResponse } from '../../types';
import useUpdateLead from '../../api/useUpdateLead';
import AddressCombobox from '../../../../components/AddressGoogleCombobox';
import prepareState from '../../../../utils/prepareState';

interface ShippingAddressFormProps {
    lead?: LeadDetailsResponse;
    onSuccess?: () => void;
}

const FormElements: React.FC<{ isSaveDisabled?: boolean }> = ({ isSaveDisabled = false }) => {
    const isMinL = useIsMinBreakpoint('l');

    const {
        setValue,
        formState: { isDirty }
    } = useFormContext();

    React.useEffect(() => {
        setValue('isDirty', isDirty);
    }, [setValue, isDirty]);

    return (
        <Section noDefaultPadding>
            <div
                style={{
                    width: '100%',
                    textAlign: 'left',
                    marginBottom: '3.6rem',
                    marginTop: '0.4rem'
                }}
            >
                <P noDefaultMargin strong size="large" styles={{ marginBottom: '1.2rem' }}>
                    Shipping address
                </P>
                <P noDefaultMargin>Address where Hero dispenser should be delivered</P>
            </div>
            <AddressCombobox />
            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <Input name="address_line_2" displayName="Apt, suite, etc (optional)" />
                <Input name="city" displayName="City" />
            </Container>

            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <div style={{ width: '100%' }}>
                    <Combobox
                        name="state"
                        displayName="State"
                        suggestions={usStateNames}
                        noExtraMargin
                        onlyValidateWithError={true}
                    />
                </div>
                <Input name="zip" displayName="Zip" />
            </Container>
            <Section centered noDefaultPadding marginTop="small">
                <Button type="submit" styles={{ width: '16.5rem' }} disabled={isSaveDisabled}>
                    Save
                </Button>
            </Section>
        </Section>
    );
};

const ShippingAddressForm: React.FC<ShippingAddressFormProps> = ({ lead, onSuccess }) => {
    const { mutate, isPending: isLoading, isError } = useUpdateLead(onSuccess);

    const handleSubmit = (attributes: ShippingAddressFormSchemaParams) => {
        if (!attributes.isDirty) {
            onSuccess && onSuccess();
            return;
        }

        const leadId = lead?.primary_user_details.lead_id;
        leadId &&
            mutate({
                query: { lead_id: leadId },
                payload: {
                    street_address: attributes.address_line_1,
                    apartment: attributes?.address_line_2 || undefined,
                    state: prepareState(attributes.state),
                    city: attributes.city,
                    shipping_zip: attributes.zip
                }
            });
    };
    return (
        <Form
            submitFn={handleSubmit}
            validationSchema={shippingAddressFormSchema}
            defaultValues={{
                address_line_1: lead?.address_details?.street_address,
                address_line_2: lead?.address_details?.apartment,
                city: lead?.address_details?.city,
                state: lead?.address_details?.state,
                zip: lead?.address_details?.shipping_zip
            }}
        >
            <FormElements isSaveDisabled={isLoading} />
            {isError ? (
                <Section
                    subtheme={['status', 'fail', 'beta']}
                    role="aside"
                    border
                    padding="small"
                    marginVertical="small"
                >
                    Error: Network response was not ok
                </Section>
            ) : null}
        </Form>
    );
};

export default ShippingAddressForm;
