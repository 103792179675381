import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import React from 'react';

interface UserErrorComponentProps {
    updateError: {
        errors: string[];
    } | null;
    deactivateError: {
        errors: string[];
    } | null;
    userName?: string;
    userEmail?: string | null;
    closeModal: () => void;
}

const UserErrorComponent: React.FC<UserErrorComponentProps> = ({
    updateError,
    deactivateError,
    userEmail,
    userName,
    closeModal
}) => {
    if (
        updateError?.errors.includes('last_admin') ||
        deactivateError?.errors.includes('last_admin')
    ) {
        const header = updateError?.errors.includes('last_admin')
            ? 'Cannot update user role'
            : 'Cannot deactivate user';
        return (
            <Section centered>
                <P size="large" strong>
                    {header}
                </P>
                <P>
                    {userEmail} is currently the only Admin on the account. Each account must have
                    at least one Admin. Please assign another Admin before deactivating this user.
                </P>
                <Button onClick={closeModal} width={'large'}>
                    Cancel
                </Button>
            </Section>
        );
    }

    return (
        <Section centered>
            <P size="large" strong>
                {userName} not updated.
            </P>
            <P>Please try again or contact support.</P>
            <P>Error: {updateError?.errors[0] || deactivateError?.errors[0] || 'System error'}</P>
            <Button onClick={closeModal} width={'large'}>
                Ok
            </Button>
        </Section>
    );
};

export default UserErrorComponent;
