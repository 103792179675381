import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    AllApprovalStatusSubStates,
    ApprovalStatus,
    LeadStatesResponse,
    StatesFilter
} from './types';
import getApprovalStatusesBySubStatus from './utils/getApprovalStatusesBySubStatus';

const useStatesQueryParams = (allLeadStates?: LeadStatesResponse) => {
    const [searchParams] = useSearchParams();

    return useMemo(() => {
        let approvalStatuses = searchParams.get('approval_status')
            ? searchParams.get('approval_status')!.split(',')
            : [];
        let approvalSubStatuses = searchParams.get('approval_sub_status')
            ? searchParams.get('approval_sub_status')!.split(',')
            : [];

        const preparedApprovalStates: StatesFilter[] = approvalStatuses.map((approval_status) => ({
            approval_status: approval_status as ApprovalStatus,
            approval_sub_statuses: []
        }));

        let preparedApprovalSubStates: StatesFilter[] = [];

        approvalSubStatuses.forEach((approvalSubStatus) => {
            const approvalStatusesBySubStatus = getApprovalStatusesBySubStatus(
                allLeadStates,
                approvalSubStatus
            );

            approvalStatusesBySubStatus?.forEach((approvalStatus) => {
                const index = preparedApprovalSubStates.findIndex(
                    (preparedApprovalSubState) =>
                        preparedApprovalSubState.approval_status === approvalStatus
                );

                if (index > -1) {
                    preparedApprovalSubStates[index].approval_sub_statuses.push(
                        approvalSubStatus as AllApprovalStatusSubStates
                    );
                } else {
                    preparedApprovalSubStates.push({
                        approval_status: approvalStatus,
                        approval_sub_statuses: [approvalSubStatus as AllApprovalStatusSubStates]
                    });
                }
            });
        });

        if (preparedApprovalStates.length) {
            return preparedApprovalStates.map((preparedApprovalState) => {
                const find = preparedApprovalSubStates.find(
                    (preparedApprovalSubState) =>
                        preparedApprovalState.approval_status ===
                        preparedApprovalSubState.approval_status
                );

                return find || preparedApprovalState;
            });
        }

        return [...preparedApprovalSubStates];
    }, [searchParams, allLeadStates]);
};

export default useStatesQueryParams;
