import P from '@hero/ui-kit/typography/P';
import React from 'react';
import { MedicationDetails, MedicationSchedule } from '../../types';
import getPreparedMedications from './utils/getPreparedMedications';

interface ScheduleDetailsProps {
    show?: boolean;
    medication: MedicationDetails;
}

const ScheduleItem: React.FC<{ schedules: MedicationSchedule[]; show?: boolean }> = ({
    schedules,
    show = false
}) => {
    const schedulesLabel = React.useMemo(() => {
        return schedules.map(
            (schedule, index) =>
                `${schedule.time} (${schedule.qty} ${(schedule.qty || 0) > 1 ? 'pills' : 'pill'})${
                    index !== schedules.length - 1 ? ', ' : ''
                }`
        );
    }, [schedules]);

    return schedules.length > 0 && show ? <P noDefaultMargin>{schedulesLabel}</P> : null;
};

const ScheduleDetails: React.FC<ScheduleDetailsProps> = ({ medication, show = false }) => {
    const prepareMedications = React.useMemo(() => {
        return getPreparedMedications(medication);
    }, [medication]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {Object.entries(prepareMedications.dowListGrouped).map((entry, index) => (
                <React.Fragment key={`${index}-${entry[0]}`}>
                    <P noDefaultMargin strong>
                        {`${entry[0]} - ${entry[1].length} ${
                            entry[1].length > 1 ? 'times' : 'time'
                        }`}
                    </P>
                    <ScheduleItem schedules={entry[1]} show={show} />
                </React.Fragment>
            ))}
            {Object.entries(prepareMedications.everyXDaysList).map((entry, index) => (
                <React.Fragment key={`${index}-${entry[0]}`}>
                    <P noDefaultMargin strong>
                        {`Every ${entry[0]} days - ${entry[1].length} ${
                            entry[1].length > 1 ? 'times' : 'time'
                        }`}
                    </P>
                    <ScheduleItem schedules={entry[1]} show={show} />
                </React.Fragment>
            ))}
        </div>
    );
};

export default ScheduleDetails;
