import P from '@hero/ui-kit/typography/P';
import Span from '@hero/ui-kit/typography/Span';
import React from 'react';
import getDeviceStatusLabel from '../../OrganizationMemberList/utils/getDeviceStatusLabel';
import { DeviceStatus as DeviceStatusEnum, MemberRole } from '../../types';
import getIsDeviceSetupNeeded from '../../utils/getIsDeviceSetupNeeded';
import DeviceStatus from './DeviceStatus';
import { formatDistanceToNowStrict } from 'date-fns';
import getMemberRoleLabel from '../../OrganizationMemberList/utils/getMemberRoleLabel';
import Link from '../../../../components/Link';

interface UserDeviceStatusProps {
    isDeviceOnline: boolean;
    deviceStatus: DeviceStatusEnum | null;
    deviceSerial: string;
    userFullName?: string;
    userLink?: string;
    lastOnline?: string;
    heroUserId?: number;
    userRole?: MemberRole | null;
}

const UserDeviceStatus: React.FC<UserDeviceStatusProps> = ({
    isDeviceOnline,
    deviceSerial,
    userFullName,
    userLink,
    lastOnline,
    deviceStatus,
    userRole
}) => {
    const isDeviceSetupNeeded = React.useMemo(() => {
        return getIsDeviceSetupNeeded(deviceStatus);
    }, [deviceStatus]);

    const deviceSerialLabel = React.useMemo(() => {
        if (['DELIVERED', 'ORDERED', 'ACTIVATED', 'RETURN_NEEDED'].includes(deviceStatus || '')) {
            return (
                <>
                    {deviceSerial} <Span>{getDeviceStatusLabel(deviceStatus)}</Span>
                </>
            );
        }

        if (isDeviceSetupNeeded) {
            return (
                <>
                    {deviceSerial} <Span>{'Setup Needed'}</Span>
                </>
            );
        }

        if (!isDeviceOnline) {
            const lastOnlineDate = lastOnline ? new Date(lastOnline) : 'N/A';
            const lastOnlineDistance =
                lastOnlineDate instanceof Date
                    ? formatDistanceToNowStrict(lastOnlineDate)
                    : lastOnlineDate;
            return (
                <>
                    {deviceSerial} <Span>{`Offline ${lastOnlineDistance}`}</Span>
                </>
            );
        }

        return deviceSerial;
    }, [isDeviceOnline, deviceSerial, lastOnline, isDeviceSetupNeeded, deviceStatus]);

    const memberRoleLabel = getMemberRoleLabel(userRole);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <DeviceStatus
                isDeviceOnline={isDeviceOnline}
                isDeviceSetupNeeded={isDeviceSetupNeeded}
                deviceStatus={deviceStatus}
            />
            <div style={{ marginLeft: '2.4rem' }}>
                {deviceSerial === 'N/A' ? (
                    <div>{getDeviceStatusLabel(deviceStatus)}</div>
                ) : (
                    <>
                        <P noDefaultMargin strong>
                            {deviceSerialLabel}
                        </P>
                        {userFullName && userLink ? (
                            <Link to={userLink}>{userFullName}</Link>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {memberRoleLabel !== 'N/A' ? (
                                    <P noDefaultMargin strong>
                                        {memberRoleLabel}
                                    </P>
                                ) : null}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default UserDeviceStatus;
