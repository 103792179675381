import React from 'react';
import { MedicationDetails } from '../../types';
import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';
import ScheduleDetails from './ScheduleDetails';
import DotLabel from './DotLabel';
import PillDetails from './PillDetails';
import getPillLevel from './utils/getPillLevel';
import { getIsBeforeComparedDate } from '../../../../utils/date';
import Section from '@hero/ui-kit/layout/Section';

const noop = () => null;

const MedDetails: React.FC<{ med: MedicationDetails; styles?: {}; isLastElement?: boolean }> = ({
    med,
    styles = {},
    isLastElement = false
}) => {
    const isMinL = useIsMinBreakpoint('l');
    // const [show, setShow] = React.useState(true);
    const show = true;

    const isMedAvailable = React.useMemo(() => {
        return med.name !== null;
    }, [med]);

    const isMedDateExpired = React.useMemo(() => {
        return med?.expires ? getIsBeforeComparedDate(new Date(med.expires), new Date()) : false;
    }, [med]);

    const handleClick = React.useCallback(() => {
        // https://herohealth.atlassian.net/browse/HWM-16479
        // disable show/hide
        // setShow((prevState) => !prevState);
    }, []);

    const handleKeyDown = React.useCallback(() => {
        // https://herohealth.atlassian.net/browse/HWM-16479
        // disable show/hide
        // if (e.key === 'Escape') {
        //     setShow(false);
        // }
    }, []);

    const showDotDetails = React.useMemo(() => {
        const pillLevel = getPillLevel(med);
        const isPillLevelLow = pillLevel === 'low';
        const hasPillCount = !!med?.exact_pill_count;
        const isPillLevelEmpty = pillLevel === 'empty';
        const showDot = isPillLevelEmpty || (isPillLevelLow && hasPillCount);
        return {
            showDot,
            isPillLevelEmpty,
            isPillLevelLow
        };
    }, [med]);

    const nameDetails = React.useMemo(() => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DotLabel
                    type={showDotDetails.isPillLevelLow && !isMedDateExpired ? 'warn' : 'fail'}
                    showDot={(showDotDetails.showDot || isMedDateExpired) && !show}
                    label={med?.name || 'N/A'}
                    labelStrong={isMedAvailable}
                />
                {show ? (
                    <PillDetails
                        med={med}
                        showDot={showDotDetails.showDot}
                        isMedDateExpired={isMedDateExpired}
                    />
                ) : null}
            </div>
        );
    }, [med, showDotDetails, show, isMedAvailable, isMedDateExpired]);

    const allowAsNeededText = React.useMemo(() => {
        const mainLabel = med?.max_doses ? `Max ${med.max_doses} pills/day` : 'Not Allowed';
        const maxPillsPerDispense = med?.max_pills_per_dispense;
        const minTimeBetweenDispenses = med?.min_time_between_dispenses;
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <P noDefaultMargin strong={isMedAvailable}>
                    {isMedAvailable ? mainLabel : ''}
                </P>
                {show ? (
                    <>
                        {maxPillsPerDispense ? (
                            <P noDefaultMargin>{`Up to ${maxPillsPerDispense} pills / dispense`}</P>
                        ) : null}
                        {minTimeBetweenDispenses ? (
                            <P noDefaultMargin>{`Min ${minTimeBetweenDispenses} hrs apart`}</P>
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    }, [isMedAvailable, show, med]);

    return (
        <div
            onClick={isMedAvailable ? handleClick : noop}
            onKeyDown={isMedAvailable ? handleKeyDown : noop}
            role="button"
            tabIndex={0}
            style={{
                outline: 'none',
                padding: '2rem 0',
                ...(isLastElement && {
                    borderBottomLeftRadius: '2rem',
                    borderBottomRightRadius: '2rem'
                })
            }}
        >
            <Section noDefaultPadding paddingHorizontal="small">
                <Container
                    gridTemplateColumns={isMinL ? '0.4fr 4.6fr 5fr 2fr' : '1fr'}
                    styles={{ ...styles }}
                >
                    <P noDefaultMargin>{med.slot || 'N/A'}</P>
                    {nameDetails}
                    {med.pill_schedules?.length ? (
                        <ScheduleDetails medication={med} show={show} />
                    ) : (
                        <P noDefaultMargin strong={isMedAvailable}>
                            {isMedAvailable ? 'No schedule' : ''}
                        </P>
                    )}
                    {allowAsNeededText}
                </Container>
            </Section>
        </div>
    );
};

export default MedDetails;
