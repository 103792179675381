import React from 'react';
import * as Style from './style.module.scss';
import P from '@hero/ui-kit/typography/P';
import XButton from '@hero/ui-kit/inputs/XButton';
import PDFIcon from './PDFIcon';
import ImageIcon from './ImageIcon';

interface AttachmentComponentProps {
    imgSrc?: string;
    imgName: string;
    label?: string;
    onClose?: () => void;
    imgType?: string;
}

const AttachmentComponent: React.FC<AttachmentComponentProps> = ({
    imgName,
    imgType = 'png',
    label = 'Image',
    onClose
}) => {
    const handleClose = () => {
        onClose && onClose();
    };

    const icon = imgType === 'pdf' ? <PDFIcon /> : <ImageIcon />;

    return (
        <div className={Style.wrapper}>
            {onClose ? (
                <XButton
                    onClick={handleClose}
                    alt="remove attachment"
                    className={Style.closeBtn}
                    size="small"
                />
            ) : null}
            <div className={Style.iconWrapper}>{icon}</div>
            <div className={Style.textWrapper}>
                <P strong noDefaultMargin>
                    {label}
                </P>
                <P size="small" noDefaultMargin>
                    {imgName}
                </P>
            </div>
        </div>
    );
};

export default AttachmentComponent;
