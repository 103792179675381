// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jkSqyuIZ0fSm79GU_200{display:block}.KYV2wYIiMb6jLFtvO8dS{text-align:center;font-size:2.4rem;line-height:3.2rem;font-weight:700;margin-bottom:2.4rem}.wcVUPtSxNmEIi69W7OOI{overflow:auto;display:block}.WTcKBdGPvwrRsNTRjA0n{display:flex;margin-bottom:1.2rem}.WTcKBdGPvwrRsNTRjA0n>div{flex:5;display:flex;align-items:center;flex-direction:column}.WTcKBdGPvwrRsNTRjA0n>div:first-of-type{margin-right:1.2rem;flex:2;border-right:1px solid var(--color-neutrals-borderAlpha);justify-content:center}.uOnCcIjfSjnwBne71d39{display:inherit}`, "",{"version":3,"sources":["webpack://./../hero-frontend-packages/packages/enterprise-portal-common/src/Adherence/AdherenceCalendar/Sidebar/SidebarDay/SidebarDay.module.scss"],"names":[],"mappings":"AACA,sBACI,aAAA,CAEJ,sBACI,iBAAA,CACA,gBAAA,CACA,kBAAA,CACA,eAAA,CACA,oBAAA,CAEJ,sBACI,aAAA,CACA,aAAA,CAEJ,sBACI,YAAA,CACA,oBAAA,CACA,0BACI,MAAA,CACA,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,wCACI,mBAAA,CACA,MAAA,CACA,wDAAA,CACA,sBAAA,CAKZ,sBACI,eAAA","sourceRoot":""}]);
// Exports
export var root = `jkSqyuIZ0fSm79GU_200`;
export var header = `KYV2wYIiMb6jLFtvO8dS`;
export var content = `wcVUPtSxNmEIi69W7OOI`;
export var periodRow = `WTcKBdGPvwrRsNTRjA0n`;
export var asneeded = `uOnCcIjfSjnwBne71d39`;
export default ___CSS_LOADER_EXPORT___;
