import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GetDeviceStatisticsParams } from '../api/useGetDeviceStatistics';
import { GetOrganizationMembersParams } from '../api/useGetOrganizationMembers';

type UsePagerProps = () => {
    query: GetOrganizationMembersParams;
    deviceStatisticsQuery: GetDeviceStatisticsParams;
};

const useListQueryParams: UsePagerProps = () => {
    const [searchParams] = useSearchParams();

    return useMemo(() => {
        const offset = searchParams.get('offset') || 0;
        const limit = searchParams.get('limit');
        const online_status = searchParams.get('online_status');
        const state = searchParams.get('state');
        const device_status = searchParams.get('device_status');
        const search = searchParams.get('search') || '';
        const list_version = searchParams.get('list_version');
        let params = {};
        let deviceStatisticsParams = {};

        if (search) {
            params = { ...params, search };
        }

        if (state) {
            params = { ...params, state };
        }

        if (list_version) {
            params = {
                ...params,
                list_version,
                ...(list_version === 'discharged_and_cancelled' && { ordering: 'by_dates' })
            };
        }

        if (online_status) {
            const is_online = online_status === 'online' ? true : false;
            params = { ...params, is_online };
            deviceStatisticsParams = { ...deviceStatisticsParams, is_online };
        }

        if (device_status) {
            params = { ...params, device_status };
            deviceStatisticsParams = { ...deviceStatisticsParams, device_status };
        }

        params = { ...params, limit: limit ? +limit : 10, offset };

        return {
            query: params,
            deviceStatisticsQuery: deviceStatisticsParams
        };
    }, [searchParams]);
};

export default useListQueryParams;
