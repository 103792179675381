import { useMutation, useQueryClient } from '@tanstack/react-query';

import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { LEAD_DETAILS } from './constants';

const useExtendLockLead = (onSuccess?: () => void) => {
    const req = useRequest();
    const queryClient = useQueryClient();

    const request = ({ payload }: { payload: { lead_id: string } }) => {
        return req<{ lock_expires: string }>({
            url: `${envVars.API_RTM_HOSTNAME}enterprise/extend-lead-claim/`,
            method: 'POST',
            body: JSON.stringify(payload)
        });
    };

    return useMutation({
        mutationFn: request,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [LEAD_DETAILS] });
            onSuccess && onSuccess();
        },
        onError: (error: { errors: string[] }) => {
            return error;
        }
    });
};

export default useExtendLockLead;
