// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._Mw0MqTnTUas6ky_YOyQ{background:var(--lead-status-yellow)}.sH4DwKjvtHyh3MWIhT6w{background:var(--lead-status-tangerine)}.LlUOucRg13neEHdjNMO_{background:var(--lead-status-purple)}.jFaIZTsk7FqFEfyTlNbY{background:var(--lead-status-blue)}.POfBPfohVTvLL1RaC3nc{background:var(--lead-status-success)}.vdzUHO3hiFbrk2yCcKdU{background:var(--lead-status-red)}.UcKJRp8IymnAd4d4EXNS{background:var(--lead-status-grey);color:#fff}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/utils/style.module.scss"],"names":[],"mappings":"AACA,sBACI,oCAAA,CAGJ,sBACI,uCAAA,CAGJ,sBACI,oCAAA,CAGJ,sBACI,kCAAA,CAGJ,sBACI,qCAAA,CAGJ,sBACI,iCAAA,CAGJ,sBACI,kCAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
export var NEW_APPLICATION = `_Mw0MqTnTUas6ky_YOyQ`;
export var PENDING_MANUAL_VOB = `sH4DwKjvtHyh3MWIhT6w`;
export var LEAD_QUALIFIED = `LlUOucRg13neEHdjNMO_`;
export var PENDING_MEDICAL_NECESSITY = `jFaIZTsk7FqFEfyTlNbY`;
export var APPROVED = `POfBPfohVTvLL1RaC3nc`;
export var REJECTED = `vdzUHO3hiFbrk2yCcKdU`;
export var EXPIRED = `UcKJRp8IymnAd4d4EXNS`;
export default ___CSS_LOADER_EXPORT___;
