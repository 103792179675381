import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { OrganizationMemberDetails } from '../types';
import { ORGANIZATION_MEMBER_KEY } from './constants';

type GetOrganizationMemberParams = {
    hero_user_id?: number | null;
    member_id?: number | null;
};

const useGetOrganizationMemberById = (
    query: GetOrganizationMemberParams,
    options?: { enabled?: boolean }
) => {
    const req = useRequest();

    const action = useCallback(() => {
        return req<OrganizationMemberDetails>({
            url: `${envVars.API_COMMERCE_HOSTNAME}enterprise/member`,
            query
        });
    }, [req, query]);

    return useQuery({
        queryFn: action,
        queryKey: [ORGANIZATION_MEMBER_KEY, query],
        ...options
    });
};

export default useGetOrganizationMemberById;
