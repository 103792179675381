import React from 'react';
import Form from '@hero/ui-kit/inputs/Form';

import { LeadEnrolmentPayload } from '../../types';
import FormElements from './FormElements';
import { enrolmentFormSchema, EnrolmentFormSchema } from './validator';
import useLeadEnrolment from '../../api/useLeadEnrolment';
import SuccessEnrollModal from '../SuccessEnrollModal';
import LeadErrorModal from '../LeadErrorModal';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
import prepareState from '../../../../utils/prepareState';
import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';

// BE returns error in form: { errors: ['StateMachineErrorString': 'some-error-type'] }
const getEnrollErrorMessage = (error?: string[]) => {
    const defaultErrorMessage = 'Not supported error';
    const message = error ? error[0] : undefined;
    if (message) {
        const split = message.split(':');
        return split.length > 1 ? split[1].trim() : defaultErrorMessage;
    }

    return defaultErrorMessage;
};

type Props = {
    onSuccess?: () => void;
    onSuccessModal?: () => void;
    leadId: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    isCaregiver?: boolean;
    address_line_1?: string | null;
    address_line_2?: string | null;
    city?: string | null;
    state?: string | null;
    zip_code?: string | null;
    phone?: string | null;
    isMember?: boolean;
    admin_email?: string | null;
    external_serial?: string | null;
    external_user_id?: string | null;
    formVersion?: string | null;
};

const MedicareLeadForm: React.FC<Props> = ({
    onSuccess,
    onSuccessModal,
    leadId,
    firstName,
    lastName,
    email,
    isCaregiver = false,
    isMember = false,
    address_line_1,
    address_line_2,
    city,
    zip_code,
    state,
    phone,
    admin_email,
    external_serial,
    external_user_id,
    formVersion
}) => {
    const mutation = useLeadEnrolment(onSuccess);
    const { isError, error, isSuccess, reset: resetUpdateLeadApprovalStatus } = mutation;

    const [showSuccessModal, setShowSuccessModal] = React.useState(false);
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const [showAlreadyEnrolledModal, setShowAlreadyEnrolledModal] = React.useState(false);
    const [successModalType, setSuccessModalType] = React.useState<string | undefined>();

    const handleSubmit = React.useCallback(
        ({
            address_line_1,
            address_line_2,
            city,
            zip_code,
            state,
            ...data
        }: EnrolmentFormSchema) => {
            const { is_member, admin_email, external_serial, phone } = data;
            const payload: LeadEnrolmentPayload = {
                lead_id: leadId,
                approval_sub_status: 'ENROLLED',
                is_member,
                ...(admin_email && { admin_email }),
                ...(external_serial && { external_serial }),
                ...(address_line_1 && {
                    aux_data: {
                        ...data,
                        phone: formatPhoneNumber(phone),
                        shipping_address: {
                            address_line_1,
                            address_line_2: address_line_2 || undefined,
                            city,
                            zip_code,
                            state: prepareState(state)
                        }
                    }
                })
            };

            setSuccessModalType(data.is_member ? 'enrollment-complete' : undefined);
            mutation.mutate({ payload });
        },
        [leadId, mutation]
    );

    const errorTypeString = error?.errors[0];

    const isAlreadyEnrolledError =
        errorTypeString === 'LeadStateMachineException: already_enrolled';

    const errorType = getEnrollErrorMessage(error?.errors);

    React.useEffect(() => {
        if (isSuccess) {
            setShowSuccessModal(true);
        }
        if (
            isError &&
            !['serial_not_exist', 'email_not_exist'].includes(errorType || '') &&
            !isAlreadyEnrolledError
        ) {
            setShowErrorModal(true);
        }

        if (isAlreadyEnrolledError) {
            setShowAlreadyEnrolledModal(true);
        }
    }, [isError, errorType, isSuccess, isAlreadyEnrolledError]);

    return (
        <>
            <Form
                submitFn={handleSubmit}
                validationSchema={enrolmentFormSchema}
                defaultValues={{
                    first_name: firstName,
                    last_name: lastName,
                    role: isCaregiver ? 'caregiver' : 'primary_user',
                    email,
                    address_line_1,
                    address_line_2,
                    city,
                    zip_code,
                    state,
                    phone,
                    is_member: isMember,
                    admin_email,
                    external_serial,
                    external_user_id
                }}
                validationMode="onTouched"
            >
                <FormElements
                    loading={mutation.isPending}
                    showAdminEmailError={errorType === 'email_not_exist'}
                    showHeroSerialErrorError={errorType === 'serial_not_exist'}
                    adminEmail={admin_email}
                    externalSerial={external_serial}
                    isExistingMember={isMember}
                    formVersion={formVersion}
                />
            </Form>
            <SuccessEnrollModal
                type={successModalType}
                externalControls={[showSuccessModal, setShowSuccessModal]}
                onDone={onSuccessModal}
            />
            <LeadErrorModal
                externalControls={[showErrorModal, setShowErrorModal]}
                errorType={errorType}
                onCancel={() => setShowErrorModal(false)}
            />
            <Modal
                externalControls={[showAlreadyEnrolledModal, setShowAlreadyEnrolledModal]}
                isCancelable
                onClose={resetUpdateLeadApprovalStatus}
                styles={{ padding: '0' }}
            >
                <H role="h4">Enrollment not possible!</H>
                <P centered>
                    {`${firstName} ${lastName}`} is already enrolled in the program with another
                    provider.
                </P>
                <Section centered>
                    <Button
                        onClick={() => {
                            resetUpdateLeadApprovalStatus();
                            setShowAlreadyEnrolledModal(false);
                        }}
                        variant="outlineSecondary"
                    >
                        Close
                    </Button>
                </Section>
            </Modal>
        </>
    );
};

export default MedicareLeadForm;
