import React from 'react';

import envVars from '../../../../../constants/envVars';
import useRequest from '../../../../../hooks/useRequest';
import { AdherenceSummary } from '@hero/enterprise-portal-common/src/Adherence/types';

type Query = {
    target_datetime: string | null; // ending datetime for the period in YYYY-mm-dd HH:MM:SS
    mode: 'weekly' | 'monthly' | null;
};

const useGetAdherenceSummary = (hero_user_id: number) => {
    const req = useRequest();

    const request = React.useCallback(
        (query: Query) => {
            return req<AdherenceSummary>({
                url: `${envVars.API_CLOUD_HOSTNAME}enterprise/adherence/summary`,
                query: { ...query, hero_user_id }
            });
        },
        [req, hero_user_id]
    );

    return request;
};

export default useGetAdherenceSummary;
