import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuthContext } from '@hero/hero-auth/v2/AuthProvider';
import InactivityTimer from '@hero/hero-utils/inactivityTimer';
import AlertBar from '@hero/ui-kit/components/AlertBar';

import Loading from '../components/States/Loading';
import { useUserPolicyContext } from '../context/UserPolicy';
import { useUserStatusContext } from '../context/UserStatus';

const ProtectedRoute: React.FC<{
    children?: React.ReactElement;
}> = ({ children }) => {
    const { auth, isLoading, makeAuthCall, logout } = useAuthContext();
    const { userStatus } = useUserStatusContext();
    const { userPolicy, isLoading: isPolicyLoading } = useUserPolicyContext();
    const [inactivityWarning, setInactivityWarning] = React.useState(0);
    const [searchParams] = useSearchParams();

    React.useEffect(makeAuthCall, [makeAuthCall]);

    React.useEffect(() => {
        // TODO: Remove test once the feature has been SQA confirmed
        const idleSessionMinutes = searchParams.get('logout-test')
            ? 1.05
            : userPolicy?.idle_session_minutes || 30; // default 30min

        let countdown: NodeJS.Timeout;
        const timer = new InactivityTimer({
            timeout: idleSessionMinutes * 60,
            onTimeout: () => {
                logout();
            },
            warningTimeout: (idleSessionMinutes - 1) * 60,
            onWarning: () => {
                setInactivityWarning(59);
                countdown = setInterval(() => {
                    setInactivityWarning((prev) => prev - 1);
                }, 1000);
            },
            onWarningExit: () => {
                countdown && clearInterval(countdown);
                setInactivityWarning(0);
            }
        });

        return () => {
            timer.cleanUp();
            clearInterval(countdown);
        };
    }, [logout, userPolicy]);

    if (auth && userStatus && !isLoading && !isPolicyLoading) {
        return (
            <>
                {inactivityWarning ? (
                    <AlertBar type="warn">
                        {`You will be automatically logged out due to inactivity - ${inactivityWarning}`}
                    </AlertBar>
                ) : null}

                {children || null}
            </>
        );
    }

    return <Loading fullscreen />;
};

export default ProtectedRoute;
