import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import XButton from '@hero/ui-kit/inputs/XButton';
import * as React from 'react';
import * as Styles from './style.module.scss';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { getIsExistingMemberCheckError } from '../../Details/v2/components/General';

type LeadErrorModalProps = {
    onCancel: () => void;
    onSave?: () => void;
    errorType?: string;
    isLoading?: boolean;
} & Pick<ModalProps, 'externalControls'>;

const LeadErrorModal: React.FC<LeadErrorModalProps> = ({
    onCancel,
    onSave,
    externalControls,
    isLoading = false,
    errorType
}) => {
    const isExistingMemberCheckError = getIsExistingMemberCheckError(errorType);

    const { headerText, text } = React.useMemo(() => {
        if (errorType === 'transfer_not_allowed') {
            return {
                headerText: 'Lead is not eligible for enrollment',
                text: (
                    <P noDefaultMargin>
                        This person is already enrolled in an RTM program with another provider. You
                        will not be able to enroll them in your organization.
                    </P>
                )
            };
        }

        if (['no_valid_membership', 'no_match'].includes(errorType || '')) {
            return {
                headerText: 'Hero member info not found',
                text: (
                    <P noDefaultMargin>
                        Make sure you’ve collected the correct information from your lead. <br />
                        Member details are stored on <strong>Device info</strong> screen of the Hero
                        <br />
                        dispenser:{' '}
                        <strong>{'Main Menu > Device Settings > About > Device info.'}</strong>
                    </P>
                )
            };
        }

        if (['invalid_state_change'].includes(errorType || '')) {
            return {
                headerText: 'Change not possible!',
                text: <P noDefaultMargin>Invalid state change for lead.</P>
            };
        }

        if (['already_enrolled'].includes(errorType || '')) {
            return {
                headerText: 'Change not possible!',
                text: (
                    <P noDefaultMargin>
                        Lead is already enrolled in the program with another provider.
                    </P>
                )
            };
        }

        if (isExistingMemberCheckError) {
            return {
                headerText: 'Warning',
                text: (
                    <P noDefaultMargin>
                        Admin Email and Hero S/N provided do not match our records. You can choose
                        to save your changes without entering an Admin Email and Hero S/N, or go
                        back to editing.{' '}
                        <strong>
                            Please note that while a correct Admin Email and Hero S/N are not
                            required at this moment, they will be necessary to enroll the patient.
                        </strong>
                    </P>
                )
            };
        }

        return {
            headerText: 'Something went wrong',
            text: <P noDefaultMargin>{errorType || 'Please contact customer support'}</P>
        };
    }, [errorType]);

    return (
        <Modal
            styles={{ padding: 0, maxWidth: '57rem', minWidth: '47rem' }}
            externalControls={externalControls}
        >
            <>
                <div className={Styles.header}>
                    <div className={Styles.headerText}>
                        <P noDefaultMargin strong styles={{ fontSize: '2.4rem' }}>
                            {headerText}
                        </P>
                    </div>

                    <XButton className={Styles.xBtn} onClick={() => onCancel()} alt="Close modal" />
                </div>
                {text}
                <div className={Styles.btnWrapper}>
                    {onSave ? (
                        <>
                            {isExistingMemberCheckError ? (
                                <>
                                    <Button onClick={onSave} disabled={isLoading}>
                                        Save w/o Admin Email and S/N
                                    </Button>
                                    <Button
                                        onClick={onCancel}
                                        variant="outlineSecondary"
                                        disabled={isLoading}
                                    >
                                        Back to edit
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button onClick={onCancel} disabled={isLoading}>
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={onSave}
                                        variant="outlineSecondary"
                                        disabled={isLoading}
                                    >
                                        Save it anyway
                                    </Button>
                                </>
                            )}
                        </>
                    ) : (
                        <Button onClick={onCancel} variant="outlineSecondary">
                            Close
                        </Button>
                    )}
                </div>
            </>
        </Modal>
    );
};

export default LeadErrorModal;
