import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import animation from './gifs/hero_animation.gif';

type Props = {
    fullscreen?: boolean;
};

const Loading: React.FC<Props> = ({ fullscreen }) => {
    return (
        <Section centered styles={fullscreen ? { height: '100vh' } : { marginTop: '5rem' }}>
            <img
                src={animation}
                alt="hero-animation-concept-3"
                style={{ width: '41.8rem', height: '25rem' }}
            />
        </Section>
    );
};

export default Loading;
