import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { IAMListRole } from '../types';

const useGetListRoles = () => {
    const req = useRequest();

    const action = useCallback(() => {
        return req<IAMListRole[]>({
            url: `${envVars.API_ID_HOSTNAME}api/enterprise/roles`
        });
    }, [req]);

    return useQuery({
        queryFn: action,
        queryKey: ['iam-roles-list']
    });
};

export default useGetListRoles;
