import React from 'react';
import Container from '../../../ui-kit/layout/Container';
import Section from '../../../ui-kit/layout/Section';
import AdherenceCalendarWeekly from './AdherenceCalendar/AdherenceCalendarWeekly';
import AdherenceCalendarMonthly from './AdherenceCalendar/AdherenceCalendarMonthly';

import AdherenceCircle from './AdherenceCircle';
import BarGraph from './BarGraph';
import Timeframe from './Filters/Timeframe';
import DateSelect from './Filters/Date';
import useDefaultFilters from './Filters/useDefaultFilters';
import { useSearchParams } from 'react-router-dom';
import { PARAM_TIMEFRAME, PARAM_DATE } from './constants/searchParams';
import AdherenceProvider, { AdherenceContextProps } from './context/AdherenceContext';

const Adherence: React.FC<AdherenceContextProps> = (props) => {
    useDefaultFilters();
    const [searchParams] = useSearchParams();

    return (
        <AdherenceProvider {...props}>
            <Section
                paddingHorizontal="none"
                paddingBottom="small"
                styles={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <DateSelect />
                <Timeframe />
            </Section>

            <Container gridRowGap="regular">
                <Container gridTemplateColumns={'1fr 2fr'} gridColumnGap="regular">
                    <Section
                        border
                        noDefaultPadding
                        paddingVertical="small"
                        styles={{ overflow: 'visible' }}
                    >
                        <AdherenceCircle />
                    </Section>
                    <Section
                        border
                        padding="small"
                        centeredVertically
                        styles={{ minHeight: '32rem' }}
                    >
                        <BarGraph />
                    </Section>
                </Container>
                {searchParams.get(PARAM_TIMEFRAME) === 'weekly' && (
                    <AdherenceCalendarWeekly dateTo={searchParams.get(PARAM_DATE) || ''} />
                )}
                {searchParams.get(PARAM_TIMEFRAME) === 'monthly' && (
                    <AdherenceCalendarMonthly dateTo={searchParams.get(PARAM_DATE) || ''} />
                )}
            </Container>
        </AdherenceProvider>
    );
};
export default Adherence;
