import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import { useUserStatusContext } from '../../../../context/UserStatus';
import useUpdateUserInvite from '../../api/useUpdateUserInvite';
import * as Style from './style.module.scss';
import ButtonToggler from '../../../../components/ButtonToggler';
import RolePicker from '../InviteForm/RolePicker';
import InfoIcon from '@hero/ui-kit/icons/utility/InfoIcon';
import { UserDetailsProps } from '.';
import UserErrorComponent from './UserErrorComponent';
import { UpdateUserAction } from '../../types';

const EditDeactivatedUser: React.FC<UserDetailsProps> = ({ user, userName, closeModal, roles }) => {
    const { id: userId, email: userEmail } = user;

    const roleId = user.role?.id;

    const [resetMFA, setResetMFA] = React.useState(false);
    const [selectedRoleId, setSelectedRoleId] = React.useState(roleId);
    const {
        mutateAsync: updateInvite,
        error: updateError,
        isPending: isUpdatePending
    } = useUpdateUserInvite();

    const { permissions } = useUserStatusContext();

    const isUpdateDisabled = React.useMemo(() => {
        return !permissions.iam.inviteUserEdit || isUpdatePending;
    }, [permissions, isUpdatePending]);

    const handleReactivate = React.useCallback(() => {
        const isRoleChanged = roleId !== selectedRoleId;

        let action: UpdateUserAction[] = ['ENABLE_INVITE'];

        if (isRoleChanged) {
            action.push('UPDATE_ROLE');
        }

        if (resetMFA) {
            action.push('RESET_MFA');
        }

        updateInvite({ id: userId, action, ...(isRoleChanged && { role_id: selectedRoleId }) })
            .then(() => closeModal())
            .catch(() => {});
    }, [updateInvite, roleId, selectedRoleId, resetMFA, userId, closeModal]);

    const [proceed, setProceed] = React.useState(false);

    if (!!updateError) {
        return (
            <UserErrorComponent
                updateError={updateError}
                deactivateError={null}
                userEmail={userEmail}
                userName={userName}
                closeModal={closeModal}
            />
        );
    }

    if (proceed) {
        return (
            <>
                <Section centered noDefaultPadding>
                    <H role="h5" noDefaultMargin>
                        {userName}
                    </H>
                    <P noDefaultMargin>{userEmail}</P>
                </Section>

                <div className={Style.roleLabel}>
                    <H noDefaultMargin role="h6">
                        User role
                    </H>
                </div>
                <RolePicker
                    roles={roles}
                    onChange={(role) => setSelectedRoleId(role)}
                    selectedId={selectedRoleId}
                />
                <hr className={Style.divider} />
                <div className={Style.mfaResetWrapper}>
                    <div>
                        <P strong noDefaultMargin>
                            MFA Reset
                        </P>
                        <P noDefaultMargin>Authenticator app</P>
                    </div>
                    <ButtonToggler
                        checked={resetMFA}
                        onChange={(checked) => setResetMFA(checked)}
                    />
                </div>
                {resetMFA ? (
                    <div className={Style.infoResetMFA}>
                        <InfoIcon width={20} height={22} type="utility" />
                        <P noDefaultMargin>
                            The user will be required to set up MFA again on next login.
                        </P>
                    </div>
                ) : null}

                <Button
                    disabled={isUpdateDisabled}
                    onClick={handleReactivate}
                    styles={{ marginTop: '6rem' }}
                >
                    {isUpdatePending ? 'Pending...' : 'Reactivate User'}
                </Button>
            </>
        );
    }

    return (
        <>
            <Section centered noDefaultPadding>
                <P
                    noDefaultMargin
                    strong
                    styles={{ fontSize: '24px', lineHeight: '24px', marginBottom: '1.2rem' }}
                >
                    {userName} (Deactivated)
                </P>
                <P noDefaultMargin styles={{ marginBottom: '3.6rem' }}>
                    {userEmail}
                </P>
                <P
                    noDefaultMargin
                    styles={{ textAlign: 'center', paddingLeft: '3.6rem', paddingRight: '3.6rem' }}
                >
                    The user account for {userEmail} is currently deactivated. Initiate the
                    reactivation process. This will allow the user to access their account again.
                </P>
                <Button onClick={() => setProceed(true)} styles={{ marginTop: '6rem' }}>
                    Reactivate account
                </Button>
            </Section>
        </>
    );
};

export default EditDeactivatedUser;
