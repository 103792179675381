import React from 'react';
import Form from '@hero/ui-kit/inputs/Form';

import { LeadCreatePayload } from '../../types';
import FormElements from './FormElements';
import { createLeadFormSchema, CreateLeadFormShema } from './validator';
import { formatShortDate } from '@hero/hero-utils/date';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
import useLeadCreate from '../../api/useLeadCreate';
import LeadErrorModal from '../LeadErrorModal';
import { getIsExistingMemberCheckError } from '../../Details/v2/components/General';

type Props = {
    onSuccess?: () => void;
};

const LeadsForm: React.FC<Props> = ({ onSuccess }) => {
    const mutation = useLeadCreate(onSuccess);
    const { isError, error, reset } = mutation;

    const [formAttributes, setFormAttributes] = React.useState<LeadCreatePayload | undefined>();

    const handleSubmit = React.useCallback(
        ({
            hero_for,
            is_member = false,
            first_name_user,
            last_name_user,
            date_of_birth,
            zip,
            first_name,
            last_name,
            phone,
            email,
            insurance_type,
            insurance_id,
            insurance_company,
            medigap_insurance_name,
            medigap_insurance_policy_id,
            admin_email,
            external_serial,
            ...rest
        }: CreateLeadFormShema) => {
            const patient_information = {
                first_name: first_name_user,
                last_name: last_name_user,
                date_of_birth: formatShortDate(date_of_birth.replaceAll('-', '/')),
                zip: zip,
                phone: hero_for === 'loved_one' ? null : formatPhoneNumber(phone),
                email: hero_for === 'loved_one' ? null : email
            };
            const caregiver_information = {
                first_name: hero_for === 'loved_one' ? first_name : null,
                last_name: hero_for === 'loved_one' ? last_name : null,
                email: hero_for === 'loved_one' ? email : null,
                phone: hero_for === 'loved_one' ? formatPhoneNumber(phone) : null
            };
            const anonymous_id = '';

            const payload: LeadCreatePayload = {
                hero_for,
                is_member,
                anonymous_id,
                patient_information,
                caregiver_information,
                insurance_type,
                form_version: 'enterprise-portal',
                ...(is_member && admin_email && { admin_email }),
                ...(is_member && external_serial && { external_serial }),
                ...(insurance_id && { insurance_policy_id: insurance_id }),
                ...(insurance_type === 'ORIGINAL_MEDICARE' &&
                    medigap_insurance_name && { medigap_insurance_name }),
                ...(insurance_type === 'ORIGINAL_MEDICARE' &&
                    medigap_insurance_policy_id && { medigap_insurance_policy_id }),
                ...(insurance_type !== 'ORIGINAL_MEDICARE' &&
                    insurance_company && {
                        insurance_carrier_by_user: insurance_company
                    }),
                ...rest
            };

            setFormAttributes(payload);

            mutation.mutate({ payload });
        },
        [mutation]
    );
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const errorType = error?.errors[0];

    const handleRemoveDeviceParamsSave = () => {
        if (formAttributes) {
            reset();
            setShowErrorModal(false);
            mutation.mutate({
                payload: {
                    ...formAttributes,
                    external_serial: undefined,
                    admin_email: undefined,
                    is_member: undefined
                }
            });
        }
    };

    const handleErrorSave = () => {
        reset();

        if (getIsExistingMemberCheckError(errorType)) {
            handleRemoveDeviceParamsSave();
        }

        return undefined;
    };

    React.useEffect(() => {
        if (isError && !['serial_not_exist', 'email_not_exist'].includes(errorType || '')) {
            setShowErrorModal(true);
        }
    }, [isError, errorType]);

    return (
        <>
            <Form
                submitFn={handleSubmit}
                validationSchema={createLeadFormSchema}
                defaultValues={{
                    is_member: false,
                    hero_for: 'myself'
                }}
                validationMode="onChange"
            >
                <FormElements
                    loading={mutation.isPending}
                    showAdminEmailError={errorType === 'email_not_exist'}
                    showHeroSerialErrorError={errorType === 'serial_not_exist'}
                />
            </Form>
            <LeadErrorModal
                externalControls={[showErrorModal, setShowErrorModal]}
                onCancel={() => setShowErrorModal(false)}
                errorType={errorType}
                onSave={handleErrorSave}
            />
        </>
    );
};

export default LeadsForm;
