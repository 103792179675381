// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w0WvRScm84GuTcQOwJH3{height:8rem;border-radius:8px;display:flex;align-items:center;height:8.4rem;position:relative;box-shadow:0px 2px 6px 0px rgba(73,7,7,.4)}.N3E7L1VZkbOEtQ1GyeEr{border-top-left-radius:8px;border-bottom-left-radius:8px;border-right:2px solid #e7e7e7;width:11rem !important;min-width:11rem;height:100%}.pD4CKyYQHMaae6ZNIsg1{border-top-left-radius:8px;border-bottom-left-radius:8px;border-right:2px solid #fed3cb;width:11rem !important;min-width:11rem;height:100%;display:flex;align-items:center;justify-content:center;background:#fed3cb}.hCD_Mkb_o0t62fOdUZJc{display:flex;flex-direction:column;row-gap:.4rem;margin-left:2.4rem}.QyRDFk8zWkz3s0ImkeSB{position:absolute;z-index:1;right:8px;top:8px}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/components/AttachmentComponent/style.module.scss"],"names":[],"mappings":"AACA,sBACI,WAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,aAAA,CACA,iBAAA,CACA,0CAAA,CAGJ,sBACI,0BAAA,CACA,6BAAA,CACA,8BAAA,CACA,sBAAA,CACA,eAAA,CACA,WAAA,CAGJ,sBACI,0BAAA,CACA,6BAAA,CACA,8BAAA,CACA,sBAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,aAAA,CACA,kBAAA,CAGJ,sBACI,iBAAA,CACA,SAAA,CACA,SAAA,CACA,OAAA","sourceRoot":""}]);
// Exports
export var wrapper = `w0WvRScm84GuTcQOwJH3`;
export var img = `N3E7L1VZkbOEtQ1GyeEr`;
export var iconWrapper = `pD4CKyYQHMaae6ZNIsg1`;
export var textWrapper = `hCD_Mkb_o0t62fOdUZJc`;
export var closeBtn = `QyRDFk8zWkz3s0ImkeSB`;
export default ___CSS_LOADER_EXPORT___;
