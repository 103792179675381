// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H9riHuS4AarjGLoOxlg2{min-height:6rem;padding:2.4rem;border-radius:2rem;width:100%;background:#f4f4f4;display:flex;justify-content:center;flex-direction:column}.D8xnMDWgTFBJcMi1acUv{display:flex;align-items:center;justify-content:space-between}.c87zj0vZQBUBHpztb2RQ{display:flex;align-items:center;padding:0 calc(var(--gutter)) !important}.V6de1pwhieEJcgVBMjLd{display:flex;align-items:center;column-gap:1.2rem;max-height:2.4rem}.dsKvzJCiRePFjT9vqmnA{display:flex;flex-direction:column;row-gap:1.2rem;margin-bottom:1.2rem}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/DischargeModal/style.module.scss"],"names":[],"mappings":"AAEA,sBACI,eAAA,CACA,cAAA,CACA,kBAAA,CACA,UAAA,CACA,kBAAA,CACA,YAAA,CACA,sBAAA,CACA,qBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,wCAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,cAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
export var main = `H9riHuS4AarjGLoOxlg2`;
export var header = `D8xnMDWgTFBJcMi1acUv`;
export var reasonItem = `c87zj0vZQBUBHpztb2RQ`;
export var reasonItemInner = `V6de1pwhieEJcgVBMjLd`;
export var reasonsWrapper = `dsKvzJCiRePFjT9vqmnA`;
export default ___CSS_LOADER_EXPORT___;
