import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import XButton from '@hero/ui-kit/inputs/XButton';
import Section from '@hero/ui-kit/layout/Section';
import * as React from 'react';
import * as Styles from './style.module.scss';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import ThumbsUpIllustration from '@hero/ui-kit/graphics/Illustrations/ThumbsUpIllustration';
import HeroDeviceIllustration from '@hero/ui-kit/graphics/Illustrations/HeroDeviceIllustration';
import { useSetNotification } from '../../../../context/Notification';

type SuccessEnrollModalProps = {
    type?: string;
    onDone?: () => void;
} & Pick<ModalProps, 'externalControls'>;

const SuccessEnrollModal: React.FC<SuccessEnrollModalProps> = ({
    type,
    externalControls,
    onDone
}) => {
    const [, setExternalState] = externalControls ?? [];
    const setNotification = useSetNotification();
    const props = React.useMemo(() => {
        if (type === 'enrollment-complete') {
            return {
                headerText: 'Patient enrollment complete',
                text: 'Patient already has a Hero dispenser in use. Their member profile is created, you can immediately start with Remote Therapy program.',
                illustration: <ThumbsUpIllustration styles={{ width: '11rem', height: '8rem' }} />
            };
        }

        return {
            headerText: 'Hero order complete',
            text: 'Hero order has been created. Dispenser delivery is expected in 2-5 days. Patient will receive automatic email notifications related to the progress of shipping and delivery of the dispenser.',
            illustration: <HeroDeviceIllustration styles={{ width: '14rem', height: '8rem' }} />
        };
    }, [type]);

    const handleDone = () => {
        setNotification({ type: 'success', message: 'Enrollment completed' });
        setExternalState && setExternalState(false);
        onDone && onDone();
    };

    return (
        <Modal styles={{ padding: 0, maxWidth: '57rem' }} externalControls={externalControls}>
            <div>
                <div className={Styles.header}>
                    <XButton onClick={handleDone} alt="Close modal" />
                </div>
                <Section centered noDefaultPadding>
                    {props.illustration}
                </Section>
                <P
                    noDefaultMargin
                    strong
                    styles={{ fontSize: '3.2rem', marginTop: '2.6rem' }}
                    centered
                >
                    {props.headerText}
                </P>
                <P
                    noDefaultMargin
                    centered
                    styles={{ marginBottom: '4.8rem', marginTop: '2.4rem' }}
                >
                    {props.text}
                </P>
                <Section centered noDefaultPadding>
                    <Button width="large" onClick={handleDone}>
                        Done
                    </Button>
                </Section>
            </div>
        </Modal>
    );
};

export default SuccessEnrollModal;
