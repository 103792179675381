// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FUge4dgbRBaqrgRtUb9A{height:28px;padding:8px 16px 8px 16px;border-radius:8px;background:#c9e2ff}._zg6g6g2aBe6SZmlYFwK{height:28px;padding:8px 16px 8px 16px;border-radius:8px;background:#e7e7e7}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/components/DischargeMemberStatusPill/style.module.scss"],"names":[],"mappings":"AACA,sBACI,WAAA,CACA,yBAAA,CACA,iBAAA,CACA,kBAAA,CAGJ,sBACI,WAAA,CACA,yBAAA,CACA,iBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
export var processing = `FUge4dgbRBaqrgRtUb9A`;
export var finalized = `_zg6g6g2aBe6SZmlYFwK`;
export default ___CSS_LOADER_EXPORT___;
