import CheckCircleIcon from '@hero/ui-kit/icons/utility/CheckCircleIcon';
import React from 'react';
import { useNotification, useSetNotification } from '../../context/Notification';
import * as Style from './style.module.scss';

let timeout: NodeJS.Timeout;

const Notification: React.FC = () => {
    const notification = useNotification();
    const setNotification = useSetNotification();
    const [active, setActive] = React.useState(false);

    React.useEffect(() => {
        if (notification?.message) {
            setActive(true);

            timeout = setTimeout(() => {
                setActive(false);
            }, 4000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [notification, setNotification]);

    return (
        <div className={`${Style.wrapper} ${active ? '' : Style.hidden}`}>
            <div className={`${Style.notification} ${Style[notification?.type || 'success']}`}>
                <CheckCircleIcon type="inverted" size="utility" />
                <div>{notification?.message}</div>
            </div>
        </div>
    );
};

export default Notification;
