import envVars from '../constants/envVars';
import { config } from './config';
import {
    ReadyState,
    RequestAuthorizationMessage,
    RequestMessage,
    ResponseAuthorizationSuccessMessage,
    ResponseMessages,
    HandlerWithoutPayload,
    HandlerWithPayload,
    ExtractMapValueType,
    WsMessageSubscribers,
    MessagesWithoutPayload,
    ResponseUnauthorizedErrorMessage
} from './types';
import { WsLogger } from './ws-logger';

export interface TokenResponse {
    accessToken: string;
    refresh_token: string;
    expires_in: number;
    scope: string;
    token_type: string;
}

class WS {
    private ws!: WebSocket;
    private accessToken: TokenResponse['accessToken'];
    private reconnectTimeoutId: number | null = null;
    private pingIntervalId: number | null = null;
    private reAuthenticateTimeoutId: number | null = null;

    private readonly reconnectIntervalInMs = config.ws.reconnectIntervalInMs;
    private readonly reauthenticateIntervalInMs = config.ws.reauthenticateIntervalInMs;

    private readonly wsLogger = new WsLogger();
    private readonly wsUrl: string = `${envVars.API_CLOUD_SOCKET}ws/enterprise/`;

    private shouldDestroyConnection = false;
    private isReconnecting = false;
    private _isAuthenticated = false;

    get isAuthenticated() {
        return this._isAuthenticated;
    }

    private static subscribers: WsMessageSubscribers = {
        request_should_call_user_flags: new Map(),
        request_should_call_user_status: new Map(),
        enterprise_new_lead: new Map(),
        reconnected: new Map(),
        connected: new Map(),
        response_authorization: new Map(),
        isAuthenticated: new Map()
    };

    private static notifySubscribers = <K extends keyof WsMessageSubscribers>(
        args: ExtractMapValueType<WsMessageSubscribers[K]> extends HandlerWithoutPayload
            ? { messageType: K; payload?: never }
            : {
                  messageType: K;
                  payload: Parameters<ExtractMapValueType<WsMessageSubscribers[K]>>[0];
              }
    ) => {
        // Get current subscribers for event type, break infinitive loop with newly registered handlers
        const subscribers = [...(WS.subscribers[args.messageType]?.values() ?? [])];
        subscribers.forEach((handler) => {
            if (args.payload) {
                (handler as HandlerWithPayload<(typeof args)['payload']>)(args.payload);
            } else {
                (handler as HandlerWithoutPayload)();
            }
        });
    };

    constructor({ accessToken }: { accessToken: TokenResponse['accessToken'] }) {
        this.accessToken = accessToken;

        this.connect();
    }

    private connect = () => {
        this.wsLogger.handleWsLog(this.wsLogger.infoLogFactory(() => 'connect fired'));
        this.ws = new WebSocket(this.wsUrl);
        this.ws.onopen = this.onopen;
        this.ws.onmessage = this.onmessage;
        this.ws.onerror = this.onerror;
        this.ws.onclose = this.onclose;
    };

    private reconnect = () => {
        if (this.reconnectTimeoutId) clearTimeout(this.reconnectTimeoutId);

        this.isReconnecting = true;

        this.reconnectTimeoutId = +setTimeout(() => this.connect(), this.reconnectIntervalInMs);
    };

    private authenticate = () => {
        const authMessage: RequestAuthorizationMessage = {
            type: 'request_authorization',
            payload: { authorization_token: `Bearer ${this.accessToken}` }
        };

        this.send(authMessage);
    };

    private reauthenticate = () => {
        if (this.reAuthenticateTimeoutId) clearTimeout(this.reAuthenticateTimeoutId);

        this.reAuthenticateTimeoutId = +setTimeout(
            () => this.authenticate(),
            this.reauthenticateIntervalInMs
        );
    };

    private send = (message: RequestMessage) => {
        if (this.ws.readyState !== ReadyState.OPEN) return;

        this.wsLogger.handleWsLog(this.wsLogger.requestLogFactory(() => message));

        this.ws.send(JSON.stringify(message));
    };

    private onopen = () => {
        if (this.isReconnecting) {
            this.wsLogger.handleWsLog(this.wsLogger.infoLogFactory(() => 'reconnected'));

            WS.notifySubscribers({ messageType: 'reconnected' });

            this.isReconnecting = false;
        } else {
            this.wsLogger.handleWsLog(this.wsLogger.infoLogFactory(() => 'connection opened'));

            WS.notifySubscribers({ messageType: 'connected' });
        }

        this.authenticate();
    };

    private onmessage = (event: MessageEvent) => {
        try {
            const parsedMessage: ResponseMessages = JSON.parse(event.data);

            this.wsLogger.handleWsLog(this.wsLogger.responseLogFactory(() => parsedMessage));

            if (this.isAuthorizationSuccessMessage(parsedMessage)) {
                this._isAuthenticated = true;
                WS.notifySubscribers({
                    messageType: 'isAuthenticated',
                    payload: { isAuthenticated: true }
                });

                this.wsLogger.handleWsLog(
                    this.wsLogger.infoLogFactory(() => 'authentication success')
                );
                WS.notifySubscribers({
                    messageType: parsedMessage.type,
                    payload: parsedMessage.payload
                });
            } else if (this.isAuthorizationErrorMessage(parsedMessage)) {
                this._isAuthenticated = false;
                WS.notifySubscribers({
                    messageType: 'isAuthenticated',
                    payload: { isAuthenticated: false }
                });

                this.reauthenticate();
            } else if (this.isChangeMessage(parsedMessage)) {
                if (this.isMessageWithoutPayload(parsedMessage)) {
                    WS.notifySubscribers({
                        messageType: parsedMessage.type
                    });
                } else {
                    WS.notifySubscribers({
                        messageType: parsedMessage.type,
                        payload: parsedMessage.payload
                    });
                }
            }
        } catch (error) {
            this.wsLogger.handleWsLog(
                this.wsLogger.errorLogFactory(
                    () => new WS.WsParseError('message could not be parsed')
                )
            );
        }
    };

    private onerror: WebSocket['onerror'] = () => {
        this.wsLogger.handleWsLog(
            this.wsLogger.errorLogFactory(() => new WS.WsNetworkError('WS Error'))
        );

        this.close({ shouldDestroyConnection: false });
    };

    close = (options?: { shouldDestroyConnection: boolean }) => {
        if (this.reconnectTimeoutId) clearTimeout(this.reconnectTimeoutId);
        if (this.pingIntervalId) clearInterval(this.pingIntervalId);
        if (this.reAuthenticateTimeoutId) clearTimeout(this.reAuthenticateTimeoutId);

        this.reconnectTimeoutId = null;
        this.pingIntervalId = null;
        this.reAuthenticateTimeoutId = null;

        this.shouldDestroyConnection = !options || options.shouldDestroyConnection;

        this.ws.close();
    };

    static subscribe = <K extends keyof WsMessageSubscribers>(args: {
        messageType: K;
        handler: ExtractMapValueType<WsMessageSubscribers[K]>;
    }) => {
        const key = {};

        const messageMap = WS.subscribers[args.messageType];

        messageMap.set(key, args.handler as any);

        return () => messageMap.delete(key);
    };

    private onclose: WebSocket['onclose'] = () => {
        if (this.shouldDestroyConnection) {
            this.shouldDestroyConnection = false;
            return this.wsLogger.handleWsLog(
                this.wsLogger.infoLogFactory(() => 'socket connection destroyed')
            );
        }

        this.wsLogger.handleWsLog(
            this.wsLogger.infoLogFactory(() => `socket is closed, trying reconnect...`)
        );

        this.reconnect();

        return null;
    };

    private isChangeMessage = (message: ResponseMessages): message is ResponseMessages => {
        return [
            'request_should_call_user_status',
            'request_should_call_user_flags',
            'enterprise_new_lead'
        ].includes(message.type);
    };

    private isAuthorizationSuccessMessage = (
        message: ResponseMessages
    ): message is ResponseAuthorizationSuccessMessage => {
        return message.type === 'response_authorization' && message.payload.status === 'success';
    };

    private isAuthorizationErrorMessage = (
        message: ResponseMessages
    ): message is ResponseUnauthorizedErrorMessage => {
        return message.type === 'response_error' && message.payload.error === 'unauthorized';
    };

    private isMessageWithoutPayload = (
        message: ResponseMessages
    ): message is MessagesWithoutPayload<ResponseMessages> => {
        return !Object.prototype.hasOwnProperty.call(message, 'payload');
    };

    static WsAuthError = class extends Error {
        constructor(message: string) {
            super(message);
            this.name = 'WsAuthError';
        }
    };

    static WsNetworkError = class extends Error {
        constructor(message: string) {
            super(message);
            this.name = 'WsNetworkError';
        }
    };

    static WsParseError = class extends Error {
        constructor(message: string) {
            super(message);
            this.name = 'WsParseError';
        }
    };
}

export default WS;
