import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import MedsErrorIllustration from '@hero/ui-kit/graphics/Illustrations/MedsErrorIllustration';

interface Props {
    search: string;
    searchSupportsLabel?: string;
    hideSearchLabel?: boolean;
}

const NoResults: React.FC<Props> = ({
    search,
    searchSupportsLabel = 'User ID, Name, E-mail and Device Serial Number',
    hideSearchLabel = false
}) => {
    return (
        <Section centered styles={{ marginTop: '5rem' }}>
            {!hideSearchLabel ? (
                <P strong noDefaultMargin styles={{ fontSize: '2.4rem', paddingBottom: '1.2rem' }}>
                    Sorry, we were not able to find any match for {`"${search}"`}
                </P>
            ) : null}

            <P noDefaultMargin styles={{ paddingBottom: '6rem' }}>
                Remember search supports:
                <strong> {searchSupportsLabel}</strong>
            </P>
            <MedsErrorIllustration styles={{ height: '20rem', width: '64rem' }} />
        </Section>
    );
};

export default NoResults;
