import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import React from 'react';
import newActivityFormSchema, { NewActivityFormParams } from './validator';
import FormInputs from './FormInputs';
import P from '@hero/ui-kit/typography/P';
import Section from '@hero/ui-kit/layout/Section';
import {
    AllApprovalStatusSubStates,
    ApprovalStatus,
    LeadStateResponse,
    UpdateLeadApprovalStatusPayload
} from '../../types';
import * as Style from './style.module.scss';
import { format } from 'date-fns';
import useUpdateLeadApprovalStatus from '../../api/useUpdateLeadApprovalStatus';
import useUnsavedChangeBlocker from '../../Details/v2/hooks/useUnsavedChangeBlocker';
import UnsavedChangesModal from '../../Details/v2/components/UnsavedChangesModal';
import SideBar from '../../../../components/SideBar';
import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import Button from '@hero/ui-kit/inputs/Button';
import LeadErrorModal from '../LeadErrorModal';

interface NewActivityFormProps {
    state?: LeadStateResponse;
    leadId: string;
    onActivitySuccess?: () => void;
    handleModalClose: () => void;
    leadName?: string;
}

function isApprovalStatus(value: string): value is ApprovalStatus {
    const statuses: ApprovalStatus[] = [
        'INITIAL_INTEREST',
        'INSURANCE_VALIDATION',
        'QUALIFIED',
        'MEDICAL_CHECK',
        'APPROVED',
        'REJECTED'
    ];
    return statuses.includes(value as ApprovalStatus);
}

const getLeadUpdateError = (errorType?: string) => {
    const errors: Record<string, string> = {
        unknown: 'Unknown error'
    };

    return errorType && errors[errorType]
        ? errors[errorType]
        : 'Something went wrong. Please contact customer support.';
};

const NewActivityFormInner: React.FC<{
    isError: boolean;
    isSuccess: boolean;
    isLoading: boolean;
    requiredDateTimeStates: string[];
    errorType?: string;
    state: LeadStateResponse | undefined;
    handleModalClose: () => void;
}> = ({
    isError = false,
    isSuccess = false,
    isLoading = false,
    requiredDateTimeStates,
    errorType,
    state,
    handleModalClose
}) => {
    const [showBlockerModal, setShowBlockerModal] = React.useState(false);
    const {
        formState: { dirtyFields }
    } = useFormContext();

    const isChanged = Object.keys(dirtyFields).length !== 0;

    const { handleCancel, handleDiscard } = useUnsavedChangeBlocker({
        isChanged,
        isSuccess,
        changeBlockerModal: setShowBlockerModal
    });

    const closeModal = () => {
        handleCancel();
        handleModalClose();
    };

    return (
        <>
            <SideBar className={Style.sidebarContent} onOutsideClick={closeModal} listenToKeydown>
                <Section padding="small" className={Style.formWrapper}>
                    <P size="large" strong centered>
                        New activity
                    </P>
                    <FormInputs
                        state={state}
                        isSubmitDisabled={isLoading}
                        requiredDateTimeStates={requiredDateTimeStates}
                    />
                </Section>
            </SideBar>

            <UnsavedChangesModal
                showBlockerModal={showBlockerModal}
                onCancel={handleCancel}
                onSave={handleCancel}
                saveBtnLabel="Go Back to Edit"
                isLoading={isLoading}
                onDiscard={handleDiscard}
                isError={isError}
                errorMessage={getLeadUpdateError(errorType)}
            />
        </>
    );
};

const NewActivityForm: React.FC<NewActivityFormProps> = ({
    state,
    leadId,
    onActivitySuccess,
    handleModalClose,
    leadName
}) => {
    const {
        mutate,
        isPending: isLoading,
        isError,
        isSuccess,
        error,
        reset: resetUpdateLeadApprovalStatus
    } = useUpdateLeadApprovalStatus(onActivitySuccess);

    const [showErrorModal, setShowErrorModal] = React.useState(true);

    const requiredDateTimeStates = React.useMemo(() => {
        const statesRequiringDateTime = state?.states_requiring_date_time
            ? state.states_requiring_date_time.map((value) => value.toUpperCase())
            : [];
        const subStatesRequiringDateTime = state?.sub_states_requiring_note_date_time
            ? state?.sub_states_requiring_note_date_time.map((value) => value.toUpperCase())
            : [];

        return [...statesRequiringDateTime, ...subStatesRequiringDateTime];
    }, [state]);

    const onSubmit = (attributes: NewActivityFormParams) => {
        const { schedule_date, schedule_time, activity_type, reject_reason, note } = attributes;

        let payload: UpdateLeadApprovalStatusPayload = {
            lead_id: leadId,
            ...(note && { note }),
            ...(isApprovalStatus(activity_type)
                ? { approval_status: activity_type }
                : { approval_sub_status: activity_type as AllApprovalStatusSubStates })
        };

        if (requiredDateTimeStates.includes(activity_type || '')) {
            // "note_date_time": "1998-01-31 05:15:00", // Optional
            const scheduleDate =
                schedule_date && schedule_time
                    ? new Date(`${schedule_date} ${schedule_time}`)
                    : undefined;

            const formattedDate = scheduleDate
                ? new Date(
                      scheduleDate.getUTCFullYear(),
                      scheduleDate.getUTCMonth(),
                      scheduleDate.getUTCDate(),
                      scheduleDate.getUTCHours(),
                      scheduleDate.getUTCMinutes(),
                      scheduleDate.getUTCSeconds()
                  )
                : new Date();
            payload = {
                ...payload,
                note_date_time: format(formattedDate, 'yyyy-MM-dd kk:mm:ss')
            };
        } else if (activity_type === 'REJECTED') {
            payload = {
                ...payload,
                approval_sub_status: (reject_reason || '') as AllApprovalStatusSubStates
            };
        }

        mutate({ payload });
    };

    const errorType = error?.errors[0];

    const isAlreadyEnrolledError = errorType === 'LeadStateMachineException: already_enrolled';

    const types = state?.possible_state_transitions.map((type) => {
        const approvalStatus = type.toUpperCase() as ApprovalStatus;
        return {
            value: approvalStatus
        };
    });

    if (isAlreadyEnrolledError) {
        return (
            <Modal isCancelable onClose={resetUpdateLeadApprovalStatus} styles={{ padding: '0' }}>
                <H role="h4">Reactivation not possible!</H>
                <P centered>{leadName} is already enrolled in the program with another provider.</P>
                <Section centered>
                    <Button onClick={resetUpdateLeadApprovalStatus} variant="outlineSecondary">
                        Close
                    </Button>
                </Section>
            </Modal>
        );
    }

    if (!isAlreadyEnrolledError && isError) {
        return (
            <LeadErrorModal
                errorType={error?.errors[0]}
                externalControls={[showErrorModal, setShowErrorModal]}
                onCancel={() => {
                    resetUpdateLeadApprovalStatus();
                }}
            />
        );
    }

    return (
        <Form
            validationSchema={newActivityFormSchema}
            submitFn={onSubmit}
            defaultValues={{
                activity_type: types?.length ? types[0].value : '',
                requiredDateTimeStates
            }}
        >
            <NewActivityFormInner
                handleModalClose={handleModalClose}
                isSuccess={isSuccess}
                state={state}
                isLoading={isLoading}
                requiredDateTimeStates={requiredDateTimeStates}
                isError={isError}
                errorType={errorType}
            />
        </Form>
    );
};

export default NewActivityForm;
