import useTheme from '@hero/branding/theme';
import getIconColor from '@hero/ui-kit/icons/getIconColor';
import { IconComponentProps } from '@hero/ui-kit/types';
import React from 'react';

const ControlIcon: React.FC<IconComponentProps> = ({
    type = 'utility',
    size = 'regular',
    disabled = false,
    styles,
    ...rest
}) => {
    const { colors, sizing } = useTheme();
    const color = getIconColor(colors, type, disabled);
    const sizePx = sizing.widths.icon[size];

    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            width={sizePx}
            height={sizePx}
            style={styles}
            {...rest}
        >
            <rect x="1" y="1" width="22" height="22" rx="11" stroke={color} strokeWidth="2" />
            <rect x="4" y="4" width="16" height="16" rx="8" fill="#F26C3A" />
        </svg>
    );
};

export default ControlIcon;
