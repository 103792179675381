import React from 'react';
import useTheme from '@hero/branding/theme';
import Theme, { Status } from '@hero/branding/types';

export type DotSignifierType = Status | 'brand' | 'bold' | 'secondary' | 'neutral';
interface DotSignifierProps {
    width?: string;
    height?: string;
    styles?: React.CSSProperties;
    type?: DotSignifierType;
}

const getBackgroundColor = (colors: Theme['colors'], type: DotSignifierType) => {
    switch (type) {
        case 'success':
            return colors.status.success.beta;
        case 'warn':
            return colors.brand.secondary.alpha;
        case 'fail':
            return colors.status.fail.alpha;
        case 'brand':
            return colors.brand.primary.beta;
        case 'bold':
            return colors.brand.primary.alpha;
        case 'secondary':
            return colors.brand.secondary.gamma;
        default:
            // 'neutral'
            return colors.neutrals.background;
    }
};

const DotSignifier: React.FC<DotSignifierProps> = ({
    type = 'fail',
    width = '1.2rem',
    height = '1.2rem',
    styles = {}
}) => {
    const { colors } = useTheme();

    return (
        <div
            style={{
                width,
                height,
                backgroundColor: getBackgroundColor(colors, type),
                borderRadius: '50%',
                ...styles
            }}
        />
    );
};

export default DotSignifier;
