import Button from '@hero/ui-kit/inputs/Button';
import XButton from '@hero/ui-kit/inputs/XButton';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import { validEmailRegex } from '@hero/validators/email';
import React from 'react';
import useCreateUserInvite from '../../api/useCreateUserInvite';
import RolePicker from './RolePicker';
import * as Style from './style.module.scss';
import { IAMListRole } from '../../../IAM/types';
import CheckCircleIcon from '@hero/ui-kit/icons/utility/CheckCircleIcon';
import { UserCreateResponse } from '../../types';

const validateEmail = (email: string) => {
    return email.match(validEmailRegex);
};

interface InviteFormProps {
    closeModal: () => void;
    roles?: IAMListRole[];
}

export const getInvitedUsers = (response?: UserCreateResponse) => {
    let notCreatedUsers: string[] = [];
    let createdUsers: string[] = [];
    if (response?.messages.length) {
        response.messages.forEach((message) => {
            const splitMessage = message.split(':');

            if (
                splitMessage[0] &&
                (splitMessage[0] === 'not created (already active)' ||
                    splitMessage[0] === 'not created (email taken)')
            ) {
                splitMessage[1] && notCreatedUsers.push(splitMessage[1]);
            }
            if (
                splitMessage[0] &&
                (splitMessage[0] === 'created (new)' ||
                    splitMessage[0] === 'created (discarded existing)')
            ) {
                splitMessage[1] && createdUsers.push(splitMessage[1]);
            }
        });
    }

    return {
        createdUsers,
        notCreatedUsers
    };
};

const InviteForm: React.FC<InviteFormProps> = ({ closeModal, roles }) => {
    const [selectedId, setSelectedId] = React.useState<string | undefined>();
    const [emails, setEmails] = React.useState<string[]>([]);
    const [emailInput, setEmailInput] = React.useState<string>('');
    const [isAdded, setIsAdded] = React.useState(false);
    const { mutateAsync, data: successData, reset, isPending: isInviting } = useCreateUserInvite();

    const addInviteEmail = React.useCallback((emailInvite: string) => {
        const isValidEmail = validateEmail(emailInvite);

        if (isValidEmail) {
            setEmails((prevState) => {
                const findEmail = prevState.some((email) => email === emailInvite);
                return !findEmail ? [...prevState, emailInvite] : prevState;
            });
            setEmailInput('');
        }
    }, []);

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                const value = event.currentTarget.value;
                addInviteEmail(value);
            }
        },
        [addInviteEmail]
    );

    const handleOnBlur = React.useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            const value = event?.currentTarget?.value;
            value && addInviteEmail(value);
        },
        [addInviteEmail]
    );

    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setEmailInput(value);
            const findEmail = emails.some((email) => email === value);
            setIsAdded(findEmail);
        },
        [emails]
    );

    const isInviteDisabled = React.useMemo(() => {
        return emails.length === 0 || !selectedId?.length || isInviting;
    }, [selectedId, emails, isInviting]);

    const handleReset = () => {
        reset();
        setEmails([]);
        setSelectedId(undefined);
        closeModal();
    };

    const handleInvite = React.useCallback(() => {
        const invitees = emails.map((email) => ({ email }));
        if (selectedId && invitees.length) {
            mutateAsync({ role_id: selectedId, invitees })
                .then((response) => {
                    const { notCreatedUsers } = getInvitedUsers(response);
                    if (notCreatedUsers.length === 0) {
                        handleReset();
                    }
                })
                .catch(() => {});
        }
    }, [emails, selectedId, mutateAsync]);

    const removeEmail = React.useCallback((email: string) => {
        setEmails((prevState) => prevState.filter((item) => item !== email));
    }, []);

    const handleRoleChange = React.useCallback((newId: string) => {
        setSelectedId(newId);
    }, []);

    const inviteResponse = React.useMemo(() => {
        return getInvitedUsers(successData);
    }, [successData]);

    if (inviteResponse.notCreatedUsers.length) {
        return (
            <Section noDefaultPadding paddingHorizontal="none" paddingVertical="none">
                <>
                    <H role="h5" centered>
                        {inviteResponse.createdUsers.length
                            ? 'Invite partially sent, duplicate emails detected'
                            : 'Duplicate emails detected, invites not sent'}
                    </H>
                    <P centered>Some emails are already associated with active agents.</P>
                    <>
                        <P strong size="large" noDefaultMargin styles={{ marginBottom: '1.2rem' }}>
                            Active
                        </P>
                        <div className={Style.userEmails}>
                            {inviteResponse.notCreatedUsers.map((email) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        borderBottom: '1px solid var(--color-neutrals-borderBeta)',
                                        paddingBottom: '1.2rem'
                                    }}
                                    key={email}
                                >
                                    <P noDefaultMargin key={email}>
                                        {email}
                                    </P>
                                </div>
                            ))}
                        </div>
                    </>

                    {inviteResponse.createdUsers.length ? (
                        <>
                            <P
                                strong
                                size="large"
                                noDefaultMargin
                                styles={{ marginTop: '2.4rem', marginBottom: '1.2rem' }}
                            >
                                Invited
                            </P>
                            <div className={Style.userEmails}>
                                {inviteResponse.createdUsers.map((email) => (
                                    <div key={email} className={Style.invitedUser}>
                                        <P noDefaultMargin>{email}</P>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                columnGap: '1rem'
                                            }}
                                        >
                                            <P noDefaultMargin strong>
                                                Invite sent
                                            </P>
                                            <CheckCircleIcon type="utility" size="utility" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : null}

                    <Section centered noDefaultPadding>
                        <Button onClick={handleReset} styles={{ marginTop: '2.4rem' }}>
                            Got It
                        </Button>
                    </Section>
                </>
            </Section>
        );
    }

    return (
        <Section noDefaultPadding paddingHorizontal="none" paddingVertical="none">
            <>
                <H role="h5" centered>
                    Invite portal agent
                </H>
                <P noDefaultMargin className={Style.agentEmailLabel}>
                    Agent email
                </P>
                <div className={Style.emailWrapper}>
                    <div className={Style.emailItem}>
                        {emails.map((email) => {
                            return (
                                <div key={email} className={Style.emailPillWrapper}>
                                    <P
                                        strong
                                        noDefaultMargin
                                        styles={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {email}
                                    </P>
                                    <XButton
                                        alt={`remove ${email}`}
                                        onClick={() => removeEmail(email)}
                                        className={Style.removeBtn}
                                    />
                                </div>
                            );
                        })}
                        <input
                            name="email"
                            type="email"
                            placeholder="Enter email"
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            onBlur={handleOnBlur}
                            value={emailInput}
                            className={Style.input}
                        />
                    </div>
                </div>
                {isAdded ? (
                    <P className={Style.invalidColor} noDefaultMargin>
                        Agent already added for invite
                    </P>
                ) : null}
                <P strong noDefaultMargin className={Style.agentRoleLabel}>
                    User role
                </P>
                <RolePicker roles={roles} onChange={handleRoleChange} selectedId={selectedId} />
                <Section noDefaultPadding paddingTop="small" centered>
                    <Button disabled={isInviteDisabled} width="large" onClick={handleInvite}>
                        {isInviting ? 'Invite in progress' : 'Send Invite'}
                    </Button>
                </Section>
            </>
        </Section>
    );
};

export default InviteForm;
