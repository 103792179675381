import React from 'react';

import envVars from '../../../../../constants/envVars';
import useRequest from '../../../../../hooks/useRequest';
import { MonthlyDose } from '@hero/enterprise-portal-common/src/Adherence/AdherenceCalendar/AdherenceCalendarMonthly/Acm.types';

type Query = {
    target_datetime: string | null; // ending datetime for the period in YYYY-mm-dd HH:MM:SS
};

const useGetAdherenceCalendarMonthly = (hero_user_id: number) => {
    const req = useRequest();

    const request = React.useCallback(
        (query: Query) => {
            return req<MonthlyDose[]>({
                url: `${envVars.API_CLOUD_HOSTNAME}enterprise/adherence/monthly-brief`,
                query: { ...query, hero_user_id }
            });
        },
        [req, hero_user_id]
    );

    return request;
};

export default useGetAdherenceCalendarMonthly;
