import React from 'react';
import { DeviceInfo, MedicationDetails, OrganizationMemberDetails } from '../../types';
import getIsDeviceSetupNeeded from '../../utils/getIsDeviceSetupNeeded';

type OrganizationMemberDetailsContextType = {
    memberDetails: OrganizationMemberDetails;
    memberName: string;
    currentDevice: DeviceInfo | undefined;
    medications: {
        outside: MedicationDetails[];
        inside: MedicationDetails[];
    };
    isDeviceSetupNeeded: boolean;
    isDeviceConfigured: boolean;
};

const OrganizationMemberDetailsContext = React.createContext<
    OrganizationMemberDetailsContextType | undefined
>(undefined);

export const useOrganizationMemberDetailsContext = () => {
    const ctx = React.useContext(OrganizationMemberDetailsContext);

    if (ctx === undefined) {
        throw new Error(
            `'useOrganizationMemberDetailsContext' must be used within a 'OrganizationMemberDetailsContextProvider'`
        );
    }

    return ctx;
};

type IOrganizationMemberDetailsProvider = {
    children: React.ReactNode;
    memberDetails: OrganizationMemberDetails;
};

const OrganizationMemberDetailsProvider = ({
    children,
    memberDetails
}: IOrganizationMemberDetailsProvider) => {
    const { member_info, owned_device_info, medication_info } = memberDetails;

    const memberName = React.useMemo(() => {
        if (member_info?.first_name || member_info?.last_name) {
            return `${member_info.first_name} ${member_info.last_name}`;
        }
        return 'N/A';
    }, [member_info]);

    const currentDevice = React.useMemo(() => {
        return owned_device_info;
    }, [owned_device_info]);

    const medications = React.useMemo(() => {
        return {
            outside: medication_info?.filter((med) => !med.stored_in_hero) || [],
            inside: medication_info?.filter((med) => med.stored_in_hero) || []
        };
    }, [medication_info]);

    const isDeviceSetupNeeded = React.useMemo(() => {
        return getIsDeviceSetupNeeded(currentDevice?.device_status);
    }, [currentDevice]);

    return (
        <OrganizationMemberDetailsContext.Provider
            value={{
                memberDetails,
                memberName,
                currentDevice,
                medications,
                isDeviceSetupNeeded,
                isDeviceConfigured: owned_device_info?.device_status === 'CONFIGURED'
            }}
        >
            {children}
        </OrganizationMemberDetailsContext.Provider>
    );
};

export default OrganizationMemberDetailsProvider;
