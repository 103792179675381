import * as React from 'react';
import { LeadConsentResponse, LeadDetailsResponse } from '../types';
import AttachmentComponent from '../components/AttachmentComponent';
import downloadFile from '../../../utils/downloadFile';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import useRequest from '../../../hooks/useRequest';
import envVars from '../../../constants/envVars';

interface ConsentPDFProps {
    lead?: LeadDetailsResponse;
}

const ConsentPDF: React.FC<ConsentPDFProps> = ({ lead }) => {
    const req = useRequest();

    const handleDownload = () => {
        req<LeadConsentResponse>({
            url: `${envVars.API_RTM_HOSTNAME}enterprise/consent/`,
            query: { lead_id: lead?.primary_user_details.lead_id || '' }
        }).then((response) => {
            downloadFile(response.download_url, `${lead?.primary_user_details.lead_id}.pdf`);
        });
    };

    return (
        <ClickableOpacity
            onClick={handleDownload}
            alt="download consent"
            styles={{ width: '100%' }}
        >
            <AttachmentComponent
                imgName={`${lead?.primary_user_details.lead_id || 'consent'}.pdf`}
                label="PDF"
                imgType="pdf"
            />
        </ClickableOpacity>
    );
};

export default ConsentPDF;
