// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Aqtmsokzg84bicUlZ8gq{position:relative;height:3.6rem}.GFJE97NhQP9OvqtCU5cM{position:absolute;top:120%;background:var(--color-neutrals-background) !important;width:24rem}.lE3bdNzys66NgIUCzUO5{width:3.6rem;height:3.6rem;border-radius:100%}.lE3bdNzys66NgIUCzUO5:hover{background-color:var(--color-neutrals-borderBeta) !important}.FlBEtU_xQU5SZHzH7eH1{background-color:var(--color-neutrals-borderBeta) !important}.cUBFaxsoo1g9m2Y9b1Lt{padding-top:1.4rem;padding-left:1.6rem}.TU0JytuKAxci12lN4vW_{display:flex;align-items:center;column-gap:1rem;margin:1.2rem 0;padding:0 1.6rem !important;outline:none !important}.GkOqGFGHP0yPP6rpudgZ p{color:var(--color-primary-alpha) !important;font-weight:bold !important}`, "",{"version":3,"sources":["webpack://./components/LimitItems/style.module.scss"],"names":[],"mappings":"AACA,sBACI,iBAAA,CACA,aAAA,CAGJ,sBACI,iBAAA,CACA,QAAA,CAEA,sDAAA,CACA,WAAA,CAGJ,sBACI,YAAA,CACA,aAAA,CACA,kBAAA,CACA,4BACI,4DAAA,CAIR,sBACI,4DAAA,CAGJ,sBACI,kBAAA,CACA,mBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,eAAA,CACA,eAAA,CACA,2BAAA,CACA,uBAAA,CAIA,wBACI,2CAAA,CACA,2BAAA","sourceRoot":""}]);
// Exports
export var filterStatus = `Aqtmsokzg84bicUlZ8gq`;
export var filterStatusDropdown = `GFJE97NhQP9OvqtCU5cM`;
export var filterStatusToggle = `lE3bdNzys66NgIUCzUO5`;
export var openLimitBox = `FlBEtU_xQU5SZHzH7eH1`;
export var headerLabel = `cUBFaxsoo1g9m2Y9b1Lt`;
export var filterStatusItem = `TU0JytuKAxci12lN4vW_`;
export var selected = `GkOqGFGHP0yPP6rpudgZ`;
export default ___CSS_LOADER_EXPORT___;
