import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import * as Style from './style.module.scss';
import CheckboxSquare from '@hero/ui-kit/graphics/CheckboxSquare';
import P from '@hero/ui-kit/typography/P';
import Filter from '../Filter';
import { usStateByCode, usStateByName, usStates } from '@hero/hero-utils/address/states';
import LocationIcon from './LocationIcon';

const LocationFilter: React.FC<{
    locations?: { code: string; name: string }[];
    searchParam?: string;
}> = ({ locations = usStates, searchParam = 'state' }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    React.useEffect(() => {
        const sessionState = window.sessionStorage.getItem(searchParam);

        if (sessionState) {
            setSearchParams((searchParams) => {
                searchParams.set(searchParam, sessionState);
                return searchParams;
            });
        }
    }, [searchParam, setSearchParams]);

    const updateLocationFilter = React.useCallback(
        (stateCode: string) => {
            setSearchParams((searchParams) => {
                const param = searchParams.get(searchParam)?.split(',') || [];

                const stateCodeIndex = param?.indexOf(stateCode);

                if (stateCodeIndex < 0) {
                    param.push(stateCode);
                } else {
                    param.splice(stateCodeIndex, 1);
                }

                const stateName = usStateByCode(stateCode)?.name;

                if (stateName) {
                    const stateNameIndex = param?.indexOf(stateName);

                    if (stateNameIndex < 0) {
                        param.push(stateName);
                    } else {
                        param.splice(stateNameIndex, 1);
                    }
                }

                const paramString = param.filter((i) => !!i).join(',');

                if (paramString) {
                    searchParams.set(searchParam, paramString);
                    window.sessionStorage.setItem(searchParam, paramString);
                } else {
                    searchParams.delete(searchParam);
                    window.sessionStorage.removeItem(searchParam);
                }

                searchParams.delete('offset');

                return searchParams;
            });
        },
        [setSearchParams, searchParam]
    );

    const handleClear = React.useCallback(() => {
        setSearchParams((prev) => {
            prev.delete(searchParam);
            window.sessionStorage.removeItem(searchParam);

            return prev;
        });
    }, [searchParam, setSearchParams]);

    const filterLabel = React.useMemo(() => {
        const locations = searchParams.get(searchParam);

        if (locations) {
            const locationArray = locations.split(',').map((location) => {
                if (location.length > 2) {
                    const stateCode = usStateByName(location)?.code;

                    return stateCode ? stateCode : location;
                }

                return location;
            });

            const uniqueArray = [...new Set(locationArray)];

            return uniqueArray.map((item) => usStateByCode(item)?.name).join(', ');
        }

        return 'Location: All';
    }, [searchParam, searchParams]);

    const selectedArray = React.useMemo(() => {
        const selected = searchParams.get(searchParam);
        return selected ? selected.split(',').reverse() : [];
    }, [searchParams, searchParam]);

    const selectedLocations = searchParams.get(searchParam);

    return (
        <Filter
            filterLabel={
                <button
                    className={`${Style.filterButton} ${
                        typeof selectedLocations === 'string' ? Style.activeFilter : ''
                    }`}
                >
                    <LocationIcon type="utility" size="utility" />
                    <div>{filterLabel}</div>
                </button>
            }
            width="34rem"
            className={Style.filterBtnLabel}
            dropdownPosition="right"
        >
            <div className={`${Style.filterWrapper}`}>
                {selectedArray.length >= 1 ? (
                    <ClickableOpacity
                        onClick={handleClear}
                        alt={`Show all`}
                        className={Style.filterClearBtn}
                    >
                        Show all
                    </ClickableOpacity>
                ) : null}
                {locations.map((location) => {
                    return (
                        <ClickableOpacity
                            fullWidth
                            key={location.code}
                            alt={`Toggle ${location.name}`}
                            className={Style.filterStatusItem}
                            onClick={() => {
                                updateLocationFilter(location.code);
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                <CheckboxSquare
                                    isChecked={
                                        searchParams.get(searchParam)?.includes(location.code) ||
                                        false
                                    }
                                    isFocused={false}
                                    isHovered={false}
                                    disabled={false}
                                    styles={{
                                        minWidth: '1.6rem',
                                        height: '1.6rem',
                                        width: '1.6rem'
                                    }}
                                />

                                <P noDefaultMargin>{location.name}</P>
                            </div>
                        </ClickableOpacity>
                    );
                })}
            </div>
        </Filter>
    );
};

export default LocationFilter;
