import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { useLeadDetailsContext } from '../context/LeadDetailsContext';

type UnsavedChangeBlockerProps = {
    isChanged: boolean;
    isSuccess: boolean;
    changeBlockerModal: (show: boolean) => void;
};

type ReturnProps = {
    handleCancel: () => void;
    handleDiscard: () => void;
};

type UseUnsavedChangeBlockerProps = (props: UnsavedChangeBlockerProps) => ReturnProps;

const useUnsavedChangeBlocker: UseUnsavedChangeBlockerProps = ({
    isChanged,
    isSuccess,
    changeBlockerModal
}) => {
    const { handleFormChanged, onBlockNext, onBlockCancel, hasUnsavedChanges } =
        useLeadDetailsContext();

    useEffect(() => {
        handleFormChanged(isChanged);
    }, [handleFormChanged, isChanged]);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            isChanged && currentLocation.pathname !== nextLocation.pathname
    );

    useEffect(() => {
        if (blocker.state === 'blocked' || hasUnsavedChanges) {
            changeBlockerModal(true);
        }
    }, [blocker, hasUnsavedChanges, changeBlockerModal]);

    useEffect(() => {
        if (blocker.state === 'blocked' && isSuccess) {
            blocker.proceed && blocker.proceed();
        }
    }, [blocker, isSuccess]);

    useEffect(() => {
        if (hasUnsavedChanges && isSuccess) {
            onBlockNext();
        }
    }, [hasUnsavedChanges, isSuccess, onBlockNext]);

    const handleDiscard = () => {
        blocker.proceed ? blocker.proceed() : onBlockNext();
    };

    const handleCancel = () => {
        blocker.reset ? blocker.reset() : onBlockCancel();
        changeBlockerModal(false);
    };

    return {
        handleCancel,
        handleDiscard
    };
};

export default useUnsavedChangeBlocker;
