import Modal from '@hero/ui-kit/components/Modal';
import CircularButton from '@hero/ui-kit/inputs/CircularButton';
import Section from '@hero/ui-kit/layout/Section';
import React from 'react';
import Layout from '../../components/Layout';
import { useUserStatusContext } from '../../context/UserStatus';
import UserList from './UserList';
import InviteForm from './UserList/InviteForm';
import useGetListRoles from '../IAM/api/useGetListRoles';
import PlusIcon from '@hero/ui-kit/icons/utility/PlusIcon';

const UsersManagement: React.FC = () => {
    const [showInviteModal, setShowInviteModal] = React.useState(false);
    const { permissions } = useUserStatusContext();
    const handleOpenInviteModal = React.useCallback(() => {
        permissions.iam.createInvites && setShowInviteModal(true);
    }, [permissions]);

    const handleCloseInviteModal = React.useCallback(() => {
        setShowInviteModal(false);
    }, []);

    const { data: roles } = useGetListRoles();

    return (
        <Layout
            title="Users"
            search
            searchLabel="Search agent"
            placeholder="Enter agent email"
            additionalElement={
                permissions.iam.createInvites ? (
                    <Section noDefaultPadding centeredVertically>
                        <CircularButton
                            variant="secondary"
                            isLink={false}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleOpenInviteModal();
                            }}
                            marginLeft="small"
                            className="flex-center"
                            alt="Add User"
                        >
                            <PlusIcon size="utility" />
                        </CircularButton>
                    </Section>
                ) : undefined
            }
        >
            <UserList roles={roles} />
            <Modal
                styles={{ maxWidth: '90rem' }}
                externalControls={[showInviteModal, setShowInviteModal]}
                isCancelable
            >
                <InviteForm roles={roles} closeModal={handleCloseInviteModal} />
            </Modal>
        </Layout>
    );
};

export default UsersManagement;
