import { format, isToday } from 'date-fns';

const formatDateTime = (date: string, showOnlyTimeForToday?: boolean) => {
    const dateT = new Date(date);
    const dateObj = new Date(
        Date.UTC(
            dateT.getFullYear(),
            dateT.getMonth(),
            dateT.getDate(),
            dateT.getHours(),
            dateT.getMinutes(),
            dateT.getSeconds()
        )
    );

    if (showOnlyTimeForToday && isToday(dateObj)) {
        return format(dateObj, 'p'); // 12:59 PM
    }

    return format(dateObj, 'PP'); // Oct 11, 2022
};

export default formatDateTime;
