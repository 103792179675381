import { useMutation, useQueryClient } from '@tanstack/react-query';

import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { LeadEnrolmentPayload } from '../types';
import { LEAD_LIST_KEY } from './constants';
import { useSetNotification } from '../../../context/Notification';

const useLeadEnrolment = (onSuccess?: () => void) => {
    const req = useRequest();
    const queryClient = useQueryClient();
    const setNotification = useSetNotification();

    const request = ({ payload }: { payload: LeadEnrolmentPayload }) => {
        return req({
            url: `${envVars.API_RTM_HOSTNAME}enterprise/update-approval-status/`,
            method: 'POST',
            body: JSON.stringify(payload)
        });
    };

    return useMutation({
        mutationFn: request,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [LEAD_LIST_KEY] });
            setNotification({ message: `Patient enrolled` });
            onSuccess && onSuccess();
        },
        onError: (error: { errors: string[] }) => {
            return error;
        }
    });
};

export default useLeadEnrolment;
