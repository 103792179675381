import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import { useUserStatusContext } from '../../../../context/UserStatus';
import useUpdateUserInvite from '../../api/useUpdateUserInvite';
import * as Style from './style.module.scss';
import RolePicker from '../InviteForm/RolePicker';
import { UserDetailsProps } from '.';
import UserErrorComponent from './UserErrorComponent';

const EditInvitedUser: React.FC<UserDetailsProps> = ({ user, userName, closeModal, roles }) => {
    const { id: userId, email: userEmail } = user;

    const isInviteExpired = user.expired;

    const roleId = user.role?.id;

    const [selectedRoleId, setSelectedRoleId] = React.useState(roleId);
    const {
        mutate: updateInvite,
        error: updateError,
        isPending: isUpdatePending,
        isSuccess
    } = useUpdateUserInvite();

    const { permissions } = useUserStatusContext();

    const isUpdateDisabled = React.useMemo(() => {
        return !permissions.iam.inviteUserEdit || isUpdatePending;
    }, [permissions, isUpdatePending]);

    const handleResendInvite = React.useCallback(() => {
        const isRoleChanged = roleId !== selectedRoleId;

        updateInvite({
            id: userId,
            action: ['RESEND_INVITE'],
            ...(isRoleChanged && { role_id: selectedRoleId })
        });
    }, [updateInvite, selectedRoleId, roleId]);

    const handleRevokeInvite = () => {
        updateInvite({ id: userId, action: ['REVOKE_INVITE'] });
    };

    React.useEffect(() => {
        if (isSuccess) {
            closeModal();
        }
    }, [isSuccess, closeModal]);

    const [proceed, setProceed] = React.useState(false);

    if (!!updateError) {
        return (
            <UserErrorComponent
                updateError={updateError}
                deactivateError={null}
                userEmail={userEmail}
                userName={userName}
                closeModal={closeModal}
            />
        );
    }
    if (proceed) {
        return (
            <>
                <Section centered noDefaultPadding>
                    <H role="h5" noDefaultMargin>
                        {userName}
                    </H>
                    <P noDefaultMargin>{userEmail}</P>
                </Section>

                <div className={Style.roleLabel}>
                    <H noDefaultMargin role="h6">
                        User role
                    </H>
                </div>
                <RolePicker
                    roles={roles}
                    onChange={(role) => setSelectedRoleId(role)}
                    selectedId={selectedRoleId}
                />
                <hr className={Style.dividerInvite} />
                <div className={Style.btnWrapper}>
                    <Button disabled={isUpdateDisabled} onClick={handleResendInvite}>
                        {isUpdatePending ? 'Pending...' : 'Send invite'}
                    </Button>
                </div>
            </>
        );
    }

    return (
        <>
            <Section centered noDefaultPadding>
                <P
                    noDefaultMargin
                    strong
                    styles={{ fontSize: '24px', lineHeight: '24px', marginBottom: '3.6rem' }}
                >
                    {`Invite ${isInviteExpired ? 'expired' : 'pending'}`}
                </P>
                {isInviteExpired ? (
                    <P
                        noDefaultMargin
                        styles={{
                            textAlign: 'center',
                            paddingLeft: '3.6rem',
                            paddingRight: '3.6rem'
                        }}
                    >
                        The invite for <strong>{userEmail}</strong> has expired. Invites must be
                        accepted within 10 days of being sent.
                    </P>
                ) : (
                    <P
                        noDefaultMargin
                        styles={{
                            textAlign: 'center',
                            paddingLeft: '3.6rem',
                            paddingRight: '3.6rem'
                        }}
                    >
                        The invite for <strong>{userEmail}</strong> has not been accepted yet. What
                        would you like to do?
                    </P>
                )}

                <div className={Style.btnWrapper}>
                    {!isInviteExpired ? (
                        <Button
                            variant="outlineSecondary"
                            onClick={handleRevokeInvite}
                            disabled={isUpdateDisabled}
                        >
                            {isUpdatePending ? 'Pending...' : 'Mark as expired'}
                        </Button>
                    ) : null}
                    <Button disabled={isUpdateDisabled} onClick={() => setProceed(true)}>
                        {isUpdatePending ? 'Pending...' : 'Resend Invite'}
                    </Button>
                </div>
            </Section>
        </>
    );
};

export default EditInvitedUser;
