import useTheme from '@hero/branding/theme';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import { useOrganizationMemberDetailsContext } from '../context/OrganizationMemberDetailsContext';

const CaregiverBasicInfo: React.FC = () => {
    const { memberDetails, memberName } = useOrganizationMemberDetailsContext();
    const { member_info } = memberDetails;
    const theme = useTheme();

    return (
        <>
            <div style={{ padding: '2.4rem' }}>
                <H role="h5" noDefaultMargin>
                    {memberName}
                </H>
                <P noDefaultMargin>{member_info?.external_user_id || ''}</P>
            </div>

            <Section border padding="small">
                <P noDefaultMargin styles={{ color: theme.colors.neutrals.textGamma }}>
                    Email
                </P>
                <P noDefaultMargin>{member_info?.contact_info?.email || 'N/A'}</P>
                <P noDefaultMargin styles={{ color: theme.colors.neutrals.textGamma }}>
                    Phone
                </P>
                <P noDefaultMargin>{member_info?.contact_info?.phone_number || 'N/A'}</P>
            </Section>
        </>
    );
};

export default CaregiverBasicInfo;
