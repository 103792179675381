import { usStateByName } from '@hero/hero-utils/address/states';

const prepareState = (state: string) => {
    if (state.length > 2) {
        return usStateByName(state)?.code || state.toUpperCase();
    }

    return state.toUpperCase();
};

export default prepareState;
