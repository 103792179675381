import React from 'react';
import useGetUsersList from '../api/useGetUsersList';
import UserItem from './UserItem';
import { useSearchParams } from 'react-router-dom';
import NoResults from '../../../components/States/NoResults';
import Error from '../../../components/States/Error';
import Table from '../../../components/Table';
// import SortDir from '../../../components/SortDir';
import LimitItems from '../../../components/LimitItems';
import { IAMListRole } from '../../IAM/types';
import RoleFilter from './RoleFilter';
import InviteStatusFilter from './InviteStatusFilter';
import * as Style from './style.module.scss';

const UserList: React.FC<{ roles?: IAMListRole[] }> = ({ roles }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchParam = searchParams.get('search') || '';
    const offset = searchParams.get('offset');
    const limit = searchParams.get('limit');
    const sort_dir = searchParams.get('sort_dir');
    const role = searchParams.get('role');
    const status = searchParams.get('status');

    const query = React.useMemo(() => {
        return {
            sort_by: 'email',
            sort_dir: sort_dir || 'DESC',
            ...(limit && { limit: +limit }),
            ...(searchParam.length && { search: encodeURIComponent(searchParam) }),
            ...(offset && { offset: +offset }),
            ...(role && { role }),
            ...(status && { status })
        };
    }, [searchParam, offset, limit, sort_dir, role, status]);

    const hasFilters = ['search', 'role', 'status'].some((key) => searchParams.has(key));

    const handleClearFilter = () => {
        setSearchParams((prevState) => {
            let keys = [];

            for (let key of prevState.keys()) {
                keys.push(key);
            }

            keys.forEach((key) => prevState.delete(key));

            return prevState;
        });
    };

    const { data, isLoading, isError } = useGetUsersList(query);

    if (isError) {
        return <Error />;
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1.6rem',
                    minHeight: '4rem',
                    marginBottom: '2.4rem',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1.6rem',
                        minHeight: '4rem'
                    }}
                >
                    <LimitItems
                        isLoading={isLoading}
                        limit={limit ? +limit : 10}
                        offset={offset ? +offset : 0}
                        total_count={data?.total_count}
                    />
                    <RoleFilter roles={roles} />
                    <InviteStatusFilter />
                </div>

                {hasFilters ? (
                    <button className={Style.filterClearBtn} onClick={handleClearFilter}>
                        Clear filters
                    </button>
                ) : null}
            </div>

            {data?.invites.length === 0 ? (
                <NoResults
                    search={searchParam}
                    hideSearchLabel={searchParam.length === 0}
                    searchSupportsLabel="E-mail"
                />
            ) : (
                <Table
                    pagerData={data}
                    headerColumns={['Name', 'Role', 'Status']}
                    headerGridTemplateColumns={'3fr 1fr 1fr'}
                    loading={isLoading}
                >
                    {data?.invites.map((item) => (
                        <UserItem key={item.id} user={item} roles={roles} />
                    ))}
                </Table>
            )}
        </>
    );
};

export default UserList;
