import { useNavigate, useLocation } from 'react-router-dom';

type UseBackType = () => (url: string) => void;

const useBack: UseBackType = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (url) => navigate(location.state?.from || url);
};

export default useBack;
