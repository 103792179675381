// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dgjLfGA6Fr42cFpxiHBb{display:flex;align-items:center;justify-content:space-between;margin:1.6rem 0;padding:0 1.6rem !important;outline:none !important}.g7RSIWsozb0TzKq1ljpA{max-height:62vh;overflow-y:auto}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/components/HeroMemberFilter/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA,CACA,2BAAA,CACA,uBAAA,CAGJ,sBACI,eAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
export var filterStatusItem = `dgjLfGA6Fr42cFpxiHBb`;
export var filterWrapper = `g7RSIWsozb0TzKq1ljpA`;
export default ___CSS_LOADER_EXPORT___;
