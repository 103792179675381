import React from 'react';

type ILeadDetailsContext = {
    isFormChanged: boolean;
    handleFormChanged: (changed: boolean) => void;
    handleChangeTab: (newTab: string) => void;
    handlePrepareChangeTab: (preparedTab?: string) => void;
    onBlockNext: () => void;
    onBlockCancel: () => void;
    tab: string;
    preparedTab?: string;
    hasUnsavedChanges: boolean;
};

const LeadDetailsContext = React.createContext<ILeadDetailsContext | undefined>(undefined);

export const useLeadDetailsContext = () => {
    const ctx = React.useContext(LeadDetailsContext);

    if (ctx === undefined) {
        throw new Error(
            `'useLeadDetailsContext' must be used within a 'LeadDetailsContextProvider'`
        );
    }

    return ctx;
};

const LeadDetailsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [tab, setTab] = React.useState('general');
    const [preparedTab, setPreparedTab] = React.useState<string | undefined>();
    const [isFormChanged, setFormChanged] = React.useState(false);

    const handleFormChanged = (changed: boolean) => {
        setFormChanged(changed);
    };

    const handleChangeTab = (newTab: string) => {
        setTab(newTab);
    };

    const handlePrepareChangeTab = (preparedTab?: string) => {
        setPreparedTab(preparedTab);
    };

    const onBlockNext = () => {
        preparedTab && handleChangeTab(preparedTab);
        setFormChanged(false);
        handlePrepareChangeTab(undefined);
    };

    const onBlockCancel = () => {
        setFormChanged(false);
        handlePrepareChangeTab(undefined);
    };

    return (
        <LeadDetailsContext.Provider
            value={{
                isFormChanged,
                handleFormChanged,
                tab,
                handleChangeTab,
                handlePrepareChangeTab,
                onBlockNext,
                onBlockCancel,
                preparedTab,
                hasUnsavedChanges: preparedTab !== undefined && isFormChanged
            }}
        >
            {children}
        </LeadDetailsContext.Provider>
    );
};

export default LeadDetailsProvider;
