import React from 'react';

import { usStateNames } from '@hero/hero-utils/address/states';
import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import Button from '@hero/ui-kit/inputs/Button';
import Combobox from '@hero/ui-kit/inputs/Combobox';
import { useFormContext } from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import AddressCombobox from '../../../../components/AddressGoogleCombobox';
import StandaloneCheckbox from '@hero/ui-kit/inputs/StandaloneCheckbox';
import P from '@hero/ui-kit/typography/P';
import ExistingHeroUserFormFields from '../ExistingHeroUserFormFields';
import VerifiedUser from '../VerifiedUser';

const FormElements: React.FC<{
    error?: string;
    loading: boolean;
    showAdminEmailError?: boolean;
    showHeroSerialErrorError?: boolean;
    adminEmail?: string | null;
    externalSerial?: string | null;
    isExistingMember?: boolean;
    formVersion?: string | null;
}> = ({
    loading,
    showAdminEmailError = false,
    showHeroSerialErrorError = false,
    isExistingMember = false,
    adminEmail,
    externalSerial,
    formVersion
}) => {
    const { watch } = useFormContext();
    const isMinL = useIsMinBreakpoint('l');
    const isMember = watch('is_member');

    const isElectionMember = ['backoffice', 'mobile'].includes(formVersion || '');

    return (
        <Section noDefaultPadding>
            <div style={{ marginBottom: '2.4rem' }}>
                <strong>Details</strong>
            </div>

            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <Input autoFocus name="first_name" displayName="First name" />
                <Input name="last_name" displayName="Last name" />
            </Container>
            <Input name="email" displayName="Primary Patient Email" />
            {isExistingMember && adminEmail && externalSerial && isElectionMember ? (
                <div style={{ marginBottom: 'var(--gutter)' }}>
                    <VerifiedUser adminEmail={adminEmail} serialNumber={externalSerial} />
                </div>
            ) : (
                <>
                    <div style={{ marginBottom: 'var(--gutter)' }}>
                        <StandaloneCheckbox
                            name="is_member"
                            labelText="Already using Hero dispenser"
                        />
                    </div>
                    {isMember ? (
                        <ExistingHeroUserFormFields
                            showAdminEmailError={showAdminEmailError}
                            showHeroSerialErrorError={showHeroSerialErrorError}
                        />
                    ) : null}
                </>
            )}

            <div style={{ marginBottom: '0.4rem', marginTop: '1.2rem' }}>
                <strong>Org info</strong>
            </div>
            <P noDefaultMargin size="small" styles={{ marginBottom: '2.4rem' }}>
                Internal patient record. If you have a patient ID from your EMR you may enter it
                below.
            </P>

            <Input name="external_user_id" displayName="Patient ID (optional)" placeholder="" />

            <>
                <div style={{ marginBottom: '2.4rem', marginTop: '1.2rem' }}>
                    <strong>Shipping address</strong>
                </div>

                <AddressCombobox zipName="zip_code" />
                <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                    <Input name="address_line_2" displayName="Apt, suite, etc (optional)" />
                    <Input name="city" displayName="City" />
                </Container>

                <Container
                    gridTemplateColumns={isMinL ? '1fr 1fr 2fr' : '1fr'}
                    gridColumnGap="regular"
                >
                    <div style={{ width: '100%' }}>
                        <Combobox
                            name="state"
                            displayName="State"
                            suggestions={usStateNames}
                            noExtraMargin
                            onlyValidateWithError={true}
                        />
                    </div>
                    <Input name="zip_code" displayName="Zip" />
                    <Input name="phone" displayName="Phone" styles={{ marginBottom: '0.5rem' }} />
                </Container>
            </>

            <Section noDefaultPadding marginVertical="regular">
                <Section centered noDefaultPadding>
                    <Button type="submit" disabled={loading} width={isMinL ? 'large' : 'full'}>
                        Submit
                    </Button>
                </Section>
            </Section>
        </Section>
    );
};

export default FormElements;
