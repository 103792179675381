import React from 'react';
import { useAuthContext } from '@hero/hero-auth/v2/AuthProvider';
import WS from './index';

const WsWrapper: React.FC = () => {
    const { auth } = useAuthContext();
    const accessToken = auth?.access_token;

    React.useEffect(() => {
        if (!accessToken) {
            return;
        }

        const newWs = new WS({ accessToken });

        /* eslint-disable */
        return () => {
            newWs.close();
        };
    }, [accessToken]);

    return null;
};

export default WsWrapper;
