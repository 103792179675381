import React from 'react';
import { useOrganizationMemberDetailsContext } from '../../../context/OrganizationMemberDetailsContext';
import useTheme from '@hero/branding/theme';
import P from '@hero/ui-kit/typography/P';
import Pill from '../../../../../LeadsManagement/components/Pill';
import Divider from '@hero/ui-kit/components/Divider';
import { CaregiverInfo } from '../../../../types';
import EmailIcon from '@hero/ui-kit/icons/branding/EmailIcon';
import CallIcon from '@hero/ui-kit/icons/branding/CallIcon';
import * as Style from './style.module.scss';

const Caregiver: React.FC<{ user: CaregiverInfo; isAdmin?: boolean }> = ({
    user,
    isAdmin = false
}) => {
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <P strong noDefaultMargin>{`${user.first_name} ${user.last_name}`}</P>
                <Pill
                    label={isAdmin ? 'Admin' : 'Read only'}
                    styles={{
                        textTransform: 'none',
                        fontWeight: '700',
                        fontSize: '1.6rem',
                        height: '3.2rem',
                        borderRadius: '1.8rem',
                        background: isAdmin ? 'rgba(255, 229, 214, 1)' : 'transparent',

                        ...(!isAdmin && {
                            border: '2px solid rgba(231, 231, 231, 1)'
                        })
                    }}
                />
            </div>
            <div className={Style.iconWrapper}>
                <EmailIcon
                    className={Style.emailIcon}
                    type="utility"
                    styles={{ width: '20px', height: '20px' }}
                />
                <P noDefaultMargin>{user.email || 'Email not provided'}</P>
            </div>

            <div className={Style.iconWrapper}>
                <CallIcon
                    className={Style.callIcon}
                    type="utility"
                    styles={{ width: '20px', height: '20px' }}
                />
                <P noDefaultMargin>{user.cell_phone || 'No phone number'}</P>
            </div>
        </div>
    );
};

const Caregivers: React.FC = () => {
    const { memberDetails } = useOrganizationMemberDetailsContext();
    const theme = useTheme();
    const { caregivers } = memberDetails;

    const caregiverUsers = React.useMemo(() => {
        return {
            adminList: caregivers?.filter((user) => user?.member_role === 'CAREGIVER_ADMIN') || [],
            readOnlyList:
                caregivers?.filter((user) => user?.member_role === 'CAREGIVER_READ_ONLY') || []
        };
    }, [caregivers]);

    return (
        <div>
            <P
                strong
                noDefaultMargin
                styles={{ fontSize: '2.4rem', lineHeight: '3.2rem', marginBottom: '3.6rem' }}
            >
                Caregivers
            </P>
            {caregiverUsers.adminList.length ? (
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '3.6rem' }}>
                    {caregiverUsers.adminList.map((user, index) => (
                        <Caregiver
                            key={`${index}-${user.member_id}-${user.hero_user_id}`}
                            user={user}
                            isAdmin
                        />
                    ))}
                </div>
            ) : null}
            {caregiverUsers.adminList.length > 0 && caregiverUsers.readOnlyList.length > 0 ? (
                <Divider
                    styles={{
                        borderColor: theme.colors.neutrals.borderBeta,
                        borderWidth: '0.1rem'
                    }}
                />
            ) : null}
            {caregiverUsers.readOnlyList.length ? (
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '3.6rem' }}>
                    {caregiverUsers.readOnlyList.map((user, index) => (
                        <Caregiver
                            key={`${index}-${user.member_id}-${user.hero_user_id}`}
                            user={user}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default Caregivers;
