import useTheme from '@hero/branding/theme';
import Divider from '@hero/ui-kit/components/Divider';
import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import getMemberUrl from '../../utils/getMemberUrl';
import { useOrganizationMemberDetailsContext } from '../context/OrganizationMemberDetailsContext';
import UserDeviceStatus from '../DeviceInfo/UserDeviceStatus';
import MemberDetailsWrapper from '../MemberDetailsWrapper';

interface CaregivingInfoProps {
    styles?: {};
}

const CaregivingInfo: React.FC<CaregivingInfoProps> = ({ styles = {} }) => {
    const { memberDetails } = useOrganizationMemberDetailsContext();
    const theme = useTheme();
    const { caregiving } = memberDetails;

    const caregivingUsers = React.useMemo(() => {
        return {
            adminList: caregiving?.filter((user) => user?.member_role === 'CAREGIVER_ADMIN') || [],
            readOnlyList:
                caregiving?.filter((user) => user?.member_role === 'CAREGIVER_READ_ONLY') || []
        };
    }, [caregiving]);

    return (
        <div style={{ ...styles }}>
            {caregivingUsers.adminList.length ? (
                <div style={{ paddingLeft: '2.4rem' }}>
                    <P strong>Role: Caregiver - Admin</P>
                    <Container gridTemplateColumns={'repeat(4, 3fr)'}>
                        {caregivingUsers.adminList.map((user, index) => (
                            <UserDeviceStatus
                                key={`${index}-${user.member_id}-${user.hero_user_id}`}
                                userFullName={`${user.first_name} ${user.last_name}`}
                                isDeviceOnline={
                                    user?.device_info?.online_status?.is_online || false
                                }
                                deviceStatus={user?.device_info?.device_status}
                                deviceSerial={user?.device_info?.device_serial || 'unknown'}
                                userLink={getMemberUrl(user.hero_user_id, user.member_id)}
                                lastOnline={
                                    user?.device_info?.online_status?.last_online || undefined
                                }
                            />
                        ))}
                    </Container>
                </div>
            ) : null}
            {caregivingUsers.adminList.length > 0 && caregivingUsers.readOnlyList.length > 0 ? (
                <Divider
                    styles={{
                        borderColor: theme.colors.neutrals.borderBeta,
                        borderWidth: '0.1rem'
                    }}
                />
            ) : null}
            {caregivingUsers.readOnlyList.length ? (
                <div style={{ paddingLeft: '2.4rem' }}>
                    <P strong>Role: Caregiver - Read Only</P>
                    <Container gridTemplateColumns={'repeat(4, 3fr)'}>
                        {caregivingUsers.readOnlyList.map((user, index) => (
                            <UserDeviceStatus
                                key={`${index}-${user.member_id}-${user.hero_user_id}`}
                                userFullName={`${user.first_name} ${user.last_name}`}
                                isDeviceOnline={
                                    user?.device_info?.online_status?.is_online || false
                                }
                                deviceStatus={user?.device_info?.device_status}
                                deviceSerial={user?.device_info?.device_serial || 'unknown'}
                                userLink={getMemberUrl(user.hero_user_id, user.member_id)}
                                lastOnline={
                                    user?.device_info?.online_status?.last_online || undefined
                                }
                            />
                        ))}
                    </Container>
                </div>
            ) : null}
        </div>
    );
};

const CaregivingInfoWrapped = () => (
    <MemberDetailsWrapper>
        <CaregivingInfo />
    </MemberDetailsWrapper>
);

export default CaregivingInfoWrapped;
