// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c539F2fsyK1iPTJme2Xi{margin-top:calc(var(--gutter)/2)}.c539F2fsyK1iPTJme2Xi>*{flex:auto;padding-left:0 !important;margin-top:0 !important;margin-right:var(--gutter);background:rgba(0,0,0,0) !important}.JXyvVqF6hyqfSfxjo0sa{display:flex;align-items:center;justify-content:space-between}.JXyvVqF6hyqfSfxjo0sa>*+*{margin-left:var(--gutter)}.Tq1_NpDp_tNHqNPyZ_DM label{font-weight:400 !important}.Tq1_NpDp_tNHqNPyZ_DM svg{min-width:30px !important}.xG8a6NUQDRO83Lt7LaMX{align-items:flex-start !important;overflow:hidden;padding:0 !important}.xG8a6NUQDRO83Lt7LaMX.vUGoOp6n1D1rvW7AvDKE{align-items:center !important}.xG8a6NUQDRO83Lt7LaMX:hover{background-color:rgba(0,0,0,0) !important}.xG8a6NUQDRO83Lt7LaMX label{width:inherit !important;min-width:inherit !important;align-items:flex-start;white-space:inherit !important}.xG8a6NUQDRO83Lt7LaMX svg{border-color:var(--color-neutrals-textGamma) !important;border-width:2px !important;background-color:var(--color-neutrals-background) !important;box-shadow:none !important}.D8BCid3q8GECllv6qlxi{font-size:2.4rem;line-height:1.35;margin-top:3.6rem;margin-bottom:2.4rem}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/components/LeadsForm/style.module.scss"],"names":[],"mappings":"AACA,sBACI,gCAAA,CACA,wBACI,SAAA,CACA,yBAAA,CACA,uBAAA,CACA,0BAAA,CACA,mCAAA,CAIR,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,0BACI,yBAAA,CAKJ,4BACI,0BAAA,CAEJ,0BACI,yBAAA,CAIR,sBACI,iCAAA,CACA,eAAA,CACA,oBAAA,CAEA,2CACI,6BAAA,CAGJ,4BACI,yCAAA,CAGJ,4BACI,wBAAA,CACA,4BAAA,CACA,sBAAA,CACA,8BAAA,CAGJ,0BACI,uDAAA,CACA,2BAAA,CACA,4DAAA,CACA,0BAAA,CAIR,sBACI,gBAAA,CACA,gBAAA,CACA,iBAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
export var radioGroup = `c539F2fsyK1iPTJme2Xi`;
export var radioPillWrap = `JXyvVqF6hyqfSfxjo0sa`;
export var radio = `Tq1_NpDp_tNHqNPyZ_DM`;
export var checkboxStyle = `xG8a6NUQDRO83Lt7LaMX`;
export var center = `vUGoOp6n1D1rvW7AvDKE`;
export var infoTitle = `D8BCid3q8GECllv6qlxi`;
export default ___CSS_LOADER_EXPORT___;
