import { DeviceStatus } from '../../types';

const getDeviceStatusLabel = (status?: DeviceStatus | null) => {
    const statuses = {
        ORDERED: 'Ordered',
        DELIVERED: 'Delivered',
        ACTIVATED: 'Activated',
        SETUP_NEEDED: 'Setup Needed',
        CONFIGURED: 'Configured',
        RETURN_NEEDED: 'Return Needed',
        None: 'No device'
    };

    return status ? statuses[status] : 'N/A';
};

export default getDeviceStatusLabel;
