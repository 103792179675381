import Input from '@hero/ui-kit/inputs/Input';
import React from 'react';

interface PhoneNumberProps {
    className?: string;
    displayName?: string;
    styles?: React.CSSProperties;
}

const phoneFormat = (input: string) => {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, '');

    if (input.length > 3) {
        input = input.replace(/.{3}/, '$&-');
    }
    if (input.length > 7) {
        input = input.replace(/.{7}/, '$&-');
    }

    return input;
};

const Phone: React.FC<PhoneNumberProps> = ({ styles, displayName = 'Phone number', className }) => {
    const handleKeyUp = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        let target = event.target as HTMLInputElement;
        let v = target.value;

        target.value = phoneFormat(v);
    }, []);

    return (
        <Input
            maxLength={12}
            onKeyUp={handleKeyUp}
            name="phone"
            displayName={displayName}
            className={className}
            styles={styles}
            placeholder="123-456-7890"
            inputMode="numeric"
        />
    );
};

export default Phone;
