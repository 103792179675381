import AlertIcon from '@hero/ui-kit/icons/utility/AlertIcon';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import { format, isValid } from 'date-fns';
import React from 'react';
import { LeadActivityDetail } from '../types';

interface ExpiredLeadActivityProps {
    activityDetails: LeadActivityDetail;
    isExpiredCurrentStatus?: boolean;
}

const ExpiredLeadActivity: React.FC<ExpiredLeadActivityProps> = ({
    activityDetails,
    isExpiredCurrentStatus = false
}) => {
    const updatedAt =
        activityDetails.updated_at && isValid(new Date(activityDetails.updated_at))
            ? new Date(activityDetails.updated_at)
            : undefined;
    const date = updatedAt
        ? format(
              new Date(
                  Date.UTC(
                      updatedAt.getFullYear(),
                      updatedAt.getMonth(),
                      updatedAt.getDate(),
                      updatedAt.getHours(),
                      updatedAt.getMinutes(),
                      updatedAt.getSeconds()
                  )
              ),
              'PPPPp'
          )
        : 'Invalid date';
    return (
        <Section
            noDefaultPadding
            styles={{
                background: isExpiredCurrentStatus ? '#feece8' : '#f4f4f4',
                borderRadius: '8px',
                padding: '1.2rem 2.4rem',
                marginBottom: '3.6rem',
                marginTop: '2rem'
            }}
        >
            {isExpiredCurrentStatus ? (
                <div style={{ display: 'flex', alignItems: 'center', columnGap: '2.4rem' }}>
                    <AlertIcon width={30} />
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.2rem' }}>
                        <P noDefaultMargin>
                            <span
                                style={{ fontWeight: 700, fontSize: '1.6rem' }}
                            >{`Application expired`}</span>
                            <span
                                style={{ fontSize: '1.4rem', color: '#777777' }}
                            >{` • ${date}`}</span>
                        </P>
                        <P noDefaultMargin>
                            Application has been automatically set to expired after 28 days without
                            completion
                        </P>
                    </div>
                </div>
            ) : (
                <P noDefaultMargin>
                    <span
                        style={{ fontWeight: 700, fontSize: '1.6rem' }}
                    >{`Application expired`}</span>
                    <span style={{ fontSize: '1.4rem', color: '#777777' }}>{` • ${date}`}</span>
                </P>
            )}
        </Section>
    );
};

export default ExpiredLeadActivity;
