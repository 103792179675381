import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import { useUserStatusContext } from '../../../../context/UserStatus';
import useUpdateUserInvite from '../../api/useUpdateUserInvite';
import * as Style from './style.module.scss';
import ButtonToggler from '../../../../components/ButtonToggler';
import RolePicker from '../InviteForm/RolePicker';
import InfoIcon from '@hero/ui-kit/icons/utility/InfoIcon';
import useRemoveUserInvite from '../../api/useRemoveUserInvite';
import { UserDetailsProps } from '.';
import UserErrorComponent from './UserErrorComponent';
import { UpdateUserAction } from '../../types';

const EditActiveUser: React.FC<UserDetailsProps> = ({
    user,
    userName,
    closeModal,
    roles,
    isLoggedUser
}) => {
    console.log(isLoggedUser, 'isLoggedUser');
    const { id: userId, email: userEmail } = user;

    const roleId = user.role?.id;

    const [showDeactivateConfirm, setShowDeactivateConfirm] = React.useState(false);
    const [resetMFA, setResetMFA] = React.useState(false);
    const [selectedRoleId, setSelectedRoleId] = React.useState(roleId);

    const {
        mutate: updateInvite,
        error: updateError,
        isPending: isUpdatePending,
        isSuccess
    } = useUpdateUserInvite();

    const {
        mutateAsync: deactivateUser,
        isPending: isDeactivatePending,
        error: deactivateError
    } = useRemoveUserInvite();

    const { permissions } = useUserStatusContext();

    const isRoleChanged = roleId !== selectedRoleId;

    const isUpdateDisabled = React.useMemo(() => {
        const isUpdated = isRoleChanged || resetMFA;

        return !permissions.iam.inviteUserEdit || isUpdatePending || !isUpdated;
    }, [permissions, isUpdatePending, isRoleChanged, resetMFA]);

    React.useEffect(() => {
        if (isSuccess) {
            closeModal();
        }
    }, [isSuccess, closeModal]);

    const handleUpdate = React.useCallback(() => {
        let action: UpdateUserAction[] = [];

        if (isRoleChanged) {
            action.push('UPDATE_ROLE');
        }

        if (resetMFA) {
            action.push('RESET_MFA');
        }

        updateInvite({
            id: userId,
            action,
            ...(isRoleChanged && { role_id: selectedRoleId })
        });
    }, [updateInvite, isRoleChanged, selectedRoleId, resetMFA]);

    if (!!updateError || !!deactivateError) {
        return (
            <UserErrorComponent
                updateError={updateError}
                deactivateError={deactivateError}
                userEmail={userEmail}
                userName={userName}
                closeModal={closeModal}
            />
        );
    }

    if (showDeactivateConfirm) {
        return (
            <Section centered>
                <P size="large" strong>
                    Are you sure you want to deactivate account?
                </P>
                <P noDefaultMargin>
                    This user will lose their access immediately. Do you want to proceed?
                </P>
                <div className={Style.btnWrapper}>
                    <Button
                        disabled={isDeactivatePending}
                        onClick={() => setShowDeactivateConfirm(false)}
                        variant="outlineSecondary"
                    >
                        No, keep active
                    </Button>
                    <Button
                        disabled={isDeactivatePending}
                        onClick={() => {
                            deactivateUser({ id: userId })
                                .then(() => {
                                    setShowDeactivateConfirm(false);
                                    closeModal();
                                })
                                .catch(() => {});
                        }}
                    >
                        {isDeactivatePending ? 'Pending...' : 'Yes, deactivate'}
                    </Button>
                </div>
            </Section>
        );
    }

    return (
        <>
            <Section centered noDefaultPadding>
                <H role="h5" noDefaultMargin>
                    {userName}
                </H>
                <P noDefaultMargin>{userEmail}</P>
            </Section>
            {isLoggedUser ? (
                <P size="small" noDefaultMargin styles={{ marginTop: '1.2rem' }}>
                    You cannot modify your role, reset your MFA, or delete your account.
                </P>
            ) : null}

            <div className={Style.roleLabel}>
                <H noDefaultMargin role="h6">
                    User role
                </H>
            </div>

            <RolePicker
                roles={roles}
                onChange={(role) => setSelectedRoleId(role)}
                selectedId={selectedRoleId}
                disabled={isLoggedUser}
            />

            <hr className={Style.divider} />

            <div className={Style.mfaResetWrapper}>
                <div>
                    <P strong noDefaultMargin>
                        MFA Reset
                    </P>
                    <P noDefaultMargin>Authenticator app</P>
                </div>
                <ButtonToggler
                    disabled={isLoggedUser}
                    checked={resetMFA}
                    onChange={(checked) => setResetMFA(checked)}
                />
            </div>
            {resetMFA ? (
                <div className={Style.infoResetMFA}>
                    <InfoIcon width={20} height={22} type="utility" />
                    <P noDefaultMargin>
                        The user will be required to set up MFA again on next login.
                    </P>
                </div>
            ) : null}

            <div className={Style.btnWrapper}>
                <Button
                    variant="outlineSecondary"
                    disabled={isLoggedUser}
                    onClick={() => setShowDeactivateConfirm(true)}
                >
                    Deactivate User
                </Button>
                <Button disabled={isUpdateDisabled || isLoggedUser} onClick={handleUpdate}>
                    {isUpdatePending ? 'Pending...' : 'Update'}
                </Button>
            </div>
        </>
    );
};

export default EditActiveUser;
