import React from 'react';
import P from '@hero/ui-kit/typography/P';
import useGetProviderDetails from '../../../api/useGetProviderDetails';

const ProviderDetails: React.FC<{ herOrgId: string }> = ({ herOrgId }) => {
    const { data: provider } = useGetProviderDetails({ hero_organization_id: herOrgId });

    return provider?.provider_name || provider?.provider_logo ? (
        <div>
            {provider?.provider_logo ? (
                <img
                    src={provider?.provider_logo}
                    alt={provider?.provider_name || 'Provider'}
                    style={{ height: '2.8rem', width: 'auto' }}
                />
            ) : (
                <P strong size="large" noDefaultMargin>
                    {provider?.provider_name}
                </P>
            )}
        </div>
    ) : null;
};

export default ProviderDetails;
