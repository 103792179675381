import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { OrganizationDeviceStatistics } from '../types';
import { DEVICE_STATISTICS_KEY } from './constants';

export type GetDeviceStatisticsParams = {
    is_online?: boolean;
    device_status?: string;
};

const useGetDeviceStatistics = (query?: GetDeviceStatisticsParams) => {
    const req = useRequest();

    const action = useCallback(() => {
        return req<OrganizationDeviceStatistics>({
            url: `${envVars.API_COMMERCE_HOSTNAME}enterprise/device-statistics`,
            query
        });
    }, [req, query]);

    return useQuery({
        queryFn: action,
        queryKey: [DEVICE_STATISTICS_KEY, query]
    });
};

export default useGetDeviceStatistics;
