import * as yup from 'yup';

const newActivityFormSchema = yup
    .object()
    .shape({
        requiredDateTimeStates: yup.array().of(yup.string()),
        activity_type: yup.string().nullable().required('Activity type is required'),
        schedule_date: yup.string().when(['activity_type', 'requiredDateTimeStates'], {
            is: (activity_type?: string, requiredDateTimeStates?: string[]) =>
                activity_type &&
                requiredDateTimeStates &&
                requiredDateTimeStates.includes(activity_type),
            then: (schema) => schema.nullable().required('Schedule date is required'),
            otherwise: (schema) => schema.nullable()
        }),
        schedule_time: yup.string().when(['activity_type', 'requiredDateTimeStates'], {
            is: (activity_type?: string, requiredDateTimeStates?: string[]) =>
                activity_type &&
                requiredDateTimeStates &&
                requiredDateTimeStates.includes(activity_type),
            then: (schema) => schema.nullable().required('Schedule time is required'),
            otherwise: (schema) => schema.nullable()
        }),
        reject_reason: yup.string().when('activity_type', {
            is: (activity_type?: string) => ['REJECTED'].includes(activity_type || ''),
            then: (schema) => schema.nullable().required('Reject reason is required'),
            otherwise: (schema) => schema.nullable()
        }),
        note: yup.string().nullable().notRequired()
    })
    .defined();

export type NewActivityFormParams = yup.InferType<typeof newActivityFormSchema>;

export default newActivityFormSchema;
