import React from 'react';

import envVars from '../../../../../constants/envVars';
import useRequest from '../../../../../hooks/useRequest';
import { DoseDetails } from '@hero/enterprise-portal-common/src/Adherence/AdherenceCalendar/Sidebar/SidebarPeriod/SidebarPeriod.types';

type Query = {
    datetime_from: string | null; // ending datetime for the period in YYYY-mm-dd HH:MM:SS
    datetime_to: string | null;
};

const useGetSidebarPeriod = (hero_user_id: number) => {
    const req = useRequest();

    const request = React.useCallback(
        (query: Query) => {
            return req<DoseDetails[]>({
                url: `${envVars.API_CLOUD_HOSTNAME}enterprise/adherence/dose-details`,
                query: { ...query, hero_user_id }
            });
        },
        [req, hero_user_id]
    );

    return request;
};

export default useGetSidebarPeriod;
