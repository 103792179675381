import React from 'react';
import { ExchangeDeviceInfo, OrganizationMemberListDeviceInfo } from '../../types';
import useTheme from '@hero/branding/theme';
import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import getIsDeviceSetupNeeded from '../../utils/getIsDeviceSetupNeeded';
import AlertIcon from '@hero/ui-kit/icons/utility/AlertIcon';
import getDeviceStatusLabel from '../utils/getDeviceStatusLabel';
import P from '@hero/ui-kit/typography/P';
import { formatDistance, differenceInHours } from 'date-fns';

const StatusTimeLabel: React.FC<{ date: string }> = ({ date }) => {
    const theme = useTheme();
    const now = new Date();
    const providedDate = new Date(date);

    const diffInHours = differenceInHours(now, providedDate);
    const time = formatDistance(providedDate, now, { addSuffix: true });

    return (
        <P
            size="small"
            noDefaultMargin
            styles={{
                color: diffInHours > 48 ? 'rgba(232, 63, 63, 1)' : theme.colors.neutrals.textGamma
            }}
        >
            {diffInHours < 24 ? '< 1 day ago' : time}
        </P>
    );
};

const DeviceStatus: React.FC<{
    deviceInfo?: OrganizationMemberListDeviceInfo;
    exchangeDeviceInfo?: ExchangeDeviceInfo;
}> = ({ deviceInfo, exchangeDeviceInfo }) => {
    const theme = useTheme();
    const isMinL = useIsMinBreakpoint('l');
    const isSetupNeeded = getIsDeviceSetupNeeded(deviceInfo?.device_status);
    const statusDate = deviceInfo?.device_status_timestamp;

    const wrapperStyle: React.CSSProperties = {
        margin: 'auto',
        height: '4rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        ...(!isMinL && { flexWrap: 'wrap' })
    };

    const deviceSerialIcon = React.useMemo(() => {
        const diffInHours = statusDate ? differenceInHours(new Date(), new Date(statusDate)) : 0;
        const isOffline =
            typeof deviceInfo?.is_device_online === 'boolean' && !deviceInfo.is_device_online;
        const show =
            ((isSetupNeeded || isOffline) && !exchangeDeviceInfo) ||
            (diffInHours > 48 && !['CONFIGURED'].includes(deviceInfo?.device_status || ''));

        return show ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: '2.4rem'
                    }}
                >
                    <AlertIcon width={20} height={20} />
                </div>
            </div>
        ) : null;
    }, [isSetupNeeded, deviceInfo, exchangeDeviceInfo, theme]);

    const deviceSerialText = React.useMemo(() => {
        const onlineStatus = deviceInfo?.is_device_online ? 'Online' : 'Offline';
        const deviceStatusLabel = getDeviceStatusLabel(deviceInfo?.device_status);
        const isConfiguredStatus = deviceInfo?.device_status === 'CONFIGURED';

        let text = deviceStatusLabel;

        if (isSetupNeeded) {
            text = 'Setup Needed';
        }

        if (isConfiguredStatus && !isSetupNeeded) {
            text = onlineStatus;
        }

        const date = text === 'Offline' ? deviceInfo?.last_online : statusDate;

        return (
            <>
                <P strong noDefaultMargin>
                    {text}
                </P>
                {text !== 'Online' && date ? <StatusTimeLabel date={date} /> : null}
            </>
        );
    }, [isSetupNeeded, theme, deviceInfo, exchangeDeviceInfo]);

    return (
        <div style={wrapperStyle}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>{deviceSerialText}</div>
            {deviceSerialIcon}
        </div>
    );
};

export default DeviceStatus;
