import { useEffect } from 'react';
import WS from '../../ws';
import useSavedRef from './useSavedRef';

const useWSNewLeadListener = (refetchLeads: () => void) => {
    const handleRef = useSavedRef(refetchLeads);

    useEffect(() => {
        const unsubscribe = WS.subscribe({
            messageType: 'enterprise_new_lead',
            handler: () => {
                handleRef.current();
            }
        });

        return () => {
            unsubscribe && unsubscribe();
        };
    }, [handleRef]);
};

export default useWSNewLeadListener;
