import React from 'react';
import { useSearchParams } from 'react-router-dom';

import XIcon from '@hero/ui-kit/icons/utility/XIcon';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';

import { getSidebarParam, setSidebarParam } from './sidebarHooks';
import * as Styles from './style.module.scss';

interface SideBarProps {
    children: React.ReactNode;
    onOutsideClick?: () => void;
    listenToKeydown?: boolean;
    className?: string;
}

const SideBar: React.FC<SideBarProps> = ({
    children,
    onOutsideClick,
    listenToKeydown = false,
    className = ''
}) => {
    const [, setSearchParams] = useSearchParams();
    const [isVisible, setIsVisible] = React.useState(true);

    const handleSideBarClose = React.useCallback(() => {
        setIsVisible(false);

        setTimeout(() => {
            setSearchParams((prevState) => {
                const sidebars = getSidebarParam(prevState);
                sidebars.pop();
                return setSidebarParam(prevState, sidebars);
            });

            onOutsideClick && onOutsideClick();
        }, 300);
    }, [onOutsideClick, setSearchParams]);

    React.useEffect(() => {
        if (!listenToKeydown) {
            return () => {};
        }

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                handleSideBarClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown, { once: true });

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleSideBarClose, listenToKeydown]);

    return (
        <div
            className={`${Styles.sidebar} ${className.length ? className : ''} ${
                isVisible ? Styles.isVisible : ''
            }`}
        >
            <ClickableOpacity alt="Close sidebar" onClick={handleSideBarClose}>
                <XIcon size="utility" className={Styles.closeIcon} />
            </ClickableOpacity>
            <div className={Styles.content}>{children}</div>
        </div>
    );
};

export default SideBar;
