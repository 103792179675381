import Combobox from '@hero/ui-kit/inputs/Combobox';
import Input from '@hero/ui-kit/inputs/Input';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import React from 'react';
import { usStateNames } from '@hero/hero-utils/address/states';
import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import { useFormContext } from '@hero/ui-kit/inputs/Form';
import P from '@hero/ui-kit/typography/P';
import AddressCombobox from '../../../../../../components/AddressGoogleCombobox';

interface ContactFormProps {
    isLoading?: boolean;
    addressName?: string;
    addressName2?: string;
    cityName?: string;
    stateName?: string;
    zipName?: string;
    canEdit?: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({
    addressName = 'address_line_1',
    addressName2 = 'address_line_2',
    cityName = 'city',
    stateName = 'state',
    zipName = 'zip',
    canEdit = true
}) => {
    const isMinL = useIsMinBreakpoint('l');
    const disabled = !canEdit;

    const {
        setValue,
        formState: { isDirty }
    } = useFormContext();

    React.useEffect(() => {
        setValue('isDirty', isDirty);
    }, [setValue, isDirty]);

    return (
        <Section noDefaultPadding>
            <div
                style={{
                    width: '100%',
                    textAlign: 'left',
                    marginBottom: '3.6rem',
                    marginTop: '0.4rem'
                }}
            >
                <P noDefaultMargin strong size="large" styles={{ marginBottom: '1.2rem' }}>
                    Contact
                </P>
                <P noDefaultMargin>Patient address</P>
            </div>
            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <Input disabled={disabled} name="email" displayName="Patient email" />
                <Input disabled={disabled} name="phone" displayName="Phone number" />
            </Container>
            <AddressCombobox
                addressName={addressName}
                cityName={cityName}
                stateName={stateName}
                zipName={zipName}
                disabled={disabled}
            />
            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <Input
                    disabled={disabled}
                    name={addressName2}
                    displayName="Apt, suite, etc (optional)"
                />
                <Input disabled={disabled} name={cityName} displayName="City" />
            </Container>

            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <div style={{ width: '100%' }}>
                    <Combobox
                        name={stateName}
                        displayName="State"
                        suggestions={usStateNames}
                        noExtraMargin
                        onlyValidateWithError={true}
                        disabled={disabled}
                    />
                </div>
                <Input name={zipName} displayName="Zip" disabled={disabled} />
            </Container>
        </Section>
    );
};

export default ContactForm;
