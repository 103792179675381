import { useQuery } from '@tanstack/react-query';

import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { ReportsDataResponse, ReportsQueryParams } from '../types';
import { REPORTS_LIST_KEY } from './constants';

const useGetReportsList = (query?: ReportsQueryParams) => {
    const req = useRequest();

    const request = () => {
        return req<ReportsDataResponse>({
            url: `${envVars.API_RTM_HOSTNAME}billing/device-activity-report/list/`,
            query
        });
    };
    return useQuery({
        queryFn: request,
        queryKey: [REPORTS_LIST_KEY, query]
    });
};

export default useGetReportsList;
