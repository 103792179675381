import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import P from '@hero/ui-kit/typography/P';
import * as Style from './style.module.scss';

const LeadsFilter: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const setQueryFilterType = (type: string) => {
        setSearchParams((searchParams) => {
            searchParams.set('filter_type', type);

            searchParams.delete('offset');

            return searchParams;
        });
    };

    const tabs = React.useMemo(() => {
        const filterType = searchParams.get('filter_type');

        return [
            {
                value: 'default',
                label: 'All Leads',
                isActive: filterType === 'default' || !filterType
            },
            {
                value: 'hot_leads',
                label: 'Hot Leads',
                isActive: filterType === 'hot_leads'
            },
            {
                value: 'follow_ups_with_patient',
                label: 'Followup with lead',
                isActive: filterType === 'follow_ups_with_patient'
            },
            {
                value: 'follow_ups_not_with_patient',
                label: 'Other followup',
                isActive: filterType === 'follow_ups_not_with_patient'
            },
            {
                value: 'expiring_leads',
                label: 'Expiring',
                isActive: filterType === 'expiring_leads'
            }
        ];
    }, [searchParams]);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4.8rem',
                minHeight: '4rem',
                marginBottom: '3.6rem'
            }}
        >
            {tabs.map((tab) => (
                <ClickableOpacity
                    key={tab.value}
                    alt={`Set ${tab.value} filter`}
                    onClick={() => setQueryFilterType(tab.value)}
                >
                    <P
                        noDefaultMargin
                        strong
                        className={`${Style.btn} ${tab.isActive ? Style.active : ''}`}
                    >
                        {tab.label}
                    </P>
                </ClickableOpacity>
            ))}
        </div>
    );
};

export default LeadsFilter;
