import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetNotification } from '../../../context/Notification';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { UserCreateResponse } from '../types';
import { USERS_LIST_KEY } from './constants';
import { getInvitedUsers } from '../UserList/InviteForm';

type CreateUserInviteParams = {
    role_id: string;
    invitees: {
        email: string;
    }[];
};

const useCreateUserInvite = () => {
    const req = useRequest();
    const setNotification = useSetNotification();
    const queryClient = useQueryClient();

    return useMutation<UserCreateResponse, unknown, CreateUserInviteParams>({
        mutationFn: (body) => {
            return req<UserCreateResponse>({
                url: `${envVars.API_ID_HOSTNAME}api/enterprise/invite/`,
                method: 'POST',
                body
            });
        },
        onSuccess: (data) => {
            const { notCreatedUsers } = getInvitedUsers(data);

            notCreatedUsers.length === 0 &&
                setNotification({ message: 'Invitation sent', icon: 'checkmark' });
            queryClient.invalidateQueries({ queryKey: [USERS_LIST_KEY] });
        }
    });
};

export default useCreateUserInvite;
