import { StatusInfo } from '../types';

const getDischargeStatus = (statusInfo?: StatusInfo | null) => {
    if (statusInfo?.status === 'CANCELLED') {
        return 'FINALIZED';
    }

    return 'PROCESSING';
};

export default getDischargeStatus;
