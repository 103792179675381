import { useEffect } from 'react';

type UseMouseDownEventProps = (handleMouseDown: (event: MouseEvent) => void) => void;

const useMouseDownEvent: UseMouseDownEventProps = (handleMouseDown) => {
    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, [handleMouseDown]);
};

export default useMouseDownEvent;
