import getQueryParams from '@hero/hero-utils/getQueryParams';
import buildQuery from '@hero/hero-utils/buildQueryParams';

import useSetQueryParams from './useSetQueryParams';

type Props = { limit: number; offset: number };
export type HandleSetPagerClickQuery = (props: Props) => () => void;
type UsePagerClickQuery = () => HandleSetPagerClickQuery;

const usePagerClickQuery: UsePagerClickQuery = () => {
    const queryParams = getQueryParams<Props>();
    const setQueryParams = useSetQueryParams();

    const handleSetPageLimit: HandleSetPagerClickQuery =
        ({ limit, offset }) =>
        () => {
            const newQueryParams = buildQuery({ ...queryParams, offset, limit });

            setQueryParams(newQueryParams);
        };

    return handleSetPageLimit;
};

export default usePagerClickQuery;
