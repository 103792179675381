import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LeadQueryParams } from './types';

const getDates = (param: string, dates: string[]) => {
    return {
        [`${param}_from`]: dates[0],
        [`${param}_to`]: dates[1]
    };
};

const useQueryParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return useMemo(() => {
        const isMember = searchParams.get('is_member');
        const state = searchParams.get('state');

        return {
            hasFilters: [
                'search',
                'state',
                'updated',
                'created',
                'is_member',
                'state',
                'approval_status',
                'approval_sub_status'
            ].some((key) => searchParams.has(key)),
            query: {
                limit: searchParams.get('limit') || 10,
                filter_type: searchParams.get('filter_type') || 'default',
                sort_by: 'created_at',
                offset: searchParams.get('offset') || 0,
                sort_dir: searchParams.get('sort_dir') || 'DESC',
                ...(searchParams.get('updated') && {
                    ...getDates('updated', searchParams.get('updated')!.split('|'))
                }),
                ...(searchParams.get('created') && {
                    ...getDates('created', searchParams.get('created')!.split('|'))
                }),
                ...(state && {
                    state: state.split(',')
                }),
                ...(searchParams.get('search') && {
                    search: searchParams.get('search')
                }),
                ...(isMember && {
                    is_member: +isMember
                })
            } as LeadQueryParams,
            setSearchParams
        };
    }, [searchParams]);
};

export default useQueryParams;
