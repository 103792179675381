import Divider from '@hero/ui-kit/components/Divider';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import P from '@hero/ui-kit/typography/P';
import * as React from 'react';
import ControlIcon from '../../../../icons/ControlIcon';
import Circle from '@hero/ui-kit/graphics/Circle';
import * as Style from './style.module.scss';
import CaretIcon from '@hero/ui-kit/icons/utility/CaretIcon';

interface DischargeReason {
    value: number;
    label: string;
}

interface DischargeReasonPickerProps {
    label: string;
    reasons: DischargeReason[];
    onSelect: (reasonId: number) => void;
    selectedReason?: number;
    onToggleReasons?: () => void;
    showReasons?: boolean;
}

const DischargeReasonPicker: React.FC<DischargeReasonPickerProps> = ({
    label,
    reasons,
    onSelect,
    selectedReason,
    onToggleReasons,
    showReasons = false
}) => {
    return (
        <div className={Style.main}>
            <ClickableOpacity
                alt={`Toggle ${label}`}
                onClick={() => onToggleReasons && onToggleReasons()}
            >
                <div className={Style.header}>
                    <P noDefaultMargin strong size="large">
                        {label}
                    </P>
                    <CaretIcon size="utility" direction={showReasons ? 'up' : 'down'} />
                </div>
            </ClickableOpacity>

            {showReasons ? (
                <div>
                    <Divider />
                    <div className={Style.reasonsWrapper}>
                        {reasons?.map((reason) => (
                            <ClickableOpacity
                                fullWidth
                                key={reason.value}
                                alt={`Toggle ${reason.label}`}
                                className={`${Style.reasonItem}`}
                                onClick={() => onSelect(reason.value)}
                            >
                                <div className={Style.reasonItemInner}>
                                    {reason.value === selectedReason ? (
                                        <ControlIcon
                                            type="brand"
                                            styles={{ minWidth: '24px' }}
                                            width={'24px'}
                                        />
                                    ) : (
                                        <Circle
                                            colorOverride="#777777"
                                            inverted
                                            styles={{ minWidth: '24px' }}
                                            width={'24px'}
                                        />
                                    )}
                                    <div style={{ textAlign: 'left' }}>
                                        <P strong noDefaultMargin>
                                            {reason.label}
                                        </P>
                                    </div>
                                </div>
                            </ClickableOpacity>
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default DischargeReasonPicker;
