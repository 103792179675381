// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PvMlwr7dgId4nGcRAMq_{display:flex;flex-direction:row}.d2o5jF6rKpj6KAlx0pJG{display:flex;align-items:center;justify-content:center;min-height:6rem;flex-direction:column}.d2o5jF6rKpj6KAlx0pJG.PHo5cLbzNpXxdZ_pK8Hx>*{color:var(--color-primary-alpha)}.d2o5jF6rKpj6KAlx0pJG div{color:var(--color-neutrals-textGamma)}.d2o5jF6rKpj6KAlx0pJG>:first-of-type{font-size:1.4rem;line-height:2rem}.d2o5jF6rKpj6KAlx0pJG>:last-of-type{margin-top:1.2rem;font-size:2.4rem;line-height:3.2rem}.hHhC9ttbe2ob_QtFWroq{flex:1;display:flex;flex-direction:column;position:relative}.hHhC9ttbe2ob_QtFWroq>div{width:100%}.o8S8yKOufQEOiOCqGQpS{min-height:20rem;display:flex;justify-content:center;align-items:center}.o8S8yKOufQEOiOCqGQpS+.o8S8yKOufQEOiOCqGQpS{display:none}.wAFMtvcz67LbgEY85X7Z{position:relative;display:flex;flex-direction:column;width:100%;flex:10}.PxC6OdyxyTe8KTdS06Cu{flex:1;border-right:2px solid var(--color-neutrals-borderBeta);display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./../hero-frontend-packages/packages/enterprise-portal-common/src/Adherence/AdherenceCalendar/common.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CAEJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,qBAAA,CACA,6CACI,gCAAA,CAGJ,0BACI,qCAAA,CAEJ,qCACI,gBAAA,CACA,gBAAA,CAEJ,oCACI,iBAAA,CACA,gBAAA,CACA,kBAAA,CAGR,sBACI,MAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,0BACI,UAAA,CAGR,sBACI,gBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,4CACI,YAAA,CAGR,sBACI,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,OAAA,CAEJ,sBACI,MAAA,CACA,uDAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
export var root = `PvMlwr7dgId4nGcRAMq_`;
export var heading = `d2o5jF6rKpj6KAlx0pJG`;
export var active = `PHo5cLbzNpXxdZ_pK8Hx`;
export var columnByDay = `hHhC9ttbe2ob_QtFWroq`;
export var loader = `o8S8yKOufQEOiOCqGQpS`;
export var dosesDow = `wAFMtvcz67LbgEY85X7Z`;
export var timeLabel = `PxC6OdyxyTe8KTdS06Cu`;
export default ___CSS_LOADER_EXPORT___;
