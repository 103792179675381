// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QlGuS_UJZWfygoQIvLUA{margin-top:calc(var(--gutter)/2)}.QlGuS_UJZWfygoQIvLUA>*{flex:auto;padding-left:0 !important;margin-top:0 !important;margin-right:var(--gutter);background:rgba(0,0,0,0) !important}.Uj80C4KmvPTZuEWPEwie label{font-weight:var(--typo-fontweight-strong) !important}.Uj80C4KmvPTZuEWPEwie svg{width:3rem !important;min-width:3rem !important;height:3rem}.kNBDnUh25vup1MtYA6Ib{width:16.5rem}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/Details/v2/components/LeadUpdateForm/InsuranceForm/style.module.scss"],"names":[],"mappings":"AACA,sBACI,gCAAA,CACA,wBACI,SAAA,CACA,yBAAA,CACA,uBAAA,CACA,0BAAA,CACA,mCAAA,CAKJ,4BACI,oDAAA,CAGJ,0BACI,qBAAA,CACA,yBAAA,CACA,WAAA,CAIR,sBACI,aAAA","sourceRoot":""}]);
// Exports
export var radioGroup = `QlGuS_UJZWfygoQIvLUA`;
export var radio = `Uj80C4KmvPTZuEWPEwie`;
export var saveBtn = `kNBDnUh25vup1MtYA6Ib`;
export default ___CSS_LOADER_EXPORT___;
