import React from 'react';

import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';

import Error from '../../../components/States/Error';
import NoResults from '../../../components/States/NoResults';
import Table from '../../../components/Table';
import ListItem from './ListItem';
import { useUserStatusContext } from '../../../context/UserStatus';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import MembersSparklingIllustration from '@hero/ui-kit/graphics/Illustrations/MembersSparklingIllustration';
import LimitItems from '../../../components/LimitItems';
import { OrganizationMemberListPaginated } from '../types';
import SearchForm from '../../../components/SearchForm';
import useListQueryParams from '../OrganizationMemberList/useListQueryParams';

const DischargedMemberList: React.FC<{
    isLoading: boolean;
    isError: boolean;
    data?: OrganizationMemberListPaginated;
}> = ({ isLoading, isError, data }) => {
    const isMinL = useIsMinBreakpoint('l');
    const { permissions } = useUserStatusContext();

    const { query } = useListQueryParams();
    const { limit, offset } = query;
    const search = query?.search || '';
    const hasFilters = !!query.device_status || !!query.is_online;

    React.useEffect(() => {
        return () => {
            localStorage.removeItem('locationFrom');
        };
    }, []);

    if (isError) {
        return <Error />;
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '3rem'
                }}
            >
                <LimitItems
                    limit={limit ? +limit : 10}
                    offset={offset ? +offset : 0}
                    total_count={data?.total_count}
                    isLoading={isLoading}
                />
                <SearchForm
                    removeQueryParamsOnSearch={[
                        'limit',
                        'offset',
                        'online_status',
                        'device_status'
                    ]}
                    showSearch
                    customSearchWidth="28rem"
                    disableCloseSearchForm
                    placeholder="Search"
                />
            </div>

            {data?.list.length === 0 && search.length === 0 && !hasFilters ? (
                <Section centered>
                    <H role="h6" styles={{ fontSize: '24px' }}>
                        No discharged members
                    </H>
                    <MembersSparklingIllustration
                        styles={{ height: '20rem', width: '64rem', marginTop: '5rem' }}
                    />
                </Section>
            ) : null}

            {data?.list.length === 0 && search.length ? <NoResults search={search} /> : null}

            {data?.list.length ? (
                <Table
                    pagerData={data}
                    headerColumns={[
                        'Member',
                        'Discharge Date',
                        'Reason',
                        'Status',
                        'Finalized Date'
                    ]}
                    headerGridTemplateColumns={isMinL ? '3.5fr 2fr 3fr 2fr 1.5fr' : '1fr'}
                    loading={isLoading}
                    gridColumnGap="small"
                >
                    {data?.list.map((item, index) => (
                        <ListItem
                            statusInfo={item.status_info}
                            memberId={item.member_id}
                            heroUserId={item.hero_user_id}
                            key={`${index}-${item.hero_user_id || item.member_id}-${
                                item.email || 'n/a'
                            }`}
                            name={
                                item.first_name || item.last_name
                                    ? `${item.first_name} ${item.last_name}`
                                    : 'N/A'
                            }
                            email={item.email || undefined}
                            search={search}
                            canOpenDetails={permissions.fleet.fleetMemberView}
                        />
                    ))}
                </Table>
            ) : null}
        </>
    );
};

export default DischargedMemberList;
