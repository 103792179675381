import React from 'react';
import * as Styles from './style.module.scss';

interface IOverlay extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
}

export const Overlay = React.forwardRef<HTMLButtonElement, IOverlay>(
    ({ className, ...rest }, ref) => {
        return (
            <button
                ref={ref}
                type="button"
                {...rest}
                tabIndex={-1}
                className={`${Styles.overlay} ${className}`}
            />
        );
    }
);
