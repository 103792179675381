// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kidwBiMoU0ovBty3hZL9{position:relative;display:inline-block;width:70px;height:40px}.kidwBiMoU0ovBty3hZL9 .kgKD_B5xYHJI_SyKLiov{opacity:0;width:0;height:0}.mLGBxKNxBxQDQ5P93sFQ{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;-webkit-transition:.4s;transition:.4s}.mLGBxKNxBxQDQ5P93sFQ:before{position:absolute;content:"";height:28px;width:28px;left:5px;bottom:5px;background-color:#fff;-webkit-transition:.4s;transition:.4s}.kgKD_B5xYHJI_SyKLiov:checked+.mLGBxKNxBxQDQ5P93sFQ{background-color:var(--color-primary-alpha)}.kgKD_B5xYHJI_SyKLiov:focus+.mLGBxKNxBxQDQ5P93sFQ{box-shadow:0 0 1px var(--color-primary-alpha)}.kgKD_B5xYHJI_SyKLiov:checked+.mLGBxKNxBxQDQ5P93sFQ:before{-webkit-transform:translateX(28px);-ms-transform:translateX(28px);transform:translateX(28px)}.mLGBxKNxBxQDQ5P93sFQ.yMJCXNXLnwwA7QVI4lPe{border-radius:34px}.mLGBxKNxBxQDQ5P93sFQ.yMJCXNXLnwwA7QVI4lPe:before{border-radius:50%}.YNj5FYV03jtseYCYZ5Uc{pointer-events:none;opacity:.5}`, "",{"version":3,"sources":["webpack://./components/ButtonToggler/style.module.scss"],"names":[],"mappings":"AAEA,sBACI,iBAAA,CACA,oBAAA,CACA,UAAA,CACA,WAAA,CAIF,4CACE,SAAA,CACA,OAAA,CACA,QAAA,CAIF,sBACE,iBAAA,CACA,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,qBAAA,CACA,sBAAA,CACA,cAAA,CAGF,6BACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,QAAA,CACA,UAAA,CACA,qBAAA,CACA,sBAAA,CACA,cAAA,CAGF,oDACE,2CAAA,CAGF,kDACE,6CAAA,CAGF,2DACE,kCAAA,CACA,8BAAA,CACA,0BAAA,CAIF,2CACE,kBAAA,CAGF,kDACE,iBAAA,CAGF,sBACE,mBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
var _1 = `kidwBiMoU0ovBty3hZL9`;
export { _1 as "switch" };
export var input = `kgKD_B5xYHJI_SyKLiov`;
export var slider = `mLGBxKNxBxQDQ5P93sFQ`;
export var round = `yMJCXNXLnwwA7QVI4lPe`;
export var disabled = `YNj5FYV03jtseYCYZ5Uc`;
export default ___CSS_LOADER_EXPORT___;
