import { useQuery } from '@tanstack/react-query';
import React from 'react';

import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { LeadInsuranceResponse } from '../types';
import { LEAD_INSURANCE } from './constants';

const useGetLeadInsurance = (lead_id: string) => {
    const req = useRequest();

    const request = React.useCallback(() => {
        return req<LeadInsuranceResponse>({
            url: `${envVars.API_RTM_HOSTNAME}enterprise/insurance/`,
            query: { lead_id }
        });
    }, [lead_id, req]);

    return useQuery({
        queryFn: request,
        queryKey: [LEAD_INSURANCE, lead_id]
    });
};

export default useGetLeadInsurance;
