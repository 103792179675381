import React from 'react';
import { LeadDetailsResponse } from '../types';
import Section from '@hero/ui-kit/layout/Section';
import Pill from '../components/Pill';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import P from '@hero/ui-kit/typography/P';
import Divider from '@hero/ui-kit/components/Divider';
import * as Style from './style.module.scss';
import PlusIcon from '@hero/ui-kit/icons/utility/PlusIcon';
import VerifiedIcon from '@hero/ui-kit/icons/utility/VerifiedIcon';
import CalendarIcon from '@hero/ui-kit/icons/utility/CalendarIcon';
import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import PatientDetailsForm from '../components/PatientDetailsForm';
import ContactPersonForm from '../components/ContactPersonForm';
import ShippingAddressForm from '../components/ShippingAddressForm';
import getInsuranceTypeLabel from '../utils/getInsuranceTypeLabel';
import Button from '@hero/ui-kit/inputs/Button';
import ConsentPDF from './ConsentPDF';
import { getDOBFormat } from '../../../utils/date';
// import AttachmentSection from './AttachmentSection';

interface LeadDetailsProps {
    lead?: LeadDetailsResponse;
    approvalStatus: { label: string; className: string };
    refetchLeadDetails?: () => void;
    isLocked?: boolean;
    onEnrollBtn?: () => void;
    hideEdit?: boolean;
    hideNameSection?: boolean;
}

const getLabel = (label?: string | null) => {
    return label || '-';
};

const LeadContactDetails: React.FC<LeadDetailsProps> = ({
    lead,
    approvalStatus,
    refetchLeadDetails,
    isLocked = false,
    hideEdit = false,
    hideNameSection = false,
    onEnrollBtn
}) => {
    const [showPatientDetailsModal, setShowPatientDetailsModal] = React.useState(false);
    const [showContactDetailsModal, setShowContactDetailsModal] = React.useState(false);
    const [showShippingAddressModal, setShowShippingAddressModal] = React.useState(false);
    const disableEdit =
        (lead?.status?.toUpperCase() === 'APPROVED' &&
            lead?.sub_status?.toUpperCase() === 'ENROLLED') ||
        isLocked ||
        lead?.status?.toUpperCase() === 'REJECTED';

    const handleOpenPatientDetailsForm = () => {
        setShowPatientDetailsModal(true);
    };
    const handleClosePatientDetailsForm = () => {
        setShowPatientDetailsModal(false);
    };

    const handleOpenContactDetailsForm = () => {
        setShowContactDetailsModal(true);
    };
    const handleCloseContactDetailsForm = () => {
        setShowContactDetailsModal(false);
    };

    const handleOpenShippingAddressForm = () => {
        setShowShippingAddressModal(true);
    };
    const handleCloseShippingAddressForm = () => {
        setShowShippingAddressModal(false);
    };

    const handleShippingAddressSuccess = () => {
        handleCloseShippingAddressForm();
        refetchLeadDetails && refetchLeadDetails();
    };

    const handleContactDetailsSuccess = () => {
        handleCloseContactDetailsForm();
        refetchLeadDetails && refetchLeadDetails();
    };

    const handlePatientDetailsSuccess = () => {
        handleClosePatientDetailsForm();
        refetchLeadDetails && refetchLeadDetails();
    };

    const showEdit = !disableEdit && !hideEdit;

    return (
        <>
            <Section border noDefaultPadding styles={{ minWidth: '45rem' }}>
                {!hideNameSection ? (
                    <Section paddingVertical="small" paddingHorizontal="regular">
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Pill
                                label={approvalStatus.label}
                                className={approvalStatus.className}
                            />
                            <Button
                                onClick={onEnrollBtn}
                                size="small"
                                styles={{ visibility: 'hidden' }} // TODO enrollment
                            >
                                Enroll
                            </Button>
                        </div>
                        <Section
                            paddingHorizontal="none"
                            styles={{ paddingTop: '1.6rem', paddingBottom: '1.2rem' }}
                        >
                            <P strong size="large" noDefaultMargin styles={{ fontSize: '2.4rem' }}>
                                {lead?.primary_user_details?.first_name &&
                                lead?.primary_user_details?.last_name
                                    ? `${lead.primary_user_details.first_name} ${lead.primary_user_details.last_name}`
                                    : `-`}
                            </P>
                        </Section>

                        <P
                            noDefaultMargin
                            styles={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                marginBottom: '2.4rem'
                            }}
                            title={getLabel(lead?.primary_user_details?.lead_id)}
                        >{`Lead ID: ${getLabel(lead?.primary_user_details?.lead_id)}`}</P>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                columnGap: '1rem',
                                marginBottom: lead?.is_existing_member ? '1.2rem' : 0
                            }}
                        >
                            <CalendarIcon type="utility" width={20} height={20} />
                            <P noDefaultMargin size="small">
                                {getLabel(lead?.primary_user_details?.created_date)}
                            </P>
                        </div>

                        {lead?.is_existing_member ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    columnGap: '1rem'
                                }}
                            >
                                <VerifiedIcon type="utility" width={20} height={20} />
                                <div>
                                    <P noDefaultMargin size="small" strong>
                                        Using Hero dispenser
                                    </P>
                                    {lead?.is_forbidden_rtm_election ? (
                                        <P
                                            noDefaultMargin
                                            size="small"
                                            styles={{ color: '#e83f3f', fontWeight: 400 }}
                                        >
                                            Already enrolled with another provider!
                                        </P>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </Section>
                ) : null}
                <Divider noDefaultMargin />
                <Section paddingVertical="small" paddingHorizontal="regular">
                    <div className={Style.sectionHeader}>
                        <P strong noDefaultMargin size="large">
                            Patient details
                        </P>
                        {showEdit ? (
                            <ClickableOpacity
                                onClick={handleOpenPatientDetailsForm}
                                alt="Open patient details form"
                                className={Style.editBtn}
                            >
                                <P strong noDefaultMargin className={Style.editText}>
                                    Edit
                                </P>
                            </ClickableOpacity>
                        ) : null}
                    </div>

                    <P noDefaultMargin size="small" className={Style.label}>
                        DOB
                    </P>
                    <P noDefaultMargin styles={{ marginBottom: '1.2rem' }}>
                        {getDOBFormat(lead?.primary_user_details?.date_of_birth)}
                    </P>
                    <P noDefaultMargin size="small" className={Style.label}>
                        Zip
                    </P>
                    <P noDefaultMargin styles={{ marginBottom: '1.2rem' }}>
                        {getLabel(lead?.primary_user_details.zip)}
                    </P>
                    <P noDefaultMargin size="small" className={Style.label}>
                        Email
                    </P>
                    <P noDefaultMargin styles={{ marginBottom: '1.2rem' }}>
                        {getLabel(lead?.primary_user_details?.email)}
                    </P>
                    <P noDefaultMargin size="small" className={Style.label}>
                        Phone number
                    </P>
                    <P noDefaultMargin styles={{ marginBottom: '1.2rem' }}>
                        {getLabel(lead?.primary_user_details?.phone)}
                    </P>
                    <P noDefaultMargin size="small" className={Style.label}>
                        Insurance type
                    </P>
                    <P noDefaultMargin styles={{ marginBottom: '1.2rem' }}>
                        {getInsuranceTypeLabel(lead?.primary_user_details?.insurance_type)}
                    </P>
                    {lead?.primary_user_details?.insurance_type?.toUpperCase() !==
                    'ORIGINAL_MEDICARE' ? (
                        <>
                            <P noDefaultMargin size="small" className={Style.label}>
                                Insurance company
                            </P>
                            <P noDefaultMargin styles={{ marginBottom: '1.2rem' }}>
                                {getLabel(lead?.primary_user_details?.insurance_company)}
                            </P>
                        </>
                    ) : null}

                    <P noDefaultMargin size="small" className={Style.label}>
                        Member ID
                    </P>
                    <P noDefaultMargin>{getLabel(lead?.primary_user_details.insurance_id)}</P>
                </Section>

                {lead?.contact_details?.email ? (
                    <>
                        <Divider noDefaultMargin />
                        <Section paddingVertical="small" paddingHorizontal="regular">
                            <div className={Style.sectionHeader}>
                                <P strong noDefaultMargin size="large">
                                    Contact person
                                </P>
                                {showEdit ? (
                                    <ClickableOpacity
                                        onClick={handleOpenContactDetailsForm}
                                        alt="Open contact details form"
                                        className={Style.editBtn}
                                    >
                                        <P strong noDefaultMargin className={Style.editText}>
                                            Edit
                                        </P>
                                    </ClickableOpacity>
                                ) : null}
                            </div>
                            <P noDefaultMargin size="small" className={Style.label}>
                                Name
                            </P>
                            <P noDefaultMargin styles={{ marginBottom: '1.2rem' }}>
                                {lead?.contact_details?.first_name && lead.contact_details.last_name
                                    ? `${lead?.contact_details.first_name} ${lead?.contact_details?.last_name}`
                                    : '-'}
                            </P>
                            <P noDefaultMargin size="small" className={Style.label}>
                                Email
                            </P>
                            <P
                                styles={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    marginBottom: '1.2rem'
                                }}
                                title={getLabel(lead?.contact_details?.email)}
                            >
                                {getLabel(lead?.contact_details?.email)}
                            </P>
                            <P noDefaultMargin size="small" className={Style.label}>
                                Phone
                            </P>
                            <P noDefaultMargin>{getLabel(lead?.contact_details?.phone)}</P>
                        </Section>
                    </>
                ) : (
                    <div
                        className={Style.addWrapper}
                        style={{ ...(!showEdit && { display: 'none' }) }}
                    >
                        <Divider noDefaultMargin />
                        <ClickableOpacity
                            onClick={handleOpenContactDetailsForm}
                            alt="Open contact details form"
                            className={Style.addFooter}
                            disabled={!showEdit}
                        >
                            <PlusIcon size="utility" width={20} height={20} type="brand" />
                            <P strong noDefaultMargin>
                                Add contact person
                            </P>
                        </ClickableOpacity>
                    </div>
                )}
                {lead?.address_details?.street_address ? (
                    <>
                        <Divider noDefaultMargin />
                        <Section paddingVertical="small" paddingHorizontal="regular">
                            <div className={Style.sectionHeader}>
                                <P strong noDefaultMargin size="large">
                                    Shipping address
                                </P>
                                {showEdit ? (
                                    <ClickableOpacity
                                        onClick={handleOpenShippingAddressForm}
                                        alt="Open shipping address form"
                                        className={Style.editBtn}
                                    >
                                        <P strong noDefaultMargin className={Style.editText}>
                                            Edit
                                        </P>
                                    </ClickableOpacity>
                                ) : null}
                            </div>
                            <P noDefaultMargin size="small" className={Style.label}>
                                Street address (no p.o. boxes)
                            </P>
                            <P noDefaultMargin styles={{ marginBottom: '1.2rem' }}>
                                {getLabel(lead?.address_details?.street_address)}
                            </P>
                            <P noDefaultMargin size="small" className={Style.label}>
                                Apt, Suite, Etc
                            </P>
                            <P
                                styles={{
                                    marginBottom: '1.2rem'
                                }}
                            >
                                {getLabel(lead?.address_details?.apartment)}
                            </P>
                            <P noDefaultMargin size="small" className={Style.label}>
                                City
                            </P>
                            <P
                                styles={{
                                    marginBottom: '1.2rem'
                                }}
                            >
                                {getLabel(lead?.address_details?.city)}
                            </P>
                            <P noDefaultMargin size="small" className={Style.label}>
                                State
                            </P>
                            <P
                                styles={{
                                    marginBottom: '1.2rem'
                                }}
                            >
                                {getLabel(lead?.address_details?.state)}
                            </P>
                            <P noDefaultMargin size="small" className={Style.label}>
                                Zip
                            </P>
                            <P noDefaultMargin>{getLabel(lead?.address_details?.shipping_zip)}</P>
                        </Section>
                    </>
                ) : (
                    <div
                        className={Style.addWrapper}
                        style={{ ...(!showEdit && { display: 'none' }) }}
                    >
                        <Divider noDefaultMargin />
                        <ClickableOpacity
                            onClick={handleOpenShippingAddressForm}
                            alt="Open shipping address form"
                            className={Style.addFooter}
                            disabled={!showEdit}
                        >
                            <PlusIcon size="utility" width={20} height={20} type="brand" />
                            <P strong noDefaultMargin>
                                Add shipping information
                            </P>
                        </ClickableOpacity>
                    </div>
                )}

                {lead?.primary_user_details.signed_consent_uploaded ? (
                    <>
                        <Divider noDefaultMargin />
                        <Section paddingVertical="small" paddingHorizontal="regular">
                            <P size="large" strong>
                                Attachments
                            </P>
                            <ConsentPDF lead={lead} />
                        </Section>
                    </>
                ) : null}
            </Section>
            <Modal
                externalControls={[showPatientDetailsModal, setShowPatientDetailsModal]}
                isCancelable
                onClose={handleClosePatientDetailsForm}
                styles={{ padding: '0', width: '75rem' }}
            >
                <Section noDefaultPadding styles={{ width: '100%' }}>
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'left',
                            marginTop: '0.4rem',
                            marginBottom: '3.6rem'
                        }}
                    >
                        <H role="h5" noDefaultMargin styles={{ fontSize: '24px' }}>
                            {`${lead?.primary_user_details.first_name} ${lead?.primary_user_details.last_name}`}
                        </H>
                        <P style={{ textAlign: 'left' }} noDefaultMargin>
                            Lead ID: {lead?.primary_user_details?.lead_id} &#x2022; Created:{''}
                            {lead?.primary_user_details.created_date}
                        </P>
                    </div>
                    <PatientDetailsForm lead={lead} onSuccess={handlePatientDetailsSuccess} />
                </Section>
            </Modal>
            <Modal
                externalControls={[showContactDetailsModal, setShowContactDetailsModal]}
                isCancelable
                onClose={handleCloseContactDetailsForm}
                styles={{ padding: '0', width: '75rem' }}
            >
                <Section noDefaultPadding styles={{ width: '100%' }}>
                    <ContactPersonForm lead={lead} onSuccess={handleContactDetailsSuccess} />
                </Section>
            </Modal>
            <Modal
                externalControls={[showShippingAddressModal, setShowShippingAddressModal]}
                isCancelable
                onClose={handleCloseShippingAddressForm}
                styles={{ padding: '0', width: '75rem' }}
            >
                <Section noDefaultPadding styles={{ width: '100%' }}>
                    <ShippingAddressForm lead={lead} onSuccess={handleShippingAddressSuccess} />
                </Section>
            </Modal>
        </>
    );
};

export default LeadContactDetails;
