import Modal from '@hero/ui-kit/components/Modal';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import { useUserStatusContext } from '../../../context/UserStatus';
import { UserInvite } from '../types';
import * as Style from './style.module.scss';
import { IAMListRole } from '../../IAM/types';
import UserDetails from './UserDetails';

const UserItem: React.FC<{ user: UserInvite; roles?: IAMListRole[] }> = ({ user, roles }) => {
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const { permissions } = useUserStatusContext();

    const userName = React.useMemo(() => {
        if (user?.first_name && user?.last_name) {
            return `${user.first_name} ${user.last_name}`;
        }
        return undefined;
    }, [user]);

    const smallMarginLeft = useComponentMargin({ marginLeft: 'small' });

    const handleOpenEditModal = React.useCallback(() => {
        permissions.iam.inviteUserView && user.status !== 'DISABLED' && setOpenEditModal(true);
    }, [permissions, user]);

    const handleCloseEditModal = React.useCallback(() => {
        setOpenEditModal(false);
    }, []);

    const status =
        user.status === 'INVITED' && user.expired ? 'INVITE EXPIRED' : user?.status || 'N/A';

    return (
        <Section noDefaultPadding>
            <ClickableOpacity
                fullWidth
                alt={user.status === 'DISABLED' ? 'User status disabled' : 'Open edit modal'}
                onClick={handleOpenEditModal}
                className={Style.rowBtn}
                disabled={user.status === 'DISABLED'}
            >
                <Section padding="small" className={Style.userItem}>
                    <Container gridTemplateColumns={'3fr 1fr 1fr'}>
                        <div className={Style.nameWrapper}>
                            {userName ? (
                                <P noDefaultMargin strong>
                                    {userName}
                                </P>
                            ) : null}
                            <P noDefaultMargin styles={{ ...(userName && { ...smallMarginLeft }) }}>
                                {user?.email || 'N/A'}
                            </P>
                        </div>

                        <P strong noDefaultMargin>
                            {user?.role?.name || 'N/A'}
                        </P>

                        <P noDefaultMargin strong>
                            {status}
                        </P>
                    </Container>
                </Section>
            </ClickableOpacity>

            <Modal
                externalControls={[openEditModal, setOpenEditModal]}
                isCancelable
                styles={{ minWidth: '55rem', padding: 0 }}
            >
                <UserDetails
                    closeModal={handleCloseEditModal}
                    userName={userName || 'N/A'}
                    user={user}
                    roles={roles}
                />
            </Modal>
        </Section>
    );
};

export default UserItem;
