import { WeeklyDoses } from './AdherenceCalendarWeekly/Acw.types';
import { IDose } from './common.types';

const parseTimeLabel = (time: string): string => {
    if (!time) return '';
    if (time.includes('As-Needed')) return time.slice(8);
    let hours = parseInt(time.slice(0, 2));
    let minutes = parseInt(time.slice(3, 5));
    let newHours = hours;
    let stamp = 'am';
    if (hours === 12) {
        stamp = 'pm';
    } else if (hours > 12 && hours < 24) {
        newHours = hours - 12;
        stamp = 'pm';
    } else if (hours === 24) {
        newHours = hours - 12;
    }
    return `${newHours > 9 ? newHours : '0' + newHours}:${
        minutes < 10 ? '0' + minutes : minutes
    } ${stamp}`;
};
const parseDayLabel = (date: string): string => {
    if (!date) return '';
    return new Date(date).toString().slice(4, 10);
};

const weekDaysLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const getMonday = (d: Date) => {
    let day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
};

const getDate = (dateFrom: Date, offset: number) =>
    ((d) => new Date(d.setDate(d.getDate() + offset)))(new Date(dateFrom));

const isSameDay = (date1: Date, date2: Date) =>
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear();

const groupUnscheduledDoses = (data: WeeklyDoses) => {
    let doses = [...data.doses];
    let j = 1;
    let k = data.doses.length;
    while (j < k) {
        if (doses[j].dose_type === 'unscheduled' && doses[j - 1].dose_type === 'unscheduled') {
            doses[j].scheduled_datetime = `${doses[j - 1].scheduled_datetime.split('#')[0]}#${
                doses[j].scheduled_datetime
            }`;
            doses[j].medication_count += doses[j - 1].medication_count;
            delete doses[j - 1];
        }
        j++;
    }
    return doses;
};

const dosesByPeriod = (data: WeeklyDoses[], period: { value: Date }[]) => {
    return data.reduce<Map<string, Map<string, IDose[][]>>>(
        (acc: Map<string, Map<string, IDose[][]>>, each, i) => {
            groupUnscheduledDoses(each).forEach((dose) => {
                const key =
                    dose.dose_type === 'unscheduled'
                        ? `${dose.scheduled_datetime.slice(-8)}As-Needed`
                        : dose.scheduled_datetime.slice(-8);
                if (!acc.has(key)) {
                    acc.set(key, new Map());
                }

                if (dose.schedule) {
                    let outerMap = acc.get(key);
                    if (outerMap) {
                        if (!outerMap.has(dose.schedule.hero_schedule_id)) {
                            outerMap.set(
                                dose.schedule.hero_schedule_id,
                                Array.from(Array(period.length)).map((_) => [])
                            );
                        }
                        let innerMap = outerMap.get(dose.schedule.hero_schedule_id);
                        if (innerMap) {
                            innerMap[i].push(dose);
                        }
                    }
                }
            });
            return acc;
        },
        new Map()
    );
};
const sortDoses = (doses: Map<string, Map<string, IDose[][]>> | undefined) => {
    if (!doses) return new Map<string, Map<string, IDose[][]>>();
    let sortedArr = Array.from(doses.entries()).sort(([x], [y]) => {
        let a = parseInt(x.slice(0, 2) + x.slice(3, 5));
        let b = parseInt(y.slice(0, 2) + y.slice(3, 5));
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    });
    return new Map(sortedArr);
};

const getColorByStatus = (status: IDose['status']) => {
    let style = {
        backgroundColor: 'var(--color-success-beta)',
        color: 'var(--color-neutrals-textAlpha)',
        class: 'black'
    };
    if (['missed', 'skipped'].includes(status)) {
        style.backgroundColor = 'var(--color-fail-alpha)';
        style.color = 'var(--color-neutrals-background)';
        style.class = 'white';
    } else if (['unknown', 'incoming'].includes(status)) {
        style.backgroundColor = 'var(--color-neutrals-borderBeta)';
        if (status === 'incoming') {
            style.color = 'var(--color-neutrals-textGamma)';
        }
    } else if (status === 'time_to_take' || status === 'almost_time') {
        style.backgroundColor = 'var(--color-neutrals-background)';
    } else if (status === 'unscheduled') {
        style.backgroundColor = 'var(--color-secondary-alpha)';
    }
    return style;
};

export {
    parseTimeLabel,
    weekDaysLabels,
    getDate,
    isSameDay,
    dosesByPeriod,
    sortDoses,
    getColorByStatus,
    getMonday,
    parseDayLabel
};
