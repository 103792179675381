import React from 'react';

import * as Style from './style.module.scss';

import { getUtcDateFormat } from '../../../../utils/date';
import { useOrganizationMemberDetailsContext } from '../context/OrganizationMemberDetailsContext';
import P from '@hero/ui-kit/typography/P';
import CalendarIcon from '@hero/ui-kit/icons/utility/CalendarIcon';
import { normalizeDateString } from '@hero/hero-utils/date';

interface UserInfoProps {}

const UserInfo: React.FC<UserInfoProps> = () => {
    const { memberDetails, currentDevice } = useOrganizationMemberDetailsContext();

    const activatedDate = currentDevice?.device_statuses?.find(
        (status) => status.device_status === 'ACTIVATED'
    )?.status_timestamp;

    const hasDevice = currentDevice?.device_status;

    const { member_info } = memberDetails;

    const formattedDate = activatedDate
        ? `${getUtcDateFormat(activatedDate, 'MMM d, yyyy')}`
        : 'Not Activated Yet';

    return (
        <>
            <div className={Style.userInfo}>
                {member_info?.external_user_id ? (
                    <div className={Style.patientId}>
                        <P noDefaultMargin className={Style.heroId}>
                            {`Patient ID: `} <strong>{member_info.external_user_id}</strong>
                        </P>
                    </div>
                ) : null}
                {member_info?.hero_user_id ? (
                    <P noDefaultMargin className={Style.heroId}>
                        {`Hero ID: `} <strong>{member_info?.hero_user_id}</strong>
                    </P>
                ) : null}
                {hasDevice ? (
                    <div className={`${Style.heroId} ${Style.activatedDate}`}>
                        <CalendarIcon
                            size="small"
                            style={{ marginRight: '0.8rem', height: '2.1rem', width: '2.1rem' }}
                        />
                        <div style={{ lineHeight: '2.4rem', fontSize: '1.6rem' }}>
                            {`Activated on: `} <strong>{formattedDate}</strong>
                        </div>
                    </div>
                ) : null}
                {member_info.status_info?.discharged_at ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: '0.8rem'
                        }}
                    >
                        <div style={{ marginLeft: '0.8rem', marginRight: '0.8rem' }}>|</div>
                        <div className={Style.dischargedOnCircle} />
                        <P noDefaultMargin>Discharged on: </P>
                        <P noDefaultMargin strong>
                            {getUtcDateFormat(
                                normalizeDateString(member_info.status_info?.discharged_at)
                            )}
                        </P>
                    </div>
                ) : null}
                {member_info.status_info?.cancelled_at ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: '0.8rem'
                        }}
                    >
                        <div style={{ marginLeft: '0.8rem' }} className={Style.finalizedOnCircle} />
                        <P noDefaultMargin>Discharge finalized on: </P>
                        <P noDefaultMargin strong>
                            {getUtcDateFormat(
                                normalizeDateString(member_info.status_info?.cancelled_at)
                            )}
                        </P>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default UserInfo;
