import React from 'react';
import MemberDetailsWrapper from '../MemberDetailsWrapper';
import Adherence from '@hero/enterprise-portal-common/src/Adherence';
import { useOrganizationMemberDetailsContext } from '../context/OrganizationMemberDetailsContext';
import useGetAdherenceCalendarMonthly from './api/useGetAdherenceCalendarMonthly';
import useGetAdherenceCalendarWeekly from './api/useGetAdherenceCalendarWeekly';
import useGetAdherencePeriods from './api/useGetAdherencePeriods';
import useGetAdherenceSummary from './api/useGetAdherenceSummary';
import useGetSidebarDay from './api/useGetSidebarDay';
import useGetSidebarPeriod from './api/useGetSidebarPeriod';
import Section from '@hero/ui-kit/layout/Section';

const AdherenceWrapper: React.FC = () => {
    const { memberDetails } = useOrganizationMemberDetailsContext();
    const heroUserId = memberDetails?.member_info?.hero_user_id || 0;

    const fetchAdherenceCalendarMonthly = useGetAdherenceCalendarMonthly(heroUserId);
    const fetchAdherenceCalendarWeekly = useGetAdherenceCalendarWeekly(heroUserId);
    const fetchAdherencePeriods = useGetAdherencePeriods(heroUserId);
    const fetchAdherenceSummary = useGetAdherenceSummary(heroUserId);
    const fetchSidebarDay = useGetSidebarDay(heroUserId);
    const fetchSidebarPeriod = useGetSidebarPeriod(heroUserId);

    if (heroUserId === 0) {
        return <div>No hero user id</div>;
    }

    return (
        <Adherence
            fetchAdherenceCalendarMonthly={fetchAdherenceCalendarMonthly}
            fetchAdherenceCalendarWeekly={fetchAdherenceCalendarWeekly}
            fetchAdherencePeriods={fetchAdherencePeriods}
            fetchAdherenceSummary={fetchAdherenceSummary}
            fetchSidebarDay={fetchSidebarDay}
            fetchSidebarPeriod={fetchSidebarPeriod}
        />
    );
};

const AdherenceModule: React.FC = () => {
    return (
        <MemberDetailsWrapper>
            <Section noDefaultPadding paddingHorizontal="regular">
                <AdherenceWrapper />
            </Section>
        </MemberDetailsWrapper>
    );
};
export default AdherenceModule;
