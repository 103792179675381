import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { PROVIDER_DETAILS } from './constants';
import useRequest from '../hooks/useRequest';
import envVars from '../constants/envVars';

type IProviderProperties = {
    hero_org_id: string | null;
    provider_name: string | null;
    provider_app_logo_light: string | null;
    provider_app_logo_dark: string | null;
    provider_logo: string | null;
};

export type GetProviderDetailsParams = {
    hero_organization_id: string;
};

const useGetProviderDetails = (query?: GetProviderDetailsParams) => {
    const req = useRequest();

    const action = useCallback(() => {
        return req<IProviderProperties>({
            url: `${envVars.API_RTM_HOSTNAME}api/enterprise/provider/`,
            query
        });
    }, [req, query]);

    return useQuery({
        queryFn: action,
        queryKey: [PROVIDER_DETAILS, query]
    });
};

export default useGetProviderDetails;
