import * as yup from 'yup';

const activityScreenRecorderFormSchema = yup
    .object()
    .shape({
        note: yup.string().trim().required('Note is required.')
    })
    .defined();

export type ActivityScreenRecorderFormSchemaParams = yup.InferType<
    typeof activityScreenRecorderFormSchema
>;

export default activityScreenRecorderFormSchema;
