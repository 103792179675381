import React from 'react';
import { Helmet } from 'react-helmet';

import useTheme from '@hero/branding/theme';

import './global.scss';

const Theme = React.memo(() => {
    const theme = useTheme();

    const colors = {
        ...theme.colors.brand,
        ...theme.colors.status,
        neutrals: { ...theme.colors.neutrals }
    };

    return (
        <Helmet>
            <style type="text/css">{`
				:root {
					--gutter: ${theme.sizing.component.margin.regular};
					--border-radius: ${theme.borders.shape.rounded.borderRadius};
					--border-radius-large: ${theme.borders.shape.veryRounded.borderRadius};

					--typo-fontweight-strong: ${theme.typography.strong.fontWeight};
					--typo-fontsize-regular: ${theme.typography.paragraph.regular.fontSize};
					--typo-fontsize-large: ${theme.typography.paragraph.large.fontSize};
					--typo-fontsize-small: ${theme.typography.paragraph.small.fontSize};
					--typo-fontsize-mini: 1.2rem;

					--typo-h1-fontsize: ${theme.typography.heading.h1.fontSize};
					--typo-h2-fontsize: ${theme.typography.heading.h2.fontSize};
					--typo-h3-fontsize: ${theme.typography.heading.h3.fontSize};
					--typo-h4-fontsize: ${theme.typography.heading.h4.fontSize};
					--typo-h5-fontsize: ${theme.typography.heading.h5.fontSize};
					--typo-h6-fontsize: ${theme.typography.heading.h6.fontSize};

                    --lead-status-yellow: #ffea7a;
                    --lead-status-gray: #393939;
                    --lead-status-blue: #c9e2ff;
                    --lead-status-tangerine: #ffe3d0;
                    --lead-status-success: #c7fab5;
                    --lead-status-red: #ffcece;
                    --lead-status-purple: #edcfff;
                    --lead-status-grey: #777777;

					--color-dark-overlay: rgba(67, 67, 67, 0.8);

					${Object.entries(colors)
                        .map((set) => {
                            return Object.entries(set[1])
                                .map((color) => {
                                    return `--color-${set[0]}-${color[0]}: ${color[1]};`;
                                })
                                .join('');
                        })
                        .join('')};
				}
			`}</style>
        </Helmet>
    );
});

export default Theme;
