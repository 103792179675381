import React from 'react';
import Notification from './components/Notification';
import AppRoutes from './routes';
import Style from './style';
import ActivityScreenRecorder from './pages/FleetManagement/OrganizationMemberDetails/PatientActivity/components/ActivityScreenRecorder';
import WsWrapper from './ws/wrapper';

const App: React.FC = () => {
    return (
        <>
            <Style />
            <WsWrapper />
            <ActivityScreenRecorder />
            <AppRoutes />
            <Notification />
        </>
    );
};

export default App;
