// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UBBBO8CBaHoSIbBBEUrz{cursor:pointer;font-size:1.6rem;font-weight:700;padding:.4rem 1.2rem .4rem 1.2rem;border-radius:1.8rem;border-style:solid;border-width:.2rem;width:fit-content}.tcsmzXlJ7pLdmwD7WlP3{border-color:var(--color-primary-delta);background-color:var(--color-primary-delta)}.tcsmzXlJ7pLdmwD7WlP3:hover{border-color:var(--color-primary-gamma);background-color:var(--color-primary-gamma)}.wdzl4d1c6wQkZhhKysHb{border-color:var(--color-neutrals-borderBeta);background-color:rgba(0,0,0,0)}.wdzl4d1c6wQkZhhKysHb:hover{background-color:var(--color-secondary-beta)}`, "",{"version":3,"sources":["webpack://./components/PillLink/style.module.scss"],"names":[],"mappings":"AACA,sBACI,cAAA,CACA,gBAAA,CACA,eAAA,CACA,iCAAA,CACA,oBAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,sBACI,uCAAA,CACA,2CAAA,CACA,4BACI,uCAAA,CACA,2CAAA,CAIR,sBACI,6CAAA,CACA,8BAAA,CACA,4BACI,4CAAA","sourceRoot":""}]);
// Exports
export var pill = `UBBBO8CBaHoSIbBBEUrz`;
export var primary = `tcsmzXlJ7pLdmwD7WlP3`;
export var secondary = `wdzl4d1c6wQkZhhKysHb`;
export default ___CSS_LOADER_EXPORT___;
