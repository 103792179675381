import useTheme from '@hero/branding/theme';
import { IconComponentProps } from '@hero/ui-kit/types';
import React from 'react';

const CloseIcon: React.FC<IconComponentProps> = ({
    type = 'utility',
    size = 'regular',
    disabled = false,
    styles,
    color = '#D11A1F',
    ...rest
}) => {
    const { sizing } = useTheme();
    const sizePx = sizing.widths.icon[size];

    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            width={sizePx}
            height={sizePx}
            style={styles}
            {...rest}
        >
            <rect width="24" height="24" fill={color} rx="12" />
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M7.228 15.673a.777.777 0 1 0 1.1 1.1L12 13.1l3.673 3.672a.777.777 0 1 0 1.1-1.1L13.098 12l3.673-3.673a.777.777 0 1 0-1.1-1.1L12 10.9 8.327 7.228a.777.777 0 0 0-1.1 1.1l.929.927L10.9 12 7.65 15.251l-.422.422ZM12 12.457l-3.995 3.994L12 12.457Zm-4.255-5c.093-.01.189.02.26.092l.272.272L12 11.543 8.277 7.82l-.271-.27a.322.322 0 0 0-.261-.094Zm8.706 8.994a.321.321 0 0 1 0-.457L12.455 12l3.994-3.994L12.457 12l3.995 3.994a.323.323 0 0 1 0 .457Z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default CloseIcon;
