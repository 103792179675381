import {
    AllApprovalStatusSubStates,
    ApprovalStatus,
    QualifiedSubStates,
    InitialInterestSubStates,
    MedicalCheckSubStates,
    InsuranceValidationSubStates,
    RejectedSubStates,
    ApprovedSubStates,
    ExpiredSubStates
} from '../types';

const INITIAL_INTEREST = {
    PENDING_CALL: {
        label: 'Pending call',
        stageLabel: 'Pending call',
        className: ''
    },
    SCHEDULED_CALL: {
        label: 'Scheduled call',
        stageLabel: 'Schedule call',
        className: ''
    },
    CALL_ATTEMPTED: {
        label: 'Call attempted',
        stageLabel: 'Call attempted',
        className: ''
    },
    WRONG_NUMBER: {
        label: 'Wrong number',
        stageLabel: 'Wrong number',
        className: ''
    }
};

const INSURANCE_VALIDATION = {
    NEED_TO_VALIDATE: {
        label: 'Need to validate',
        stageLabel: 'Need to validate',
        className: ''
    },
    VALIDATING: {
        label: 'Validating',
        stageLabel: 'Review in progress',
        className: ''
    }
};

const QUALIFIED = {
    CONSENT_NEEDED: {
        label: 'Consent needed',
        stageLabel: 'Consent needed',
        className: ''
    },
    SCHEDULED_CALL: {
        label: 'Scheduled call',
        stageLabel: 'Schedule call',
        className: ''
    },
    CALL_ATTEMPTED: {
        label: 'Call attempted',
        stageLabel: 'Call attempted',
        className: ''
    }
};

const MEDICAL_CHECK = {
    IMPENDING_APPOINTMENT: {
        label: 'Impending appointment',
        stageLabel: 'Schedule medical check',
        className: ''
    },
    MISSED_APPOINTMENT: {
        label: 'Missed appointment',
        stageLabel: 'Missed appointment',
        className: ''
    },
    CALL_ATTEMPTED: {
        label: 'Call attempted',
        stageLabel: 'Call attempted',
        className: ''
    }
};

const APPROVED = {
    ENROLLED: {
        label: 'Enrolled',
        stageLabel: 'Enroll',
        className: ''
    },
    ENROLMENT_NEEDED: {
        label: 'Enrollment needed',
        stageLabel: 'Enrollment needed',
        className: ''
    }
};

const REJECTED = {
    NO_INSURANCE: {
        label: 'No insurance',
        stageLabel: 'No insurance',
        className: ''
    },
    DUPLICATE: {
        label: 'Duplicate',
        stageLabel: 'Duplicate',
        className: ''
    },
    STALE: {
        label: 'Stale',
        stageLabel: 'Stale',
        className: ''
    },
    NOT_INTERESTED: {
        label: 'Not interested',
        stageLabel: 'Not interested',
        className: ''
    },
    NO_NECESSITY: {
        label: 'No necessity',
        stageLabel: 'No necessity',
        className: ''
    }
};

const EXPIRED = {
    EXPIRED: {
        label: '',
        stageLabel: '',
        className: ''
    }
};

const NOT_FOUND = {
    label: 'Not Found',
    stageLabel: 'Not Found',
    className: ''
};

const getApprovalSubStatus = (subStatus?: AllApprovalStatusSubStates, status?: ApprovalStatus) => {
    const statuses = {
        INITIAL_INTEREST:
            subStatus && INITIAL_INTEREST[subStatus as InitialInterestSubStates]
                ? INITIAL_INTEREST[subStatus as InitialInterestSubStates]
                : NOT_FOUND,
        INSURANCE_VALIDATION:
            subStatus && INSURANCE_VALIDATION[subStatus as InsuranceValidationSubStates]
                ? INSURANCE_VALIDATION[subStatus as InsuranceValidationSubStates]
                : NOT_FOUND,
        QUALIFIED:
            subStatus && QUALIFIED[subStatus as QualifiedSubStates]
                ? QUALIFIED[subStatus as QualifiedSubStates]
                : NOT_FOUND,
        MEDICAL_CHECK:
            subStatus && MEDICAL_CHECK[subStatus as MedicalCheckSubStates]
                ? MEDICAL_CHECK[subStatus as MedicalCheckSubStates]
                : NOT_FOUND,
        APPROVED:
            subStatus && APPROVED[subStatus as ApprovedSubStates]
                ? APPROVED[subStatus as ApprovedSubStates]
                : NOT_FOUND,
        REJECTED:
            subStatus && REJECTED[subStatus as RejectedSubStates]
                ? REJECTED[subStatus as RejectedSubStates]
                : NOT_FOUND,
        EXPIRED:
            subStatus && EXPIRED[subStatus as ExpiredSubStates]
                ? EXPIRED[subStatus as ExpiredSubStates]
                : NOT_FOUND
    };

    if (!status && subStatus) {
        const merged = Object.assign(
            {},
            REJECTED,
            MEDICAL_CHECK,
            INSURANCE_VALIDATION,
            QUALIFIED,
            APPROVED,
            INITIAL_INTEREST
        );

        const find = merged[subStatus] as
            | {
                  label: string;
                  stageLabel: string;
                  className: string;
              }
            | undefined;

        return find || NOT_FOUND;
    }

    return status && statuses[status] ? statuses[status] : NOT_FOUND;
};

export default getApprovalSubStatus;
