import { add, endOfMonth, endOfWeek, format, isSameMonth, isSameWeek, sub } from 'date-fns';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CaretIcon from '../../../../ui-kit/icons/utility/CaretIcon';
import ClickableOpacity from '../../../../ui-kit/inputs/ClickableOpacity';

import {
    ADHERENCE_BEGINNING_OF_TIME,
    DATE_FORMAT,
    PARAM_DATE,
    PARAM_TIMEFRAME
} from '../constants/searchParams';
import * as Style from './style.module.scss';
import { Timeframes } from './Timeframe';

const DateSelect: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const date = searchParams.get(PARAM_DATE);
    const timeframe = searchParams.get(PARAM_TIMEFRAME) as Timeframes;

    const updateSearchParams = React.useCallback(
        (date: string) => {
            setSearchParams((params) => {
                params.set(PARAM_DATE, date);
                return params;
            });
        },
        [setSearchParams]
    );

    const dateLabel = React.useMemo(() => {
        if (!date) {
            return '';
        }

        if (searchParams.get(PARAM_TIMEFRAME) === 'monthly') {
            return format(new Date(date), 'MMM y');
        }

        return `${format(sub(new Date(`${date}T00:00:00`), { days: 6 }), 'MMM d')} - ${format(
            new Date(`${date}T00:00:00`),
            'MMM d'
        )}`;
    }, [date, searchParams]);

    const isToday = React.useMemo(() => {
        if (!date) {
            return false;
        }

        return timeframe === 'monthly'
            ? isSameMonth(new Date(date), new Date())
            : isSameWeek(new Date(date), new Date(), { weekStartsOn: 1 });
    }, [date, timeframe]);

    const isBeginningOfTime = React.useMemo(() => {
        if (!date) {
            return false;
        }

        return timeframe === 'monthly'
            ? isSameMonth(new Date(date), new Date(ADHERENCE_BEGINNING_OF_TIME))
            : isSameWeek(new Date(date), new Date(ADHERENCE_BEGINNING_OF_TIME), {
                  weekStartsOn: 1
              });
    }, [date, timeframe]);

    const moveDate = React.useCallback(
        (dir: 'prev' | 'next') => {
            if (!date) {
                return;
            }

            const diff = dir === 'prev' ? -1 : 1;

            const newDate = add(
                new Date(date),
                timeframe === 'monthly' ? { months: diff } : { weeks: diff }
            );

            updateSearchParams(
                format(
                    timeframe === 'monthly'
                        ? endOfMonth(newDate)
                        : endOfWeek(newDate, { weekStartsOn: 1 }),
                    DATE_FORMAT
                )
            );
        },
        [date, timeframe, updateSearchParams]
    );

    return (
        <div className={Style.dateElement}>
            <ClickableOpacity
                fullWidth
                alt={`Move date back in history`}
                onClick={() => {
                    moveDate('prev');
                }}
                disabled={isBeginningOfTime}
            >
                <CaretIcon direction="backward" size="utility" />
            </ClickableOpacity>

            <strong>{dateLabel}</strong>

            <ClickableOpacity
                fullWidth
                alt={`Move date forward in history`}
                onClick={() => {
                    moveDate('next');
                }}
                disabled={isToday}
            >
                <CaretIcon direction="forward" size="utility" />
            </ClickableOpacity>
        </div>
    );
};
export default DateSelect;
