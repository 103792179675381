// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes njgp1H57WzT_AYp6ZHyi{0%{opacity:0;transform:translateX(10rem)}100%{opacity:1}}@keyframes FuFiIBBmhTW6JFRy3RhT{0%{opacity:1}100%{opacity:0;transform:translateX(10rem)}}.kMVSWL2g01n7ciwAnL3N{position:fixed;width:36rem;height:100vh;top:0;right:2rem;background:var(--color-neutrals-background);border-left:1px solid var(--color-neutrals-borderAlpha);box-shadow:-15px 0px 15px 0px rgba(67,67,67,.1);animation:FuFiIBBmhTW6JFRy3RhT ease-in-out .3s 1 forwards;transition:right .25s cubic-bezier(0.4, 0, 0.3, 1) 0s}.kMVSWL2g01n7ciwAnL3N:last-child{right:0}.QoiAd2bvRu7ZVwsMijEx{animation:njgp1H57WzT_AYp6ZHyi ease-in-out .3s 1 forwards}.HsODt8hdPdSZvqGps90X{position:absolute;top:3rem;right:2.4rem;cursor:pointer;z-index:10}.XHXUsJ1iO8Hm5ysYxBHG{position:absolute;right:0;top:0;width:100%;height:100%;padding:2.4rem 1.2rem;overflow:auto}`, "",{"version":3,"sources":["webpack://./components/SideBar/style.module.scss","webpack://./style/vars-and-mixins.scss"],"names":[],"mappings":"AAUA,gCACI,GALA,SAAA,CACA,2BAAA,CAOA,KAZA,SAAA,CAAA,CAiBJ,gCACI,GAlBA,SAAA,CAqBA,KAjBA,SAAA,CACA,2BAAA,CAAA,CAqBJ,sBACI,cAAA,CACA,WAAA,CACA,YAAA,CACA,KAAA,CACA,UAAA,CACA,2CAAA,CACA,uDAAA,CACA,+CAAA,CACA,yDAAA,CCiEA,qDAAA,CD9DA,iCACI,OAAA,CAIR,sBACI,yDAAA,CAGJ,sBACI,iBAAA,CACA,QAAA,CACA,YAAA,CACA,cAAA,CACA,UAAA,CAGJ,sBACI,iBAAA,CACA,OAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
export var sidebar = `kMVSWL2g01n7ciwAnL3N`;
export var fadeOut = `FuFiIBBmhTW6JFRy3RhT`;
export var isVisible = `QoiAd2bvRu7ZVwsMijEx`;
export var fadeIn = `njgp1H57WzT_AYp6ZHyi`;
export var closeIcon = `HsODt8hdPdSZvqGps90X`;
export var content = `XHXUsJ1iO8Hm5ysYxBHG`;
export default ___CSS_LOADER_EXPORT___;
