import React from 'react';
import Filter from '../../../../components/Filter';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import CheckboxSquare from '@hero/ui-kit/graphics/CheckboxSquare';
import { useSearchParams } from 'react-router-dom';
import * as Style from './style.module.scss';
import P from '@hero/ui-kit/typography/P';
import { IAMListRole } from '../../../IAM/types';

const RoleFilter: React.FC<{ roles?: IAMListRole[] }> = ({ roles }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const searchParam = 'role';
    const selectedRoles = searchParams.get(searchParam);

    const updateStatusFilter = React.useCallback(
        (role: string) => {
            setSearchParams((searchParams) => {
                const param = selectedRoles?.split(',') || [];

                const index = param?.indexOf(role);

                if (index < 0) {
                    param.push(role);
                } else {
                    param.splice(index, 1);
                }

                const paramString = param.filter((i) => !!i).join(',');

                if (paramString) {
                    searchParams.set(searchParam, paramString);
                } else {
                    searchParams.delete(searchParam);
                }

                searchParams.delete('offset');

                return searchParams;
            });
        },
        [setSearchParams, searchParam, selectedRoles]
    );

    const filterLabel = React.useMemo(() => {
        const mainLabel = 'Role:';

        if (selectedRoles) {
            const statusesArray = selectedRoles.split(',');

            const names = statusesArray
                .map((s) => {
                    const status = roles?.find((item) => item.id === s);

                    return status?.name;
                })
                .join(', ');

            return `${mainLabel} ${names}`;
        }

        return `${mainLabel} All`;
    }, [selectedRoles]);

    return (
        <Filter
            hasFilters={typeof selectedRoles === 'string'}
            filterLabel={filterLabel}
            width="28rem"
        >
            <div className={`${Style.filterWrapper}`}>
                {roles?.map((item) => (
                    <ClickableOpacity
                        key={item.id}
                        fullWidth
                        alt={`Toggle ${item.name}`}
                        className={Style.filterStatusItem}
                        onClick={() => {
                            updateStatusFilter(item.id);
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                            <CheckboxSquare
                                isChecked={selectedRoles?.includes(item.id) || false}
                                isFocused={false}
                                isHovered={false}
                                disabled={false}
                                styles={{
                                    minWidth: '1.6rem',
                                    height: '1.6rem',
                                    width: '1.6rem'
                                }}
                            />

                            <P noDefaultMargin>{item.name}</P>
                        </div>
                    </ClickableOpacity>
                ))}
            </div>
        </Filter>
    );
};

export default RoleFilter;
