// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prjefvohOP9CYkbNwTLf{display:flex;align-items:center;justify-content:space-between;margin:1.6rem 0;padding:0 1.6rem !important;outline:none !important}.i4PO9z1McnjDud8PKpUV{max-height:62vh;overflow-y:auto}.TlqBOAo72SG8YK34nv8w{margin-left:2rem}.E5IG_vdOtVYnGhjRhuJU{border-bottom:1px solid !important;margin:1.6rem 1.6rem 0 1.6rem}._yXb_94zNrSy0sWTZKP9{border:none;cursor:pointer;background-color:rgba(0,0,0,0);padding:6px 24px 6px 10px;border-radius:100px;display:flex;align-items:center;column-gap:.8rem}._yXb_94zNrSy0sWTZKP9 div{text-overflow:ellipsis;overflow:hidden;white-space:nowrap;line-height:2.4rem;max-width:27rem;font-weight:var(--typo-fontweight-strong) !important;font-size:16px}._yXb_94zNrSy0sWTZKP9:hover{background-color:var(--color-neutrals-borderBeta) !important}.VE1u_KUdbAyVKeXIkYuM{border-radius:2rem;border:1px solid var(--color-primary-alpha) !important;background-color:rgba(242,108,58,.3019607843) !important}`, "",{"version":3,"sources":["webpack://./components/LocationFilter/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA,CACA,2BAAA,CACA,uBAAA,CAGJ,sBACI,eAAA,CACA,eAAA,CAGJ,sBACI,gBAAA,CAGJ,sBACI,kCAAA,CACA,6BAAA,CAGJ,sBACI,WAAA,CACA,cAAA,CACA,8BAAA,CACA,yBAAA,CACA,mBAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CAEA,0BACI,sBAAA,CACA,eAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,oDAAA,CACA,cAAA,CAGJ,4BACI,4DAAA,CAIR,sBACI,kBAAA,CACA,sDAAA,CACA,wDAAA","sourceRoot":""}]);
// Exports
export var filterStatusItem = `prjefvohOP9CYkbNwTLf`;
export var filterWrapper = `i4PO9z1McnjDud8PKpUV`;
export var filterBtnLabel = `TlqBOAo72SG8YK34nv8w`;
export var filterClearBtn = `E5IG_vdOtVYnGhjRhuJU`;
export var filterButton = `_yXb_94zNrSy0sWTZKP9`;
export var activeFilter = `VE1u_KUdbAyVKeXIkYuM`;
export default ___CSS_LOADER_EXPORT___;
