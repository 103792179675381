import DeviceIcon from '@hero/ui-kit/icons/branding/DeviceIcon';
import AlertIcon from '@hero/ui-kit/icons/utility/AlertIcon';
import React from 'react';
import DotSignifier from '../../../../components/DotSignifier';
import { DeviceStatus as DeviceStatusEnum } from '../../types';

interface DeviceStatusProps {
    isDeviceSetupNeeded?: boolean;
    isDeviceOnline?: boolean;
    deviceStatus?: DeviceStatusEnum | null;
}

const DeviceStatus: React.FC<DeviceStatusProps> = ({
    isDeviceSetupNeeded = false,
    isDeviceOnline = false,
    deviceStatus
}) => {
    const deviceSignifier = React.useMemo(() => {
        if (!['ACTIVATED', 'CONFIGURED', 'SETUP_NEEDED'].includes(deviceStatus || '')) {
            return null;
        }

        return isDeviceSetupNeeded || !isDeviceOnline ? (
            <AlertIcon
                styles={{ position: 'absolute', right: 0, bottom: 0 }}
                width={'2rem'}
                height={'1.6rem'}
            />
        ) : (
            <DotSignifier
                styles={{ position: 'absolute', right: 0, bottom: 0 }}
                width={'1.6rem'}
                height={'1.6rem'}
                type={'success'}
            />
        );
    }, [isDeviceSetupNeeded, isDeviceOnline, deviceStatus]);

    return (
        <div
            style={{
                background: '#F7F7F7',
                height: '6rem',
                width: '6rem',
                borderRadius: '50%',
                position: 'relative'
            }}
        >
            {deviceSignifier}
            <DeviceIcon
                width={15}
                height={28}
                styles={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
                type={'utility'}
            />
        </div>
    );
};

export default DeviceStatus;
