import { InferType, lazy, object, string, StringSchema } from 'yup';

import email, { validEmailRegex } from '@hero/validators/email';
import emailDomain from '@hero/validators/email-domain';
import state from '@hero/validators/state';
import { phoneYupValidation } from '../../../../../utils/validator';

const createMemberFormSchema = object()
    .shape({
        first_name: string().trim().required('First name is required.'),
        last_name: string().trim().required('Last name is required.'),
        role: lazy((val?: string) =>
            !val
                ? string().required('This field is required')
                : string().oneOf(['primary_user', 'caregiver'], 'Invalid application submit')
        ) as unknown as StringSchema<'primary_user' | 'caregiver', object>,
        external_user_id: string().trim(),
        email: lazy((val?: string) => {
            if (!val) {
                return string().trim().required('Email address is required');
            } else if (!validEmailRegex.test(`${val}`)) {
                return email;
            } else {
                return emailDomain;
            }
        }) as unknown as StringSchema<string, object>,
        address_line_1: string()
            .trim()
            .required('Street address is required')
            .test(
                'no-po-boxes',
                'P.O. Boxes are not allowed',
                (val) => !(val && /po\s*box/i.test(val))
            ),
        address_line_2: string().trim(),
        city: string().trim().required('City is required'),
        state: lazy((val) =>
            !val ? string().trim().required('State is required') : state
        ) as unknown as StringSchema<string, object>,
        zip_code: string()
            .trim()
            .required('Zip code is required')
            .matches(/^\d{5}$/, 'Invalid zip code'),
        phone: phoneYupValidation,
        caregiver_spoken: string().when('role', {
            is: (role?: string) => role === 'caregiver',
            then: (schema) => schema.oneOf(['yes', 'no']),
            otherwise: (schema) => schema.notRequired()
        })
    })
    .defined();

export type CreateMemberFormSchema = InferType<typeof createMemberFormSchema>;

export { createMemberFormSchema };
