import * as yup from 'yup'

const getSearchSchema = (name: string) =>
    yup
        .object()
        .shape({
            [name]: yup.string().defined()
        })
        .defined();

export default getSearchSchema;
