import React from 'react';
import DroppedIceCreamIllustration from '@hero/ui-kit/graphics/Illustrations/DroppedIceCreamIllustration';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';

interface Props {
    errorMessage?: string;
}

const Error: React.FC<Props> = ({
    errorMessage = 'Oops, something went wrong. Please try again'
}) => {
    return (
        <Section centered styles={{ marginTop: '5rem' }}>
            <P strong noDefaultMargin styles={{ fontSize: '2.4rem', paddingBottom: '4.8rem' }}>
                {errorMessage}
            </P>
            <DroppedIceCreamIllustration styles={{ height: '20rem', width: '64rem' }} />
        </Section>
    );
};

export default Error;
