import * as yup from 'yup';

const agentFormSchema = yup
    .object()
    .shape({
        note: yup.string().trim().required('Note is required.')
    })
    .defined();

export type AgentFormSchemaParams = yup.InferType<typeof agentFormSchema>;

export default agentFormSchema;
