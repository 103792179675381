// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rP_ArX_gsbclfLDmhOPr{max-width:64%;margin:0 auto;margin-bottom:4.2rem}.aN8DPC889W1LRoEZhi9k{display:flex;align-items:center;justify-content:space-between}.OIIDrfFmmWbJVlZQHDJ4{padding:36px 36px 30px 36px;border-radius:20px;margin:0 auto;max-width:64%;margin-bottom:4.8rem}.DTZpdTtB8683mv4VWUiA{margin:0 auto;max-width:64%;margin-top:4.8rem}.Td0x0ao7GPBvdAiADHMV{box-shadow:0px 8px 16px 0px rgba(148,72,43,.24)}.LYRoR57NPg_E94vHqxyM{box-shadow:0px 8px 16px 0px rgba(148,72,43,.24)}.qgvh7mxY0CD6XzU9wkrQ{box-shadow:0px 8px 16px 0px rgba(148,72,43,.24)}.ReNYUEG5XNbV325vbNKg{box-shadow:0px 8px 16px 0px rgba(148,72,43,.24)}.THG4AGygmIM1_reUWM1c{box-shadow:0px 8px 16px 0px rgba(148,72,43,.24)}.GC7buGb1VL43bYjWI1HZ{display:flex;flex-direction:column;row-gap:2rem}.HQvIBThKhgm0KAF0TIBC{display:flex;flex-direction:column;row-gap:1rem;float:left}.qZjUmY6wH5UUaaYUWwaJ{height:4.4rem;min-width:20rem;text-align:left;font-size:1.6rem;font-weight:700;line-height:2.4rem;padding:10px 16px !important}.qZjUmY6wH5UUaaYUWwaJ:hover{background:#ffede1 !important;border-radius:8px}._guJN9AwDTE8Cw7NU3Hz{background:#ffede1 !important;border-radius:8px}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/Demographics/style.module.scss"],"names":[],"mappings":"AACA,sBACI,aAAA,CACA,aAAA,CACA,oBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CAGJ,sBACI,2BAAA,CACA,kBAAA,CACA,aAAA,CACA,aAAA,CACA,oBAAA,CAGJ,sBACI,aAAA,CACA,aAAA,CACA,iBAAA,CAGJ,sBACI,+CAAA,CAGJ,sBACI,+CAAA,CAGJ,sBACI,+CAAA,CAGJ,sBACI,+CAAA,CAGJ,sBACI,+CAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,YAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,YAAA,CACA,UAAA,CAGJ,sBACI,aAAA,CACA,eAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CACA,kBAAA,CACA,4BAAA,CAEA,4BACI,6BAAA,CACA,iBAAA,CAIR,sBACI,6BAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var headerSection = `rP_ArX_gsbclfLDmhOPr`;
export var headerInner = `aN8DPC889W1LRoEZhi9k`;
export var section = `OIIDrfFmmWbJVlZQHDJ4`;
export var btnSection = `DTZpdTtB8683mv4VWUiA`;
export var patientSection = `Td0x0ao7GPBvdAiADHMV`;
export var insuranceSection = `LYRoR57NPg_E94vHqxyM`;
export var contactSection = `qgvh7mxY0CD6XzU9wkrQ`;
export var shippingSection = `ReNYUEG5XNbV325vbNKg`;
export var attachmentSection = `THG4AGygmIM1_reUWM1c`;
export var sectionWrapper = `GC7buGb1VL43bYjWI1HZ`;
export var leftMenuWrapper = `HQvIBThKhgm0KAF0TIBC`;
export var leftMenuItem = `qZjUmY6wH5UUaaYUWwaJ`;
export var leftMenuActive = `_guJN9AwDTE8Cw7NU3Hz`;
export default ___CSS_LOADER_EXPORT___;
