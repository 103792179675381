import { InferType, lazy, object, string, StringSchema, boolean } from 'yup';

import email, { validEmailRegex } from '@hero/validators/email';
import emailDomain from '@hero/validators/email-domain';
import state from '@hero/validators/state';
import { phoneYupValidation } from '../../../../../utils/validator';

const emailSchema = lazy((val?: string) => {
    if (!val) {
        return string().trim().required('Email is required');
    } else if (!validEmailRegex.test(`${val}`)) {
        return email;
    } else {
        return emailDomain;
    }
}) as unknown as StringSchema<string, object>;

const enrolmentFormSchema = object()
    .shape({
        first_name: string().trim().required('First name is required.'),
        last_name: string().trim().required('Last name is required.'),
        external_user_id: string().trim(),
        is_member: boolean().default(false),
        email: emailSchema,
        admin_email: string()
            .trim()
            .when('is_member', {
                is: (is_member?: boolean) => !!is_member,
                then: () => emailSchema,
                otherwise: (schema) => schema.nullable().notRequired()
            }),
        external_serial: string()
            .trim()
            .when('is_member', {
                is: (is_member?: boolean) => !!is_member,
                then: (schema) => schema.nullable().required('Hero serial number is required'),
                otherwise: (schema) => schema.nullable().notRequired()
            }),
        address_line_1: string().trim().required('Street address is required'),
        address_line_2: string().trim().nullable(),
        city: string().trim().required('City is required'),
        state: lazy((val) =>
            !val ? string().trim().required('State is required') : state
        ) as unknown as StringSchema<string, object>,
        zip_code: string()
            .trim()
            .required('Zip code is required')
            .matches(/^\d{5}$/, 'Invalid zip code'),
        phone: phoneYupValidation
    })
    .defined();

export type EnrolmentFormSchema = InferType<typeof enrolmentFormSchema>;

export { enrolmentFormSchema };
