// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wh_NK3Sa9q9ATTc0sbWw{display:flex;align-items:center;padding:4px 24px 4px 4px;border:2px solid rgba(0,0,0,0)}.OizeTqcVm4HS6uHF9nlv:hover{cursor:pointer;border-radius:100px;border-color:#e7e7e7}.__ndpjjNA2Rj7H0z8kHj{z-index:20;min-width:36rem !important;box-shadow:-2px 0px 24px 0px rgba(0,0,0,.24) !important;border-left:1px solid #fff !important}.hXaAR5ZRpgM1ai3nkIOO{padding:0 1.2rem !important}.t92QM4KCvwagB8rvHfTY{display:flex;flex-direction:column;row-gap:.8rem}.HagO5lehAJe0QrWcr2Hy{display:flex;align-items:center;border:2px solid #e7e7e7;padding:10px 24px 10px 24px;border-radius:20px}.nM0TYO_shOzygFcMWLBg{color:#919191 !important}.q25U3eqttOELhTv6yz0A{color:#74a305 !important}.ge_akS2w7gpSY9kehOww{color:#e83f3f !important}.vIJRx82PL3QmMG7w3Aa3{color:#eb730a !important}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/MemberDetailsWrapper/device_info.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,8BAAA,CAIA,4BACI,cAAA,CACA,mBAAA,CACA,oBAAA,CAIR,sBACI,UAAA,CACA,0BAAA,CACA,uDAAA,CACA,qCAAA,CAGJ,sBACI,2BAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,aAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,2BAAA,CACA,kBAAA,CAGJ,sBACI,wBAAA,CAGJ,sBACI,wBAAA,CAGJ,sBACI,wBAAA,CAGJ,sBACI,wBAAA","sourceRoot":""}]);
// Exports
export var wrapper = `wh_NK3Sa9q9ATTc0sbWw`;
export var hover = `OizeTqcVm4HS6uHF9nlv`;
export var sidebarContent = `__ndpjjNA2Rj7H0z8kHj`;
export var sectionWrapper = `hXaAR5ZRpgM1ai3nkIOO`;
export var deviceLog = `t92QM4KCvwagB8rvHfTY`;
export var deviceLogItem = `HagO5lehAJe0QrWcr2Hy`;
export var deviceStatusLogHeader = `nM0TYO_shOzygFcMWLBg`;
export var online = `q25U3eqttOELhTv6yz0A`;
export var alert = `ge_akS2w7gpSY9kehOww`;
export var warning = `vIJRx82PL3QmMG7w3Aa3`;
export default ___CSS_LOADER_EXPORT___;
