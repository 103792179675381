import React from 'react';
import { useAuthContext } from '@hero/hero-auth/v2/AuthProvider';
import envVars from '../constants/envVars';
import { useUserStatusContext } from './UserStatus';

type IUserPolicy = {
    idle_session_minutes: number | null;
    refresh_token_duration_hours: number | null;
};

type IOrgProperties = {
    has_device_activity_report: boolean;
};

type IUserPolicyContext = {
    isLoading: boolean;
    userPolicy?: IUserPolicy;
    orgProperties?: IOrgProperties;
};

const UserPolicyContext = React.createContext<IUserPolicyContext | undefined>(undefined);

export const useUserPolicyContext = () => {
    const ctx = React.useContext(UserPolicyContext);

    if (ctx === undefined) {
        throw new Error(`'useUserPolicyContext' must be used within a 'UserPolicyContextProvider'`);
    }

    return ctx;
};

const UserPolicyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [userPolicy, setUserPolicy] = React.useState<IUserPolicy>();
    const [orgProperties, setOrgProperties] = React.useState<IOrgProperties>();
    const { userStatus } = useUserStatusContext();
    const [isLoading, setIsLoading] = React.useState(false);
    const { auth } = useAuthContext();

    React.useEffect(() => {
        if (!auth || !userStatus) {
            return;
        }

        setIsLoading(true);

        const headers = { Authorization: `Bearer ${auth.access_token}` };

        const policy = fetch(
            `${envVars.API_ID_HOSTNAME}api/enterprise/policy?hero_organization_id=${userStatus?.hero_organization_id}`,
            {
                headers
            }
        );

        const organizationProperties = fetch(
            `${envVars.API_RTM_HOSTNAME}api/enterprise/organization-properties?hero_organization_id=${userStatus?.hero_organization_id}`,
            {
                headers
            }
        );

        Promise.all([policy, organizationProperties])
            .then(([policyResponse, organizationPropertiesResponse]) => {
                if (!policyResponse.ok || !organizationPropertiesResponse.ok) {
                    throw new Error('Network response was not ok');
                }

                Promise.all([policyResponse.json(), organizationPropertiesResponse.json()])
                    .then(([policyJson, orgPropertiesJson]) => {
                        setUserPolicy(policyJson);
                        setOrgProperties(orgPropertiesJson);
                        setIsLoading(false);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [auth, userStatus]);

    return (
        <UserPolicyContext.Provider
            value={{
                userPolicy,
                orgProperties,
                isLoading
            }}
        >
            {children}
        </UserPolicyContext.Provider>
    );
};

export default UserPolicyProvider;
