import { AllApprovalStatusSubStates, ApprovalStatus } from '../types';

type Props = {
    isLocked: boolean;
    previous_member_id?: number | null;
    approval_status?: ApprovalStatus;
    approval_sub_status?: AllApprovalStatusSubStates | null;
};

const getShouldShowExpiringAlert = (props: Props) => {
    if (
        !props.previous_member_id ||
        props.isLocked ||
        (props.approval_status?.toUpperCase() === 'APPROVED' &&
            props.approval_sub_status?.toUpperCase() === 'ENROLLED') ||
        ['REJECTED', 'EXPIRED'].includes(props.approval_status?.toUpperCase() || '')
    ) {
        return false;
    }
    return true;
};

export default getShouldShowExpiringAlert;
