// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RWRRGFalby3ETgFHnTNZ{display:flex;align-items:center;justify-content:space-between;margin:1.6rem 0;padding:0 1.6rem !important;outline:none !important}.cHMMY9IPIaWVGOb2n2oV{max-height:62vh;overflow-y:auto}.c5CKCfXww57zrwMNAuRs{border-bottom:1px solid !important;margin:1.6rem 1.6rem 0 1.6rem}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/components/StatusFilter/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA,CACA,2BAAA,CACA,uBAAA,CAGJ,sBACI,eAAA,CACA,eAAA,CAGJ,sBACI,kCAAA,CACA,6BAAA","sourceRoot":""}]);
// Exports
export var filterStatusItem = `RWRRGFalby3ETgFHnTNZ`;
export var filterWrapper = `cHMMY9IPIaWVGOb2n2oV`;
export var filterClearBtn = `c5CKCfXww57zrwMNAuRs`;
export default ___CSS_LOADER_EXPORT___;
