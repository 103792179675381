import Button from '@hero/ui-kit/inputs/Button';
import XButton from '@hero/ui-kit/inputs/XButton';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import * as Style from './style.module.scss';
interface UnsavedChangesModalProps {
    isError: boolean;
    isLoading: boolean;
    errorMessage?: string;
    onCancel: () => void;
    onDiscard: () => void;
    showBlockerModal: boolean;
    headerText?: string;
    bodyText?: string;
    cancelBtnLabel?: string;
    saveBtnLabel?: string;
    onSave?: () => void;
}

const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
    isError,
    errorMessage,
    isLoading,
    onCancel,
    onDiscard,
    showBlockerModal,
    headerText = 'Unsaved changes',
    bodyText = "Are you sure you want to leave page since there is unsaved changes? Changes won't be saved.",
    cancelBtnLabel = 'Discard Changes',
    saveBtnLabel = 'Save',
    onSave
}) => {
    return (
        <div className={Style.modal} style={{ display: showBlockerModal ? 'block' : 'none' }}>
            <div className={Style.modalContent}>
                <div className={Style.modalHeader}>
                    <div />
                    <XButton onClick={onCancel} alt="Close modal" />
                </div>
                <P centered strong styles={{ fontSize: '2.4rem' }}>
                    {headerText}
                </P>
                <P centered>{bodyText}</P>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        columnGap: '1rem'
                    }}
                >
                    {onSave ? (
                        <Button
                            variant="primary"
                            type="button"
                            onClick={onSave}
                            disabled={isLoading}
                        >
                            {saveBtnLabel}
                        </Button>
                    ) : (
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {saveBtnLabel}
                        </Button>
                    )}
                    <Button disabled={isLoading} variant="secondary" onClick={onDiscard}>
                        {cancelBtnLabel}
                    </Button>
                </div>
                {isError ? (
                    <P
                        styles={{ color: '#ff7f65', marginTop: '2.4rem' }}
                        strong
                        noDefaultMargin
                        centered
                    >
                        {errorMessage}
                    </P>
                ) : null}
            </div>
        </div>
    );
};

export default UnsavedChangesModal;
