import { MedicationDetails } from '../../../types';

type PillsLevel = 'empty' | 'low' | 'medium' | 'high' | 'estimating' | 'N/A';

const pillLevelLabels = {
    empty: 'empty',
    low: 'low',
    midlow: 'low',
    mid: 'medium',
    high: 'high',
    unknown: 'N/A',
    ok: 'medium',
    estimating: 'estimating'
};

export const estimatedPillsLevel = (pill?: MedicationDetails | null) => {
    if (!pill || (!pill?.stored_in_hero && pill?.pill_level_enum === 'unknown')) {
        return pillLevelLabels['unknown'];
    }

    if (pill?.pill_level_enum === 'unknown' || pill?.pill_level_enum === null) {
        return pillLevelLabels['estimating'];
    }

    return pillLevelLabels[`${pill.pill_level_enum}`];
};

export const exactCountPillLevel = (exactPillCount: number) => {
    if (exactPillCount === 0) {
        return pillLevelLabels['empty'];
    }
    if (exactPillCount > 0 && exactPillCount <= 7) {
        return pillLevelLabels['low'];
    }
    if (exactPillCount > 7 && exactPillCount <= 20) {
        return pillLevelLabels['mid'];
    }
    if (exactPillCount > 20) {
        return pillLevelLabels['high'];
    }

    return pillLevelLabels['unknown'];
};

const getPillLevel = (pill?: MedicationDetails | null) => {
    if (typeof pill?.exact_pill_count === 'number') {
        return exactCountPillLevel(pill.exact_pill_count) as PillsLevel;
    }
    return estimatedPillsLevel(pill) as PillsLevel;
};

export default getPillLevel;
