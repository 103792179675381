import { ApprovalStatus, LeadStatesResponse } from '../types';

const getApprovalStatusesBySubStatus = (
    allLeadStates?: LeadStatesResponse,
    approvalSubStatus?: string,
    exclude?: string[]
) => {
    if (!allLeadStates || !approvalSubStatus) {
        return undefined;
    }

    let statuses: ApprovalStatus[] = [];
    for (const [status, subStatuses] of Object.entries(allLeadStates)) {
        const preparedStatus = status.toUpperCase();
        const preparedSubStatuses = subStatuses.map((status) => status.toUpperCase());
        if (preparedSubStatuses.includes(approvalSubStatus) && !exclude?.includes(preparedStatus)) {
            statuses.push(preparedStatus as ApprovalStatus);
        }
    }

    return statuses;
};

export default getApprovalStatusesBySubStatus;
