import React from 'react';
import { AdherencePeriods, AdherenceSummary } from '../types';
import { Timeframes } from '../Filters/Timeframe';
import { WeeklyDoses } from '../AdherenceCalendar/AdherenceCalendarWeekly/Acw.types';
import { DoseDetails } from '../AdherenceCalendar/Sidebar/SidebarPeriod/SidebarPeriod.types';
import { MonthlyDose } from '../AdherenceCalendar/AdherenceCalendarMonthly/Acm.types';

type GetAdherencePeriodsParams = {
    mode: Timeframes;
    target_datetime: string;
    number_of_periods: number;
};

type GetAdherenceSummaryParams = {
    mode: Timeframes;
    target_datetime: string;
};

type GetSidebarDayParams = {
    target_datetime: string;
};

type GetSidebarPeriodParams = {
    datetime_from: string | null;
    datetime_to: string | null;
};

type GetAdherenceCalendarWeeklyParams = {
    target_datetime: string;
};

type GetAdherenceCalendarMonthlyParams = {
    target_datetime: string;
};

type AdherenceContextType = {
    getAdherencePeriods: (attributes: GetAdherencePeriodsParams) => Promise<AdherencePeriods>;
    getAdherenceSummary: (attributes: GetAdherenceSummaryParams) => Promise<AdherenceSummary>;
    getSidebarDay: (attributes: GetSidebarDayParams) => Promise<WeeklyDoses[]>;
    getSidebarPeriod: (attributes: GetSidebarPeriodParams) => Promise<DoseDetails[]>;
    getAdherenceCalendarWeekly: (
        attributes: GetAdherenceCalendarWeeklyParams
    ) => Promise<WeeklyDoses[]>;
    getAdherenceCalendarMonthly: (
        attributes: GetAdherenceCalendarMonthlyParams
    ) => Promise<MonthlyDose[]>;
};

const AdherenceContext = React.createContext<AdherenceContextType | undefined>(undefined);

export const useAdherenceContext = () => {
    const ctx = React.useContext(AdherenceContext);

    if (ctx === undefined) {
        throw new Error(`'useAdherenceContext' must be used within a 'AdherenceContextProvider'`);
    }

    return ctx;
};

export type AdherenceContextProps = {
    fetchAdherencePeriods: (attributes: GetAdherencePeriodsParams) => Promise<AdherencePeriods>;
    fetchAdherenceSummary: (attributes: GetAdherenceSummaryParams) => Promise<AdherenceSummary>;
    fetchSidebarDay: (attributes: GetSidebarDayParams) => Promise<WeeklyDoses[]>;
    fetchSidebarPeriod: (attributes: GetSidebarPeriodParams) => Promise<DoseDetails[]>;
    fetchAdherenceCalendarWeekly: (
        attributes: GetAdherenceCalendarWeeklyParams
    ) => Promise<WeeklyDoses[]>;
    fetchAdherenceCalendarMonthly: (
        attributes: GetAdherenceCalendarMonthlyParams
    ) => Promise<MonthlyDose[]>;
};

type IAdherenceProvider = {
    children: React.ReactNode;
};

const AdherenceProvider = ({
    children,
    fetchAdherencePeriods,
    fetchAdherenceSummary,
    fetchSidebarDay,
    fetchSidebarPeriod,
    fetchAdherenceCalendarWeekly,
    fetchAdherenceCalendarMonthly
}: IAdherenceProvider & AdherenceContextProps) => {
    const getAdherencePeriods = React.useCallback((attributes: GetAdherencePeriodsParams) => {
        return fetchAdherencePeriods(attributes);
    }, []);

    const getAdherenceSummary = React.useCallback((attributes: GetAdherenceSummaryParams) => {
        return fetchAdherenceSummary(attributes);
    }, []);

    const getSidebarDay = React.useCallback((attributes: GetSidebarDayParams) => {
        return fetchSidebarDay(attributes);
    }, []);

    const getSidebarPeriod = React.useCallback((attributes: GetSidebarPeriodParams) => {
        return fetchSidebarPeriod(attributes);
    }, []);

    const getAdherenceCalendarWeekly = React.useCallback(
        (attributes: GetAdherenceCalendarWeeklyParams) => {
            return fetchAdherenceCalendarWeekly(attributes);
        },
        []
    );

    const getAdherenceCalendarMonthly = React.useCallback(
        (attributes: GetAdherenceCalendarMonthlyParams) => {
            return fetchAdherenceCalendarMonthly(attributes);
        },
        []
    );

    return (
        <AdherenceContext.Provider
            value={{
                getAdherencePeriods,
                getAdherenceSummary,
                getSidebarDay,
                getSidebarPeriod,
                getAdherenceCalendarWeekly,
                getAdherenceCalendarMonthly
            }}
        >
            {children}
        </AdherenceContext.Provider>
    );
};

export default AdherenceProvider;
